export enum SecurityPermissionState {
  Deny = 'Deny',
  Allow = 'Allow',
}

export enum SecurityPermissionPolicy {
  DenyAllByDefault = 'DenyAllByDefault',
  ReadOnlyAllByDefault = 'ReadOnlyAllByDefault',
  AllowAllByDefault = 'AllowAllByDefault',
}

export enum ViewItemVisibility {
  Hide = 'Hide',
  ShowEmptySpace = 'ShowEmptySpace',
  Show = 'Show',
}

export enum ActionsToolbarVisibility {
  Default = 'Default',
  Show = 'Show',
  Hide = 'Hide',
}

export interface DashboardViewItemDescriptor {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ViewId` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ViewId: string
}

export type DashboardViewItemDescriptorId = string | { ViewId: string }

export interface EditableDashboardViewItemDescriptor {}

export interface NonPersistentLiteObject {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Oid` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  Oid: string
}

export type NonPersistentLiteObjectId = string | { Oid: string }

export interface EditableNonPersistentLiteObject {}

export interface DashboardOrganizationItem extends NonPersistentLiteObject {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Visibility` |
   * | Type | `DevExpress.ExpressApp.Editors.ViewItemVisibility` |
   * | Nullable | `false` |
   */
  Visibility: ViewItemVisibility
}

export interface EditableDashboardOrganizationItem extends Pick<DashboardOrganizationItem, 'Visibility'> {}

export interface DashboardOrganizationItem_1OfIModelDashboardViewItem extends DashboardOrganizationItem {}

export interface EditableDashboardOrganizationItem_1OfIModelDashboardViewItem
  extends Pick<DashboardOrganizationItem_1OfIModelDashboardViewItem, 'Visibility'> {}

export interface ViewDashboardOrganizationItem extends DashboardOrganizationItem_1OfIModelDashboardViewItem {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ObjectType` |
   * | Type | `System.Type` |
   */
  ObjectType: Type | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Criteria` |
   * | Type | `Edm.String` |
   */
  Criteria: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ActionsToolbarVisibility` |
   * | Type | `DevExpress.ExpressApp.Templates.ActionsToolbarVisibility` |
   * | Nullable | `false` |
   */
  ActionsToolbarVisibility: ActionsToolbarVisibility
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `AvailableViews` |
   * | Type | `Collection(DevExpress.ExpressApp.SystemModule.DashboardViewItemDescriptor)` |
   */
  AvailableViews?: Array<DashboardViewItemDescriptor>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ViewDescriptor` |
   * | Type | `DevExpress.ExpressApp.SystemModule.DashboardViewItemDescriptor` |
   */
  ViewDescriptor?: DashboardViewItemDescriptor | null
}

export interface EditableViewDashboardOrganizationItem
  extends Pick<ViewDashboardOrganizationItem, 'Visibility' | 'ActionsToolbarVisibility'>,
    Partial<Pick<ViewDashboardOrganizationItem, 'Criteria'>> {
  ObjectType?: EditableType | null
}

export interface DashboardOrganizationItem_1OfIModelStaticText extends DashboardOrganizationItem {}

export interface EditableDashboardOrganizationItem_1OfIModelStaticText
  extends Pick<DashboardOrganizationItem_1OfIModelStaticText, 'Visibility'> {}

export interface StaticTextDashboardOrganizationItem extends DashboardOrganizationItem_1OfIModelStaticText {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Text` |
   * | Type | `Edm.String` |
   */
  Text: string | null
}

export interface EditableStaticTextDashboardOrganizationItem
  extends Pick<StaticTextDashboardOrganizationItem, 'Visibility'>,
    Partial<Pick<StaticTextDashboardOrganizationItem, 'Text'>> {}

export interface DashboardOrganizationItem_1OfIModelStaticImage extends DashboardOrganizationItem {}

export interface EditableDashboardOrganizationItem_1OfIModelStaticImage
  extends Pick<DashboardOrganizationItem_1OfIModelStaticImage, 'Visibility'> {}

export interface StaticImageDashboardOrganizationItem extends DashboardOrganizationItem_1OfIModelStaticImage {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ImageName` |
   * | Type | `Edm.String` |
   */
  ImageName: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `SvgImageWidth` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  SvgImageWidth: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `SvgImageHeight` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  SvgImageHeight: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Preview` |
   * | Type | `Edm.Binary` |
   */
  Preview: string | null
}

export interface EditableStaticImageDashboardOrganizationItem
  extends Pick<StaticImageDashboardOrganizationItem, 'Visibility' | 'SvgImageWidth' | 'SvgImageHeight'>,
    Partial<Pick<StaticImageDashboardOrganizationItem, 'ImageName' | 'Preview'>> {}

export interface DashboardOrganizationItem_1OfIModelActionContainerViewItem extends DashboardOrganizationItem {}

export interface EditableDashboardOrganizationItem_1OfIModelActionContainerViewItem
  extends Pick<DashboardOrganizationItem_1OfIModelActionContainerViewItem, 'Visibility'> {}

export interface ActionContainerDashboardOrganizationItem
  extends DashboardOrganizationItem_1OfIModelActionContainerViewItem {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ActionContainer` |
   * | Type | `DevExpress.ExpressApp.SystemModule.ActionContainerDescriptor` |
   */
  ActionContainer?: ActionContainerDescriptor | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ActionContainers` |
   * | Type | `Collection(DevExpress.ExpressApp.SystemModule.ActionContainerDescriptor)` |
   */
  ActionContainers?: Array<ActionContainerDescriptor>
}

export interface EditableActionContainerDashboardOrganizationItem
  extends Pick<ActionContainerDashboardOrganizationItem, 'Visibility'> {}

export interface ActionContainerDescriptor {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ActionContainerId` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ActionContainerId: string
}

export type ActionContainerDescriptorId = string | { ActionContainerId: string }

export interface EditableActionContainerDescriptor {}

export interface DashboardName {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ID` |
   * | Type | `Edm.Int64` |
   * | Nullable | `false` |
   */
  ID: number
}

export type DashboardNameId = number | { ID: number }

export interface EditableDashboardName {}

export interface NonPersistentBaseObject {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Oid` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  Oid: string
}

export type NonPersistentBaseObjectId = string | { Oid: string }

export interface EditableNonPersistentBaseObject {}

export interface PostponeTime {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ID` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ID: string
}

export type PostponeTimeId = string | { ID: string }

export interface EditablePostponeTime {}

export interface ProceedMember extends NonPersistentBaseObject {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Name` |
   * | Type | `Edm.String` |
   */
  Name: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Caption` |
   * | Type | `Edm.String` |
   */
  Caption: string | null
}

export interface EditableProceedMember extends Partial<Pick<ProceedMember, 'Name' | 'Caption'>> {}

export interface SecuredActionDescriptor {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Id` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  Id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Image` |
   * | Type | `Edm.Binary` |
   */
  Image: string | null
}

export type SecuredActionDescriptorId = string | { Id: string }

export interface EditableSecuredActionDescriptor extends Partial<Pick<SecuredActionDescriptor, 'Image'>> {}

export interface BaseObject {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Oid` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  Oid: string
}

export type BaseObjectId = string | { Oid: string }

export interface EditableBaseObject {}

export interface PermissionPolicyUser extends BaseObject {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ChangePasswordOnFirstLogon` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  ChangePasswordOnFirstLogon: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `UserName` |
   * | Type | `Edm.String` |
   */
  UserName: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `IsActive` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  IsActive: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Roles` |
   * | Type | `Collection(DevExpress.Persistent.BaseImpl.PermissionPolicy.PermissionPolicyRole)` |
   */
  Roles?: Array<PermissionPolicyRole>
}

export interface EditablePermissionPolicyUser
  extends Pick<PermissionPolicyUser, 'ChangePasswordOnFirstLogon' | 'IsActive'>,
    Partial<Pick<PermissionPolicyUser, 'UserName'>> {}

export interface PermissionPolicyRoleBase extends BaseObject {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Name` |
   * | Type | `Edm.String` |
   */
  Name: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `IsAdministrative` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  IsAdministrative: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `CanEditModel` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  CanEditModel: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `PermissionPolicy` |
   * | Type | `DevExpress.Persistent.Base.SecurityPermissionPolicy` |
   * | Nullable | `false` |
   */
  PermissionPolicy: SecurityPermissionPolicy
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `TypePermissions` |
   * | Type | `Collection(DevExpress.Persistent.BaseImpl.PermissionPolicy.PermissionPolicyTypePermissionObject)` |
   */
  TypePermissions?: Array<PermissionPolicyTypePermissionObject>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `NavigationPermissions` |
   * | Type | `Collection(DevExpress.Persistent.BaseImpl.PermissionPolicy.PermissionPolicyNavigationPermissionObject)` |
   */
  NavigationPermissions?: Array<PermissionPolicyNavigationPermissionObject>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ActionPermissions` |
   * | Type | `Collection(DevExpress.Persistent.BaseImpl.PermissionPolicy.PermissionPolicyActionPermissionObject)` |
   */
  ActionPermissions?: Array<PermissionPolicyActionPermissionObject>
}

export interface EditablePermissionPolicyRoleBase
  extends Pick<PermissionPolicyRoleBase, 'IsAdministrative' | 'CanEditModel' | 'PermissionPolicy'>,
    Partial<Pick<PermissionPolicyRoleBase, 'Name'>> {}

export interface PermissionPolicyRole extends PermissionPolicyRoleBase {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Users` |
   * | Type | `Collection(DevExpress.Persistent.BaseImpl.PermissionPolicy.PermissionPolicyUser)` |
   */
  Users?: Array<PermissionPolicyUser>
}

export interface EditablePermissionPolicyRole
  extends Pick<PermissionPolicyRole, 'IsAdministrative' | 'CanEditModel' | 'PermissionPolicy'>,
    Partial<Pick<PermissionPolicyRole, 'Name'>> {}

export interface PermissionPolicyTypePermissionObject extends BaseObject {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `TargetTypeFullName` |
   * | Type | `Edm.String` |
   */
  TargetTypeFullName: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ReadState` |
   * | Type | `DevExpress.Persistent.Base.SecurityPermissionState` |
   */
  ReadState: SecurityPermissionState | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `WriteState` |
   * | Type | `DevExpress.Persistent.Base.SecurityPermissionState` |
   */
  WriteState: SecurityPermissionState | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `CreateState` |
   * | Type | `DevExpress.Persistent.Base.SecurityPermissionState` |
   */
  CreateState: SecurityPermissionState | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `DeleteState` |
   * | Type | `DevExpress.Persistent.Base.SecurityPermissionState` |
   */
  DeleteState: SecurityPermissionState | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `NavigateState` |
   * | Type | `DevExpress.Persistent.Base.SecurityPermissionState` |
   */
  NavigateState: SecurityPermissionState | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Role` |
   * | Type | `DevExpress.Persistent.BaseImpl.PermissionPolicy.PermissionPolicyRoleBase` |
   */
  Role?: PermissionPolicyRoleBase | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `MemberPermissions` |
   * | Type | `Collection(DevExpress.Persistent.BaseImpl.PermissionPolicy.PermissionPolicyMemberPermissionsObject)` |
   */
  MemberPermissions?: Array<PermissionPolicyMemberPermissionsObject>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ObjectPermissions` |
   * | Type | `Collection(DevExpress.Persistent.BaseImpl.PermissionPolicy.PermissionPolicyObjectPermissionsObject)` |
   */
  ObjectPermissions?: Array<PermissionPolicyObjectPermissionsObject>
}

export interface EditablePermissionPolicyTypePermissionObject
  extends Partial<
    Pick<
      PermissionPolicyTypePermissionObject,
      'TargetTypeFullName' | 'ReadState' | 'WriteState' | 'CreateState' | 'DeleteState' | 'NavigateState'
    >
  > {}

export interface PermissionPolicyMemberPermissionsObject extends BaseObject {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Members` |
   * | Type | `Edm.String` |
   */
  Members: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ReadState` |
   * | Type | `DevExpress.Persistent.Base.SecurityPermissionState` |
   */
  ReadState: SecurityPermissionState | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `WriteState` |
   * | Type | `DevExpress.Persistent.Base.SecurityPermissionState` |
   */
  WriteState: SecurityPermissionState | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Criteria` |
   * | Type | `Edm.String` |
   */
  Criteria: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `TypePermissionObject` |
   * | Type | `DevExpress.Persistent.BaseImpl.PermissionPolicy.PermissionPolicyTypePermissionObject` |
   */
  TypePermissionObject?: PermissionPolicyTypePermissionObject | null
}

export interface EditablePermissionPolicyMemberPermissionsObject
  extends Partial<Pick<PermissionPolicyMemberPermissionsObject, 'Members' | 'ReadState' | 'WriteState' | 'Criteria'>> {}

export interface PermissionPolicyObjectPermissionsObject extends BaseObject {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Criteria` |
   * | Type | `Edm.String` |
   */
  Criteria: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ReadState` |
   * | Type | `DevExpress.Persistent.Base.SecurityPermissionState` |
   */
  ReadState: SecurityPermissionState | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `WriteState` |
   * | Type | `DevExpress.Persistent.Base.SecurityPermissionState` |
   */
  WriteState: SecurityPermissionState | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `DeleteState` |
   * | Type | `DevExpress.Persistent.Base.SecurityPermissionState` |
   */
  DeleteState: SecurityPermissionState | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `NavigateState` |
   * | Type | `DevExpress.Persistent.Base.SecurityPermissionState` |
   */
  NavigateState: SecurityPermissionState | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `TypePermissionObject` |
   * | Type | `DevExpress.Persistent.BaseImpl.PermissionPolicy.PermissionPolicyTypePermissionObject` |
   */
  TypePermissionObject?: PermissionPolicyTypePermissionObject | null
}

export interface EditablePermissionPolicyObjectPermissionsObject
  extends Partial<
    Pick<
      PermissionPolicyObjectPermissionsObject,
      'Criteria' | 'ReadState' | 'WriteState' | 'DeleteState' | 'NavigateState'
    >
  > {}

export interface PermissionPolicyNavigationPermissionObject extends BaseObject {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ItemPath` |
   * | Type | `Edm.String` |
   */
  ItemPath: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `NavigateState` |
   * | Type | `DevExpress.Persistent.Base.SecurityPermissionState` |
   */
  NavigateState: SecurityPermissionState | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Role` |
   * | Type | `DevExpress.Persistent.BaseImpl.PermissionPolicy.PermissionPolicyRoleBase` |
   */
  Role?: PermissionPolicyRoleBase | null
}

export interface EditablePermissionPolicyNavigationPermissionObject
  extends Partial<Pick<PermissionPolicyNavigationPermissionObject, 'ItemPath' | 'NavigateState'>> {}

export interface PermissionPolicyActionPermissionObject extends BaseObject {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ActionId` |
   * | Type | `Edm.String` |
   */
  ActionId: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Image` |
   * | Type | `Edm.Binary` |
   */
  Image: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ActionDescriptor` |
   * | Type | `DevExpress.Persistent.Base.SecuredActionDescriptor` |
   */
  ActionDescriptor?: SecuredActionDescriptor | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `SecuredActionDescriptors` |
   * | Type | `Collection(DevExpress.Persistent.Base.SecuredActionDescriptor)` |
   */
  SecuredActionDescriptors?: Array<SecuredActionDescriptor>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Role` |
   * | Type | `DevExpress.Persistent.BaseImpl.PermissionPolicy.PermissionPolicyRoleBase` |
   */
  Role?: PermissionPolicyRoleBase | null
}

export interface EditablePermissionPolicyActionPermissionObject
  extends Partial<Pick<PermissionPolicyActionPermissionObject, 'ActionId' | 'Image'>> {}

export interface ApplicationUser extends PermissionPolicyUser {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `userData` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   * | Nullable | `false` |
   */
  userData?: user
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `LoginInfo` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ApplicationUserLoginInfo)` |
   */
  LoginInfo?: Array<ApplicationUserLoginInfo>
}

export interface EditableApplicationUser
  extends Pick<ApplicationUser, 'ChangePasswordOnFirstLogon' | 'IsActive'>,
    Partial<Pick<ApplicationUser, 'UserName'>> {}

export interface user {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `fullname` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  fullname: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `cognome` |
   * | Type | `Edm.String` |
   */
  cognome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `email` |
   * | Type | `Edm.String` |
   */
  email: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivo` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  attivo: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `contractor` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  contractor: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `email_ad` |
   * | Type | `Edm.String` |
   */
  email_ad: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `applicationUser` |
   * | Type | `QsAdminApi.WebApi.Model.ApplicationUser` |
   * | Nullable | `false` |
   */
  applicationUser?: ApplicationUser
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `reparto` |
   * | Type | `QsAdminApi.WebApi.Model.reparto` |
   */
  reparto?: reparto | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `info` |
   * | Type | `QsAdminApi.WebApi.Model.info_impiegato` |
   */
  info?: info_impiegato | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `azienda` |
   * | Type | `QsAdminApi.WebApi.Model.azienda` |
   */
  azienda?: azienda | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `interventi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.intervento)` |
   */
  interventi?: Array<intervento>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `riunioni_assistite` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.riunione)` |
   */
  riunioni_assistite?: Array<riunione>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tasks_tecnico` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.task)` |
   */
  tasks_tecnico?: Array<task>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivita_commerciali` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.attivita_comm)` |
   */
  attivita_commerciali?: Array<attivita_comm>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `eventi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.evento)` |
   */
  eventi?: Array<evento>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `riunioni_create` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.riunione)` |
   */
  riunioni_create?: Array<riunione>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tasks_create` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.task)` |
   */
  tasks_create?: Array<task>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tasks_responsabile_tecnico` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.task)` |
   */
  tasks_responsabile_tecnico?: Array<task>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `telefonate_create` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.telefonata)` |
   */
  telefonate_create?: Array<telefonata>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `telefonate_ricevute` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.telefonata)` |
   */
  telefonate_ricevute?: Array<telefonata>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tickets` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ticket)` |
   */
  tickets?: Array<ticket>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `eventi_assistiti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.partecipante_evento)` |
   */
  eventi_assistiti?: Array<partecipante_evento>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offerte` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.offerta)` |
   */
  offerte?: Array<offerta>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivita_lavorative` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.attivita_lavorativa)` |
   */
  attivita_lavorative?: Array<attivita_lavorativa>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `commesse_create` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.commessa)` |
   */
  commesse_create?: Array<commessa>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `agenti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.agente)` |
   */
  agenti?: Array<agente>
}

export type userId = number | { id: number }

export interface Editableuser
  extends Pick<user, 'fullname' | 'attivo' | 'contractor'>,
    Partial<Pick<user, 'cognome' | 'nome' | 'email' | 'note' | 'email_ad'>> {}

export interface reparto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `descrizione` |
   * | Type | `Edm.String` |
   */
  descrizione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `impiegati` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.user)` |
   */
  impiegati?: Array<user>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `centri_costo` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.centro_costo_visibility)` |
   */
  centri_costo?: Array<centro_costo_visibility>
}

export type repartoId = number | { id: number }

export interface Editablereparto extends Partial<Pick<reparto, 'nome' | 'descrizione'>> {}

export interface centro_costo_visibility {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `centro_costo` |
   * | Type | `QsAdminApi.WebApi.Model.centro_costo` |
   */
  centro_costo?: centro_costo | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `reparto` |
   * | Type | `QsAdminApi.WebApi.Model.reparto` |
   */
  reparto?: reparto | null
}

export type centro_costo_visibilityId = number | { id: number }

export interface Editablecentro_costo_visibility {}

export interface centro_costo {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivita_lavorative` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.attivita_lavorativa)` |
   */
  attivita_lavorative?: Array<attivita_lavorativa>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `causali_centro_costo` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.causale_centro_costo)` |
   */
  causali_centro_costo?: Array<causale_centro_costo>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `visibility` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.centro_costo_visibility)` |
   */
  visibility?: Array<centro_costo_visibility>
}

export type centro_costoId = number | { id: number }

export interface Editablecentro_costo extends Partial<Pick<centro_costo, 'nome' | 'note'>> {}

export interface attivita_lavorativa {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `data` |
   * | Type | `Edm.DateTimeOffset` |
   */
  data: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_RootFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_RootFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Dis` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Dis: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Id` |
   * | Type | `Edm.Int32` |
   */
  ded_Id: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tempo_cliente` |
   * | Type | `Edm.Duration` |
   * | Nullable | `false` |
   */
  tempo_cliente: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tempo_trasferta` |
   * | Type | `Edm.Duration` |
   * | Nullable | `false` |
   */
  tempo_trasferta: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tempo_ufficio` |
   * | Type | `Edm.Duration` |
   * | Nullable | `false` |
   */
  tempo_ufficio: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `spese_trasferta` |
   * | Type | `Edm.Double` |
   * | Nullable | `false` |
   */
  spese_trasferta: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `spese_vitto` |
   * | Type | `Edm.Double` |
   * | Nullable | `false` |
   */
  spese_vitto: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `spese_alloggio` |
   * | Type | `Edm.Double` |
   * | Nullable | `false` |
   */
  spese_alloggio: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `impiegato` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  impiegato?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `centro_costo` |
   * | Type | `QsAdminApi.WebApi.Model.centro_costo` |
   */
  centro_costo?: centro_costo | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `piattaforma` |
   * | Type | `QsAdminApi.WebApi.Model.piattaforma_attivita_lavorativa` |
   */
  piattaforma?: piattaforma_attivita_lavorativa | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `causale` |
   * | Type | `QsAdminApi.WebApi.Model.causale_centro_costo` |
   */
  causale?: causale_centro_costo | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `task` |
   * | Type | `QsAdminApi.WebApi.Model.task` |
   */
  task?: task | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `intervento` |
   * | Type | `QsAdminApi.WebApi.Model.intervento` |
   */
  intervento?: intervento | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sede` |
   * | Type | `QsAdminApi.WebApi.Model.sede` |
   */
  sede?: sede | null
}

export type attivita_lavorativaId = number | { id: number }

export interface Editableattivita_lavorativa
  extends Pick<
      attivita_lavorativa,
      | 'ded_RootFam'
      | 'ded_Dis'
      | 'tempo_cliente'
      | 'tempo_trasferta'
      | 'tempo_ufficio'
      | 'spese_trasferta'
      | 'spese_vitto'
      | 'spese_alloggio'
    >,
    Partial<Pick<attivita_lavorativa, 'data' | 'ded_Id' | 'note'>> {}

export interface piattaforma_attivita_lavorativa {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivita_lavorative` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.attivita_lavorativa)` |
   */
  attivita_lavorative?: Array<attivita_lavorativa>
}

export type piattaforma_attivita_lavorativaId = number | { id: number }

export interface Editablepiattaforma_attivita_lavorativa
  extends Partial<Pick<piattaforma_attivita_lavorativa, 'nome' | 'note'>> {}

export interface causale_centro_costo {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `centro_costo` |
   * | Type | `QsAdminApi.WebApi.Model.centro_costo` |
   */
  centro_costo?: centro_costo | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivita_lavorative` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.attivita_lavorativa)` |
   */
  attivita_lavorative?: Array<attivita_lavorativa>
}

export type causale_centro_costoId = number | { id: number }

export interface Editablecausale_centro_costo extends Partial<Pick<causale_centro_costo, 'nome' | 'note'>> {}

export interface task {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_RootFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_RootFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_SubFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_SubFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Dis` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Dis: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attachmentsFolderUrl` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  attachmentsFolderUrl: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Id` |
   * | Type | `Edm.Int32` |
   */
  ded_Id: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `consegna_prevista` |
   * | Type | `Edm.DateTimeOffset` |
   */
  consegna_prevista: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `apertura` |
   * | Type | `Edm.DateTimeOffset` |
   */
  apertura: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `concluso` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  concluso: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `chiusura` |
   * | Type | `Edm.DateTimeOffset` |
   */
  chiusura: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note_completamento` |
   * | Type | `Edm.String` |
   */
  note_completamento: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ultima_modifica` |
   * | Type | `Edm.DateTimeOffset` |
   */
  ultima_modifica: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `avviso_completamento` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  avviso_completamento: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `appuntamento_outlook` |
   * | Type | `Edm.String` |
   */
  appuntamento_outlook: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `anno_rif` |
   * | Type | `Edm.Int16` |
   * | Nullable | `false` |
   */
  anno_rif: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `resp_tecnico` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  resp_tecnico?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ordine` |
   * | Type | `QsAdminApi.WebApi.Model.ordine` |
   */
  ordine?: ordine | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `commessa` |
   * | Type | `QsAdminApi.WebApi.Model.commessa` |
   */
  commessa?: commessa | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `proprietario` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  proprietario?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `stato` |
   * | Type | `QsAdminApi.WebApi.Model.stato_task` |
   */
  stato?: stato_task | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sede` |
   * | Type | `QsAdminApi.WebApi.Model.sede` |
   */
  sede?: sede | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tecnici` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.user)` |
   */
  tecnici?: Array<user>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivita_lavorative` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.attivita_lavorativa)` |
   */
  attivita_lavorative?: Array<attivita_lavorativa>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `eventi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.evento)` |
   */
  eventi?: Array<evento>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `interventi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.intervento)` |
   */
  interventi?: Array<intervento>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `personalizzazioni` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.personalizzazione)` |
   */
  personalizzazioni?: Array<personalizzazione>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documenti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_task)` |
   */
  documenti?: Array<documento_task>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `pacchetti_venduti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.pacchetto_vendita)` |
   */
  pacchetti_venduti?: Array<pacchetto_vendita>
}

export type taskId = number | { id: number }

export interface Editabletask
  extends Pick<
      task,
      | 'ded_RootFam'
      | 'ded_SubFam'
      | 'ded_Dis'
      | 'attachmentsFolderUrl'
      | 'concluso'
      | 'avviso_completamento'
      | 'anno_rif'
    >,
    Partial<
      Pick<
        task,
        | 'ded_Id'
        | 'consegna_prevista'
        | 'apertura'
        | 'chiusura'
        | 'note'
        | 'note_completamento'
        | 'ultima_modifica'
        | 'appuntamento_outlook'
      >
    > {}

export interface ordine {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_RootFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_RootFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_SubFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_SubFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Dis` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Dis: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `mainDocumentUrl` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  mainDocumentUrl: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attachmentsFolderUrl` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  attachmentsFolderUrl: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Id` |
   * | Type | `Edm.Int32` |
   */
  ded_Id: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `creazione` |
   * | Type | `Edm.DateTimeOffset` |
   */
  creazione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `evasione` |
   * | Type | `Edm.DateTimeOffset` |
   */
  evasione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `importo` |
   * | Type | `Edm.Decimal` |
   * | Nullable | `false` |
   */
  importo: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note_evasione` |
   * | Type | `Edm.String` |
   */
  note_evasione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `anno_rif` |
   * | Type | `Edm.Int16` |
   * | Nullable | `false` |
   */
  anno_rif: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivitaTecnicaRichiesta` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  attivitaTecnicaRichiesta: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `stato` |
   * | Type | `QsAdminApi.WebApi.Model.stato_ordine` |
   */
  stato?: stato_ordine | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offerta` |
   * | Type | `QsAdminApi.WebApi.Model.offerta` |
   */
  offerta?: offerta | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `quota` |
   * | Type | `QsAdminApi.WebApi.Model.ad_quote` |
   */
  quota?: ad_quote | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `condivisione` |
   * | Type | `QsAdminApi.WebApi.Model.stato_condivisione` |
   */
  condivisione?: stato_condivisione | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `commessa` |
   * | Type | `QsAdminApi.WebApi.Model.commessa` |
   */
  commessa?: commessa | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tasks` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.task)` |
   */
  tasks?: Array<task>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `personalizzazioni` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.personalizzazione)` |
   */
  personalizzazioni?: Array<personalizzazione>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documenti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_ordine)` |
   */
  documenti?: Array<documento_ordine>
}

export type ordineId = number | { id: number }

export interface Editableordine
  extends Pick<
      ordine,
      | 'ded_RootFam'
      | 'ded_SubFam'
      | 'ded_Dis'
      | 'mainDocumentUrl'
      | 'attachmentsFolderUrl'
      | 'importo'
      | 'anno_rif'
      | 'attivitaTecnicaRichiesta'
    >,
    Partial<Pick<ordine, 'ded_Id' | 'creazione' | 'evasione' | 'note' | 'note_evasione'>> {}

export interface stato_ordine {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ordini` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ordine)` |
   */
  ordini?: Array<ordine>
}

export type stato_ordineId = number | { id: number }

export interface Editablestato_ordine extends Partial<Pick<stato_ordine, 'nome' | 'note'>> {}

export interface offerta {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_RootFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_RootFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_SubFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_SubFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Rev` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Rev: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Dis` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Dis: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `mainDocumentUrl` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  mainDocumentUrl: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attachmentsFolderUrl` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  attachmentsFolderUrl: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Id` |
   * | Type | `Edm.Int32` |
   */
  ded_Id: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `revisione` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  revisione: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `data_creazione` |
   * | Type | `Edm.DateTimeOffset` |
   */
  data_creazione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `anno_rif` |
   * | Type | `Edm.Int16` |
   * | Nullable | `false` |
   */
  anno_rif: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `data_invio` |
   * | Type | `Edm.DateTimeOffset` |
   */
  data_invio: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `creazione_automatica` |
   * | Type | `Edm.String` |
   */
  creazione_automatica: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivitaTecnicaRichiesta` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  attivitaTecnicaRichiesta: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `stato` |
   * | Type | `QsAdminApi.WebApi.Model.stato_offerta` |
   */
  stato?: stato_offerta | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `autore` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  autore?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `commessa` |
   * | Type | `QsAdminApi.WebApi.Model.commessa` |
   */
  commessa?: commessa | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `analisi` |
   * | Type | `QsAdminApi.WebApi.Model.analisi` |
   */
  analisi?: analisi | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia_pagamento` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_pagamento` |
   */
  tipologia_pagamento?: tipologia_pagamento | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sede` |
   * | Type | `QsAdminApi.WebApi.Model.sede` |
   */
  sede?: sede | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `condivisione` |
   * | Type | `QsAdminApi.WebApi.Model.stato_condivisione` |
   */
  condivisione?: stato_condivisione | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `template` |
   * | Type | `QsAdminApi.WebApi.Model.template_offerta` |
   */
  template?: template_offerta | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `riferimenti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.contatto_aziendale)` |
   */
  riferimenti?: Array<contatto_aziendale>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `riferimenti_entita` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.entita_aziendale)` |
   */
  riferimenti_entita?: Array<entita_aziendale>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documenti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_offerta)` |
   */
  documenti?: Array<documento_offerta>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ordini` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ordine)` |
   */
  ordini?: Array<ordine>
}

export type offertaId = number | { id: number }

export interface Editableofferta
  extends Pick<
      offerta,
      | 'ded_RootFam'
      | 'ded_SubFam'
      | 'ded_Rev'
      | 'ded_Dis'
      | 'mainDocumentUrl'
      | 'attachmentsFolderUrl'
      | 'revisione'
      | 'anno_rif'
      | 'attivitaTecnicaRichiesta'
    >,
    Partial<Pick<offerta, 'ded_Id' | 'data_creazione' | 'note' | 'data_invio' | 'creazione_automatica'>> {}

export interface stato_offerta {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offerte` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.offerta)` |
   */
  offerte?: Array<offerta>
}

export type stato_offertaId = number | { id: number }

export interface Editablestato_offerta extends Partial<Pick<stato_offerta, 'nome' | 'note'>> {}

export interface commessa {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_RootFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_RootFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_SubFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_SubFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Dis` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Dis: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attachmentsFolderUrl` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  attachmentsFolderUrl: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `inizio` |
   * | Type | `Edm.DateTimeOffset` |
   */
  inizio: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `fine` |
   * | Type | `Edm.DateTimeOffset` |
   */
  fine: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `anno_rif` |
   * | Type | `Edm.Int16` |
   * | Nullable | `false` |
   */
  anno_rif: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `titolo` |
   * | Type | `Edm.String` |
   */
  titolo: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `creazione` |
   * | Type | `Edm.DateTimeOffset` |
   */
  creazione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `legacy` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  legacy: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `stato` |
   * | Type | `QsAdminApi.WebApi.Model.stato_commessa` |
   */
  stato?: stato_commessa | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sede` |
   * | Type | `QsAdminApi.WebApi.Model.sede` |
   */
  sede?: sede | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `autore` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  autore?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `condivisione` |
   * | Type | `QsAdminApi.WebApi.Model.stato_condivisione` |
   */
  condivisione?: stato_condivisione | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivita_commerciali` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.attivita_comm)` |
   */
  attivita_commerciali?: Array<attivita_comm>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `pacchetti_vendita` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.pacchetto_vendita)` |
   */
  pacchetti_vendita?: Array<pacchetto_vendita>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tasks` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.task)` |
   */
  tasks?: Array<task>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offerte` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.offerta)` |
   */
  offerte?: Array<offerta>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documenti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_commessa)` |
   */
  documenti?: Array<documento_commessa>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ad_quotes` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ad_quote)` |
   */
  ad_quotes?: Array<ad_quote>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ordini` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ordine)` |
   */
  ordini?: Array<ordine>
}

export type commessaId = number | { id: number }

export interface Editablecommessa
  extends Pick<commessa, 'ded_RootFam' | 'ded_SubFam' | 'ded_Dis' | 'attachmentsFolderUrl' | 'anno_rif' | 'legacy'>,
    Partial<Pick<commessa, 'note' | 'inizio' | 'fine' | 'titolo' | 'creazione'>> {}

export interface stato_commessa {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `commesse` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.commessa)` |
   */
  commesse?: Array<commessa>
}

export type stato_commessaId = number | { id: number }

export interface Editablestato_commessa extends Partial<Pick<stato_commessa, 'nome' | 'note'>> {}

export interface sede {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `principale` |
   * | Type | `Edm.Boolean` |
   */
  principale: boolean | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `email` |
   * | Type | `Edm.String` |
   */
  email: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `email_pec` |
   * | Type | `Edm.String` |
   */
  email_pec: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `telefono` |
   * | Type | `Edm.String` |
   */
  telefono: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `fax` |
   * | Type | `Edm.String` |
   */
  fax: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `leg_srvpath` |
   * | Type | `Edm.String` |
   */
  leg_srvpath: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `leg_email_rin_ad` |
   * | Type | `Edm.String` |
   */
  leg_email_rin_ad: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `leg_rif_ad` |
   * | Type | `Edm.String` |
   */
  leg_rif_ad: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `strada` |
   * | Type | `Edm.String` |
   */
  strada: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `citta` |
   * | Type | `Edm.String` |
   */
  citta: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `provincia` |
   * | Type | `Edm.String` |
   */
  provincia: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `cap` |
   * | Type | `Edm.String` |
   */
  cap: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `stato` |
   * | Type | `Edm.String` |
   */
  stato: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `archived` |
   * | Type | `Edm.Boolean` |
   */
  archived: boolean | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `azienda` |
   * | Type | `QsAdminApi.WebApi.Model.azienda` |
   */
  azienda?: azienda | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `resp_tecnico` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  resp_tecnico?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `pacchetti_vendita` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.pacchetto_vendita)` |
   */
  pacchetti_vendita?: Array<pacchetto_vendita>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivita_commerciali` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.attivita_comm)` |
   */
  attivita_commerciali?: Array<attivita_comm>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `contatti_aziendali` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.contatto_aziendale)` |
   */
  contatti_aziendali?: Array<contatto_aziendale>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tickets` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ticket)` |
   */
  tickets?: Array<ticket>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivita_lavorative` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.attivita_lavorativa)` |
   */
  attivita_lavorative?: Array<attivita_lavorativa>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `interventi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.intervento)` |
   */
  interventi?: Array<intervento>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `commesse` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.commessa)` |
   */
  commesse?: Array<commessa>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `analisi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.analisi)` |
   */
  analisi?: Array<analisi>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `licenze_esterne` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.est_licenza)` |
   */
  licenze_esterne?: Array<est_licenza>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `assistenze` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.assistenza)` |
   */
  assistenze?: Array<assistenza>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `licenze_qs` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.qs_licenza)` |
   */
  licenze_qs?: Array<qs_licenza>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offerte` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.offerta)` |
   */
  offerte?: Array<offerta>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documenti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_sede)` |
   */
  documenti?: Array<documento_sede>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `entita_aziendali` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.entita_aziendale)` |
   */
  entita_aziendali?: Array<entita_aziendale>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `storico_licenza` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.qs_storico_licenza)` |
   */
  storico_licenza?: Array<qs_storico_licenza>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tasks` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.task)` |
   */
  tasks?: Array<task>
}

export type sedeId = number | { id: number }

export interface Editablesede
  extends Partial<
    Pick<
      sede,
      | 'principale'
      | 'nome'
      | 'note'
      | 'email'
      | 'email_pec'
      | 'telefono'
      | 'fax'
      | 'leg_srvpath'
      | 'leg_email_rin_ad'
      | 'leg_rif_ad'
      | 'strada'
      | 'citta'
      | 'provincia'
      | 'cap'
      | 'stato'
      | 'archived'
    >
  > {}

export interface azienda {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_RootFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_RootFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Dis` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Dis: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Id` |
   * | Type | `Edm.Int32` |
   */
  ded_Id: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `email` |
   * | Type | `Edm.String` |
   */
  email: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `email_pec` |
   * | Type | `Edm.String` |
   */
  email_pec: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `rapp_inizio` |
   * | Type | `Edm.DateTimeOffset` |
   */
  rapp_inizio: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `p_iva` |
   * | Type | `Edm.String` |
   */
  p_iva: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `contattabile` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  contattabile: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `blacklist` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  blacklist: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `leg_origine_rapp` |
   * | Type | `Edm.String` |
   */
  leg_origine_rapp: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note_fatturazione` |
   * | Type | `Edm.String` |
   */
  note_fatturazione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `is_rivenditore` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  is_rivenditore: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `archived` |
   * | Type | `Edm.Boolean` |
   */
  archived: boolean | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `agente` |
   * | Type | `QsAdminApi.WebApi.Model.agente` |
   * | Nullable | `false` |
   */
  agente?: agente
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sede_principale` |
   * | Type | `QsAdminApi.WebApi.Model.sede` |
   * | Nullable | `false` |
   */
  sede_principale?: sede
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `gruppo_aziendale` |
   * | Type | `QsAdminApi.WebApi.Model.gruppo_aziendale` |
   */
  gruppo_aziendale?: gruppo_aziendale | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia_rapporto` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_rapporto_azienda` |
   */
  tipologia_rapporto?: tipologia_rapporto_azienda | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia_pagamento` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_pagamento` |
   */
  tipologia_pagamento?: tipologia_pagamento | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `rivenditore` |
   * | Type | `QsAdminApi.WebApi.Model.azienda` |
   */
  rivenditore?: azienda | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nomi_noti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.nome_noto)` |
   */
  nomi_noti?: Array<nome_noto>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sedi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.sede)` |
   */
  sedi?: Array<sede>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `software_concorrenti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.software_concorrente)` |
   */
  software_concorrenti?: Array<software_concorrente>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documenti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_azienda)` |
   */
  documenti?: Array<documento_azienda>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `rivendite` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.azienda)` |
   */
  rivendite?: Array<azienda>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ad_csns` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ad_csn)` |
   */
  ad_csns?: Array<ad_csn>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `gruppi_rappresentati` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.gruppo_aziendale)` |
   */
  gruppi_rappresentati?: Array<gruppo_aziendale>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ad_quotes` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ad_quote)` |
   */
  ad_quotes?: Array<ad_quote>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `storico_agenti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.storico_agente)` |
   */
  storico_agenti?: Array<storico_agente>
}

export type aziendaId = number | { id: number }

export interface Editableazienda
  extends Pick<azienda, 'ded_RootFam' | 'ded_Dis' | 'contattabile' | 'blacklist' | 'is_rivenditore'>,
    Partial<
      Pick<
        azienda,
        | 'ded_Id'
        | 'email'
        | 'email_pec'
        | 'rapp_inizio'
        | 'p_iva'
        | 'nome'
        | 'note'
        | 'leg_origine_rapp'
        | 'note_fatturazione'
        | 'archived'
      >
    > {}

export interface agente {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivo` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  attivo: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `commerciale_qs` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  commerciale_qs?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `storico_agente` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.storico_agente)` |
   */
  storico_agente?: Array<storico_agente>
}

export type agenteId = number | { id: number }

export interface Editableagente extends Pick<agente, 'attivo'>, Partial<Pick<agente, 'note'>> {}

export interface storico_agente {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `data` |
   * | Type | `Edm.DateTimeOffset` |
   */
  data: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `agente` |
   * | Type | `QsAdminApi.WebApi.Model.agente` |
   */
  agente?: agente | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `azienda` |
   * | Type | `QsAdminApi.WebApi.Model.azienda` |
   */
  azienda?: azienda | null
}

export type storico_agenteId = number | { id: number }

export interface Editablestorico_agente extends Partial<Pick<storico_agente, 'data'>> {}

export interface gruppo_aziendale {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `capogruppo` |
   * | Type | `QsAdminApi.WebApi.Model.azienda` |
   */
  capogruppo?: azienda | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `aziende` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.azienda)` |
   */
  aziende?: Array<azienda>
}

export type gruppo_aziendaleId = number | { id: number }

export interface Editablegruppo_aziendale extends Partial<Pick<gruppo_aziendale, 'nome' | 'note'>> {}

export interface tipologia_rapporto_azienda {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `aziende` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.azienda)` |
   */
  aziende?: Array<azienda>
}

export type tipologia_rapporto_aziendaId = number | { id: number }

export interface Editabletipologia_rapporto_azienda
  extends Partial<Pick<tipologia_rapporto_azienda, 'nome' | 'note'>> {}

export interface tipologia_pagamento {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `aziende` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.azienda)` |
   */
  aziende?: Array<azienda>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offerte` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.offerta)` |
   */
  offerte?: Array<offerta>
}

export type tipologia_pagamentoId = number | { id: number }

export interface Editabletipologia_pagamento extends Partial<Pick<tipologia_pagamento, 'nome' | 'note'>> {}

export interface nome_noto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `azienda` |
   * | Type | `QsAdminApi.WebApi.Model.azienda` |
   */
  azienda?: azienda | null
}

export type nome_notoId = number | { id: number }

export interface Editablenome_noto extends Partial<Pick<nome_noto, 'nome' | 'note'>> {}

export interface software_concorrente {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `concorrente` |
   * | Type | `QsAdminApi.WebApi.Model.azienda` |
   */
  concorrente?: azienda | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `features` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.concorrente_software_feature)` |
   */
  features?: Array<concorrente_software_feature>
}

export type software_concorrenteId = number | { id: number }

export interface Editablesoftware_concorrente extends Partial<Pick<software_concorrente, 'nome' | 'note'>> {}

export interface concorrente_software_feature {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `software` |
   * | Type | `QsAdminApi.WebApi.Model.software_concorrente` |
   */
  software?: software_concorrente | null
}

export type concorrente_software_featureId = number | { id: number }

export interface Editableconcorrente_software_feature
  extends Partial<Pick<concorrente_software_feature, 'nome' | 'note'>> {}

export interface documento_azienda {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `azienda` |
   * | Type | `QsAdminApi.WebApi.Model.azienda` |
   */
  azienda?: azienda | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documento` |
   * | Type | `QsAdminApi.WebApi.Model.documento` |
   */
  documento?: documento | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_documento` |
   */
  tipologia?: tipologia_documento | null
}

export type documento_aziendaId = number | { id: number }

export interface Editabledocumento_azienda {}

export interface documento {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `estensione` |
   * | Type | `Edm.String` |
   */
  estensione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `path` |
   * | Type | `Edm.String` |
   */
  path: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `creazione` |
   * | Type | `Edm.DateTimeOffset` |
   */
  creazione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Docid` |
   * | Type | `Edm.String` |
   */
  ded_Docid: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `analisi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_analisi)` |
   */
  analisi?: Array<documento_analisi>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tasks` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_task)` |
   */
  tasks?: Array<documento_task>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `riunioni` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_riunione)` |
   */
  riunioni?: Array<documento_riunione>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tickets` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_ticket)` |
   */
  tickets?: Array<documento_ticket>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `aziende` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_azienda)` |
   */
  aziende?: Array<documento_azienda>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `contatti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_contatto)` |
   */
  contatti?: Array<documento_contatto>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ordini` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_ordine)` |
   */
  ordini?: Array<documento_ordine>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offerte` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_offerta)` |
   */
  offerte?: Array<documento_offerta>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `commesse` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_commessa)` |
   */
  commesse?: Array<documento_commessa>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sedi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_sede)` |
   */
  sedi?: Array<documento_sede>
}

export type documentoId = number | { id: number }

export interface Editabledocumento
  extends Partial<Pick<documento, 'estensione' | 'nome' | 'path' | 'creazione' | 'ded_Docid'>> {}

export interface documento_analisi {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documento` |
   * | Type | `QsAdminApi.WebApi.Model.documento` |
   */
  documento?: documento | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `analisi` |
   * | Type | `QsAdminApi.WebApi.Model.analisi` |
   */
  analisi?: analisi | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_documento` |
   */
  tipologia?: tipologia_documento | null
}

export type documento_analisiId = number | { id: number }

export interface Editabledocumento_analisi {}

export interface analisi {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_RootFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_RootFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_SubFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_SubFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Rev` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Rev: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Dis` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Dis: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `mainDocumentUrl` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  mainDocumentUrl: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attachmentsFolderUrl` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  attachmentsFolderUrl: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Id` |
   * | Type | `Edm.Int32` |
   */
  ded_Id: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `revisione` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  revisione: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `oggetto` |
   * | Type | `Edm.String` |
   */
  oggetto: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `creazione` |
   * | Type | `Edm.DateTimeOffset` |
   */
  creazione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `anno_rif` |
   * | Type | `Edm.Int16` |
   * | Nullable | `false` |
   */
  anno_rif: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `stato` |
   * | Type | `QsAdminApi.WebApi.Model.stato_analisi` |
   */
  stato?: stato_analisi | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `creatore` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  creatore?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sede` |
   * | Type | `QsAdminApi.WebApi.Model.sede` |
   */
  sede?: sede | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `condivisione` |
   * | Type | `QsAdminApi.WebApi.Model.stato_condivisione` |
   */
  condivisione?: stato_condivisione | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `riferimenti_entita` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.entita_aziendale)` |
   */
  riferimenti_entita?: Array<entita_aziendale>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `riferimenti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.contatto_aziendale)` |
   */
  riferimenti?: Array<contatto_aziendale>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documenti_analisi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_analisi)` |
   */
  documenti_analisi?: Array<documento_analisi>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offerte` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.offerta)` |
   */
  offerte?: Array<offerta>
}

export type analisiId = number | { id: number }

export interface Editableanalisi
  extends Pick<
      analisi,
      | 'ded_RootFam'
      | 'ded_SubFam'
      | 'ded_Rev'
      | 'ded_Dis'
      | 'mainDocumentUrl'
      | 'attachmentsFolderUrl'
      | 'revisione'
      | 'anno_rif'
    >,
    Partial<Pick<analisi, 'ded_Id' | 'oggetto' | 'creazione' | 'note'>> {}

export interface stato_analisi {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `analisi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.analisi)` |
   */
  analisi?: Array<analisi>
}

export type stato_analisiId = number | { id: number }

export interface Editablestato_analisi extends Partial<Pick<stato_analisi, 'nome' | 'note'>> {}

export interface stato_condivisione {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offerte` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.offerta)` |
   */
  offerte?: Array<offerta>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `analisi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.analisi)` |
   */
  analisi?: Array<analisi>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `commesse` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.commessa)` |
   */
  commesse?: Array<commessa>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `pacchetti_vendita` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.pacchetto_vendita)` |
   */
  pacchetti_vendita?: Array<pacchetto_vendita>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ordini` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ordine)` |
   */
  ordini?: Array<ordine>
}

export type stato_condivisioneId = number | { id: number }

export interface Editablestato_condivisione extends Partial<Pick<stato_condivisione, 'nome' | 'note'>> {}

export interface pacchetto_vendita {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_RootFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_RootFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_SubFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_SubFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Dis` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Dis: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isExhaust` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isExhaust: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isExpired` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isExpired: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Id` |
   * | Type | `Edm.Int32` |
   */
  ded_Id: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `consuntivo` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  consuntivo: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `unita` |
   * | Type | `Edm.Double` |
   * | Nullable | `false` |
   */
  unita: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `costo_unita` |
   * | Type | `Edm.Decimal` |
   * | Nullable | `false` |
   */
  costo_unita: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `unita_restanti` |
   * | Type | `Edm.Double` |
   * | Nullable | `false` |
   */
  unita_restanti: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `creazione` |
   * | Type | `Edm.DateTimeOffset` |
   */
  creazione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `data_fatturazione` |
   * | Type | `Edm.DateTimeOffset` |
   */
  data_fatturazione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `commessa` |
   * | Type | `QsAdminApi.WebApi.Model.commessa` |
   */
  commessa?: commessa | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia_unita` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_durata_pacchetto` |
   */
  tipologia_unita?: tipologia_durata_pacchetto | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `task` |
   * | Type | `QsAdminApi.WebApi.Model.task` |
   */
  task?: task | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sede` |
   * | Type | `QsAdminApi.WebApi.Model.sede` |
   */
  sede?: sede | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `condivisione` |
   * | Type | `QsAdminApi.WebApi.Model.stato_condivisione` |
   */
  condivisione?: stato_condivisione | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `utilizzi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.pacchetto_utilizzo)` |
   */
  utilizzi?: Array<pacchetto_utilizzo>
}

export type pacchetto_venditaId = number | { id: number }

export interface Editablepacchetto_vendita
  extends Pick<
      pacchetto_vendita,
      | 'ded_RootFam'
      | 'ded_SubFam'
      | 'ded_Dis'
      | 'isExhaust'
      | 'isExpired'
      | 'consuntivo'
      | 'unita'
      | 'costo_unita'
      | 'unita_restanti'
    >,
    Partial<Pick<pacchetto_vendita, 'ded_Id' | 'creazione' | 'note' | 'data_fatturazione'>> {}

export interface tipologia_durata_pacchetto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `pacchetto_venditas` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.pacchetto_vendita)` |
   */
  pacchetto_venditas?: Array<pacchetto_vendita>
}

export type tipologia_durata_pacchettoId = number | { id: number }

export interface Editabletipologia_durata_pacchetto
  extends Partial<Pick<tipologia_durata_pacchetto, 'nome' | 'note'>> {}

export interface pacchetto_utilizzo {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_RootFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_RootFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_SubFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_SubFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Dis` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Dis: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Id` |
   * | Type | `Edm.Int32` |
   */
  ded_Id: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `unita_utilizzate` |
   * | Type | `Edm.Double` |
   * | Nullable | `false` |
   */
  unita_utilizzate: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `creazione` |
   * | Type | `Edm.DateTimeOffset` |
   */
  creazione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `pacchetto_vendita` |
   * | Type | `QsAdminApi.WebApi.Model.pacchetto_vendita` |
   */
  pacchetto_vendita?: pacchetto_vendita | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `intervento` |
   * | Type | `QsAdminApi.WebApi.Model.intervento` |
   */
  intervento?: intervento | null
}

export type pacchetto_utilizzoId = number | { id: number }

export interface Editablepacchetto_utilizzo
  extends Pick<pacchetto_utilizzo, 'ded_RootFam' | 'ded_SubFam' | 'ded_Dis' | 'unita_utilizzate'>,
    Partial<Pick<pacchetto_utilizzo, 'ded_Id' | 'note' | 'creazione'>> {}

export interface intervento {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_RootFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_RootFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_SubFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_SubFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Dis` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Dis: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `durata_intervento` |
   * | Type | `Edm.Double` |
   * | Nullable | `false` |
   */
  durata_intervento: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `mainDocumentUrl` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  mainDocumentUrl: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Id` |
   * | Type | `Edm.Int32` |
   */
  ded_Id: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `data` |
   * | Type | `Edm.DateTimeOffset` |
   */
  data: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `durata_viaggio` |
   * | Type | `Edm.Double` |
   * | Nullable | `false` |
   */
  durata_viaggio: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `anno_rif` |
   * | Type | `Edm.Int16` |
   * | Nullable | `false` |
   */
  anno_rif: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `mattina_inizio` |
   * | Type | `Edm.DateTimeOffset` |
   */
  mattina_inizio: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `mattina_fine` |
   * | Type | `Edm.DateTimeOffset` |
   */
  mattina_fine: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `pomeriggio_inizio` |
   * | Type | `Edm.DateTimeOffset` |
   */
  pomeriggio_inizio: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `pomeriggio_fine` |
   * | Type | `Edm.DateTimeOffset` |
   */
  pomeriggio_fine: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `remote` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  remote: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `locale` |
   * | Type | `Edm.String` |
   */
  locale: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `task` |
   * | Type | `QsAdminApi.WebApi.Model.task` |
   */
  task?: task | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `stato_fatturazione` |
   * | Type | `QsAdminApi.WebApi.Model.fatturazione_intervento` |
   */
  stato_fatturazione?: fatturazione_intervento | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sede` |
   * | Type | `QsAdminApi.WebApi.Model.sede` |
   */
  sede?: sede | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `impiegati` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.user)` |
   */
  impiegati?: Array<user>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `riferimenti_cliente` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.contatto_aziendale)` |
   */
  riferimenti_cliente?: Array<contatto_aziendale>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `pacchetti_utilizzo` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.pacchetto_utilizzo)` |
   */
  pacchetti_utilizzo?: Array<pacchetto_utilizzo>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivita_lavorative` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.attivita_lavorativa)` |
   */
  attivita_lavorative?: Array<attivita_lavorativa>
}

export type interventoId = number | { id: number }

export interface Editableintervento
  extends Pick<
      intervento,
      | 'ded_RootFam'
      | 'ded_SubFam'
      | 'ded_Dis'
      | 'durata_intervento'
      | 'mainDocumentUrl'
      | 'durata_viaggio'
      | 'anno_rif'
      | 'remote'
    >,
    Partial<
      Pick<
        intervento,
        | 'ded_Id'
        | 'data'
        | 'note'
        | 'mattina_inizio'
        | 'mattina_fine'
        | 'pomeriggio_inizio'
        | 'pomeriggio_fine'
        | 'locale'
      >
    > {}

export interface fatturazione_intervento {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `interventi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.intervento)` |
   */
  interventi?: Array<intervento>
}

export type fatturazione_interventoId = number | { id: number }

export interface Editablefatturazione_intervento extends Partial<Pick<fatturazione_intervento, 'nome' | 'note'>> {}

export interface contatto_aziendale {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `fullname` |
   * | Type | `Edm.String` |
   */
  fullname: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ad_recipient` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  ad_recipient: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `cognome` |
   * | Type | `Edm.String` |
   */
  cognome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ruolo` |
   * | Type | `Edm.String` |
   */
  ruolo: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `titolo` |
   * | Type | `Edm.String` |
   */
  titolo: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `telefono` |
   * | Type | `Edm.String` |
   */
  telefono: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivo` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  attivo: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `preposto` |
   * | Type | `QsAdminApi.WebApi.Model.contatto_aziendale` |
   */
  preposto?: contatto_aziendale | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `rapporto` |
   * | Type | `QsAdminApi.WebApi.Model.rapporto` |
   */
  rapporto?: rapporto | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sede` |
   * | Type | `QsAdminApi.WebApi.Model.sede` |
   */
  sede?: sede | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `analisi_assegnate` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.analisi)` |
   */
  analisi_assegnate?: Array<analisi>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `interventi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.intervento)` |
   */
  interventi?: Array<intervento>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offerte` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.offerta)` |
   */
  offerte?: Array<offerta>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `emails` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.email_aziendale)` |
   */
  emails?: Array<email_aziendale>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `entita_aziendali` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.entita_aziendale)` |
   */
  entita_aziendali?: Array<entita_aziendale>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ad_quotes_contatti_addizionali` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ad_quote)` |
   */
  ad_quotes_contatti_addizionali?: Array<ad_quote>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivita_commerciali` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.attivita_comm)` |
   */
  attivita_commerciali?: Array<attivita_comm>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `staff` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.contatto_aziendale)` |
   */
  staff?: Array<contatto_aziendale>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `eventi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.partecipante_evento)` |
   */
  eventi?: Array<partecipante_evento>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documenti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_contatto)` |
   */
  documenti?: Array<documento_contatto>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ad_quotes` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ad_quote)` |
   */
  ad_quotes?: Array<ad_quote>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ad_csns` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ad_csn)` |
   */
  ad_csns?: Array<ad_csn>
}

export type contatto_aziendaleId = number | { id: number }

export interface Editablecontatto_aziendale
  extends Pick<contatto_aziendale, 'ad_recipient' | 'attivo'>,
    Partial<Pick<contatto_aziendale, 'fullname' | 'nome' | 'cognome' | 'ruolo' | 'note' | 'titolo' | 'telefono'>> {}

export interface rapporto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `impiegati` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.contatto_aziendale)` |
   */
  impiegati?: Array<contatto_aziendale>
}

export type rapportoId = number | { id: number }

export interface Editablerapporto extends Partial<Pick<rapporto, 'nome' | 'note'>> {}

export interface email_aziendale {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `email` |
   * | Type | `Edm.String` |
   */
  email: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `rif_ad` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  rif_ad: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `contatti_aziendali` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.contatto_aziendale)` |
   */
  contatti_aziendali?: Array<contatto_aziendale>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `entita_aziendali` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.entita_aziendale)` |
   */
  entita_aziendali?: Array<entita_aziendale>
}

export type email_aziendaleId = number | { id: number }

export interface Editableemail_aziendale
  extends Pick<email_aziendale, 'rif_ad'>,
    Partial<Pick<email_aziendale, 'email' | 'note'>> {}

export interface entita_aziendale {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ad_recipient` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  ad_recipient: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `telefono` |
   * | Type | `Edm.String` |
   */
  telefono: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sede` |
   * | Type | `QsAdminApi.WebApi.Model.sede` |
   */
  sede?: sede | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `parent` |
   * | Type | `QsAdminApi.WebApi.Model.entita_aziendale` |
   */
  parent?: entita_aziendale | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `analisi_assegnate` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.analisi)` |
   */
  analisi_assegnate?: Array<analisi>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offerte` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.offerta)` |
   */
  offerte?: Array<offerta>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `emails` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.email_aziendale)` |
   */
  emails?: Array<email_aziendale>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `contatti_aziendali` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.contatto_aziendale)` |
   */
  contatti_aziendali?: Array<contatto_aziendale>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `entita_dipendenti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.entita_aziendale)` |
   */
  entita_dipendenti?: Array<entita_aziendale>
}

export type entita_aziendaleId = number | { id: number }

export interface Editableentita_aziendale
  extends Pick<entita_aziendale, 'ad_recipient'>,
    Partial<Pick<entita_aziendale, 'note' | 'nome' | 'telefono'>> {}

export interface ad_quote {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int64` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `status` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  status: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `transactionId` |
   * | Type | `Edm.String` |
   */
  transactionId: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `number` |
   * | Type | `Edm.String` |
   */
  number: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `auto` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  auto: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `autoFinalizationDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  autoFinalizationDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `autoExpiringDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  autoExpiringDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivitaTecnicaRichiesta` |
   * | Type | `Edm.Boolean` |
   */
  attivitaTecnicaRichiesta: boolean | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `creationDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  creationDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `expiringDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  expiringDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `language` |
   * | Type | `Edm.String` |
   */
  language: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `csn` |
   * | Type | `QsAdminApi.WebApi.Model.ad_csn` |
   */
  csn?: ad_csn | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `azienda` |
   * | Type | `QsAdminApi.WebApi.Model.azienda` |
   */
  azienda?: azienda | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `contact` |
   * | Type | `QsAdminApi.WebApi.Model.contatto_aziendale` |
   */
  contact?: contatto_aziendale | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `admin` |
   * | Type | `QsAdminApi.WebApi.Model.contatto_aziendale` |
   */
  admin?: contatto_aziendale | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `commessa` |
   * | Type | `QsAdminApi.WebApi.Model.commessa` |
   */
  commessa?: commessa | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `author` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  author?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `contatti_addizionali` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.contatto_aziendale)` |
   */
  contatti_addizionali?: Array<contatto_aziendale>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ad_quote_histories` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ad_quote_history)` |
   */
  ad_quote_histories?: Array<ad_quote_history>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ordini` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ordine)` |
   */
  ordini?: Array<ordine>
}

export type ad_quoteId = number | { id: number }

export interface Editablead_quote
  extends Pick<ad_quote, 'status' | 'auto'>,
    Partial<
      Pick<
        ad_quote,
        | 'transactionId'
        | 'number'
        | 'autoFinalizationDate'
        | 'autoExpiringDate'
        | 'attivitaTecnicaRichiesta'
        | 'creationDate'
        | 'expiringDate'
        | 'language'
      >
    > {}

export interface ad_csn {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `csn` |
   * | Type | `Edm.String` |
   */
  csn: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `admin` |
   * | Type | `QsAdminApi.WebApi.Model.contatto_aziendale` |
   */
  admin?: contatto_aziendale | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `quoteContact` |
   * | Type | `QsAdminApi.WebApi.Model.contatto_aziendale` |
   */
  quoteContact?: contatto_aziendale | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `azienda` |
   * | Type | `QsAdminApi.WebApi.Model.azienda` |
   */
  azienda?: azienda | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ad_subscriptions` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ad_subscription)` |
   */
  ad_subscriptions?: Array<ad_subscription>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ad_quotes` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ad_quote)` |
   */
  ad_quotes?: Array<ad_quote>
}

export type ad_csnId = number | { id: number }

export interface Editablead_csn extends Partial<Pick<ad_csn, 'csn' | 'note'>> {}

export interface ad_subscription {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `subscriptionId` |
   * | Type | `Edm.String` |
   */
  subscriptionId: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `serialNumber` |
   * | Type | `Edm.String` |
   */
  serialNumber: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `quantity` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  quantity: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `status` |
   * | Type | `Edm.String` |
   */
  status: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `startDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  startDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `endDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  endDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `extensionDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  extensionDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `productName` |
   * | Type | `Edm.String` |
   */
  productName: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `productCode` |
   * | Type | `Edm.String` |
   */
  productCode: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `licenseType` |
   * | Type | `Edm.String` |
   */
  licenseType: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `term` |
   * | Type | `Edm.String` |
   */
  term: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `autoRenew` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  autoRenew: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `opportunity` |
   * | Type | `Edm.String` |
   */
  opportunity: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `release` |
   * | Type | `Edm.String` |
   */
  release: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `switchType` |
   * | Type | `Edm.String` |
   */
  switchType: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `behavior` |
   * | Type | `Edm.String` |
   */
  behavior: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `contract` |
   * | Type | `Edm.String` |
   */
  contract: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `csn` |
   * | Type | `QsAdminApi.WebApi.Model.ad_csn` |
   */
  csn?: ad_csn | null
}

export type ad_subscriptionId = number | { id: number }

export interface Editablead_subscription
  extends Pick<ad_subscription, 'quantity' | 'autoRenew'>,
    Partial<
      Pick<
        ad_subscription,
        | 'subscriptionId'
        | 'serialNumber'
        | 'status'
        | 'startDate'
        | 'endDate'
        | 'extensionDate'
        | 'productName'
        | 'productCode'
        | 'licenseType'
        | 'term'
        | 'opportunity'
        | 'release'
        | 'switchType'
        | 'behavior'
        | 'contract'
        | 'note'
      >
    > {}

export interface ad_quote_history {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int64` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `quoteStatus` |
   * | Type | `Edm.String` |
   */
  quoteStatus: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `eventDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  eventDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `message` |
   * | Type | `Edm.String` |
   */
  message: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `quote` |
   * | Type | `QsAdminApi.WebApi.Model.ad_quote` |
   */
  quote?: ad_quote | null
}

export type ad_quote_historyId = number | { id: number }

export interface Editablead_quote_history
  extends Partial<Pick<ad_quote_history, 'quoteStatus' | 'eventDate' | 'message'>> {}

export interface attivita_comm {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_RootFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_RootFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Dis` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Dis: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attachmentsFolderUrl` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  attachmentsFolderUrl: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Id` |
   * | Type | `Edm.Int32` |
   */
  ded_Id: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `inizio` |
   * | Type | `Edm.DateTimeOffset` |
   */
  inizio: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `fine` |
   * | Type | `Edm.DateTimeOffset` |
   */
  fine: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `creazione` |
   * | Type | `Edm.DateTimeOffset` |
   */
  creazione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `descrizione` |
   * | Type | `Edm.String` |
   */
  descrizione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `anno_rif` |
   * | Type | `Edm.Int16` |
   * | Nullable | `false` |
   */
  anno_rif: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note_follow_up` |
   * | Type | `Edm.String` |
   */
  note_follow_up: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `autore` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  autore?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `contatto_aziendale` |
   * | Type | `QsAdminApi.WebApi.Model.contatto_aziendale` |
   */
  contatto_aziendale?: contatto_aziendale | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_attivita_comm` |
   */
  tipologia?: tipologia_attivita_comm | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `precedente` |
   * | Type | `QsAdminApi.WebApi.Model.attivita_comm` |
   */
  precedente?: attivita_comm | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `stato` |
   * | Type | `QsAdminApi.WebApi.Model.stato_att_comm` |
   */
  stato?: stato_att_comm | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `commessa` |
   * | Type | `QsAdminApi.WebApi.Model.commessa` |
   */
  commessa?: commessa | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sede` |
   * | Type | `QsAdminApi.WebApi.Model.sede` |
   */
  sede?: sede | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivita_commerciali` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.attivita_comm)` |
   */
  attivita_commerciali?: Array<attivita_comm>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `eventi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.evento)` |
   */
  eventi?: Array<evento>
}

export type attivita_commId = number | { id: number }

export interface Editableattivita_comm
  extends Pick<attivita_comm, 'ded_RootFam' | 'ded_Dis' | 'attachmentsFolderUrl' | 'anno_rif'>,
    Partial<Pick<attivita_comm, 'ded_Id' | 'inizio' | 'fine' | 'creazione' | 'descrizione' | 'note_follow_up'>> {}

export interface tipologia_attivita_comm {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivita_commerciali` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.attivita_comm)` |
   */
  attivita_commerciali?: Array<attivita_comm>
}

export type tipologia_attivita_commId = number | { id: number }

export interface Editabletipologia_attivita_comm extends Partial<Pick<tipologia_attivita_comm, 'nome' | 'note'>> {}

export interface stato_att_comm {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivita_commerciali` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.attivita_comm)` |
   */
  attivita_commerciali?: Array<attivita_comm>
}

export type stato_att_commId = number | { id: number }

export interface Editablestato_att_comm extends Partial<Pick<stato_att_comm, 'nome' | 'note'>> {}

export interface evento {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `titolo` |
   * | Type | `Edm.String` |
   */
  titolo: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `descrizione` |
   * | Type | `Edm.String` |
   */
  descrizione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `creazione` |
   * | Type | `Edm.DateTimeOffset` |
   */
  creazione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ultimo_aggiornamento` |
   * | Type | `Edm.DateTimeOffset` |
   */
  ultimo_aggiornamento: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `inizio` |
   * | Type | `Edm.DateTimeOffset` |
   */
  inizio: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `fine` |
   * | Type | `Edm.DateTimeOffset` |
   */
  fine: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `utente` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  utente?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attivita_comm` |
   * | Type | `QsAdminApi.WebApi.Model.attivita_comm` |
   */
  attivita_comm?: attivita_comm | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `task` |
   * | Type | `QsAdminApi.WebApi.Model.task` |
   */
  task?: task | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `promemoria` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.promemoria)` |
   */
  promemoria?: Array<promemoria>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `partecipanti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.partecipante_evento)` |
   */
  partecipanti?: Array<partecipante_evento>
}

export type eventoId = number | { id: number }

export interface Editableevento
  extends Partial<Pick<evento, 'titolo' | 'descrizione' | 'creazione' | 'ultimo_aggiornamento' | 'inizio' | 'fine'>> {}

export interface promemoria {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `letto` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  letto: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `lettura` |
   * | Type | `Edm.DateTimeOffset` |
   */
  lettura: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `evento` |
   * | Type | `QsAdminApi.WebApi.Model.evento` |
   */
  evento?: evento | null
}

export type promemoriaId = number | { id: number }

export interface Editablepromemoria extends Pick<promemoria, 'letto'>, Partial<Pick<promemoria, 'lettura'>> {}

export interface partecipante_evento {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `partecipante_qs` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  partecipante_qs?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `partecipante_est` |
   * | Type | `QsAdminApi.WebApi.Model.contatto_aziendale` |
   */
  partecipante_est?: contatto_aziendale | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `evento` |
   * | Type | `QsAdminApi.WebApi.Model.evento` |
   */
  evento?: evento | null
}

export type partecipante_eventoId = number | { id: number }

export interface Editablepartecipante_evento {}

export interface documento_contatto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `contatto` |
   * | Type | `QsAdminApi.WebApi.Model.contatto_aziendale` |
   */
  contatto?: contatto_aziendale | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documento` |
   * | Type | `QsAdminApi.WebApi.Model.documento` |
   */
  documento?: documento | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_documento` |
   */
  tipologia?: tipologia_documento | null
}

export type documento_contattoId = number | { id: number }

export interface Editabledocumento_contatto {}

export interface tipologia_documento {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `analisi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_analisi)` |
   */
  analisi?: Array<documento_analisi>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tasks` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_task)` |
   */
  tasks?: Array<documento_task>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `riunioni` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_riunione)` |
   */
  riunioni?: Array<documento_riunione>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tickets` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_ticket)` |
   */
  tickets?: Array<documento_ticket>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `aziende` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_azienda)` |
   */
  aziende?: Array<documento_azienda>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `contatti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_contatto)` |
   */
  contatti?: Array<documento_contatto>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ordini` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_ordine)` |
   */
  ordini?: Array<documento_ordine>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offerte` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_offerta)` |
   */
  offerte?: Array<documento_offerta>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `commesse` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_commessa)` |
   */
  commesse?: Array<documento_commessa>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sedi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_sede)` |
   */
  sedi?: Array<documento_sede>
}

export type tipologia_documentoId = number | { id: number }

export interface Editabletipologia_documento extends Partial<Pick<tipologia_documento, 'nome' | 'note'>> {}

export interface documento_task {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `task` |
   * | Type | `QsAdminApi.WebApi.Model.task` |
   */
  task?: task | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documento` |
   * | Type | `QsAdminApi.WebApi.Model.documento` |
   */
  documento?: documento | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_documento` |
   */
  tipologia?: tipologia_documento | null
}

export type documento_taskId = number | { id: number }

export interface Editabledocumento_task {}

export interface documento_riunione {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `riunione` |
   * | Type | `QsAdminApi.WebApi.Model.riunione` |
   */
  riunione?: riunione | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documento` |
   * | Type | `QsAdminApi.WebApi.Model.documento` |
   */
  documento?: documento | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_documento` |
   */
  tipologia?: tipologia_documento | null
}

export type documento_riunioneId = number | { id: number }

export interface Editabledocumento_riunione {}

export interface riunione {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_RootFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_RootFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_SubFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_SubFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Rev` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Rev: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Dis` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Dis: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `mainDocumentUrl` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  mainDocumentUrl: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attachmentsFolderUrl` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  attachmentsFolderUrl: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Id` |
   * | Type | `Edm.Int32` |
   */
  ded_Id: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `revisione` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  revisione: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `data` |
   * | Type | `Edm.DateTimeOffset` |
   */
  data: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `creazione` |
   * | Type | `Edm.DateTimeOffset` |
   */
  creazione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `argomento` |
   * | Type | `Edm.String` |
   */
  argomento: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `anno_rif` |
   * | Type | `Edm.Int16` |
   * | Nullable | `false` |
   */
  anno_rif: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_riunione` |
   */
  tipologia?: tipologia_riunione | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `autore` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  autore?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `impiegati` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.user)` |
   */
  impiegati?: Array<user>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documenti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_riunione)` |
   */
  documenti?: Array<documento_riunione>
}

export type riunioneId = number | { id: number }

export interface Editableriunione
  extends Pick<
      riunione,
      | 'ded_RootFam'
      | 'ded_SubFam'
      | 'ded_Rev'
      | 'ded_Dis'
      | 'mainDocumentUrl'
      | 'attachmentsFolderUrl'
      | 'revisione'
      | 'anno_rif'
    >,
    Partial<Pick<riunione, 'ded_Id' | 'data' | 'creazione' | 'argomento'>> {}

export interface tipologia_riunione {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `riunioni` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.riunione)` |
   */
  riunioni?: Array<riunione>
}

export type tipologia_riunioneId = number | { id: number }

export interface Editabletipologia_riunione extends Partial<Pick<tipologia_riunione, 'nome' | 'note'>> {}

export interface documento_ticket {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ticket` |
   * | Type | `QsAdminApi.WebApi.Model.ticket` |
   */
  ticket?: ticket | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documento` |
   * | Type | `QsAdminApi.WebApi.Model.documento` |
   */
  documento?: documento | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_documento` |
   */
  tipologia?: tipologia_documento | null
}

export type documento_ticketId = number | { id: number }

export interface Editabledocumento_ticket {}

export interface ticket {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_RootFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_RootFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Dis` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Dis: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attachmentsFolderUrl` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  attachmentsFolderUrl: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Id` |
   * | Type | `Edm.Int32` |
   */
  ded_Id: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `contattato` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  contattato: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attesa` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  attesa: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `urgente` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  urgente: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `notifica_chiusura` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  notifica_chiusura: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `rating` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  rating: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `rating_note` |
   * | Type | `Edm.String` |
   */
  rating_note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `apertura` |
   * | Type | `Edm.DateTimeOffset` |
   */
  apertura: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `chiusura` |
   * | Type | `Edm.DateTimeOffset` |
   */
  chiusura: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `descrizione` |
   * | Type | `Edm.String` |
   */
  descrizione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `soluzione` |
   * | Type | `Edm.String` |
   */
  soluzione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `leg_tag1` |
   * | Type | `Edm.String` |
   */
  leg_tag1: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `leg_tag2` |
   * | Type | `Edm.String` |
   */
  leg_tag2: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `durata` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  durata: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `leg_contatto` |
   * | Type | `Edm.String` |
   */
  leg_contatto: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `leg_n_chiamate` |
   * | Type | `Edm.Int16` |
   * | Nullable | `false` |
   */
  leg_n_chiamate: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `leg_phone_contatto` |
   * | Type | `Edm.String` |
   */
  leg_phone_contatto: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `leg_email_contatto` |
   * | Type | `Edm.String` |
   */
  leg_email_contatto: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia_ticket` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_ticket` |
   */
  tipologia_ticket?: tipologia_ticket | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `assegnato` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  assegnato?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia_problema` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_problema` |
   */
  tipologia_problema?: tipologia_problema | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `argomento_problema` |
   * | Type | `QsAdminApi.WebApi.Model.argomento_problema` |
   */
  argomento_problema?: argomento_problema | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `stato` |
   * | Type | `QsAdminApi.WebApi.Model.stato_ticket` |
   */
  stato?: stato_ticket | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `piattaforma` |
   * | Type | `QsAdminApi.WebApi.Model.piattaforma_ticket` |
   */
  piattaforma?: piattaforma_ticket | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `applicazione` |
   * | Type | `QsAdminApi.WebApi.Model.qs_supporto_applicazione` |
   */
  applicazione?: qs_supporto_applicazione | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `creatore` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  creatore?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `incaricato` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  incaricato?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sede` |
   * | Type | `QsAdminApi.WebApi.Model.sede` |
   */
  sede?: sede | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documenti` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.documento_ticket)` |
   */
  documenti?: Array<documento_ticket>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note_ticket` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.nota_ticket)` |
   */
  note_ticket?: Array<nota_ticket>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `eventi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.evento_ticket)` |
   */
  eventi?: Array<evento_ticket>
}

export type ticketId = number | { id: number }

export interface Editableticket
  extends Pick<
      ticket,
      | 'ded_RootFam'
      | 'ded_Dis'
      | 'attachmentsFolderUrl'
      | 'contattato'
      | 'attesa'
      | 'urgente'
      | 'notifica_chiusura'
      | 'rating'
      | 'durata'
      | 'leg_n_chiamate'
    >,
    Partial<
      Pick<
        ticket,
        | 'ded_Id'
        | 'rating_note'
        | 'apertura'
        | 'chiusura'
        | 'descrizione'
        | 'soluzione'
        | 'leg_tag1'
        | 'leg_tag2'
        | 'leg_contatto'
        | 'leg_phone_contatto'
        | 'leg_email_contatto'
      >
    > {}

export interface tipologia_ticket {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tickets` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ticket)` |
   */
  tickets?: Array<ticket>
}

export type tipologia_ticketId = number | { id: number }

export interface Editabletipologia_ticket extends Partial<Pick<tipologia_ticket, 'nome' | 'note'>> {}

export interface tipologia_problema {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tickets` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ticket)` |
   */
  tickets?: Array<ticket>
}

export type tipologia_problemaId = number | { id: number }

export interface Editabletipologia_problema extends Partial<Pick<tipologia_problema, 'nome' | 'note'>> {}

export interface argomento_problema {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ord` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  ord: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `qs_applicazione` |
   * | Type | `QsAdminApi.WebApi.Model.qs_supporto_applicazione` |
   */
  qs_applicazione?: qs_supporto_applicazione | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `piattaforma` |
   * | Type | `QsAdminApi.WebApi.Model.piattaforma_ticket` |
   */
  piattaforma?: piattaforma_ticket | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tickets` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ticket)` |
   */
  tickets?: Array<ticket>
}

export type argomento_problemaId = number | { id: number }

export interface Editableargomento_problema
  extends Pick<argomento_problema, 'ord'>,
    Partial<Pick<argomento_problema, 'nome' | 'note'>> {}

export interface qs_supporto_applicazione {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `argomenti_problemi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.argomento_problema)` |
   */
  argomenti_problemi?: Array<argomento_problema>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tickets` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ticket)` |
   */
  tickets?: Array<ticket>
}

export type qs_supporto_applicazioneId = number | { id: number }

export interface Editableqs_supporto_applicazione extends Partial<Pick<qs_supporto_applicazione, 'nome' | 'note'>> {}

export interface piattaforma_ticket {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `argomenti_problemi` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.argomento_problema)` |
   */
  argomenti_problemi?: Array<argomento_problema>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tickets` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ticket)` |
   */
  tickets?: Array<ticket>
}

export type piattaforma_ticketId = number | { id: number }

export interface Editablepiattaforma_ticket extends Partial<Pick<piattaforma_ticket, 'nome' | 'note'>> {}

export interface stato_ticket {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tickets` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ticket)` |
   */
  tickets?: Array<ticket>
}

export type stato_ticketId = number | { id: number }

export interface Editablestato_ticket extends Partial<Pick<stato_ticket, 'nome' | 'note'>> {}

export interface nota_ticket {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `data` |
   * | Type | `Edm.DateTimeOffset` |
   */
  data: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `inviata` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  inviata: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `chiusura` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  chiusura: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `testo` |
   * | Type | `Edm.String` |
   */
  testo: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tag` |
   * | Type | `Edm.String` |
   */
  tag: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ticket` |
   * | Type | `QsAdminApi.WebApi.Model.ticket` |
   */
  ticket?: ticket | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `utente` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  utente?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `emails` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.ticket_email)` |
   */
  emails?: Array<ticket_email>
}

export type nota_ticketId = number | { id: number }

export interface Editablenota_ticket
  extends Pick<nota_ticket, 'inviata' | 'chiusura'>,
    Partial<Pick<nota_ticket, 'data' | 'testo' | 'tag'>> {}

export interface ticket_email {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `messageId` |
   * | Type | `Edm.String` |
   */
  messageId: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `incoming` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  incoming: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nota_ticket` |
   * | Type | `QsAdminApi.WebApi.Model.nota_ticket` |
   */
  nota_ticket?: nota_ticket | null
}

export type ticket_emailId = number | { id: number }

export interface Editableticket_email
  extends Pick<ticket_email, 'incoming'>,
    Partial<Pick<ticket_email, 'messageId'>> {}

export interface evento_ticket {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `data` |
   * | Type | `Edm.DateTimeOffset` |
   */
  data: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ticket` |
   * | Type | `QsAdminApi.WebApi.Model.ticket` |
   */
  ticket?: ticket | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `utente` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  utente?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_evento_ticket` |
   */
  tipologia?: tipologia_evento_ticket | null
}

export type evento_ticketId = number | { id: number }

export interface Editableevento_ticket extends Partial<Pick<evento_ticket, 'data' | 'note'>> {}

export interface tipologia_evento_ticket {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `eventi_ticket` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.evento_ticket)` |
   */
  eventi_ticket?: Array<evento_ticket>
}

export type tipologia_evento_ticketId = number | { id: number }

export interface Editabletipologia_evento_ticket extends Partial<Pick<tipologia_evento_ticket, 'nome'>> {}

export interface documento_ordine {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ordine` |
   * | Type | `QsAdminApi.WebApi.Model.ordine` |
   */
  ordine?: ordine | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documento` |
   * | Type | `QsAdminApi.WebApi.Model.documento` |
   */
  documento?: documento | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_documento` |
   */
  tipologia?: tipologia_documento | null
}

export type documento_ordineId = number | { id: number }

export interface Editabledocumento_ordine {}

export interface documento_offerta {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offerta` |
   * | Type | `QsAdminApi.WebApi.Model.offerta` |
   */
  offerta?: offerta | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documento` |
   * | Type | `QsAdminApi.WebApi.Model.documento` |
   */
  documento?: documento | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_documento` |
   */
  tipologia?: tipologia_documento | null
}

export type documento_offertaId = number | { id: number }

export interface Editabledocumento_offerta {}

export interface documento_commessa {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documento` |
   * | Type | `QsAdminApi.WebApi.Model.documento` |
   */
  documento?: documento | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `commessa` |
   * | Type | `QsAdminApi.WebApi.Model.commessa` |
   */
  commessa?: commessa | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_documento` |
   */
  tipologia?: tipologia_documento | null
}

export type documento_commessaId = number | { id: number }

export interface Editabledocumento_commessa {}

export interface documento_sede {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sede` |
   * | Type | `QsAdminApi.WebApi.Model.sede` |
   */
  sede?: sede | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `documento` |
   * | Type | `QsAdminApi.WebApi.Model.documento` |
   */
  documento?: documento | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_documento` |
   */
  tipologia?: tipologia_documento | null
}

export type documento_sedeId = number | { id: number }

export interface Editabledocumento_sede {}

export interface est_licenza {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `seriale` |
   * | Type | `Edm.String` |
   */
  seriale: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `altro_fornitore` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  altro_fornitore: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `versione` |
   * | Type | `Edm.String` |
   */
  versione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `data_vendita` |
   * | Type | `Edm.DateTimeOffset` |
   */
  data_vendita: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `scadenza` |
   * | Type | `Edm.DateTimeOffset` |
   */
  scadenza: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `rete` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  rete: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `n_contratto` |
   * | Type | `Edm.String` |
   */
  n_contratto: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `postazioni` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  postazioni: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `durata` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  durata: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `software` |
   * | Type | `QsAdminApi.WebApi.Model.est_software` |
   */
  software?: est_software | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `piano_mantenimento` |
   * | Type | `QsAdminApi.WebApi.Model.est_piano_mantenimento` |
   */
  piano_mantenimento?: est_piano_mantenimento | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `stato_noleggio` |
   * | Type | `QsAdminApi.WebApi.Model.est_stato_noleggio` |
   */
  stato_noleggio?: est_stato_noleggio | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sede` |
   * | Type | `QsAdminApi.WebApi.Model.sede` |
   */
  sede?: sede | null
}

export type est_licenzaId = number | { id: number }

export interface Editableest_licenza
  extends Pick<est_licenza, 'altro_fornitore' | 'rete' | 'postazioni' | 'durata'>,
    Partial<Pick<est_licenza, 'seriale' | 'versione' | 'data_vendita' | 'scadenza' | 'n_contratto' | 'note'>> {}

export interface est_software {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `licenze_est` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.est_licenza)` |
   */
  licenze_est?: Array<est_licenza>
}

export type est_softwareId = number | { id: number }

export interface Editableest_software extends Partial<Pick<est_software, 'nome' | 'note'>> {}

export interface est_piano_mantenimento {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `licenze_est` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.est_licenza)` |
   */
  licenze_est?: Array<est_licenza>
}

export type est_piano_mantenimentoId = number | { id: number }

export interface Editableest_piano_mantenimento extends Partial<Pick<est_piano_mantenimento, 'nome' | 'note'>> {}

export interface est_stato_noleggio {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `licenze_est` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.est_licenza)` |
   */
  licenze_est?: Array<est_licenza>
}

export type est_stato_noleggioId = number | { id: number }

export interface Editableest_stato_noleggio extends Partial<Pick<est_stato_noleggio, 'nome' | 'note'>> {}

export interface assistenza {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_RootFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_RootFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_SubFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_SubFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Rev` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Rev: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Dis` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Dis: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `posti` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  posti: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sospesa` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  sospesa: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Id` |
   * | Type | `Edm.Int32` |
   */
  ded_Id: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `importo_precedente` |
   * | Type | `Edm.Double` |
   * | Nullable | `false` |
   */
  importo_precedente: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tariffa_oraria` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  tariffa_oraria: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `canone` |
   * | Type | `Edm.Double` |
   * | Nullable | `false` |
   */
  canone: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `canone_personalizzazioni` |
   * | Type | `Edm.Double` |
   */
  canone_personalizzazioni: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `canone_telefonica` |
   * | Type | `Edm.Double` |
   * | Nullable | `false` |
   */
  canone_telefonica: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `durata` |
   * | Type | `Edm.Int16` |
   * | Nullable | `false` |
   */
  durata: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `scadenza` |
   * | Type | `Edm.DateTimeOffset` |
   */
  scadenza: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `data_fatturazione` |
   * | Type | `Edm.DateTimeOffset` |
   */
  data_fatturazione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `seconda_fatturazione` |
   * | Type | `Edm.DateTimeOffset` |
   */
  seconda_fatturazione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `data_inizio` |
   * | Type | `Edm.DateTimeOffset` |
   */
  data_inizio: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `data_sospensione` |
   * | Type | `Edm.DateTimeOffset` |
   */
  data_sospensione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `fatturazione_viaggio` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  fatturazione_viaggio: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `timbro_firma` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  timbro_firma: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `disdetta_telefonica` |
   * | Type | `Edm.DateTimeOffset` |
   */
  disdetta_telefonica: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `disdetta_aggiornamenti` |
   * | Type | `Edm.DateTimeOffset` |
   */
  disdetta_aggiornamenti: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `revisione` |
   * | Type | `Edm.Int16` |
   * | Nullable | `false` |
   */
  revisione: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `maintenance` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_assistenza` |
   */
  maintenance?: tipologia_assistenza | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `telefonica` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_assistenza` |
   */
  telefonica?: tipologia_assistenza | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sede` |
   * | Type | `QsAdminApi.WebApi.Model.sede` |
   */
  sede?: sede | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `riferimento` |
   * | Type | `QsAdminApi.WebApi.Model.assistenza` |
   */
  riferimento?: assistenza | null
}

export type assistenzaId = number | { id: number }

export interface Editableassistenza
  extends Pick<
      assistenza,
      | 'ded_RootFam'
      | 'ded_SubFam'
      | 'ded_Rev'
      | 'ded_Dis'
      | 'posti'
      | 'sospesa'
      | 'importo_precedente'
      | 'tariffa_oraria'
      | 'canone'
      | 'canone_telefonica'
      | 'durata'
      | 'fatturazione_viaggio'
      | 'timbro_firma'
      | 'revisione'
    >,
    Partial<
      Pick<
        assistenza,
        | 'ded_Id'
        | 'note'
        | 'canone_personalizzazioni'
        | 'scadenza'
        | 'data_fatturazione'
        | 'seconda_fatturazione'
        | 'data_inizio'
        | 'data_sospensione'
        | 'disdetta_telefonica'
        | 'disdetta_aggiornamenti'
      >
    > {}

export interface tipologia_assistenza {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `maintenance` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.assistenza)` |
   */
  maintenance?: Array<assistenza>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `telefonica` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.assistenza)` |
   */
  telefonica?: Array<assistenza>
}

export type tipologia_assistenzaId = number | { id: number }

export interface Editabletipologia_assistenza extends Partial<Pick<tipologia_assistenza, 'nome' | 'note'>> {}

export interface qs_licenza {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_RootFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_RootFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_SubFam` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_SubFam: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Dis` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ded_Dis: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `aggiornamenti` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  aggiornamenti: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  tipologia: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ded_Id` |
   * | Type | `Edm.Int32` |
   */
  ded_Id: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `scadenza` |
   * | Type | `Edm.DateTimeOffset` |
   */
  scadenza: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `chiave` |
   * | Type | `Edm.String` |
   */
  chiave: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id_chiave` |
   * | Type | `Edm.String` |
   */
  id_chiave: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `rete` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  rete: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `attenzione_speciale` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  attenzione_speciale: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `leg_note_sw_aggiunto` |
   * | Type | `Edm.String` |
   */
  leg_note_sw_aggiunto: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `hardware` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  hardware: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `rete_postazioni` |
   * | Type | `Edm.Int32` |
   */
  rete_postazioni: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `versione_uso` |
   * | Type | `QsAdminApi.WebApi.Model.qs_versione` |
   */
  versione_uso?: qs_versione | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sede` |
   * | Type | `QsAdminApi.WebApi.Model.sede` |
   */
  sede?: sede | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `stato_licenza` |
   * | Type | `QsAdminApi.WebApi.Model.stato_qs_licenza` |
   */
  stato_licenza?: stato_qs_licenza | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `storico` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.qs_storico_licenza)` |
   */
  storico?: Array<qs_storico_licenza>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `info_applicazioni` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.qs_licenza_applicazione)` |
   */
  info_applicazioni?: Array<qs_licenza_applicazione>
}

export type qs_licenzaId = number | { id: number }

export interface Editableqs_licenza
  extends Pick<
      qs_licenza,
      | 'ded_RootFam'
      | 'ded_SubFam'
      | 'ded_Dis'
      | 'aggiornamenti'
      | 'tipologia'
      | 'rete'
      | 'attenzione_speciale'
      | 'hardware'
    >,
    Partial<
      Pick<
        qs_licenza,
        'ded_Id' | 'scadenza' | 'chiave' | 'id_chiave' | 'note' | 'leg_note_sw_aggiunto' | 'rete_postazioni'
      >
    > {}

export interface qs_versione {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `major` |
   * | Type | `Edm.Int16` |
   * | Nullable | `false` |
   */
  major: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `minor` |
   * | Type | `Edm.Int16` |
   */
  minor: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sub` |
   * | Type | `Edm.Int16` |
   */
  sub: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `licenze` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.qs_licenza)` |
   */
  licenze?: Array<qs_licenza>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `versioni_spedite` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.qs_storico_licenza)` |
   */
  versioni_spedite?: Array<qs_storico_licenza>
}

export type qs_versioneId = number | { id: number }

export interface Editableqs_versione
  extends Pick<qs_versione, 'major'>,
    Partial<Pick<qs_versione, 'minor' | 'sub' | 'note'>> {}

export interface qs_storico_licenza {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `data` |
   * | Type | `Edm.DateTimeOffset` |
   */
  data: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `licenza` |
   * | Type | `QsAdminApi.WebApi.Model.qs_licenza` |
   */
  licenza?: qs_licenza | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `versione` |
   * | Type | `QsAdminApi.WebApi.Model.qs_versione` |
   */
  versione?: qs_versione | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `sede` |
   * | Type | `QsAdminApi.WebApi.Model.sede` |
   */
  sede?: sede | null
}

export type qs_storico_licenzaId = number | { id: number }

export interface Editableqs_storico_licenza extends Partial<Pick<qs_storico_licenza, 'data'>> {}

export interface stato_qs_licenza {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `licenze` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.qs_licenza)` |
   */
  licenze?: Array<qs_licenza>
}

export type stato_qs_licenzaId = number | { id: number }

export interface Editablestato_qs_licenza extends Partial<Pick<stato_qs_licenza, 'nome' | 'note'>> {}

export interface qs_licenza_applicazione {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `data_associazione` |
   * | Type | `Edm.DateTimeOffset` |
   */
  data_associazione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `aggiornamenti` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  aggiornamenti: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `passaggio_noleggio` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  passaggio_noleggio: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `scadenza` |
   * | Type | `Edm.DateTimeOffset` |
   */
  scadenza: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tipologia_licenza` |
   * | Type | `QsAdminApi.WebApi.Model.tipologia_qs_licenza` |
   */
  tipologia_licenza?: tipologia_qs_licenza | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ultima_versione` |
   * | Type | `QsAdminApi.WebApi.Model.qs_versione` |
   */
  ultima_versione?: qs_versione | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `applicazione` |
   * | Type | `QsAdminApi.WebApi.Model.qs_applicazione` |
   */
  applicazione?: qs_applicazione | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `licenza` |
   * | Type | `QsAdminApi.WebApi.Model.qs_licenza` |
   */
  licenza?: qs_licenza | null
}

export type qs_licenza_applicazioneId = number | { id: number }

export interface Editableqs_licenza_applicazione
  extends Pick<qs_licenza_applicazione, 'aggiornamenti' | 'passaggio_noleggio'>,
    Partial<Pick<qs_licenza_applicazione, 'data_associazione' | 'note' | 'scadenza'>> {}

export interface tipologia_qs_licenza {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `qs_licenza_applicazioni` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.qs_licenza_applicazione)` |
   */
  qs_licenza_applicazioni?: Array<qs_licenza_applicazione>
}

export type tipologia_qs_licenzaId = number | { id: number }

export interface Editabletipologia_qs_licenza extends Partial<Pick<tipologia_qs_licenza, 'nome' | 'note'>> {}

export interface qs_applicazione {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `applicazioni_licenze` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.qs_licenza_applicazione)` |
   */
  applicazioni_licenze?: Array<qs_licenza_applicazione>
}

export type qs_applicazioneId = number | { id: number }

export interface Editableqs_applicazione extends Partial<Pick<qs_applicazione, 'nome' | 'note'>> {}

export interface template_offerta {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offerte` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.offerta)` |
   */
  offerte?: Array<offerta>
}

export type template_offertaId = number | { id: number }

export interface Editabletemplate_offerta extends Partial<Pick<template_offerta, 'nome' | 'note'>> {}

export interface personalizzazione {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `rev` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  rev: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `data` |
   * | Type | `Edm.DateTimeOffset` |
   */
  data: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ordine` |
   * | Type | `QsAdminApi.WebApi.Model.ordine` |
   */
  ordine?: ordine | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `task` |
   * | Type | `QsAdminApi.WebApi.Model.task` |
   */
  task?: task | null
}

export type personalizzazioneId = number | { id: number }

export interface Editablepersonalizzazione
  extends Pick<personalizzazione, 'rev'>,
    Partial<Pick<personalizzazione, 'data'>> {}

export interface stato_task {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   */
  nome: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `tasks` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.task)` |
   */
  tasks?: Array<task>
}

export type stato_taskId = number | { id: number }

export interface Editablestato_task extends Partial<Pick<stato_task, 'nome' | 'note'>> {}

export interface info_impiegato {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `email_rec` |
   * | Type | `Edm.String` |
   */
  email_rec: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ore` |
   * | Type | `Edm.Int16` |
   * | Nullable | `false` |
   */
  ore: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `cellulare` |
   * | Type | `Edm.String` |
   */
  cellulare: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `interno` |
   * | Type | `Edm.Int32` |
   */
  interno: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `inps` |
   * | Type | `Edm.String` |
   */
  inps: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `matricola` |
   * | Type | `Edm.String` |
   */
  matricola: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `divisione` |
   * | Type | `Edm.String` |
   */
  divisione: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `entrata_mattina` |
   * | Type | `Edm.String` |
   */
  entrata_mattina: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `entrata_pomerig` |
   * | Type | `Edm.String` |
   */
  entrata_pomerig: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `auto` |
   * | Type | `QsAdminApi.WebApi.Model.auto` |
   */
  auto?: auto | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `badge` |
   * | Type | `QsAdminApi.WebApi.Model.badge` |
   */
  badge?: badge | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `users` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.user)` |
   */
  users?: Array<user>
}

export type info_impiegatoId = number | { id: number }

export interface Editableinfo_impiegato
  extends Pick<info_impiegato, 'ore'>,
    Partial<
      Pick<
        info_impiegato,
        | 'email_rec'
        | 'cellulare'
        | 'interno'
        | 'inps'
        | 'matricola'
        | 'divisione'
        | 'entrata_mattina'
        | 'entrata_pomerig'
      >
    > {}

export interface auto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `modello` |
   * | Type | `Edm.String` |
   */
  modello: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `targa` |
   * | Type | `Edm.String` |
   */
  targa: string | null
}

export type autoId = number | { id: number }

export interface Editableauto extends Partial<Pick<auto, 'modello' | 'targa'>> {}

export interface badge {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `impiegati` |
   * | Type | `Collection(QsAdminApi.WebApi.Model.info_impiegato)` |
   */
  impiegati?: Array<info_impiegato>
}

export type badgeId = string | { id: string }

export interface Editablebadge extends Partial<Pick<badge, 'note'>> {}

export interface telefonata {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  id: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `contatto` |
   * | Type | `Edm.String` |
   */
  contatto: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `data` |
   * | Type | `Edm.DateTimeOffset` |
   */
  data: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `note` |
   * | Type | `Edm.String` |
   */
  note: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `autore` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  autore?: user | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `destinatario` |
   * | Type | `QsAdminApi.WebApi.Model.user` |
   */
  destinatario?: user | null
}

export type telefonataId = number | { id: number }

export interface Editabletelefonata extends Partial<Pick<telefonata, 'contatto' | 'data' | 'note'>> {}

export interface ApplicationUserLoginInfo extends BaseObject {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `LoginProviderName` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  LoginProviderName: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ProviderUserKey` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  ProviderUserKey: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `User` |
   * | Type | `QsAdminApi.WebApi.Model.ApplicationUser` |
   * | Nullable | `false` |
   */
  User?: ApplicationUser
}

export interface EditableApplicationUserLoginInfo
  extends Pick<ApplicationUserLoginInfo, 'LoginProviderName' | 'ProviderUserKey'> {}

export interface ad_product_catalog {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offeringName` |
   * | Type | `Edm.String` |
   */
  offeringName: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offeringCode` |
   * | Type | `Edm.String` |
   */
  offeringCode: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offeringId` |
   * | Type | `Edm.String` |
   */
  offeringId: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `accessModelCode` |
   * | Type | `Edm.String` |
   */
  accessModelCode: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `accessModelDescription` |
   * | Type | `Edm.String` |
   */
  accessModelDescription: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `servicePlanCode` |
   * | Type | `Edm.String` |
   */
  servicePlanCode: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `servicePlanDescription` |
   * | Type | `Edm.String` |
   */
  servicePlanDescription: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `termCode` |
   * | Type | `Edm.String` |
   */
  termCode: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `termDescription` |
   * | Type | `Edm.String` |
   */
  termDescription: string | null
}

export type ad_product_catalogId = string | { id: string }

export interface Editablead_product_catalog
  extends Partial<
    Pick<
      ad_product_catalog,
      | 'offeringName'
      | 'offeringCode'
      | 'offeringId'
      | 'accessModelCode'
      | 'accessModelDescription'
      | 'servicePlanCode'
      | 'servicePlanDescription'
      | 'termCode'
      | 'termDescription'
    >
  > {}

export interface ad_promotion {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `code` |
   * | Type | `Edm.String` |
   */
  code: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `name` |
   * | Type | `Edm.String` |
   */
  name: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `description` |
   * | Type | `Edm.String` |
   */
  description: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `startDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  startDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `endDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  endDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `orderAction` |
   * | Type | `Edm.String` |
   */
  orderAction: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offeringId` |
   * | Type | `Edm.String` |
   */
  offeringId: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `offeringCode` |
   * | Type | `Edm.String` |
   */
  offeringCode: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `intendedUsageCode` |
   * | Type | `Edm.String` |
   */
  intendedUsageCode: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `termCode` |
   * | Type | `Edm.String` |
   */
  termCode: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `accessModelCode` |
   * | Type | `Edm.String` |
   */
  accessModelCode: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `autoApply` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  autoApply: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `quantityNeeded` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  quantityNeeded: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `um` |
   * | Type | `Edm.String` |
   */
  um: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `discount` |
   * | Type | `Edm.Double` |
   * | Nullable | `false` |
   */
  discount: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `currency` |
   * | Type | `Edm.String` |
   */
  currency: string | null
}

export type ad_promotionId = string | { id: string }

export interface Editablead_promotion
  extends Pick<ad_promotion, 'autoApply' | 'quantityNeeded' | 'discount'>,
    Partial<
      Pick<
        ad_promotion,
        | 'code'
        | 'name'
        | 'description'
        | 'startDate'
        | 'endDate'
        | 'orderAction'
        | 'offeringId'
        | 'offeringCode'
        | 'intendedUsageCode'
        | 'termCode'
        | 'accessModelCode'
        | 'um'
        | 'currency'
      >
    > {}

export interface constants {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `nome` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  nome: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `valore` |
   * | Type | `Edm.String` |
   */
  valore: string | null
}

export type constantsId = string | { nome: string }

export interface Editableconstants extends Partial<Pick<constants, 'valore'>> {}

export interface Type {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `GenericTypeArguments` |
   * | Type | `Collection(System.Type)` |
   * | Nullable | `false` |
   */
  GenericTypeArguments: Array<Type>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `CustomAttributes` |
   * | Type | `Collection(System.Reflection.CustomAttributeData)` |
   */
  CustomAttributes: Array<CustomAttributeData>
}

export interface EditableType {
  GenericTypeArguments: Array<EditableType>
  CustomAttributes?: Array<EditableCustomAttributeData>
}

export interface CustomAttributeData {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ConstructorArguments` |
   * | Type | `Collection(System.Reflection.CustomAttributeTypedArgument)` |
   */
  ConstructorArguments: Array<CustomAttributeTypedArgument>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `NamedArguments` |
   * | Type | `Collection(System.Reflection.CustomAttributeNamedArgument)` |
   */
  NamedArguments: Array<CustomAttributeNamedArgument>
}

export interface EditableCustomAttributeData {
  ConstructorArguments?: Array<EditableCustomAttributeTypedArgument>
  NamedArguments?: Array<EditableCustomAttributeNamedArgument>
}

export interface CustomAttributeTypedArgument {}

export interface EditableCustomAttributeTypedArgument {}

export interface CustomAttributeNamedArgument {}

export interface EditableCustomAttributeNamedArgument {}
