import { DataGridTypes } from 'devextreme-react/cjs/data-grid'
import { Form as DXForm, GroupItem, SimpleItem, Tab, TabbedItem, TabPanelOptions } from 'devextreme-react/form'
import type { task } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { FileList, MgtTemplateProps } from '@microsoft/mgt-react'
import { LoadIndicator } from 'devextreme-react'

const MyEvent = (props: MgtTemplateProps) => {
  const { template } = props
  switch (template) {
    case 'no-data': {
      return <div>Nessun allegato caricato</div>
    }
    case 'loading': {
      return <LoadIndicator id="small-indicator" height={20} width={20} />
    }
    default: {
      break
    }
  }
}

const TaskBriefDetail = (props: DataGridTypes.MasterDetailTemplateData) => {
  const task: task = props.data.data
  let path
  if (task.attachmentsFolderUrl) {
    path = task.attachmentsFolderUrl
  }

  return (
    <div className={'dx-card responsive-paddings'}>
      <DXForm id={'task_brief_detail_form'} formData={task} labelLocation={'top'} readOnly={true}>
        <GroupItem colCount={5} colSpan={5}>
          <GroupItem colCount={1} colSpan={5}>
            <TabbedItem>
              <TabPanelOptions selectedIndex={1} />
              <Tab title="Allegati">
                <FileList
                  driveId={import.meta.env.VITE_QSADMINSITE_DRIVE_ID}
                  itemPath={path}
                  itemView="twolines"
                  pageSize={3}
                  draggable={true}
                >
                  <MyEvent template="no-data" />
                  <MyEvent template="loading" />
                </FileList>
              </Tab>
              <Tab title="Note Ordine">
                <SimpleItem
                  dataField={'ordine.note'}
                  editorType={'dxTextArea'}
                  label={{ visible: false }}
                  editorOptions={{ height: 200 }}
                />
              </Tab>
              <Tab title="Note">
                <SimpleItem
                  dataField={'note'}
                  editorType={'dxHtmlEditor'}
                  label={{ visible: false }}
                  editorOptions={{
                    stylingMode: 'filled',
                    readOnly: true,
                    valueType: 'html',
                  }}
                ></SimpleItem>
              </Tab>
              <Tab title="Note Completamento">
                <SimpleItem
                  dataField={'note_completamento'}
                  editorType={'dxHtmlEditor'}
                  label={{ visible: false }}
                  editorOptions={{
                    stylingMode: 'filled',
                    readOnly: true,
                    valueType: 'html',
                  }}
                ></SimpleItem>
              </Tab>
              <Tab title="Note Cliente">
                <SimpleItem
                  dataField={'sede.azienda.note'}
                  editorType={'dxHtmlEditor'}
                  label={{ visible: false }}
                  editorOptions={{
                    stylingMode: 'filled',
                    readOnly: true,
                    valueType: 'html',
                  }}
                ></SimpleItem>
              </Tab>
            </TabbedItem>
          </GroupItem>
        </GroupItem>
      </DXForm>
    </div>
  )
}

export default TaskBriefDetail
