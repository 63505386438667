import DataGrid, { Column, DataGridTypes, Paging } from 'devextreme-react/cjs/data-grid'
import { Form as DXForm, GroupItem, SimpleItem, Tab, TabbedItem, TabPanelOptions } from 'devextreme-react/form'
import type { ad_quote, ad_quote_history, riunione } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import QuoteHistory from '@/routes/quotes/QuoteHistory'

export function QuoteBriefDetail(props: DataGridTypes.MasterDetailTemplateData) {
  const quote: ad_quote = props.data.data
  console.log('quote', quote)

  return (
    <>
      <div className={'dx-card responsive-paddings'}>
        <DXForm id={'quote_brief_detail_form'} formData={quote} labelLocation={'top'} readOnly={true}>
          <GroupItem colCount={5} colSpan={5}>
            <GroupItem colCount={1} colSpan={5}>
              <TabbedItem>
                <TabPanelOptions
                  animationEnabled={true}
                  swipeEnabled={false}
                  deferRendering={false}
                  scrollingEnabled={true}
                />
                <Tab title="STORICO">
                  <QuoteHistory quote={quote}></QuoteHistory>
                </Tab>
              </TabbedItem>
            </GroupItem>
          </GroupItem>
        </DXForm>
      </div>
    </>
  )
}
