import TabbedSelector from '@/components/TabbedSelector'
import { softwareSubRoutes } from '@/routes/software/SoftwarePage.route'
import { useParams } from 'react-router-dom'

export default function SoftwarePage() {
  const { clientId } = useParams()
  return (
    <>
      {!clientId && <h2 className={'content-block'}>Software</h2>}
      <div className={clientId ? '' : 'content-block'}>
        <TabbedSelector tabs={softwareSubRoutes} />
      </div>
    </>
  )
}
