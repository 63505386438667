import { Roles } from '@/auth/azure/Roles'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { type LoaderFunction } from 'react-router-dom'
import ProfilePage from '@/routes/profile/ProfilePage'
import RouterErrorHandler from '@/components/RouterErrorHandler'

const profilePageLoader = (async ({ request, params }: RouteFunctionParams) => {}) satisfies LoaderFunction

const ProfileRoute = {
  path: 'profile',
  element: <ProfilePage />,
  errorElement: <RouterErrorHandler />,
  // loader: profilePageLoader,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default ProfileRoute
