import { useEffect, useRef, useState } from 'react'
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider'
import FileManager, { Permissions, FileManagerTypes } from 'devextreme-react/file-manager'
import { AttachmentsManagerProps } from '@/components/file-manager/AttachmentsManager.types'
import { useAzureManager } from '@/auth/azure/azureManager'
import FileSystemItem from 'devextreme/file_management/file_system_item'
import UploadInfo from 'devextreme/file_management/upload_info'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import notify from 'devextreme/ui/notify'
import { AxiosError } from 'axios'
import { LoadIndicator } from 'devextreme-react'
import { DocumentPermissions } from '@/enums'

const AttachmentsManager = (props: AttachmentsManagerProps) => {
  const { folderUrl, fileManagerRef, preliminarPermissionsAction, createDirectoryAction } = props
  const [isLoading, setIsLoading] = useState(true)
  const [permissions, setPermissions] = useState<DocumentPermissions>()
  const { client } = useQsAdminApiManager()

  useEffect(() => {
    const computePermissions = async () => {
      let objperm: DocumentPermissions = DocumentPermissions.Deny
      if (preliminarPermissionsAction) {
        objperm = await preliminarPermissionsAction()
      }
      setPermissions(objperm)
      setIsLoading(false)
    }

    computePermissions()
  }, [preliminarPermissionsAction])

  const graphClient = useAzureManager.getState().graphClient

  const getItems = async (parentFolder: FileSystemItem) => {
    let currentFolder = folderUrl
    if (parentFolder.path.length > 0) {
      currentFolder = `${folderUrl}/${parentFolder.path}`
    }
    try {
      const items = await graphClient
        ?.api(`drives/${import.meta.env.VITE_QSADMINSITE_DRIVE_ID}/root:/${currentFolder}:/children`)
        .get()
      return items.value
    } catch (error) {
      console.error(`Error reading ${currentFolder}`, error)
      return []
    }
  }

  const renameItem = async (item: FileSystemItem, newName: string) => {
    const body = JSON.stringify({
      name: newName,
    })
    return graphClient?.api(`drives/${import.meta.env.VITE_QSADMINSITE_DRIVE_ID}/items/${item.dataItem.id}`).patch(body)
  }

  const moveItem = async (item: FileSystemItem, destinationDirectory: FileSystemItem) => {
    const body = JSON.stringify({
      parentReference: { id: destinationDirectory.dataItem.id },
      name: item.name,
    })
    return graphClient?.api(`drives/${import.meta.env.VITE_QSADMINSITE_DRIVE_ID}/items/${item.dataItem.id}`).patch(body)
  }

  const deleteItem = async (item: FileSystemItem) => {
    return graphClient?.api(`drives/${import.meta.env.VITE_QSADMINSITE_DRIVE_ID}/items/${item.dataItem.id}`).delete()
  }

  const createDirectory = async (parentDirectory: FileSystemItem, name: string) => {
    let currentFolder = folderUrl
    if (parentDirectory.path.length > 0) {
      currentFolder = `${folderUrl}/${parentDirectory.path}`
    }
    return createDirectoryAction(currentFolder ?? '', name, 'rename')
  }

  const uploadFileChunk = async (file: File, uploadInfo: UploadInfo, destinationDirectory: FileSystemItem) => {
    try {
      await createDirectoryAction(extractParentPath(folderUrl) ?? '', extractDirectoryName(folderUrl), 'replace')
    } catch (error: any) {
      notify(error.message, 'error', 3000)
      return
    }

    let currentFolder = folderUrl
    if (destinationDirectory.path.length > 0) {
      currentFolder = `${folderUrl}/${destinationDirectory.path}`
    }
    return graphClient
      ?.api(`drives/${import.meta.env.VITE_QSADMINSITE_DRIVE_ID}/root:/${currentFolder}/${file.name}:/content`)
      .put(file)
  }

  const isDirectoryExpr = 'folder'
  const dateModifiedExpr = 'lastModifiedDateTime'

  const customFileProvider = new CustomFileSystemProvider({
    getItems,
    renameItem,
    moveItem,
    deleteItem,
    createDirectory,
    uploadFileChunk,
    isDirectoryExpr,
    dateModifiedExpr,
  })

  const extractDirectoryName = (path: string | null | undefined) => {
    if (path === null || path === undefined) return ''
    const pathArray = path.split('/')
    const lastIndex = pathArray.length - 1
    return pathArray[lastIndex]
  }

  const extractParentPath = (path: string | null | undefined) => {
    if (path === null || path === undefined) return ''
    return path.split('/').slice(0, -1).join('/')
  }

  if (isLoading) {
    return <LoadIndicator id="small-indicator" height={20} width={20} />
  }

  // eslint-disable-next-line unicorn/prefer-ternary
  if (permissions && permissions !== DocumentPermissions.Deny) {
    return (
      <>
        <FileManager
          height={400}
          selectionMode={'single'}
          ref={fileManagerRef}
          fileSystemProvider={customFileProvider}
          onSelectedFileOpened={(e: FileManagerTypes.SelectedFileOpenedEvent) => {
            window.open(e.file.dataItem.webUrl, '_blank')
          }}
          onItemDownloading={(e: FileManagerTypes.ItemDownloadingEvent) => {
            window.open(e.item.dataItem['@microsoft.graph.downloadUrl'], '_blank')
          }}
          rootFolderName={extractDirectoryName(folderUrl)}
        >
          <Permissions
            create={permissions === DocumentPermissions.Write}
            move={permissions === DocumentPermissions.Write}
            delete={permissions === DocumentPermissions.Write}
            rename={permissions === DocumentPermissions.Write}
            upload={permissions === DocumentPermissions.Write}
            download={true}
          />
        </FileManager>
      </>
    )
  } else {
    return <div>Non si dispone dei permessi di accesso necessari. Contattare un amministratore per informazioni</div>
  }
}

export default AttachmentsManager
