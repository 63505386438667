import { ClickEvent } from 'devextreme/ui/button'
import { ValueChangedEvent } from 'devextreme/ui/select_box'

export type FormFilterItemDescriptorType = {
  fieldName: string
  placeHolder: string
  valueKeyName: string
  valueDisplayExpr: string
  currentValue?: unknown
  defaultValue?: unknown
  values: unknown[]
  composeFilterItem: () => any[] | null
}

export type GenericFilterFormProps = {
  FormItemDescriptors: FormFilterItemDescriptorType[]
  resetFilterClicked: (e: ClickEvent) => void
  filterValueChanged: () => void
}

export const createDateFilterItemDescriptor = (
  refField: string,
  pHolder: string,
  defValue?: string,
): FormFilterItemDescriptorType => {
  return {
    fieldName: refField,
    placeHolder: pHolder,
    valueKeyName: 'periodo',
    defaultValue: defValue,
    valueDisplayExpr: 'periodo',
    values: [
      { periodo: 'Ultimi 12 mesi' },
      { periodo: 'Ultimi 6 mesi' },
      { periodo: 'Ultimo mese solare' },
      { periodo: 'Ultimo mese' },
      { periodo: 'Mese corrente' },
      { periodo: 'Ultima settimana' },
      { periodo: 'Oggi' },
    ],
    composeFilterItem(): any[] | null {
      console.log('COMPOSING DATE FLT', this.currentValue)
      if (!this.currentValue) return null
      const now: Date = new Date()
      let minDate: Date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
      let maxDate: Date = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0)
      let maxDateFilterValue: Date | null = null
      let minDateFilterValue: Date | null = null
      switch (this.currentValue) {
        case 'Oggi': {
          minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
          break
        }
        case 'Ultima settimana': {
          minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7, 0, 0, 0)
          break
        }
        case 'Ultimo mese': {
          minDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate(), 0, 0, 0)
          break
        }
        case 'Ultimi 6 mesi': {
          minDate = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate(), 0, 0, 0)
          break
        }
        case 'Ultimi 12 mesi': {
          minDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate(), 0, 0, 0)
          break
        }
        case 'Ultimo mese solare': {
          minDate = new Date(now.getFullYear(), now.getMonth() - 1, 1, 0, 0, 0)
          maxDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0)
          break
        }
        case 'Mese corrente': {
          minDate = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0)
          break
        }
      }
      minDateFilterValue = new Date(minDate.toISOString().replace('Z', ''))
      maxDateFilterValue = new Date(maxDate.toISOString().replace('Z', ''))
      return [
        [refField, '<', maxDateFilterValue],
        [refField, '>=', minDateFilterValue],
      ]
    },
  }
}
