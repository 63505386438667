import { SoftwareAdskEditorForm } from './SoftwareAdskEditor.form'
import { Await, useLoaderData } from 'react-router-typesafe'
import { FormMode } from '@/enums'
import { softwareAdskRouteLoader } from '@/routes/software/adsk/SoftwareAdskEditor.route'
import { Suspense } from 'react'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { SoftwareAdskEditorProps } from '@/routes/software/adsk/SoftwareAdskEditor.types'
import { useFetcher } from 'react-router-dom'
import { isUserRoleAllowed } from '@/routes/utils'

export function SoftwareAdskEditor(props: SoftwareAdskEditorProps) {
  const { getNewSwAdsk, getClientList, getUserInfo, defaultCRUDAllowedRoles } =
    useLoaderData<typeof softwareAdskRouteLoader>()
  const fetcher = useFetcher()
  return (
    <>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getNewSwAdsk, getClientList, getUserInfo])}>
          {([license, aziende, userInfo]) => (
            <>
              <h2>{`${props.mode === FormMode.Create ? 'Nuova Licenza' : license && 'data' in license && license.data.serialNumber ? license.data.serialNumber : license && 'serialNumber' in license && license.serialNumber ? license.serialNumber : 'Seriale non definito'}`}</h2>
              <div id="edit-adsk-license-container" className={`dx-card responsive-paddings`}>
                <SoftwareAdskEditorForm
                  license={license && 'data' in license ? license.data : license}
                  mode={props.mode}
                  aziendaList={aziende.data.value}
                  isRoleAllowed={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                  fetcher={fetcher}
                />
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}
