import { ActivityEditorProps } from '@/routes/activities/ActivityEditor.types'
import { useLoaderData, Await } from 'react-router-typesafe'
import { Suspense, useRef } from 'react'
import { useFetcher, useParams, useSearchParams } from 'react-router-dom'
import ActivityEditorForm from '@/routes/activities/ActivityEditor.form'
import { Form as DXForm, Tab, TabbedItem } from 'devextreme-react/form'
import AttachmentsManager from '@/components/file-manager/AttachmentsManager'
import { activityEditorRouteLoader } from '@/routes/activities/ActivityEditor.route'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { FileManagerRef } from 'devextreme-react/file-manager'
import { isUserRoleAllowed } from '@/routes/utils'
import { DocumentPermissions } from '@/enums'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Roles } from '@/auth/azure/Roles'

const ActivityEditor = (props: ActivityEditorProps) => {
  const { creating } = props
  const {
    activity,
    getCommesse,
    getTipologie,
    getStati,
    getContatti,
    getAziende,
    getStatiCommessa,
    getAutore,
    userInfo,
    defaultCRUDAllowedRoles,
  } = useLoaderData<typeof activityEditorRouteLoader>()
  const { clientId } = useParams()

  const [searchParams] = useSearchParams()
  const activityPrecId = searchParams.get('prec')

  const title: string = creating
    ? activityPrecId
      ? `Nuova attività: follow up di attività ${activity.precedente?.ded_Dis}`
      : `Nuova attività`
    : `Attività ${activity?.ded_Dis}`

  const fetcher = useFetcher()
  const fileManagerRef = useRef<FileManagerRef>(null)
  const { client } = useQsAdminApiManager()

  return (
    <>
      <Suspense fallback={<LoadingPanel />}>
        <Await
          resolve={Promise.all([
            getCommesse,
            getTipologie,
            getStati,
            getAziende,
            getContatti,
            getStatiCommessa,
            getAutore,
          ])}
        >
          {([commesse, tipologie, stati, aziende, contatti, statiCommessa, autore]) => (
            <>
              <h2 className={clientId ? '' : 'content-block'}>{title}</h2>
              <div
                id="editor-activity-container"
                className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
              >
                <fetcher.Form>
                  <ActivityEditorForm
                    creating={creating}
                    activity={activity}
                    commesse={commesse?.data.value}
                    contatti={contatti.data.value}
                    aziende={aziende.data.value}
                    stati={stati.data.value}
                    tipologie={tipologie.data.value}
                    statiCommessa={statiCommessa.data.value}
                    autore={autore.data.value[0]}
                    isRoleAllowed={
                      creating
                        ? isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)
                        : isUserRoleAllowed(userInfo?.roles, [
                            Roles.GlobalAdministrator,
                            Roles.Administrator,
                            Roles.Supervisor,
                          ]) ||
                          (userInfo?.roles?.hasRole(Roles.Sales) &&
                            activity.sede?.azienda?.agente?.commerciale_qs?.fullname === userInfo.name) ||
                          (userInfo?.roles?.hasRole(Roles.ExternalSales) &&
                            activity.sede?.azienda?.rivenditore?.id === autore.data.value[0].azienda?.id)
                    }
                    userInfo={userInfo}
                    fetcher={fetcher}
                  />
                </fetcher.Form>
              </div>
              {!creating && (
                <div
                  id="allegati-activity-container"
                  className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
                >
                  <DXForm id="form-allegati">
                    <TabbedItem>
                      <Tab title="Allegati">
                        <AttachmentsManager
                          fileManagerRef={fileManagerRef}
                          folderUrl={activity?.attachmentsFolderUrl}
                          preliminarPermissionsAction={async () => {
                            try {
                              const response = await client.post(
                                '/api/attivitacommerciale/drivepermissions',
                                {
                                  objectId: activity.id,
                                  path: activity.attachmentsFolderUrl,
                                },
                                {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                },
                              )
                              return response.status === 200
                                ? (response.data as DocumentPermissions)
                                : DocumentPermissions.Deny
                            } catch (error) {
                              console.log('Errore in settaggio permessi sulla cartella allegati commessa', error)
                              return DocumentPermissions.Deny
                            }
                          }}
                          createDirectoryAction={async (
                            rootFolderPath: string,
                            name: string,
                            conflictBehavior: string,
                          ) => {
                            return await client.post(
                              '/api/attivitacommerciale/createfolder',
                              {
                                rootFolder: rootFolderPath,
                                newFolderName: name,
                                conflictBehavior,
                                objectId: activity.id,
                              },
                              {
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                              },
                            )
                          }}
                        />
                      </Tab>
                    </TabbedItem>
                  </DXForm>
                </div>
              )}
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default ActivityEditor
