import ActivitiesPage from '@/routes/activities/ActivitiesPage'
import { RoleRoute, RoleRouteObject, RouteFunctionParams } from '@/types'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import ActivityCreatorRoute from '@/routes/activities/ActivityCreator.route'
import ActivityEditorRoute from '@/routes/activities/ActivityEditor.route'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'

export const activitiesPageRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getAziende = qsAdminApi.azienda().query((builder, qazienda) => {
    builder.orderBy(qazienda.nome.asc())
    builder.select('nome', 'id')
  })

  const getStati = qsAdminApi.stato_att_comm().query((builder, stato) => {
    builder.orderBy(stato.nome.asc())
    builder.select('nome', 'id')
  })

  return defer({
    aziende: await getAziende,
    stati: await getStati,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Sales, Roles.ExternalSales],
  })
}) satisfies LoaderFunction

export const ActivitiesPageRoute: RoleRoute = {
  path: 'activities',
  children: [
    {
      index: true,
      element: <ActivitiesPage />,
      loader: activitiesPageRouteLoader,
      allowedRoles: [
        Roles.Sales,
        Roles.Administrator,
        Roles.GlobalAdministrator,
        Roles.Marketing,
        Roles.Supervisor,
        Roles.ExternalSales,
      ],
    } as RoleRouteObject,
    ActivityCreatorRoute,
    ActivityEditorRoute,
  ],
  errorElement: <RouterErrorHandler />,
  text: 'Attività Commerciali',
  icon: 'bookmark',
  mainMenuRoute: true,
  allowedRoles: [
    Roles.Sales,
    Roles.Administrator,
    Roles.GlobalAdministrator,
    Roles.Marketing,
    Roles.Supervisor,
    Roles.ExternalSales,
  ],
}
