import { TicketActionDescriptions, TicketActions, TicketEditorFormProps } from '@/routes/tickets/TicketEditor.types'
import { ArraySourceComposer } from '@/auth/api/connector'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import {
  argomento_problema,
  azienda,
  nota_ticket,
  piattaforma_ticket,
  qs_applicazione,
  sede,
  stato_ticket,
  ticket,
  user,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
  Tab,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useRevalidator } from 'react-router-dom'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import DataSource from 'devextreme/data/data_source'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ClickEvent } from 'devextreme/ui/button'
import { confirm } from 'devextreme/ui/dialog'
import { StatoTicket } from '@/routes/tickets/TicketEditor.enums'
import { ValidationCallbackData } from 'devextreme/common'
import { useAzureManager } from '@/auth/azure/azureManager'
import { DropDownButton, Popup, ScrollView } from 'devextreme-react'
import TicketEditorCompleteForm from '@/routes/tickets/TicketEditor.complete'
import TicketEditorNoteForm from '@/routes/tickets/TicketEditor.note'
import './TicketEditor.form.scss'
import NoteView from '@/routes/tickets/notes/NoteView'
import TicketEditorEvents from '@/routes/tickets/TIcketEditor.events'
import AttachmentsManager from '@/components/file-manager/AttachmentsManager'
import { v4 as uuidv4 } from 'uuid'
import { customButton, isUserRoleAllowed, toolbarHtmlEditor } from '@/routes/utils'
import { PopupRef } from 'devextreme-react/popup'
import { FileManagerRef } from 'devextreme-react/file-manager'
import SiteSimpleItem from '@/components/site-simple-item/SiteSimpleItem'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import notify from 'devextreme/ui/notify'
import { Roles } from '@/auth/azure/Roles'
import { DocumentPermissions } from '@/enums'
import AssignSelector from '@/routes/tickets/AssignSelector'
import { useScreenSize } from '@/themes/media-query'

const TicketEditorForm = (props: TicketEditorFormProps) => {
  const {
    creating,
    ticket,
    states,
    platforms,
    applications,
    clients,
    problemCategories,
    categories,
    employees,
    problemArguments,
    isRoleAllowed,
    userInfo,
    fetcher,
  } = props
  console.log(problemArguments)
  const newTicketNote: nota_ticket = {
    id: 0,
    data: null,
    inviata: false,
    testo: null,
    ticket,
    chiusura: false,
    tag: uuidv4(),
  }

  const popupActionRef = useRef<PopupRef>(null)
  const popupNoteClienteRef = useRef<PopupRef>(null)
  const fileManagerRef = useRef<FileManagerRef>(null)

  const { clientId } = useParams()
  const { service, client } = useQsAdminApiManager()

  const currentScreenSize = useScreenSize()
  const revalidator = useRevalidator()
  const formRef = useRef<FormRef>(null)

  const [currentSite, setCurrentSite] = useState<sede | null | undefined>()

  const [popup, setPopup] = useState<{
    visible: boolean
    action: TicketActions
    ticket?: ticket | null
    complete?: { ticket: ticket | null | undefined; closure: string; solution: string }
    note?: nota_ticket
  }>({
    visible: false,
    action: TicketActions.None,
  })

  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId ? (clients ? clients[0] : undefined) : ticket && 'sede' in ticket ? ticket?.sede?.azienda : undefined,
  )
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId && creating ? true : false)

  const [sediDataSource, setSediDataSource] = useState<DataSource>(
    ArraySourceComposer('id', ticket && 'sede' in ticket ? ticket?.sede?.azienda?.sedi : []),
  )
  const [problemArgumentsDataSource, setProblemArgumentsDataSource] = useState<DataSource>(
    ArraySourceComposer('id', problemArguments ?? []),
  )
  const [currentPlatform, setCurrentPlatform] = useState<piattaforma_ticket | null | undefined>(
    ticket && 'piattaforma' in ticket ? ticket?.piattaforma : undefined,
  )
  const [currentApplication, setCurrentApplication] = useState<qs_applicazione | null | undefined>(
    ticket && 'applicazione' in ticket ? ticket?.applicazione : undefined,
  )

  const popupAssignRef = useRef<PopupRef>(null)
  const [assignToData, setAssignToData] = useState<{
    formData: {
      technician: user | null | undefined
    }
    onSubmitEditor: (
      e: any,
      params: {
        ticketId: string | null | undefined
        userId: number | null | undefined
      },
    ) => Promise<void>
    tecniciList: (user | null | undefined)[]
    ticket: ticket | null | undefined
  }>()

  const getCompanySites = useCallback(
    async (companyId: number) => {
      const sitesData = await service.sede().query((builder, sede) => {
        builder.filter(sede.azienda.props.id.eq(companyId))
        builder.orderBy(sede.principale.desc())
        builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
            sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi')
        })
        builder.select('id', 'nome', 'azienda', 'note')
      })
      return sitesData.data.value
    },
    [service],
  )

  const getProblemArguments = async (
    applicationName: string | null | undefined,
    platformName: string | null | undefined,
  ) => {
    const problemArgumentsData = await service.argomento_problema().query((builder, argomentoProblema) => {
      builder.filter(
        argomentoProblema.piattaforma.props.nome
          .eq(platformName ?? null)
          .or(argomentoProblema.qs_applicazione.props.nome.eq(applicationName ?? null)),
      )
      builder.select('id', 'nome')
      builder.orderBy(argomentoProblema.ord.asc())
    })
    return [...new Map(problemArgumentsData.data.value.map((arg: argomento_problema) => [arg['nome'], arg])).values()]
  }

  const handleOnClickDelete = async (e: ClickEvent) => {
    const result = confirm(`<i>Vuoi davvero eliminare il ticket <b>${ticket?.ded_Dis}</b>?</i>`, 'Eliminazione Ticket')
    result.then((dialogResult) => {
      if (dialogResult === false) return
      fetcher?.submit(JSON.stringify(ticket), {
        method: 'put',
        action: `/tickets/${ticket?.id}/cancel`,
        encType: 'application/json',
      })
    })
  }

  const handleOnClickAssignToMe = async (e: ClickEvent) => {
    if (ticket?.assegnato && ticket?.assegnato.email !== userInfo?.email) {
      const result = confirm(
        `<i>Il ticket <b>${ticket.ded_Dis}</b> è assegnato all'utente ${ticket.assegnato.fullname}. Si desidera procedere ugualmente?</i>`,
        'Assegnazione Ticket',
      )
      result.then((dialogResult) => {
        if (dialogResult === false) return false
        fetcher?.submit(
          JSON.stringify({
            ticketId: ticket.ded_Dis,
            userId: employees.find((employee) => employee.email === userInfo?.email)?.id,
          }),
          {
            method: 'post',
            action: `/tickets/${ticket?.id}/assign`,
            encType: 'application/json',
          },
        )
      })
    } else
      fetcher?.submit(
        JSON.stringify({
          ticketId: ticket?.ded_Dis,
          userId: employees.find((employee) => employee.email === userInfo?.email)?.id,
        }),
        {
          method: 'post',
          action: `/tickets/${ticket?.id}/assign`,
          encType: 'application/json',
        },
      )
  }

  const handleOnClickAssignTo = (e: ClickEvent) => {
    const tecnici: (user | null | undefined)[] = []
    tecnici.push(...employees)
    setAssignToData({
      ticket,
      tecniciList: tecnici,
      formData: {
        technician: null,
      },
      onSubmitEditor: async (
        e: any,
        params: {
          ticketId: string | null | undefined
          userId: number | null | undefined
        },
      ) => {
        e.preventDefault()
        try {
          fetcher?.submit(JSON.stringify(params), {
            method: 'post',
            action: `/tickets/${ticket?.id}/assign`,
            encType: 'application/json',
          })
        } catch (error: unknown) {
          notify(`Errore assegnazione ticket '${ticket?.ded_Dis}'. Dettagli : ${error}`, 'error', 2000)
        } finally {
          popupAssignRef.current?.instance().hide()
        }
      },
    })

    popupAssignRef.current?.instance().show()
  }

  const handleOnClickTakeCharge = async (e: ClickEvent) => {
    if (
      (ticket?.incaricato && ticket?.incaricato.email !== userInfo?.email) ||
      (ticket?.assegnato && ticket?.assegnato.email !== userInfo?.email)
    ) {
      const result =
        ticket?.incaricato && ticket?.incaricato.email !== userInfo?.email
          ? confirm(
              `<i>Il ticket <b>${ticket.ded_Dis}</b> è in carico all'utente ${ticket.incaricato.fullname}. Si desidera procedere ugualmente?</i>`,
              'Assegnazione Ticket',
            )
          : confirm(
              `<i>Il ticket <b>${ticket.ded_Dis}</b> è assegnato all'utente ${ticket.assegnato?.fullname}. Si desidera procedere ugualmente?</i>`,
              'Assegnazione Ticket',
            )
      result.then((dialogResult) => {
        if (dialogResult === false) return false
        fetcher?.submit(JSON.stringify(ticket), {
          method: 'put',
          action: `/tickets/${ticket.id}/takecharge`,
          encType: 'application/json',
        })
      })
    } else
      fetcher?.submit(JSON.stringify(ticket), {
        method: 'put',
        action: `/tickets/${ticket?.id}/takecharge`,
        encType: 'application/json',
      })
  }

  const handleOnClickRemoveAssignee = async (e: ClickEvent) => {
    if (ticket?.assegnato && ticket?.assegnato.email !== userInfo?.email) {
      const result = confirm(
        `<i>Il ticket <b>${ticket.ded_Dis}</b> è assegnato all'utente ${ticket.assegnato.fullname}. Si desidera procedere ugualmente?</i>`,
        'Rimozione Assegnazione Ticket',
      )
      result.then((dialogResult) => {
        if (dialogResult === false) return
        fetcher?.submit(JSON.stringify(ticket), {
          method: 'put',
          action: `/tickets/${ticket.id}/removeassignee`,
          encType: 'application/json',
        })
      })
    } else
      fetcher?.submit(JSON.stringify(ticket), {
        method: 'put',
        action: `/tickets/${ticket?.id}/removeassignee`,
        encType: 'application/json',
      })
  }

  const handleOnClickRelease = async (e: ClickEvent) => {
    if (ticket?.incaricato && ticket?.incaricato.email !== userInfo?.email) {
      const result = confirm(
        `<i>Il ticket <b>${ticket.ded_Dis}</b> è in carico all'utente ${ticket.incaricato.fullname}. Si desidera procedere ugualmente?</i>`,
        'Rilascio Ticket',
      )
      result.then((dialogResult) => {
        if (dialogResult === false) return
        fetcher?.submit(JSON.stringify(ticket), {
          method: 'put',
          action: `/tickets/${ticket.id}/release`,
          encType: 'application/json',
        })
      })
    } else
      fetcher?.submit(JSON.stringify(ticket), {
        method: 'put',
        action: `/tickets/${ticket?.id}/release`,
        encType: 'application/json',
      })
  }

  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', currentCompany)
    if (currentCompany) {
      getCompanySites(currentCompany.id).then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        formRef.current?.instance().updateData('sede', sites[0])
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, currentCompany, getCompanySites])

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle')} />
      <DXForm
        id={'ticket_form'}
        formData={ticket}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="interventionValidation"
        ref={formRef}
        readOnly={
          !!(
            !isRoleAllowed ||
            (!creating && ticket?.stato?.id === StatoTicket.APERTO) ||
            (ticket?.assegnato && ticket?.assegnato.email !== userInfo?.email)
          )
        }
      >
        <GroupItem colCount={4}>
          <GroupItem colCount={2} colSpan={2}>
            <GroupItem colCount={3} colSpan={2}>
              <SimpleItem dataField="leg_contatto" editorType="dxTextBox" label={{ text: 'Contatto' }}>
                <RequiredRule message="Contatto obbligatorio"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                dataField="assegnato"
                label={{ text: 'Assegnato' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', employees),
                  placeholder: 'Seleziona utente...',
                  displayExpr: 'fullname',
                  searchEnabled: true,
                  dropDownCentered: true,
                  readOnly: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: `Selezionare l'utente`,
                    hideOnOutsideClick: true,
                  },
                  showClearButton: true,
                }}
              ></SimpleItem>
              <SimpleItem
                dataField="incaricato"
                label={{ text: 'Incaricato' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', employees),
                  placeholder: 'Seleziona utente...',
                  displayExpr: 'fullname',
                  searchEnabled: true,
                  dropDownCentered: true,
                  readOnly: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: `Selezionare l'utente`,
                    hideOnOutsideClick: true,
                  },
                  showClearButton: true,
                }}
              ></SimpleItem>
            </GroupItem>
            <GroupItem colCount={4} colSpan={2}>
              <SimpleItem
                label={{ text: 'Azienda' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    customButton('open', (e: ClickEvent) => {
                      if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                      else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                    }),
                    { name: 'dropDown', location: 'after' },
                  ],
                  value: currentCompany,
                  dataSource: ArraySourceComposer('id', clients),
                  placeholder: "Seleziona l'azienda...",
                  displayExpr: 'nome',
                  searchEnabled: true,
                  readOnly:
                    !isRoleAllowed ||
                    (clientId
                      ? true
                      : (!creating && ticket?.stato?.id === StatoTicket.APERTO) ||
                          (ticket?.assegnato && ticket?.assegnato.email !== userInfo?.email)
                        ? true
                        : false),
                  dropDownOptions: {
                    showTitle: true,
                    title: "Selezionare l'azienda",
                    hideOnOutsideClick: true,
                    width: currentScreenSize.isLarge ? '150%' : '',
                    resizeEnabled: currentScreenSize.isLarge ? true : false,
                  },
                  showClearButton: true,
                  onValueChanged: (e: ValueChangedEventSelectBox) => {
                    if (e.previousValue?.id === e.value?.id) return
                    console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                    if (e.value) {
                      setCurrentCompany(e.value)
                      setCurrentCompanyChanged(true)
                    }
                  },
                }}
              >
                <CustomRule
                  message="Azienda obbligatoria se stato 'ASSEGNATO'"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    return ticket?.stato?.id === StatoTicket.ASSEGNATO && !value ? false : true
                  }}
                />
                <CustomRule
                  message="Azienda obbligatoria se stato 'COMPLETATO'"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    return ticket?.stato?.id === StatoTicket.COMPLETATO && !value ? false : true
                  }}
                />
              </SimpleItem>
              {SiteSimpleItem({
                dataSource: sediDataSource,
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  console.log('site event changed')
                },
                rules: [
                  {
                    type: 'custom',
                    message: `Sede obbligatoria se stato 'ASSEGNATO'`,
                    validationCallback: ({ value }: ValidationCallbackData) => {
                      return ticket?.stato?.id === StatoTicket.ASSEGNATO && !value ? false : true
                    },
                  },
                  {
                    type: 'custom',
                    message: `Sede obbligatoria se stato 'COMPLETATO'`,
                    validationCallback: ({ value }: ValidationCallbackData) => {
                      return ticket?.stato?.id === StatoTicket.COMPLETATO && !value ? false : true
                    },
                  },
                ],
                readOnly:
                  !isRoleAllowed ||
                  (!creating && ticket?.stato?.id === StatoTicket.APERTO) ||
                  (ticket?.assegnato && ticket?.assegnato.email !== userInfo?.email)
                    ? true
                    : false,
                onInfoClick: () => {
                  console.log('ticket.sede', ticket?.sede)
                  if (ticket?.sede) {
                    setCurrentSite(ticket.sede)
                    popupNoteClienteRef.current?.instance().show()
                  } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
                },
              })}
              <SimpleItem dataField="leg_email_contatto" editorType="dxTextBox" label={{ text: 'Email Riferimento' }}>
                <CustomRule
                  message="Email riferimento obbligatoria se stato 'ASSEGNATO'"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    return ticket?.stato?.id === StatoTicket.ASSEGNATO && !value ? false : true
                  }}
                />
                <CustomRule
                  message="Email riferimento obbligatoria se stato 'COMPLETATO'"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    return ticket?.stato?.id === StatoTicket.COMPLETATO && !value ? false : true
                  }}
                />
              </SimpleItem>
              <SimpleItem
                dataField="leg_phone_contatto"
                editorType="dxTextBox"
                label={{ text: 'Telefono' }}
              ></SimpleItem>
            </GroupItem>
            <GroupItem colCount={5} colSpan={2}>
              <SimpleItem
                dataField="tipologia_ticket"
                label={{ text: 'Categoria' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', categories),
                  placeholder: 'Seleziona categoria...',
                  displayExpr: 'nome',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: `Selezionare la categoria`,
                    hideOnOutsideClick: true,
                  },
                }}
              >
                <RequiredRule message="Categoria obbligatoria" />
              </SimpleItem>
              <SimpleItem
                dataField="stato"
                label={{ text: 'Stato' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer(
                    'id',
                    creating
                      ? states.filter((state: stato_ticket) => {
                          return state.id === StatoTicket.APERTO
                        })
                      : states,
                  ),
                  readOnly: true,
                  placeholder: 'Seleziona lo stato...',
                  displayExpr: 'nome',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare lo stato',
                    hideOnOutsideClick: true,
                  },
                  onValueChanged: (e: ValueChangedEvent) => {
                    if (e.previousValue?.id === e.value?.id) return
                    console.log('STATE EVENT CHANGED', e.previousValue, e.value)
                    if (e.value.id === StatoTicket.APERTO) formRef.current?.instance().updateData('assegnato', null)
                  },
                }}
              >
                <RequiredRule message="Stato obbligatorio" />
              </SimpleItem>
              <SimpleItem
                dataField="durata"
                editorType="dxNumberBox"
                label={{ text: 'Durata' }}
                editorOptions={{
                  showSpinButtons: true,
                  min: 0,
                  step: 1,
                }}
              >
                <RequiredRule message="Durata obbligatoria"></RequiredRule>
              </SimpleItem>
              <SimpleItem dataField="urgente" label={{ text: 'Urgente' }} editorType="dxSwitch" />
              <SimpleItem dataField="attesa" label={{ text: 'Attesa' }} editorType="dxSwitch" />
            </GroupItem>
            <SimpleItem
              dataField="piattaforma"
              label={{ text: 'Piattaforma' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', platforms),
                placeholder: 'Seleziona piattaforma...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: `Selezionare la piattaforma`,
                  hideOnOutsideClick: true,
                },
                showClearButton: true,
                onValueChanged: (e: ValueChangedEvent) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('PLATFORMS EVENT CHANGED', e.previousValue, e.value)
                  setCurrentPlatform(e.value)
                  getProblemArguments(
                    currentApplication ? currentApplication?.nome : null,
                    e.value ? e.value.nome : null,
                  ).then((args: argomento_problema[]) => {
                    console.log('ARGS', args)
                    setProblemArgumentsDataSource(ArraySourceComposer('id', args))
                  })
                },
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="applicazione"
              label={{ text: 'Software Qs' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', applications),
                placeholder: 'Seleziona applicazione...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                showClearButton: true,
                dropDownOptions: {
                  showTitle: true,
                  title: `Selezionare l'applicazione`,
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEvent) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('APPLICATION EVENT CHANGED', e.previousValue, e.value)
                  setCurrentApplication(e.value)
                  getProblemArguments(
                    e.value ? e.value.nome : null,
                    currentPlatform ? currentPlatform?.nome : null,
                  ).then((args: argomento_problema[]) => {
                    console.log('ARGS', args)
                    setProblemArgumentsDataSource(ArraySourceComposer('id', args))
                  })
                },
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="tipologia_problema"
              label={{ text: 'Categoria Problema' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', problemCategories),
                placeholder: 'Seleziona categoria problema...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: `Selezionare la categoria problema`,
                  hideOnOutsideClick: true,
                },
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="argomento_problema"
              label={{ text: 'Argomento Problema' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: problemArgumentsDataSource,
                placeholder: 'Seleziona argomento problema...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: `Selezionare l'argomento problema`,
                  hideOnOutsideClick: true,
                },
              }}
            ></SimpleItem>
            {!creating && (
              <GroupItem colSpan={2}>
                <AttachmentsManager
                  fileManagerRef={fileManagerRef}
                  folderUrl={ticket?.attachmentsFolderUrl}
                  preliminarPermissionsAction={async () => {
                    try {
                      const response = await client.post(
                        '/api/ticket/drivepermissions',
                        {
                          objectId: ticket?.id,
                          path: ticket?.attachmentsFolderUrl,
                        },
                        {
                          headers: {
                            'Content-Type': 'application/json',
                          },
                        },
                      )
                      return response.status === 200 ? (response.data as DocumentPermissions) : DocumentPermissions.Deny
                    } catch (error) {
                      console.log('Errore in settaggio permessi sulla cartella allegati commessa', error)
                      return DocumentPermissions.Deny
                    }
                  }}
                  createDirectoryAction={async (rootFolderPath: string, name: string, conflictBehavior: string) => {
                    return await client.post(
                      '/api/ticket/createfolder',
                      {
                        rootFolder: rootFolderPath,
                        newFolderName: name,
                        conflictBehavior,
                        objectId: ticket?.id,
                      },
                      {
                        headers: {
                          'Content-Type': 'application/json',
                        },
                      },
                    )
                  }}
                />
              </GroupItem>
            )}
          </GroupItem>
          <GroupItem colSpan={2}>
            <TabbedItem>
              <TabPanelOptions
                animationEnabled={true}
                swipeEnabled={false}
                deferRendering={false}
                scrollingEnabled={true}
              />
              {!creating && (
                <Tab title="Note">
                  <SimpleItem
                    editorType={'dxHtmlEditor'}
                    label={{ visible: false }}
                    editorOptions={{ readOnly: true }}
                    render={() => {
                      return (
                        <>
                          <ScrollView height={700} width={'100%'} direction="vertical">
                            <div>
                              <div id="chat-window">
                                {ticket?.note_ticket && ticket?.note_ticket?.length > 0
                                  ? ticket?.note_ticket?.map((nota: nota_ticket) => {
                                      return (
                                        <>
                                          <NoteView
                                            note={nota}
                                            readOnly={
                                              !isRoleAllowed ||
                                              nota.chiusura ||
                                              nota.inviata ||
                                              !nota.utente ||
                                              (nota.utente && nota.utente.fullname !== userInfo?.name)
                                                ? true
                                                : false
                                            }
                                            onEditClick={(e: ClickEvent) => {
                                              setPopup({
                                                visible: true,
                                                action:
                                                  !isRoleAllowed ||
                                                  nota.chiusura ||
                                                  nota.inviata ||
                                                  !nota.utente ||
                                                  (nota.utente && nota.utente.fullname !== userInfo?.name)
                                                    ? TicketActions.ShowTicketNote
                                                    : TicketActions.EditTicketNote,
                                                note: nota,
                                                ticket,
                                              })
                                              popupActionRef.current?.instance().show()
                                            }}
                                          ></NoteView>
                                        </>
                                      )
                                    })
                                  : 'Nessuna nota associata al ticket'}
                              </div>
                            </div>
                          </ScrollView>
                        </>
                      )
                    }}
                  ></SimpleItem>
                </Tab>
              )}
              <Tab title="Motivo">
                <SimpleItem
                  dataField={'descrizione'}
                  editorType={'dxHtmlEditor'}
                  label={{ visible: false }}
                  editorOptions={{
                    stylingMode: 'filled',
                    readOnly: !creating,
                    valueType: 'html',
                    toolbar: toolbarHtmlEditor(currentScreenSize),
                    mediaResizing: {
                      enabled: true,
                    },
                    imageUpload: {
                      tabs: ['file', 'url'],
                      fileUploadMode: 'base64',
                    },
                    height: '400',
                  }}
                >
                  {creating && <RequiredRule message="Motivo Obbligatorio"></RequiredRule>}
                </SimpleItem>
              </Tab>
              {!creating && (
                <Tab title="Eventi">
                  <TicketEditorEvents ticket={ticket}></TicketEditorEvents>
                </Tab>
              )}
              {ticket?.stato?.id === StatoTicket.COMPLETATO && (
                <Tab title="Soluzione">
                  <SimpleItem
                    dataField={'soluzione'}
                    editorType={'dxHtmlEditor'}
                    label={{ visible: false }}
                    editorOptions={{
                      stylingMode: 'filled',
                      readOnly: true,
                      valueType: 'html',
                      toolbar: toolbarHtmlEditor(currentScreenSize),
                      mediaResizing: {
                        enabled: true,
                      },
                      imageUpload: {
                        tabs: ['file', 'url'],
                        fileUploadMode: 'base64',
                      },
                      height: '400',
                    }}
                  ></SimpleItem>
                </Tab>
              )}
              {ticket?.stato?.id === StatoTicket.COMPLETATO && ticket.rating && (
                <Tab title="Rating">
                  <SimpleItem
                    dataField={'rating_note'}
                    editorType={'dxHtmlEditor'}
                    label={{ visible: false }}
                    editorOptions={{
                      stylingMode: 'filled',
                      readOnly: true,
                      valueType: 'html',
                      height: '400',
                    }}
                  ></SimpleItem>
                </Tab>
              )}
            </TabbedItem>
          </GroupItem>
        </GroupItem>
        <GroupItem visible={isRoleAllowed} cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={creating ? 2 : 4}>
            {!creating && (
              <SimpleItem>
                <DropDownButton
                  id="ddbAzioni"
                  text="Azioni"
                  useItemTextAsTitle={false}
                  items={[
                    {
                      icon: 'email',
                      text: 'Invia nota',
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.TechDeveloper]) &&
                        !creating &&
                        ticket?.stato?.id !== StatoTicket.APERTO &&
                        ticket?.stato?.id !== StatoTicket.COMPLETATO &&
                        ticket?.stato?.id !== StatoTicket.ELIMINATO &&
                        ticket?.assegnato &&
                        ticket?.assegnato.email === userInfo?.email
                          ? true
                          : false,
                      onClick: (e: ClickEvent) => {
                        const validationResult = formRef.current?.instance().validate()
                        if (!validationResult?.isValid) return
                        setPopup({ visible: true, action: TicketActions.SendTicketNote, note: newTicketNote, ticket })
                        popupActionRef.current?.instance().show()
                      },
                    },
                    {
                      icon: 'comment',
                      text: 'Nota interna',
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [
                          Roles.GlobalAdministrator,
                          Roles.TechDeveloper,
                          Roles.Administrator,
                        ]) && !creating,
                      onClick: (e: ClickEvent) => {
                        setPopup({ visible: true, action: TicketActions.CreateTicketNote, note: newTicketNote, ticket })
                        popupActionRef.current?.instance().show()
                      },
                    },
                    {
                      icon: 'user',
                      text: `Assegna a me`,
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.TechDeveloper]) &&
                        ticket?.stato?.id !== StatoTicket.COMPLETATO &&
                        ticket?.stato?.id !== StatoTicket.ELIMINATO &&
                        !ticket?.assegnato,
                      onClick: handleOnClickAssignToMe,
                    },
                    {
                      icon: 'user',
                      text: `Assegna a`,
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.Supervisor]) &&
                        ticket?.stato?.id !== StatoTicket.COMPLETATO &&
                        ticket?.stato?.id !== StatoTicket.ELIMINATO &&
                        !ticket?.assegnato,
                      onClick: handleOnClickAssignTo,
                    },
                    {
                      icon: 'cart',
                      text: `Prendi in carico`,
                      onClick: handleOnClickTakeCharge,
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.TechDeveloper]) &&
                        !creating &&
                        ticket?.stato?.id !== StatoTicket.COMPLETATO &&
                        ticket?.stato?.id !== StatoTicket.ELIMINATO &&
                        !ticket?.incaricato &&
                        (!ticket?.assegnato || ticket.assegnato.email === userInfo?.email)
                          ? true
                          : false,
                    },
                    {
                      icon: 'arrowback',
                      text: 'Rimuovi assegnazione',
                      onClick: handleOnClickRemoveAssignee,
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.TechDeveloper]) &&
                        ticket?.stato?.id !== StatoTicket.COMPLETATO &&
                        ticket?.stato?.id !== StatoTicket.ELIMINATO &&
                        ticket?.assegnato,
                    },
                    {
                      icon: 'arrowback',
                      text: 'Rilascia',
                      onClick: handleOnClickRelease,
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.TechDeveloper]) &&
                        ticket?.stato?.id !== StatoTicket.COMPLETATO &&
                        ticket?.stato?.id !== StatoTicket.ELIMINATO &&
                        ticket?.incaricato &&
                        ticket?.incaricato.email === userInfo?.email,
                    },
                    {
                      icon: 'check',
                      text: 'Completa',
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.TechDeveloper]) &&
                        !creating &&
                        ticket?.stato?.id !== StatoTicket.APERTO &&
                        ticket?.stato?.id !== StatoTicket.COMPLETATO &&
                        ticket?.stato?.id !== StatoTicket.ELIMINATO &&
                        ticket?.assegnato &&
                        ticket?.assegnato.email === userInfo?.email
                          ? true
                          : false,
                      onClick: (e: ClickEvent) => {
                        const validationResult = formRef.current?.instance().validate()
                        if (!validationResult?.isValid) return
                        setPopup({
                          visible: true,
                          action: TicketActions.Complete,
                          complete: { closure: '', solution: '', ticket },
                        })
                        popupActionRef.current?.instance().show()
                      },
                    },
                    {
                      icon: 'close',
                      text: 'Elimina',
                      onClick: handleOnClickDelete,
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.TechDeveloper]) &&
                        !creating &&
                        ticket?.stato?.id !== StatoTicket.COMPLETATO &&
                        ticket?.stato?.id !== StatoTicket.ELIMINATO &&
                        ticket?.assegnato &&
                        ticket?.assegnato.email === userInfo?.email
                          ? true
                          : false,
                    },
                  ]}
                  dropDownOptions={{ width: '250' }}
                  width={'150px'}
                />
              </SimpleItem>
            )}
            <ButtonItem name="btn-historic">
              <ButtonOptions
                text="Storico"
                icon="eyeopen"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={(e: ClickEvent) => {
                  window.open(`/tickets/historic`, '_blank')
                }}
              />
            </ButtonItem>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!formRef.current?.instance().option().isDirty}
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  if (ticket) {
                    console.log('ticket', ticket)
                    fetcher?.submit(JSON.stringify(ticket), {
                      method: creating ? 'POST' : 'PUT',
                      encType: 'application/json',
                    })
                  } else console.error('no data tu submit')
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  disabled={!formRef.current?.instance().option().isDirty}
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <Popup
        width={300}
        height={300}
        hideOnOutsideClick={true}
        showCloseButton={true}
        deferRendering={true}
        title={'Assegna a'}
        ref={popupAssignRef}
        className="assignto-popup-content"
      >
        <AssignSelector data={assignToData} />
      </Popup>
      <Popup
        ref={popupActionRef}
        id="ticket_action_popup"
        dragEnabled={false}
        hideOnOutsideClick={false}
        showCloseButton={true}
        showTitle={true}
        title={TicketActionDescriptions[popup.action]}
        container=".dx-viewport"
        resizeEnabled={true}
        width={currentScreenSize.isLarge || currentScreenSize.isMedium ? 1000 : currentScreenSize.isXSmall ? 350 : 700}
        height={currentScreenSize.isXSmall ? 700 : currentScreenSize.isSmall ? 300 : 780}
      >
        {popup.action === TicketActions.Complete ? (
          <TicketEditorCompleteForm
            complete={popup.complete}
            action={popup.action}
            onSaveClick={() => {
              fetcher?.submit(JSON.stringify(popup.complete), {
                method: 'POST',
                action: `/tickets/${ticket?.id}/complete`,
                encType: 'application/json',
              })
              popupActionRef.current?.instance().hide()
            }}
          ></TicketEditorCompleteForm>
        ) : (
          <TicketEditorNoteForm
            ticket={popup.ticket}
            note={popup.note}
            action={popup.action}
            readOnly={
              (popup.action === TicketActions.EditTicketNote || popup.action === TicketActions.ShowTicketNote) &&
              (popup.note?.chiusura ||
                popup.note?.inviata ||
                !popup.note?.utente ||
                (popup.note?.utente && popup.note?.utente.fullname !== userInfo?.name))
                ? true
                : false
            }
            onSaveClick={() => {
              fetcher?.submit(
                JSON.stringify({
                  ticket,
                  hasToBeSent: popup.note?.inviata ? true : popup.action === TicketActions.SendTicketNote,
                  note: popup.note,
                }),
                {
                  method: popup.action === TicketActions.EditTicketNote ? 'PUT' : 'POST',
                  action: `/tickets/${ticket?.id}/ticketnote`,
                  encType: 'application/json',
                },
              )
              popupActionRef.current?.instance().hide()
            }}
          ></TicketEditorNoteForm>
        )}
      </Popup>
      <FormPopupClientNote
        data={currentSite}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites(currentCompany?.id).then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              formRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === currentSite?.id
                }),
              )
            })
          }
        }}
        isRoleAllowed={isUserRoleAllowed(userInfo?.roles, [
          Roles.TechDeveloper,
          Roles.Sales,
          Roles.GlobalAdministrator,
          Roles.ExternalSales,
          Roles.Administrator,
        ])}
      ></FormPopupClientNote>
    </>
  )
}

export default TicketEditorForm
