import {
  ButtonItem,
  ButtonOptions,
  Form as DxForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
  Tab,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form'
import type { FieldDataChangedEvent } from 'devextreme/ui/form'
import DataSource from 'devextreme/data/data_source'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useRevalidator } from 'react-router-dom'
import { FormMode } from '@/enums'
import type { sede, azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ArraySourceComposer } from '@/auth/api/connector'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { SoftwareAltrePiattaformeEditorFormProps } from '@/routes/software/altre-piattaforme/SoftwareAltrePiattaformeEditor.types'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import SiteSimpleItem from '@/components/site-simple-item/SiteSimpleItem'
import notify from 'devextreme/ui/notify'
import { PopupRef } from 'devextreme-react/cjs/popup'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { customButton, isUserRoleAllowed } from '@/routes/utils'
import { ClickEvent } from 'devextreme/ui/button'
import { Roles } from '@/auth/azure/Roles'

export const SoftwareAltrePiattaformeEditorForm = (props: SoftwareAltrePiattaformeEditorFormProps) => {
  const {
    mode,
    license,
    altrePiattaformeRentStatus,
    altrePiattaformeMaintenancePlans,
    altrePiattaformePlatforms,
    aziendaList,
    isRoleAllowed,
    userInfo,
    fetcher,
  } = props

  const today = new Date().toISOString()

  const { clientId } = useParams()
  const revalidator = useRevalidator()
  const formAltrePiattaformeRef = useRef<FormRef>(null)
  const qsAdminApi = useQsAdminApiManager.getState().service
  const popupNoteClienteRef = useRef<PopupRef>(null)
  const [currentSite, setCurrentSite] = useState<sede | null | undefined>()

  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(
    clientId && mode === FormMode.Create ? true : false,
  )
  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId
      ? aziendaList
        ? aziendaList[0]
        : undefined
      : license && 'sede' in license
        ? license?.sede?.azienda
        : undefined,
  )
  const [sediDataSource, setSediDataSource] = useState<DataSource>(
    ArraySourceComposer('id', license && 'sede' in license ? license?.sede?.azienda?.sedi : undefined),
  )

  const getCompanySites = useCallback(
    async (companyId: number) => {
      const sitesData = await qsAdminApi.sede().query((builder, qsede) => {
        builder.filter(qsede.azienda.props.id.eq(companyId))
        builder.orderBy(qsede.principale.desc())
        builder.expand('azienda')
      })
      return sitesData.data.value
    },
    [qsAdminApi],
  )

  const handleFieldDataChanged = (e: FieldDataChangedEvent) => {
    console.log('FORMDATACHANGED', e.component.option('formData'), license, license)
    if (
      formAltrePiattaformeRef.current?.instance().option().isDirty !== null &&
      formAltrePiattaformeRef.current?.instance().option().isDirty !== undefined
    ) {
      console.log('is dirty', formAltrePiattaformeRef.current?.instance().option().isDirty)
      formAltrePiattaformeRef.current
        .instance()
        .getButton('btn-save')
        ?.option('disabled', !formAltrePiattaformeRef.current?.instance().option().isDirty)
      formAltrePiattaformeRef.current
        .instance()
        .getButton('btn-cancel')
        ?.option('disabled', !formAltrePiattaformeRef.current?.instance().option().isDirty)
    }
  }

  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', currentCompany)
    if (currentCompany) {
      getCompanySites(currentCompany?.id).then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        formAltrePiattaformeRef.current?.instance().updateData('sede', sites[0])
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, currentCompany, getCompanySites])

  return (
    <>
      <LoadingPanel
        visible={!((fetcher?.state === 'idle' || mode === FormMode.View) && revalidator.state === 'idle')}
      />
      <DxForm
        id={`altrepiattaforme_editor_form ${license && 'id' in license ? license?.id : 'new'}`}
        key={`altrepiattaforme_editor_form ${license && 'id' in license ? license?.id : 'new'}`}
        formData={license}
        ref={formAltrePiattaformeRef}
        onFieldDataChanged={handleFieldDataChanged}
        // onContentReady={onContent}
        validationGroup={`altrePiattaformeLicenseDataValidation ${license && 'id' in license ? license?.id : 'new'}`}
        readOnly={mode === FormMode.View || !isRoleAllowed}
      >
        <GroupItem colCount={2}>
          <GroupItem colCount={2} cssClass="parentHeight">
            <SimpleItem
              label={{ text: 'Azienda' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton('open', (e: ClickEvent) => {
                    if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                    else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                  }),
                  { name: 'dropDown', location: 'after' },
                ],
                value: currentCompany,
                dataSource: ArraySourceComposer('id', aziendaList),
                placeholder: "Seleziona l'azienda...",
                displayExpr: 'nome',
                searchEnabled: true,
                readOnly: mode === FormMode.View || !isRoleAllowed || (clientId ? true : false),
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'azienda",
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                  setCurrentCompany(e.value)
                  setCurrentCompanyChanged(true)
                },
              }}
            >
              <RequiredRule message="Il nome del cliente è obbligatorio" />
            </SimpleItem>
            {SiteSimpleItem({
              dataSource: sediDataSource,
              rules: [
                {
                  type: 'required',
                  message: `Sede obbligatoria`,
                },
              ],
              onInfoClick: () => {
                if (license && 'sede' in license) {
                  setCurrentSite(license.sede)
                  popupNoteClienteRef.current?.instance().show()
                } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
              },
              readOnly: mode === FormMode.View || !isRoleAllowed,
            })}
            <SimpleItem
              dataField="software"
              label={{ text: 'Software' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', altrePiattaformePlatforms),
                placeholder: 'Seleziona la piattaforma...',
                displayExpr: 'nome',
                searchEnabled: true,
                showClearButton: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare la piattaforma',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <RequiredRule message="La definizione della piattaforma è obbligatoria" />
            </SimpleItem>
            <SimpleItem dataField="versione" />
            <SimpleItem
              dataField="data_vendita"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                defaultValue: { today },
                pickerType: 'rollers',
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Vendita' }}
            ></SimpleItem>
            <SimpleItem dataField="seriale" label={{ text: 'Numero serie' }} />
            <SimpleItem dataField="n_contratto" label={{ text: 'Numero contratto' }} />
            <SimpleItem dataField="contratto_precedente" label={{ text: 'Contratto Precendente' }} />
            <SimpleItem
              dataField="piano_mantenimento"
              label={{ text: 'Piano mantenimento' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', altrePiattaformeMaintenancePlans),
                placeholder: 'Seleziona il piano di mantenimento...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                showClearButton: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare il piano',
                  hideOnOutsideClick: true,
                },
              }}
            />
            <SimpleItem
              dataField="stato_noleggio"
              label={{ text: 'Noleggio' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', altrePiattaformeRentStatus),
                placeholder: 'Seleziona il tipo di noleggio...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                showClearButton: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare il noleggio',
                  hideOnOutsideClick: true,
                },
              }}
            />
            <SimpleItem dataField="postazioni" />
            <SimpleItem dataField="durata" />
            <SimpleItem
              dataField="scadenza"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                defaultValue: { today },
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Scadenza' }}
            ></SimpleItem>
          </GroupItem>
          <GroupItem colCount={1} colSpan={2}>
            <TabbedItem>
              <TabPanelOptions animationEnabled={true} swipeEnabled={true} deferRendering={false} />
              <Tab title="Note">
                <SimpleItem
                  dataField={'note'}
                  editorType={'dxTextArea'}
                  label={{ visible: false }}
                  editorOptions={{
                    height: '450',
                  }}
                ></SimpleItem>
              </Tab>
              <Tab title="Impostazioni">
                <GroupItem colCount={2}>
                  <SimpleItem dataField="rete" label={{ text: 'NLM' }} editorType="dxSwitch" />
                  <SimpleItem dataField="altro_fornitore" label={{ text: 'Altro dealer' }} editorType="dxSwitch" />
                </GroupItem>
              </Tab>
            </TabbedItem>
          </GroupItem>
        </GroupItem>
        <GroupItem visible={isRoleAllowed} cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCountByScreen={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
            {(mode === FormMode.Edit || mode === FormMode.Create) && (
              <ButtonItem name="btn-save">
                <ButtonOptions
                  disabled={!formAltrePiattaformeRef.current?.instance().option().isDirty}
                  icon="save"
                  text="Salva"
                  width="150px"
                  onClick={async () => {
                    const validationResult = formAltrePiattaformeRef.current?.instance().validate()
                    if (!validationResult?.isValid) return
                    fetcher?.submit(JSON.stringify(license), {
                      method: mode === FormMode.Create ? 'POST' : 'PATCH',
                      encType: 'application/json',
                    })
                  }}
                />
              </ButtonItem>
            )}
            {mode === FormMode.Edit && (
              <ButtonItem
                name="btn-cancel"
                buttonOptions={{
                  disabled: !formAltrePiattaformeRef.current?.instance().option().isDirty,
                  icon: 'undo',
                  text: 'Annulla',
                  width: '150px',
                  onClick() {
                    revalidator.revalidate()
                  },
                }}
              ></ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DxForm>
      <FormPopupClientNote
        data={currentSite}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites(currentCompany?.id).then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              formAltrePiattaformeRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === currentSite?.id
                }),
              )
            })
          }
        }}
        isRoleAllowed={isUserRoleAllowed(userInfo?.roles, [
          Roles.TechDeveloper,
          Roles.Sales,
          Roles.GlobalAdministrator,
          Roles.ExternalSales,
          Roles.Administrator,
        ])}
      ></FormPopupClientNote>
    </>
  )
}
