import { ButtonItem, Form, GroupItem, RequiredRule, SimpleItem } from 'devextreme-react/form'
import { useState } from 'react'
import { ArraySourceComposer } from '@/auth/api/connector'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'

type MonthUserCheckSelectorProps = {
  data:
    | {
        monthList: { index: number; description: string }[]
        formData: {
          month: { index: number; description: string }
        }
        onSubmitEditor: (e: any, params: { month: number | undefined }) => Promise<void>
      }
    | undefined
}

export const MonthUserCheckSelector = (props: MonthUserCheckSelectorProps) => {
  const { data } = props
  const [loadIndicatorVisible, setLoadIndicatorVisible] = useState(false)
  return (
    <>
      <div id="monthusercheck-selector-form-container" className={'dx-card responsive-paddings'}>
        <LoadingPanel
          position={{ of: '#monthusercheck-selector-form-container' }}
          visible={loadIndicatorVisible}
        ></LoadingPanel>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            setLoadIndicatorVisible(true)
            const params = { month: data?.formData.month.index }
            if (data?.onSubmitEditor) {
              data.onSubmitEditor(e, params).finally(() => {
                setLoadIndicatorVisible(false)
              })
            }
          }}
        >
          <Form
            id={`monthusercheck-selector-form`}
            key={`monthusercheck-selector-form`}
            formData={data?.formData}
            validationGroup="monthusercheckValidation"
          >
            <GroupItem colCount={1}>
              <SimpleItem
                dataField="month"
                label={{ text: 'Mese' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('index', data?.monthList),
                  placeholder: 'Seleziona mese...',
                  displayExpr: 'description',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare mese',
                    hideOnOutsideClick: true,
                  },
                }}
              >
                <RequiredRule message="Mese obbligatorio"></RequiredRule>
              </SimpleItem>
            </GroupItem>
            <GroupItem cssClass="last-group">
              <GroupItem cssClass="buttons-group" colCount={1}>
                <ButtonItem
                  name="btn-compute"
                  buttonOptions={{
                    icon: 'check',
                    text: 'Esporta',
                    disabled: false,
                    useSubmitBehavior: true,
                    width: '150px',
                  }}
                ></ButtonItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </>
  )
}

export default MonthUserCheckSelector
