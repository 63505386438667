import { TicketActions, TicketEditorNoteFormProps } from '@/routes/tickets/TicketEditor.types'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { useRef } from 'react'
import { ClickEvent } from 'devextreme/ui/button'
import { useAzureManager } from '@/auth/azure/azureManager'
import { FileUploader, LoadIndicator } from 'devextreme-react'

import { GraphError } from '@microsoft/microsoft-graph-client'
import { MgtTemplateProps, FileList } from '@microsoft/mgt-react'
import { toolbarHtmlEditor } from '@/routes/utils'
import { FileUploaderRef } from 'devextreme-react/file-uploader'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { useScreenSize } from '@/themes/media-query'

const TicketEditorNoteForm = (props: TicketEditorNoteFormProps) => {
  const { note, action, onSaveClick, ticket, readOnly } = props
  const ref = useRef<FormRef>(null)
  const fileUploaderRef = useRef<FileUploaderRef>(null)
  const graphClient = useAzureManager.getState().graphClient
  const { client } = useQsAdminApiManager()
  const currentScreenSize = useScreenSize()

  const MyEvent = (props: MgtTemplateProps) => {
    const { template } = props
    switch (template) {
      case 'no-data': {
        return <div>Nessun allegato caricato</div>
      }
      case 'loading': {
        return <LoadIndicator id="small-indicator" height={20} width={20} />
      }
      default: {
        break
      }
    }
  }

  const extractDirectoryName = (path: string | null | undefined) => {
    if (path === null || path === undefined) return ''
    const pathArray = path.split('/')
    const lastIndex = pathArray.length - 1
    return pathArray[lastIndex]
  }

  const extractParentPath = (path: string | null | undefined) => {
    if (path === null || path === undefined) return ''
    return path.split('/').slice(0, -1).join('/')
  }

  const ensureTicketNoteFolderExists = async () => {
    const currentTicketFolder = ticket?.attachmentsFolderUrl

    try {
      await client.post(
        '/api/ticket/createfolder',
        {
          rootFolder: `${ticket?.attachmentsFolderUrl}/_notes/${note?.tag}`,
          newFolderName: 'attachments',
          conflictBehavior: 'replace',
          objectId: ticket?.id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
    } catch (error_) {
      throw new Error(`${error_}`)
    }
  }

  const uploadToSharePoint = async (file: File) => {
    // Custom handler for the upload
    const currentTicketFolder = ticket?.attachmentsFolderUrl

    await ensureTicketNoteFolderExists()

    return graphClient
      ?.api(
        `drives/${import.meta.env.VITE_QSADMINSITE_DRIVE_ID}/root:/${currentTicketFolder}/_notes/${note?.tag}/attachments/${file.name}:/content`,
      )
      .put(file)
  }

  return (
    <>
      <DXForm
        formData={note}
        id={`ticket_action_form_${action}`}
        labelLocation={'top'}
        showValidationSummary={true}
        validationGroup={`ticketActionPopupValidation_${action}`}
        ref={ref}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            ref.current?.instance().option().isDirty !== null &&
            ref.current?.instance().option().isDirty !== undefined
          ) {
            ref.current.instance().getButton('btn-save')?.option('disabled', !ref.current?.instance().option().isDirty)
          }
        }}
      >
        <GroupItem colCount={1}>
          <SimpleItem
            dataField={'testo'}
            editorType={'dxHtmlEditor'}
            editorOptions={{
              readOnly,
              valueType: 'html',
              toolbar: toolbarHtmlEditor(currentScreenSize),
              mediaResizing: {
                enabled: true,
              },
              imageUpload: {
                tabs: ['file', 'url'],
                fileUploadMode: 'base64',
              },
              height: '400',
            }}
          >
            <RequiredRule message="Testo obbligatorio"></RequiredRule>
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <FileList
            driveId={import.meta.env.VITE_QSADMINSITE_DRIVE_ID}
            itemPath={`${note?.ticket?.attachmentsFolderUrl}/_notes/${note?.tag}/attachments`}
            itemView="twolines"
            pageSize={3}
            draggable={true}
          >
            <MyEvent template="no-data" />
            <MyEvent template="loading" />
          </FileList>
          <FileUploader
            disabled={readOnly}
            ref={fileUploaderRef}
            name={'note_files'}
            selectButtonText={'Seleziona i files'}
            labelText={'o trascina i files qui'}
            multiple={true}
            accept={'*'}
            uploadMode="useForm"
            readyToUploadMessage=""
            allowedFileExtensions={[]}
          />
        </GroupItem>
        <GroupItem cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={1}>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={readOnly || !ref.current?.instance().option().isDirty}
                icon={
                  action === TicketActions.SendTicketNote
                    ? 'email'
                    : action === TicketActions.CreateTicketNote
                      ? 'comment'
                      : 'save'
                }
                text={
                  action === TicketActions.SendTicketNote
                    ? 'Invia'
                    : action === TicketActions.CreateTicketNote
                      ? 'Crea'
                      : 'Salva'
                }
                width="150px"
                onClick={(e: ClickEvent) => {
                  const validationResult = ref.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  console.log('files', fileUploaderRef.current?.instance().option().value)
                  fileUploaderRef.current
                    ?.instance()
                    .option()
                    .value?.map((file: File) => {
                      uploadToSharePoint(file)
                        .then((res: any) => {
                          console.log(file)
                        })
                        .catch((error) => {
                          console.log(error)
                        })
                    })
                  onSaveClick()
                }}
              />
            </ButtonItem>
          </GroupItem>
        </GroupItem>
      </DXForm>
    </>
  )
}

export default TicketEditorNoteForm
