import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import OrderCreatorRoute from '@/routes/orders/OrderCreator.route'
import OrderEditorRoute from '@/routes/orders/OrderEditor.route'
import OrderResellerCreatorRoute from '@/routes/orders/OrderResellerCreator.route'
import OrdersPage from '@/routes/orders/OrdersPage'
import { RoleRoute, RoleRouteObject, RouteFunctionParams } from '@/types'
import { defer, LoaderFunction } from 'react-router-typesafe'

export const ordersPageRouteLoader = (async ({ request, params }: RouteFunctionParams) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  const getStati = qsAdminApi.stato_ordine().query((builder, ordine) => {
    builder.select('id', 'nome')
    builder.orderBy(ordine.nome.asc())
  })

  return defer({
    aziende: await getAziende,
    stati: await getStati,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Supervisor,
      Roles.Sales,
      Roles.ExternalSales,
    ],
  })
}) satisfies LoaderFunction

export const OrdersPageRoute: RoleRoute = {
  path: 'orders',
  children: [
    {
      index: true,
      element: <OrdersPage />,
      loader: ordersPageRouteLoader,
      allowedRoles: [
        Roles.Sales,
        Roles.ExternalSales,
        Roles.Administrator,
        Roles.GlobalAdministrator,
        Roles.Marketing,
        Roles.Supervisor,
      ],
    } as RoleRouteObject,
    OrderCreatorRoute,
    OrderResellerCreatorRoute,
    OrderEditorRoute,
  ],
  errorElement: <RouterErrorHandler />,
  text: 'Ordini',
  icon: 'ordersbox',
  mainMenuRoute: true,
  allowedRoles: [
    Roles.Sales,
    Roles.ExternalSales,
    Roles.Administrator,
    Roles.GlobalAdministrator,
    Roles.Marketing,
    Roles.Supervisor,
  ],
}
