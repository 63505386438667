import { DataGridTypes } from 'devextreme-react/cjs/data-grid'
import { Form as DXForm, GroupItem, SimpleItem, Tab, TabbedItem, TabPanelOptions } from 'devextreme-react/form'
import type { ordine } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { File, FileList, MgtTemplateProps } from '@microsoft/mgt-react'
import { LoadIndicator } from 'devextreme-react'
import DocumentCreator from '@/components/document/DocumentCreator'
import { useRef } from 'react'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { DocumentPermissions } from '@/enums'
import { TaskPlanner } from '@/routes/tasks/TaskEditor.planner'

const MyEvent = (props: MgtTemplateProps) => {
  const { template } = props
  switch (template) {
    case 'no-data': {
      return <div>Nessun allegato caricato</div>
    }
    case 'loading': {
      return <LoadIndicator id="small-indicator" height={20} width={20} />
    }
    default: {
      break
    }
  }
}

const MyEventDocumento = (props: MgtTemplateProps) => {
  const { template } = props
  switch (template) {
    case 'no-data': {
      return <div>Nessun documento caricato</div>
    }
    case 'loading': {
      return <LoadIndicator id="small-indicator" height={20} width={20} />
    }
    default: {
      break
    }
  }
}

const OrderBriefDetail = (props: DataGridTypes.MasterDetailTemplateData) => {
  const order: ordine = props.data.data
  let path
  if (order.attachmentsFolderUrl) {
    path = order.attachmentsFolderUrl
  }
  const documentoGetRef = useRef<any>(null)
  const { client, service } = useQsAdminApiManager()

  return (
    <div className={'dx-card responsive-paddings'}>
      <DXForm id={'order_brief_detail_form'} formData={order} labelLocation={'top'} readOnly={true}>
        <GroupItem colCount={5} colSpan={5}>
          <GroupItem colCount={1} colSpan={5}>
            <TabbedItem>
              <TabPanelOptions selectedIndex={0} />
              <Tab title="Note">
                <SimpleItem
                  dataField={'note'}
                  editorType={'dxTextArea'}
                  label={{ visible: false }}
                  editorOptions={{ height: 200 }}
                />
              </Tab>
              <Tab title="Documento">
                <DocumentCreator
                  title="Documento"
                  documentUrl={order.mainDocumentUrl}
                  documentGetRef={documentoGetRef}
                  action={async () => {
                    await service.riunione(order.id).CreateDocument()
                  }}
                  preliminarPermissionsAction={async () => {
                    try {
                      const response = await client.post(
                        '/api/ordine/drivepermissions',
                        {
                          objectId: order.id,
                          path: order.mainDocumentUrl,
                        },
                        {
                          headers: {
                            'Content-Type': 'application/json',
                          },
                        },
                      )
                      return response.status === 200 ? (response.data as DocumentPermissions) : DocumentPermissions.Deny
                    } catch (error) {
                      console.log('Errore in settaggio permessi sul documento riunione', error)
                      return DocumentPermissions.Deny
                    }
                  }}
                  readOnly={true}
                />
              </Tab>
              <Tab title="Allegati">
                <FileList
                  driveId={import.meta.env.VITE_QSADMINSITE_DRIVE_ID}
                  itemPath={path}
                  itemView="twolines"
                  pageSize={3}
                  draggable={true}
                >
                  <MyEvent template="no-data" />
                  <MyEvent template="loading" />
                </FileList>
              </Tab>
              <Tab title="Note Evasione">
                <SimpleItem
                  dataField={'note_evasione'}
                  editorType={'dxTextArea'}
                  label={{ visible: false }}
                  editorOptions={{ height: 200 }}
                />
              </Tab>
              <Tab title="Pianificazione">
                <SimpleItem>
                  <TaskPlanner order={order} readOnly={true} />
                </SimpleItem>
              </Tab>
            </TabbedItem>
          </GroupItem>
        </GroupItem>
      </DXForm>
    </div>
  )
}

export default OrderBriefDetail
