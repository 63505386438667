import SingleCard from '@/layouts/single-card/single-card'
import { useFetcher, useRouteError } from 'react-router-dom'
import { ButtonItem, ButtonOptions, Form as DXForm, SimpleItem } from 'devextreme-react/form'
import { DXFormSimpleItemRenderItem } from '@/types'
import { ITextBoxOptions } from 'devextreme-react/text-box'
import { Suspense, useState } from 'react'
import { isAxiosError } from 'axios'
import { Rating, ThinRoundedStar } from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css'
import './TicketRatingPage.scss'
import { ticketRatingWrapperRouteLoader } from '@/routes/_unauthenticated/ticketrating.route'
import { Await, useLoaderData } from 'react-router-typesafe'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { toolbarHtmlEditor } from '@/routes/utils'
import { useScreenSize } from '@/themes/media-query'

export default function TicketRatingPage() {
  const [rating, setRating] = useState<number>(0)
  const fetcher = useFetcher()
  const currentScreenSize = useScreenSize()
  const { getHmacJson } = useLoaderData<typeof ticketRatingWrapperRouteLoader>()
  return (
    <>
      <LoadingPanel visible={fetcher.state !== 'idle'} />
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.resolve(getHmacJson)}>
          {(ratingState) => (
            <SingleCard
              title={`Valutazione ticket ${ratingState?.ticketId}`}
              description={'Valutazione del livello del supporto tecnico ricevuto'}
              logo={'https://tinyurl.com/25sgqoyx'}
            >
              <DXForm
                id={'ticketrating-form'}
                className={'ticketrating-form'}
                labelLocation={'top'}
                colCount={1}
                formData={ratingState}
                validationGroup="ticketRatingValidation"
              >
                <SimpleItem
                  dataField={'rating'}
                  label={{ visible: true }}
                  render={(item: DXFormSimpleItemRenderItem<ITextBoxOptions>) => (
                    <Rating
                      style={{ maxWidth: 300 }}
                      value={rating}
                      isRequired={true}
                      onChange={(selectedValue: number) => {
                        console.log('newvalue', selectedValue)
                        // Utilizzo lo state su rating per renderizzare subito il valore selezionato
                        setRating(selectedValue)
                        ratingState.rating = selectedValue
                        console.log('rating', ratingState.rating)
                      }}
                      itemStyles={{
                        itemShapes: ThinRoundedStar,
                        activeFillColor: '#ffb700',
                        inactiveFillColor: '#fbf1a9',
                      }}
                    />
                  )}
                ></SimpleItem>
                <SimpleItem
                  dataField={'commento'}
                  editorType={'dxHtmlEditor'}
                  label={{ visible: true }}
                  editorOptions={{
                    stylingMode: 'filled',
                    valueType: 'html',
                    toolbar: toolbarHtmlEditor(currentScreenSize),
                    mediaResizing: {
                      enabled: true,
                    },
                    imageUpload: {
                      tabs: ['file', 'url'],
                      fileUploadMode: 'base64',
                    },
                    height: '500',
                  }}
                ></SimpleItem>
                <ButtonItem name="btn-save">
                  <ButtonOptions
                    icon="send"
                    text="Invia"
                    width="150px"
                    disabled={ratingState.rating === undefined || ratingState.rating === 0}
                    onClick={async () => {
                      fetcher.submit(JSON.stringify(ratingState), {
                        method: 'post',
                        encType: 'application/json',
                      })
                    }}
                  />
                </ButtonItem>
              </DXForm>
            </SingleCard>
          )}
        </Await>
      </Suspense>
    </>
  )
}

function TicketRatingLanding() {
  const { getHmacJson } = useLoaderData<typeof ticketRatingWrapperRouteLoader>()
  return (
    <>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.resolve(getHmacJson)}>
          {(ratingState) => (
            <SingleCard
              title={`Valutazione ticket ${ratingState?.ticketId}`}
              description={'Valutazione del livello del supporto tecnico ricevuto'}
              logo={'https://tinyurl.com/25sgqoyx'}
            >
              <h3>La valutazione è stato acquisita correttamente. Grazie per il feedback!</h3>
            </SingleCard>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export function TicketRatingErrorHandler() {
  const routeError = useRouteError()
  console.log(routeError)

  // Function to extract error message from Axios error
  const getErrorMessage = (error: unknown) => {
    if (isAxiosError(error)) {
      // Extract the message from the Axios error response
      if (error.response && error.response.data && error.response.data.message) {
        return error.response.data.message
      }
      // Fallback to error message if available
      return error.message || 'An unknown error occurred'
    }
    // Handle other error types if necessary
    return 'An unknown error occurred'
  }

  return (
    <SingleCard
      title={`Valutazione ticket`}
      description={'Valutazione del livello del supporto tecnico ricevuto'}
      logo={'https://tinyurl.com/25sgqoyx'}
    >
      <h3>Si sono verificati errori in fase di registrazioni della valutazione del ticket</h3>
      <div>{getErrorMessage(routeError)}</div>
    </SingleCard>
  )
}

export function TicketRatingWrapper() {
  const { getRating } = useLoaderData<typeof ticketRatingWrapperRouteLoader>()
  return (
    <>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.resolve(getRating)}>
          {(rating) => (
            <>
              {rating.data === 0 && <TicketRatingPage />}
              {rating.data > 0 && <TicketRatingLanding />}
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}
