import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'

type RatingProps = {
  value: number
  onChange?: (selectedValue: number) => void
}

const Rating = (props: RatingProps) => {
  const [value, setValue] = useState(0)
  const colorMap = {
    active: '#ffb700',
    inactive: '#fbf1a9',
  }
  useEffect(() => {
    setValue(props.value)
  }, [props.value])
  return (
    <span>
      {[1, 2, 3, 4, 5].map((index) => {
        return (
          <FontAwesomeIcon icon={faStar} color={index <= value ? colorMap.active : colorMap.inactive} key={index} />
        )
      })}
    </span>
  )
}

export default Rating
