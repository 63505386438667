import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { useScreenSize } from '@/themes/media-query'
import ODataStore from 'devextreme/data/odata/store'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLoaderData } from 'react-router-typesafe'
import type {
  CellPreparedEvent,
  ColumnButtonClickEvent,
  ExportingEvent,
  RowDblClickEvent,
} from 'devextreme/ui/data_grid'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import saveAs from 'file-saver'
import {
  Button as GridButton,
  DataGrid,
  Column,
  ColumnChooser,
  ColumnChooserSearch,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  MasterDetail,
  Scrolling,
  SearchPanel,
  Sorting,
  StateStoring,
  Toolbar,
  DataGridRef,
  Search,
  FilterPanel,
  DataGridTypes,
  Pager,
} from 'devextreme-react/data-grid'
import { attivita_comm } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ClickEvent } from 'devextreme/ui/button'
import { confirm } from 'devextreme/ui/dialog'
import notify from 'devextreme/ui/notify'
import { activitiesPageRouteLoader } from '@/routes/activities/ActivitiesPage.route'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { GridCellColor } from '@/enums'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory, isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import { gridTotalItemsLabel } from '@/constants'

const ActivitiesPage = () => {
  const navigate = useNavigate()
  const qsAdminApi = useQsAdminApiManager.getState().service
  const { clientId } = useParams()
  const dataGridRef = useRef<DataGridRef>(null)
  const currentScreenSize = useScreenSize()
  const { aziende, stati, userInfo, defaultCRUDAllowedRoles } = useLoaderData<typeof activitiesPageRouteLoader>()
  const token = useTokenRefresh()

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '120vh'
    } else if (currentScreenSize.isXSmall) {
      h = '75vh'
    }
    return h
  }, [currentScreenSize])

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['sede/azienda/id', this.currentValue]]
        },
      },
      {
        fieldName: 'stato',
        placeHolder: 'Stato',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: stati.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['stato/id', this.currentValue]]
        },
      },
      createDateFilterItemDescriptor('inizio', 'Data Inizio'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende, stati])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['sede/azienda/id', Number(clientId)]] : [])

  const activitiesDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/attivita_comm`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: [
      'autore',
      'contatto_aziendale',
      'tipologia',
      'precedente',
      'stato',
      'commessa',
      'sede($expand=azienda($expand=agente($expand=commerciale_qs)))',
    ],
    requireTotalCount: true,
    filter: currentFilter.length > 0 ? currentFilter : null,
  }

  const onExporting = (e: ExportingEvent) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('ActivitiesGrid')
    exportDataGrid({ component: e.component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Activities.xlsx')
      })
    })
  }

  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.nome' && e.value) {
      switch (e.value) {
        case 'COMPLETATA CON VENDITA':
        case 'COMPLETATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.EMERALD}`
          break
        }
        case 'PROGRAMMATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.SALMON}`
          break
        }
        case 'IN CORSO': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`
          break
        }
      }
    }
  }

  const deleteAttivita = async (activityId: number) => {
    return await qsAdminApi.attivita_comm(activityId).delete()
  }

  const handleDeleteClick = (e: ClickEvent) => {
    const rowkey = dataGridRef.current?.instance().option('focusedRowKey')
    dataGridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: attivita_comm) => {
        const result = confirm(
          `<i>Vuoi davvero eliminare l'attività <b>${rowdata.id}</b>?</i>`,
          'Eliminazione attività',
        )
        result.then((dialogResult) => {
          if (dialogResult === false) return
          deleteAttivita(rowdata.id)
            .then((res) => {
              dataGridRef.current?.instance().refresh()
              notify(
                {
                  message: `Attività ${rowdata['ded_Dis']} eliminata con successo`,
                  type: 'success',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
            .catch((error) => {
              notify(
                {
                  message: `Errore eliminazione attività: ${error}`,
                  type: 'error',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
        })
      })
  }

  const handlePromozioneClick = (e: ClickEvent) => {
    const rowkey = dataGridRef.current?.instance().option('focusedRowKey')
    dataGridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: attivita_comm) => {
        if (rowdata.commessa) {
          notify(`Attività commerciale già collegata a commessa. Commessa: ${rowdata.commessa.id}`, 'warning', 3000)
        } else {
          navigate({
            pathname: '/projects/new',
            search: `?activityId=${rowdata?.id}`,
          })
        }
      })
  }

  useEffect(() => {
    getCurrentFilter()
  }, [])

  return (
    <>
      {!clientId && <h2 className={'content-block'}>Attività Commerciali</h2>}
      <div className={clientId ? '' : 'content-block'}>
        {!clientId && (
          <div className="accordion-generic-filter-form">
            <Accordion
              className={filtersApplied ? 'with-filter' : ''}
              collapsible={true}
              onContentReady={(e: ContentReadyEvent<any, any>) => {
                e.component?.collapseItem(0)
              }}
            >
              <AccordionItem icon="filter" title={'Filtri'}>
                <GenericFilterForm
                  FormItemDescriptors={filterConfiguration}
                  resetFilterClicked={function (e: ClickEvent): void {
                    setFilterConfiguration(getFiltersConfiguration())
                  }}
                  filterValueChanged={function (): void {
                    const currentFlts = getCurrentFilter()
                    console.log('GENFLT - VALUE CHNG', currentFlts)
                    if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                      console.log('GENFLT - SETTING FILT', currentFlts)
                      setCurrentFilter(currentFlts)
                    }
                  }}
                />
              </AccordionItem>
            </Accordion>
          </div>
        )}
        <DataGrid
          id={clientId ? 'client-activities-grid' : 'activities-grid'}
          height={getGridHeight}
          className={'dx-card wide-card'}
          dataSource={activitiesDataSource}
          noDataText="Nessuna attività commerciale trovata"
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: true,
          }}
          showBorders={false}
          showColumnLines={true}
          focusedRowEnabled={true}
          defaultFocusedRowIndex={0}
          columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
          allowColumnResizing={currentScreenSize.isLarge || currentScreenSize.isMedium}
          allowColumnReordering={currentScreenSize.isLarge || currentScreenSize.isMedium}
          rowAlternationEnabled={true}
          wordWrapEnabled={false}
          onExporting={onExporting}
          ref={dataGridRef}
          grouping={{
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: currentScreenSize.isLarge || currentScreenSize.isMedium,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onCellPrepared={onCellPrepared}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`${e.data.id}`)
          }}
        >
          <Pager
            visible={true}
            allowedPageSizes={'auto'}
            displayMode={'compact'}
            showPageSizeSelector={false}
            showInfo={true}
            showNavigationButtons={false}
            infoText={gridTotalItemsLabel}
          />
          <Export enabled={true} />
          <Editing allowAdding={true} />
          <HeaderFilter visible={true} />
          <FilterPanel visible={true} />
          <FilterRow visible={true} />
          <SearchPanel visible={true} width={currentScreenSize.isXSmall ? 200 : 250} />
          <Scrolling mode="virtual" />
          <Sorting mode="multiple" />
          <ColumnChooser enabled={true} title={'Scelta Colonne'}>
            <ColumnChooserSearch enabled={true} />
          </ColumnChooser>
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey={clientId ? 'client-activities-grid' : 'activities-grid'}
            savingTimeout={50}
          />
          <MasterDetail enabled={false} />
          <Column
            visible={currentScreenSize.isXSmall || currentScreenSize.isSmall}
            type="buttons"
            width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
            alignment="left"
            showInColumnChooser={false}
          >
            <GridButton
              hint="Details"
              icon="search"
              onClick={(e: ColumnButtonClickEvent) => {
                navigate(`${e.row?.data.id}`)
              }}
            ></GridButton>
          </Column>
          <Column
            dataField={'id'}
            caption="ID"
            defaultSortOrder="desc"
            visible={false}
            dataType="number"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'attivita_comm', 'id', true)}></HeaderFilter>
          </Column>
          <Column
            dataField="ded_Dis"
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: attivita_comm = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                <>
                  <Link to={`/activities/${data.id}`}>{data.ded_Dis}</Link>
                </>
              ) : (
                <>{data.ded_Dis}</>
              )
            }}
            visible={true}
            caption="CODICE"
            width={currentScreenSize.isLarge ? '7%' : '20%'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 13 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'attivita_comm', 'ded_Dis', true)}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          {DateColumn({
            dataField: 'inizio',
            caption: 'INIZIO',
            format: 'dd/MM/yyyy',
            width: 'auto',
            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 12 : undefined,
          })}
          {DateColumn({
            dataField: 'fine',
            caption: 'FINE',
            format: 'dd/MM/yyyy',
            width: 'auto',
            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 11 : undefined,
          })}
          {!clientId && (
            <Column
              dataField={'sede.azienda.nome'}
              cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
                const data: attivita_comm = cellData.row.data
                return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                  <>
                    <Link to={`/clients/${data.sede?.azienda?.id}`}>{data.sede?.azienda?.nome}</Link>
                  </>
                ) : (
                  <>{data.sede?.azienda?.nome}</>
                )
              }}
              caption={'AZIENDA'}
              hidingPriority={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 14 : undefined
              }
            >
              <HeaderFilter dataSource={columnSourceFactory(token, 'attivita_comm', 'sede.azienda.nome')}>
                <Search enabled={true} searchExpr={'sede.azienda.nome'} />
              </HeaderFilter>
            </Column>
          )}
          <Column
            dataField={'sede.nome'}
            caption={'SEDE'}
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 10 : undefined
            }
          />
          <Column
            dataField={'sede.azienda.agente.commerciale_qs.fullname'}
            caption={'AGENTE'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'agente', 'commerciale_qs.fullname')}>
              <Search enabled={true} searchExpr={'commerciale_qs.fullname'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'autore.fullname'}
            caption={'AUTORE'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'attivita_comm', 'autore.fullname')}>
              <Search enabled={true} searchExpr={'autore.fullname'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'contatto_aziendale.fullname'}
            caption={'RIFERIMENTO'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 9 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'attivita_comm', 'contatto_aziendale.fullname')}>
              <Search enabled={true} searchExpr={'contatto_aziendale.fullname'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'tipologia.nome'}
            caption={'TIPO'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'attivita_comm', 'tipologia.nome')}>
              <Search enabled={true} searchExpr={'tipologia.nome'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'stato.nome'}
            caption={'STATO'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'attivita_comm', 'stato.nome')}>
              <Search enabled={true} searchExpr={'stato.nome'} />
            </HeaderFilter>
          </Column>
          {/* <Column dataField={'descrizione'} caption={'DESCRIZIONE'} /> */}
          <Column
            dataField={'precedente.ded_Dis'}
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: attivita_comm = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [...defaultCRUDAllowedRoles, Roles.Marketing]) ? (
                <>
                  <Link to={`/activities/${data.precedente?.id}`}>{data.precedente?.ded_Dis}</Link>
                </>
              ) : (
                <>{data.precedente?.ded_Dis}</>
              )
            }}
            caption={'PRECEDENTE'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'attivita_comm', 'precedente.ded_Dis', true)}>
              <Search enabled={true} searchExpr={'precedente.ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'commessa.ded_Dis'}
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: attivita_comm = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [...defaultCRUDAllowedRoles, Roles.Marketing]) ? (
                <>
                  <Link to={`/projects/${data.commessa?.id}`}>{data.commessa?.ded_Dis}</Link>
                </>
              ) : (
                <>{data.commessa?.ded_Dis}</>
              )
            }}
            caption={'COMMESSA'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'attivita_comm', 'commessa.ded_Dis', true)}>
              <Search enabled={true} searchExpr={'commessa.ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Toolbar>
            <Item
              location="before"
              locateInMenu="never"
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Refresh',
                text: 'Refresh',
                icon: 'refresh',
                onClick: (e: ClickEvent) => {
                  dataGridRef.current?.instance().refresh()
                },
                stylingMode: 'text',
              }}
            ></Item>
            <Item name="groupPanel" />
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              name="addRowButton"
              options={{
                hint: 'Nuova attività',
                text: 'Nuova attività',
                onClick: () => {
                  navigate(`new`)
                },
              }}
            />
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Promuovi a commessa',
                text: 'Promuovi a commessa',
                icon: 'check',
                onClick: handlePromozioneClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Elimina attività',
                text: 'Elimina attività',
                icon: 'trash',
                onClick: handleDeleteClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              name="columnChooserButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Scelta colonne', text: 'Scelta colonne' }}
            />
            <Item
              name="exportButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Esporta dati in excel', text: 'Esporta dati in excel' }}
            />
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
      </div>
    </>
  )
}

export default ActivitiesPage
