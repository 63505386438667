import TabbedSelector from '@/components/TabbedSelector'
import { packagesSubRoutes } from '@/routes/packages/PackagesPage.route'
import { useParams } from 'react-router-dom'

const PackagesPage = () => {
  const { clientId } = useParams()
  return (
    <>
      {!clientId && <h2 className={'content-block'}>Pacchetti</h2>}
      <div className={clientId ? '' : 'content-block'}>
        <TabbedSelector tabs={packagesSubRoutes} />
      </div>
    </>
  )
}

export default PackagesPage
