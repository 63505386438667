import { useAzureManager } from '@/auth/azure/azureManager'
import { Role } from '@/auth/azure/Roles'
import { allAuthenticatedChildrenRoutes } from '@/routes/authenticated/AuthenticatedLayout.route'
import { useNavigate, Outlet, Navigate, useLocation, useMatches, matchRoutes } from 'react-router-dom'

export const AuthGuard = ({ children }: { children: JSX.Element }) => {
  const location = useLocation()
  const user = useAzureManager.getState().userInfo

  if (!user) {
    return <Navigate to={`/login?redirectTo=${encodeURIComponent(location.pathname)}`} />
  }

  const allMatchingRoutes = matchRoutes(allAuthenticatedChildrenRoutes, location)
  const currentRoute = allMatchingRoutes?.at(-1)?.route
  const hasRequiredRole = currentRoute?.allowedRoles?.some((role: Role) => user?.roles?.hasRole(role))

  if (!hasRequiredRole) {
    return <Navigate to="/access-denied" />
  }

  return children
}
