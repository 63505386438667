import { ButtonItem, Form, GroupItem, RequiredRule, SimpleItem } from 'devextreme-react/form'
import { useState } from 'react'
import { ArraySourceComposer } from '@/auth/api/connector'
import { ticket, user } from '@/model/qsadminapi/QsAdminApiModuleModel'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'

type AssignSelectorProps = {
  data:
    | {
        formData: {
          technician: user | null | undefined
        }
        onSubmitEditor: (
          e: any,
          params: {
            ticketId: string | null | undefined
            userId: number | null | undefined
          },
        ) => Promise<void>
        tecniciList: (user | null | undefined)[]
        ticket: ticket | null | undefined
      }
    | undefined
}

export const AssignSelector = (props: AssignSelectorProps) => {
  const { data } = props
  const [loadIndicatorVisible, setLoadIndicatorVisible] = useState(false)

  return (
    <>
      <div id="assignto-selector-form-container" className={'dx-card responsive-paddings'}>
        <LoadingPanel
          position={{ of: '#assignto-selector-form-container' }}
          visible={loadIndicatorVisible}
        ></LoadingPanel>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            setLoadIndicatorVisible(true)
            const params = {
              userId: data?.formData.technician?.id,
              ticketId: data?.ticket?.ded_Dis,
            }
            if (data?.onSubmitEditor) {
              data.onSubmitEditor(e, params).finally(() => {
                setLoadIndicatorVisible(false)
              })
            }
          }}
        >
          <Form
            id={`assignto-selector-form}`}
            key={`assignto-selector-form}`}
            formData={data?.formData}
            validationGroup="assigntoValidation"
          >
            <GroupItem colCount={1}>
              <SimpleItem
                dataField="technician"
                label={{ text: 'Tecnico' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', data?.tecniciList),
                  placeholder: 'Seleziona tecnico...',
                  displayExpr: 'fullname',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare tecnico',
                    hideOnOutsideClick: true,
                  },
                }}
              >
                <RequiredRule message="Tecnico obbligatorio"></RequiredRule>
              </SimpleItem>
            </GroupItem>
            <GroupItem cssClass="last-group">
              <GroupItem cssClass="buttons-group" colCount={1}>
                <ButtonItem
                  name="btn-compute"
                  buttonOptions={{
                    icon: 'user',
                    text: 'Assegna',
                    disabled: false,
                    useSubmitBehavior: true,
                    width: '200px',
                  }}
                ></ButtonItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </>
  )
}

export default AssignSelector
