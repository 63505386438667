import { Roles } from '@/auth/azure/Roles'
import OrderEditor from '@/routes/orders/OrderEditor'
import { orderEditorRouteAction, orderEditorRouteLoader } from '@/routes/orders/OrderEditor.route'
import { RoleRouteObject } from '@/types'

const OrderResellerCreatorRoute = {
  path: 'newresellerorder',
  element: <OrderEditor creating={true} isReseller={true} />,
  loader: orderEditorRouteLoader,
  action: orderEditorRouteAction,
  allowedRoles: [Roles.GlobalAdministrator, Roles.ExternalSales],
} as RoleRouteObject

export default OrderResellerCreatorRoute
