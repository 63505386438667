import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { currentTheme, refreshTheme } from 'devextreme/viz/themes'
import { storageKey } from '@/themes/theme-constants'
import { applyTheme as mgtApplyTheme } from '@microsoft/mgt-components'
interface Theme {
  text: string
  value: string
}

const getThemeData = (): Theme[] => [
  {
    text: 'Orange Light',
    value: 'orange.light',
  },
  {
    text: 'Orange Compact',
    value: 'orange.light.compact',
  },
  {
    text: 'Orange Dark',
    value: 'orange.dark',
  },
  {
    text: 'Orange Dark Compact',
    value: 'orange.dark.compact',
  },
]

interface ThemeContextType {
  getThemeData: () => Theme[]
  getTheme: () => string
  setTheme: (theme: string) => void
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined)

const useTheme = () => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}

interface ThemeProviderProps {
  theme?: string
  children?: ReactNode
}

function ThemeProvider({ theme = window.localStorage[storageKey] || 'orange.light', ...props }: ThemeProviderProps) {
  const [_theme, setTheme] = useState<string>(theme)

  const getTheme = useCallback(() => _theme || window.localStorage[storageKey] || 'orange.light', [_theme])

  const applyBaseTheme = useCallback((theme: string, themeMarker: string) => {
    for (const index in document.styleSheets) {
      const styleSheet = document.styleSheets[index] as CSSStyleSheet
      const href = styleSheet.href
      if (href) {
        const themeMarkerPosition = href.indexOf(themeMarker)
        if (themeMarkerPosition >= 0) {
          const startPosition = themeMarkerPosition + themeMarker.length
          const endPosition = href.indexOf('.css')
          const fileNamePart = href.slice(startPosition, endPosition)
          if (fileNamePart === theme) {
            for (let i = 0; i < styleSheet.cssRules.length; i++) {
              const cssRule = styleSheet.cssRules.item(i) as CSSStyleRule
              if (cssRule?.selectorText === '.dx-theme-accent-as-text-color') {
                document.documentElement.style.setProperty('--base-accent', cssRule.style.color)
              }
            }
          }
          styleSheet.disabled = fileNamePart !== theme
        }
      }
    }
  }, [])

  const applySwatchVariables = useCallback((accent: string) => {
    if (accent === 'light' || accent === 'light.compact') {
      document.documentElement.style.setProperty('--base-border-color', '#575656')
      document.documentElement.style.setProperty('--base-bg', 'rgba(255, 255, 255, 0.10)')
      document.documentElement.style.setProperty('--component-back-color', '#FFF')
      document.documentElement.style.setProperty('--icon-color', 'rgba(0, 0, 0, 0.54)')
      document.documentElement.style.setProperty('--base-text-color', 'rgba(0, 0, 0, 0.87)')
      document.documentElement.style.setProperty('--base-text-color-light', 'rgba(0, 0, 0, 0.50)')
    } else {
      document.documentElement.style.setProperty('--base-border-color', '#464650')
      document.documentElement.style.setProperty('--base-bg', 'rgba(255, 255, 255, 0.10)')
      document.documentElement.style.setProperty('--component-back-color', ' #363640')
      document.documentElement.style.setProperty('--icon-color', 'rgba(255, 255, 255, 0.87)')
      document.documentElement.style.setProperty('--base-text-color', 'rgba(255, 255, 255, 0.87)')
      document.documentElement.style.setProperty('--base-text-color-light', 'rgba(255, 255, 255, 0.50)')
    }
  }, [])

  const applySwatchTheme = useCallback((accent: string, themeMarker: string) => {
    for (const index in document.styleSheets) {
      const styleSheet = document.styleSheets[index] as CSSStyleSheet
      const href = styleSheet.href
      if (href) {
        const themeMarkerPosition = href.indexOf(themeMarker)

        if (themeMarkerPosition >= 0) {
          const startPosition = themeMarkerPosition + themeMarker.length
          const endPosition = href.indexOf('.css')
          const fileNamePart = href.slice(startPosition, endPosition)
          // console.log('ACC - FN', accent, fileNamePart)
          styleSheet.disabled = accent !== fileNamePart.slice(Math.max(0, fileNamePart.indexOf('.') + 1))
        }
      }
    }
  }, [])

  const applyTheme = useCallback(() => {
    const selectedTheme = getTheme()

    // mgt initial theme
    if (selectedTheme.includes('dark')) mgtApplyTheme('dark')
    else mgtApplyTheme('light')

    applyBaseTheme(selectedTheme, 'dx.material.')

    const accent = selectedTheme?.slice(Math.max(0, selectedTheme?.indexOf('.') + 1))

    // console.log('THEME!', selectedTheme)
    // console.log('ACCENT!', accent)

    applySwatchVariables(accent)

    applySwatchTheme(accent, 'theme.additional')

    window.localStorage[storageKey] = selectedTheme
    currentTheme(`material.${selectedTheme}`)
    refreshTheme()
  }, [getTheme, applyBaseTheme, applySwatchVariables, applySwatchTheme])

  useEffect(() => {
    applyTheme()
  }, [_theme, applyTheme])

  return <ThemeContext.Provider value={{ getThemeData, getTheme, setTheme }} {...props} />
}

export { ThemeProvider, useTheme }
