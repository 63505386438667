import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { costCentersEditorRouteLoader } from '@/routes/costcenters/CostCentersEditor.route'
import { Suspense, useRef, useState } from 'react'
import { Await, useLoaderData } from 'react-router-typesafe'
import { Editing, Column, StateStoring } from 'devextreme-react/tree-list'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { Button, DataGrid, Lookup as LookupDX, Popup, Toolbar } from 'devextreme-react'
import { ArraySourceComposer } from '@/auth/api/connector'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { InitNewRowEvent, SavingEvent } from 'devextreme/ui/data_grid'
import { Lookup, Paging } from 'devextreme-react/cjs/data-grid'
import useTokenRefresh from '@/auth/azure/azureManager'
import { centro_costo, reparto } from '@/model/qsadminapi/QsAdminApiModuleModel'
import './CostCentersEditor.scss'
import { Item as ToolbarItem } from 'devextreme-react/toolbar'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { ClickEvent } from 'devextreme/ui/button'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { DataGridRef } from 'devextreme-react/data-grid'
import { PopupRef } from 'devextreme-react/popup'

const CostCentersEditor = () => {
  const { getReparti, centriCosto } = useLoaderData<typeof costCentersEditorRouteLoader>()
  const token = useTokenRefresh()

  const [currentFilter, setCurrentFilter] = useState<any[]>([])
  const [currentReparto, setCurrentReparto] = useState<reparto>()
  const [currentCentriCostoDataSource, setCurrentCentriCostoDataSource] = useState<centro_costo[]>(
    centriCosto.data.value,
  )
  const refGrid = useRef<DataGridRef>(null)
  const refForm = useRef<FormRef>(null)
  const refPopup = useRef<PopupRef>(null)

  const { service } = useQsAdminApiManager()

  const newCostCenter: centro_costo = {
    id: 0,
    nome: null,
    note: null,
  }

  const costCentersDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/centro_costo_visibility`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
    }),
    expand: ['reparto', 'centro_costo'],
    filter: currentFilter?.length > 0 ? currentFilter : [['reparto/id', null]],
    requireTotalCount: true,
  }

  return (
    <>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={getReparti}>
          {(reparti) => (
            <>
              <h2 className={'content-block'}>Editazione Centri di Costo</h2>
              <div id="editor-cost-centers-container" className={'content-block dx-card responsive-paddings'}>
                <Toolbar className={'costCentersToolbar'}>
                  <ToolbarItem location="after" locateInMenu="never">
                    <Button
                      text={'Inserisci centro di costo'}
                      type={'normal'}
                      icon={'plus'}
                      onClick={() => {
                        refPopup.current?.instance().show()
                      }}
                    />
                  </ToolbarItem>
                </Toolbar>
                <LookupDX
                  dataSource={ArraySourceComposer('id', reparti.data.value)}
                  value={currentReparto}
                  placeholder="Reparto"
                  displayExpr="nome"
                  showClearButton={true}
                  dropDownCentered={true}
                  searchEnabled={true}
                  dropDownOptions={{ showTitle: true, title: 'Reparto', hideOnOutsideClick: true }}
                  onValueChanged={(e: ValueChangedEvent) => {
                    if (e.value)
                      if (e.value?.id === e.previousValue?.id) return
                      else setCurrentFilter([['reparto/id', e.value.id]])
                    else setCurrentFilter([['reparto/id', null]])
                    setCurrentReparto(e.value)
                  }}
                />
                <DataGrid
                  id={`cost-centers-editor-datagrid`}
                  dataSource={costCentersDataSource}
                  noDataText="Nessun centro di costo trovato"
                  ref={refGrid}
                  keyExpr={'id'}
                  showBorders={true}
                  showColumnLines={true}
                  focusedRowEnabled={true}
                  defaultFocusedRowIndex={0}
                  columnHidingEnabled={false}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  rowAlternationEnabled={true}
                  wordWrapEnabled={false}
                  repaintChangesOnly={true}
                  width="100%"
                  onSaving={(e: SavingEvent) => {
                    console.log('SAVING', e)
                  }}
                  onInitNewRow={(e: InitNewRowEvent) => {
                    e.data.reparto = currentReparto
                  }}
                >
                  <StateStoring
                    enabled={true}
                    type="localStorage"
                    storageKey={'costcenters-editor-datagrid'}
                    savingTimeout={50}
                  />
                  <Paging defaultPageSize={20} />
                  <Editing
                    mode="batch"
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={false}
                    selectTextOnEditStart={true}
                    startEditAction={'click'}
                  />
                  <Column dataField={'centro_costo.id'} caption={'CENTRO COSTO'}>
                    <Lookup dataSource={currentCentriCostoDataSource} displayExpr="nome" valueExpr="id" />
                  </Column>
                </DataGrid>
                <Popup
                  id="cost_center_popup"
                  ref={refPopup}
                  dragEnabled={false}
                  hideOnOutsideClick={false}
                  showCloseButton={true}
                  onHidden={() => {
                    refPopup.current?.instance().hide()
                    refForm.current?.instance().reset()
                  }}
                  showTitle={true}
                  title={'Inserisci centro di costo'}
                  container=".dx-viewport"
                  width={300}
                  height={300}
                >
                  <DXForm
                    formData={newCostCenter}
                    id={`cost_center_form`}
                    labelLocation={'top'}
                    showValidationSummary={true}
                    validationGroup={`costCenterValidation`}
                    ref={refForm}
                  >
                    <GroupItem colCount={1}></GroupItem>
                    <SimpleItem dataField={'nome'} editorType={'dxTextBox'} label={{ visible: true, text: 'Nome' }}>
                      <RequiredRule message="Nome obbligatorio"></RequiredRule>
                    </SimpleItem>
                    <GroupItem cssClass="last-group">
                      <GroupItem cssClass="buttons-group" colCount={1}>
                        <ButtonItem name="btn-save">
                          <ButtonOptions
                            icon="save"
                            text="Salva"
                            width="150px"
                            onClick={async (e: ClickEvent) => {
                              const validationResult = refForm.current?.instance().validate()
                              if (!validationResult?.isValid) return
                              await service
                                .centro_costo()
                                .create(newCostCenter)
                                .then((response) => {
                                  const newCentriCosto: centro_costo[] = centriCosto.data.value
                                  newCentriCosto.push(response.data)
                                  setCurrentCentriCostoDataSource(
                                    newCentriCosto.sort((centroCostoA: centro_costo, centroCostoB: centro_costo) => {
                                      return centroCostoA.nome && centroCostoB.nome
                                        ? centroCostoA.nome.localeCompare(centroCostoB.nome)
                                        : 1
                                    }),
                                  )
                                })
                                .catch((error) => {
                                  console.log('error', error)
                                })
                                .finally(() => {
                                  refPopup.current?.instance().hide()
                                })
                            }}
                          />
                        </ButtonItem>
                      </GroupItem>
                    </GroupItem>
                  </DXForm>
                </Popup>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default CostCentersEditor
