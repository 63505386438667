import './TestComponent.scss'
import Rating from '@/components/Rating/Rating'

const TestComponent = () => {
  console.log(import.meta.env)
  return (
    <>
      <Rating value={2} />
    </>
  )
}

export default TestComponent
