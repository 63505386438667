import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import TicketsHistoricPage from '@/routes/tickets/historic/TicketsHistoricPage'
import { RoleRouteObject } from '@/types'
import { defer, LoaderFunction } from 'react-router-typesafe'

export const ticketsHistoricPageRouteLoader = (async () => {
  return defer({
    userInfo: await getAzureUserInformation(),
  })
}) satisfies LoaderFunction

const TicketsHistoricPageRoute = {
  path: 'historic',
  element: <TicketsHistoricPage />,
  loader: ticketsHistoricPageRouteLoader,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default TicketsHistoricPageRoute
