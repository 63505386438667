import SupportPage from './SupportPage'
import { RoleRoute, RouteFunctionParams } from '@/types'
import { SupportQuotePageRoute } from '@/routes/support/SupportQuotePage.route'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'

export const supportPageRouteLoader = (async ({ request, params }: RouteFunctionParams) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getAziende = qsAdminApi.azienda().query((builder, qazienda) => {
    builder.orderBy(qazienda.nome.asc())
    builder.select('nome', 'id')
  })

  const getTipologieAssistenza = qsAdminApi.tipologia_assistenza().query((builder, tipologia) => {
    builder.orderBy(tipologia.nome.asc())
    builder.select('nome', 'id')
  })

  const getAgenti = qsAdminApi.agente().query((builder, agente) => {
    builder.expanding('commerciale_qs', (commerciale_qsBuilder, commerciale_qs) => {
      commerciale_qsBuilder.select('id', 'fullname')
      commerciale_qsBuilder.orderBy(commerciale_qs.fullname.asc())
    })
    builder.select('id', 'commerciale_qs')
  })

  return defer({
    aziende: await getAziende,
    agenti: await getAgenti,
    tipologieAssistenza: await getTipologieAssistenza,
    userInfo: await getAzureUserInformation(),
  })
}) satisfies LoaderFunction

export const SupportPageRoute: RoleRoute = {
  path: 'support',
  element: <SupportPage />,
  errorElement: <RouterErrorHandler />,
  loader: supportPageRouteLoader,
  mainMenuRoute: true,
  children: [SupportQuotePageRoute],
  text: 'Assistenza',
  icon: 'toolbox',
  allowedRoles: [Roles.Guest],
}
