import React, { useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate, useMatches, resolvePath, useResolvedPath, matchPath } from 'react-router-dom'
import { Item, Tabs } from 'devextreme-react/tabs'
import type { InitializedEvent, ItemClickEvent } from 'devextreme/ui/tabs'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Role } from '@/auth/azure/Roles'
import { RoleRoute } from '@/types'

const filterTabsByRoles = async (routes: RoleRoute[]): Promise<RoleRoute[]> => {
  const userInfo = await getAzureUserInformation()
  return routes.filter((route) => {
    return route.allowedRoles?.some((role: Role) => userInfo?.roles?.hasRole(role))
  })
}

const TabbedSelector = ({ tabs }: { tabs: RoleRoute[] }) => {
  const navigate = useNavigate()
  const [allowedTabs, setAllowedTabs] = useState<RoleRoute[]>([])

  useEffect(() => {
    const filterRoutes = async () => {
      const filteredRoutes = await filterTabsByRoles(tabs)
      setAllowedTabs(filteredRoutes)
    }
    filterRoutes()
  }, [tabs])

  const matches = useMatches()
  // NB -> Stratagemma per avere ad esempio /clients/X anzichè /clients/X/tabname che mi ritorna uselocation
  const rootpath = useResolvedPath('.')

  const [selectedItem, setSelectedItem] = useState<RoleRoute | undefined>()

  useEffect(() => {
    const activeTabRoute = allowedTabs.find((tab: RoleRoute) => {
      if (tab.path) {
        const resolved = resolvePath(tab.path, rootpath.pathname)
        const mtf = matches.find((match) => {
          if (matchPath({ path: resolved.pathname }, match.pathname)) {
            return match
          }
        })
        if (mtf) {
          return tab
        }
      }
    })
    setSelectedItem(activeTabRoute)
  }, [allowedTabs, matches, rootpath])

  return (
    <>
      <div className={`dx-card responsive-paddings`}>
        <Tabs
          stylingMode="secondary"
          selectedItem={selectedItem}
          onItemClick={(e: ItemClickEvent) => {
            navigate(e.itemData.path?.split('/')[0], { relative: 'path' })
          }}
          scrollingEnabled={true}
          scrollByContent={true}
          dataSource={allowedTabs}
          width={'auto'}
          hint="Seleziona una scheda"
          iconPosition={'start'}
          noDataText={'Nessuna scheda disponibile'}
        />
      </div>
      <div
        style={{
          padding: '1rem',
        }}
      >
        <Outlet />
      </div>
    </>
  )
}

export default TabbedSelector
