import { useAzureManager } from '@/auth/azure/azureManager'

const ProfilePage = () => {
  const azureManager = useAzureManager()
  return (
    <div>
      <pre>{JSON.stringify(azureManager.userInfo, null, 2)}</pre>
    </div>
  )
}

export default ProfilePage
