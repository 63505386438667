import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { attivita_comm, commessa } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { redirect } from 'react-router-dom'
import { LoaderFunction, ActionFunction, defer } from 'react-router-typesafe'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import ProjectEditor from '@/routes/projects/ProjectEditor'
import { StatoCommessa } from '@/routes/projects/ProjectEditor.enums'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel } from '@odata2ts/http-client-api'
import { Roles } from '@/auth/azure/Roles'

export const projectEditorRouteLoader = (async ({ request, params }: RouteFunctionParams<'projectId' | 'clientId'>) => {
  const projectId = params.projectId
  const clientId = params.clientId
  const activityId = new URL(request.url).searchParams.get('activityId')

  const qsAdminApi = useQsAdminApiManager.getState().service

  let project: commessa

  if (projectId) {
    //editor
    const getProject = await qsAdminApi.commessa(Number(projectId)).query((builder, project) => {
      builder.expanding('sede', (sedeBuilder, sede) => {
        sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
          aziendaBuilder.expanding('sedi', (sediBuilder, sedeAzienda) => {
            sediBuilder.select('id', 'nome', 'note')
            sediBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaBuilder.expanding('agente', (agenteBuilder, agente) => {
            agenteBuilder.expanding('commerciale_qs', (commercialeQsBuilder, commercialeQs) => {
              commercialeQsBuilder.select('id', 'fullname')
            })
            agenteBuilder.select('id', 'commerciale_qs')
          })
          aziendaBuilder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
            rivenditoreBuilder.select('id', 'nome')
          })
          aziendaBuilder.select('id', 'nome', 'sedi', 'agente', 'rivenditore')
        })
        sedeBuilder.select('id', 'nome', 'azienda', 'note')
      })
      builder.expanding('stato', (statoBuilder, stato) => {
        statoBuilder.select('id', 'nome')
      })
      builder.expanding('autore', (autoreBuilder, autore) => {
        autoreBuilder.select('id', 'fullname')
      })
      builder.expanding('attivita_commerciali', (commercialiBuilder, attComm) => {
        commercialiBuilder.expanding('autore', (autoreBuilder, autore) => {
          autoreBuilder.select('id', 'fullname')
        })
        commercialiBuilder.expanding('contatto_aziendale', (contattoBuilder, contatto) => {
          contattoBuilder.select('id', 'fullname')
        })
        commercialiBuilder.expanding('tipologia', (tipologiaBuilder, tipologia) => {
          tipologiaBuilder.select('id', 'nome')
        })
        commercialiBuilder.expanding('stato', (statoBuilder, stato) => {
          statoBuilder.select('id', 'nome')
        })
      })
      builder.expanding('offerte', (offerteBuilder, offerta) => {
        offerteBuilder.expanding('riferimenti', (riferimentiBuilder, riferimento) => {
          riferimentiBuilder.select('id', 'fullname')
        })
        offerteBuilder.expanding('riferimenti_entita', (riferimentiEntitaBuilder, riferimentoEntita) => {
          riferimentiEntitaBuilder.select('id', 'nome')
        })
        offerteBuilder.expanding('autore', (autoreBuilder, autore) => {
          autoreBuilder.select('id', 'fullname')
        })
        offerteBuilder.expanding('stato', (statoBuilder, stato) => {
          statoBuilder.select('id', 'nome')
        })
        offerteBuilder.expanding('analisi', (analisiBuilder, analisi) => {
          analisiBuilder.expanding('stato', (statoBuilder, stato) => {
            statoBuilder.select('id', 'nome')
          })
          analisiBuilder.expanding('riferimenti', (riferimentiBuilder, riferimento) => {
            riferimentiBuilder.select('id', 'fullname')
          })
          analisiBuilder.expanding('riferimenti_entita', (riferimentiEntitaBuilder, riferimentoEntita) => {
            riferimentiEntitaBuilder.select('id', 'nome')
          })
          analisiBuilder.expanding('creatore', (creatoreBuilder, creatore) => {
            creatoreBuilder.select('id', 'fullname')
          })
        })
      })
      builder.expanding('ordini', (ordiniBuilder, ordine) => {
        ordiniBuilder.expanding('stato', (statoBuilder, stato) => {
          statoBuilder.select('id', 'nome')
        })
      })
      builder.expanding('tasks', (tasksBuilder, task) => {
        tasksBuilder.expanding('stato', (statoBuilder, stato) => {
          statoBuilder.select('id', 'nome')
        })
        tasksBuilder.expanding('resp_tecnico', (respBuilder, resp) => {
          respBuilder.select('id', 'fullname')
        })
        tasksBuilder.expanding('proprietario', (propBuilder, prop) => {
          propBuilder.select('id', 'fullname')
        })
        tasksBuilder.expanding('tecnici', (tecniciBuilder, tecnico) => {
          tecniciBuilder.select('id', 'fullname')
        })
      })
      builder.expand('ad_quotes')
    })
    project = getProject.data satisfies commessa
    if (clientId && project?.sede?.azienda && project?.sede?.azienda.id !== Number(clientId)) {
      throw new Error(
        `La commessa ${project.ded_Dis} appartiene al cliente ${project?.sede?.azienda.nome} e non al cliente corrente`,
      )
    }
  } else {
    //creator
    let getActivity: HttpResponseModel<ODataModelResponseV4<attivita_comm>> | undefined
    if (activityId) {
      //creator: promozione di attività commerciale devo quindi recuperare activity di partenza
      getActivity = await qsAdminApi.attivita_comm(+activityId).query((builder, attivita) => {
        builder.expanding('sede', (sedeBuilder, sede) => {
          sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
            aziendaBuilder.expanding('sedi', (sediBuilder, sedeAzienda) => {
              sediBuilder.select('id', 'nome', 'note')
            })
            aziendaBuilder.select('id', 'nome', 'sedi')
          })
        })
        builder.select('id', 'sede')
      })
    }
    const userInfo = await getAzureUserInformation()
    const getAutore = await qsAdminApi.user().query((builder, impiegato) => {
      builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
      builder.select('id', 'fullname')
    })
    const getStatoAperta = await qsAdminApi.stato_commessa(StatoCommessa.APERTA).query((builder, stato) => {
      builder.select('id', 'nome')
    })
    project = {
      id: 0,
      note: null,
      inizio: new Date().toISOString(),
      fine: null,
      anno_rif: new Date().getFullYear(),
      stato: getStatoAperta.data,
      autore: getAutore.data.value[0],
      titolo: null,
      creazione: new Date().toISOString(),
      sede: getActivity ? getActivity.data.sede : null,
      attachmentsFolderUrl: '',
      legacy: false,
      ded_Dis: '',
      ded_RootFam: '',
      ded_SubFam: '',
    }
  }

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.filter(azienda.sedi.any())
    builder.filter(clientId ? azienda.id.eq(Number(clientId)) : null)
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
  })

  const getStati = qsAdminApi.stato_commessa().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const getQsImpiegato = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  const userInfo = await getAzureUserInformation()
  const userEmail = userInfo?.email ?? ''
  const getUser = qsAdminApi.user().query((builder, impiegato) => {
    builder.filter(impiegato.email.eq(userEmail))
    builder.expanding('azienda', (aziendaBuilder, azienda) => {
      aziendaBuilder.select('id', 'nome')
    })
    builder.select('id', 'fullname', 'azienda')
  })

  return defer({
    project,
    getAziende,
    getStati,
    getQsImpiegato,
    getUser,
    userInfo,
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Supervisor,
      Roles.Sales,
      Roles.ExternalSales,
    ],
  })
}) satisfies LoaderFunction

export const projectEditorRouteAction = (async ({ request, params }: RouteFunctionParams) => {
  const project = (await request.json()) as commessa
  const qsAdminApi = useQsAdminApiManager.getState().service
  console.log('commessa', project)

  switch (request.method) {
    case 'POST': {
      const activityId = new URL(request.url).searchParams.get('activityId')
      console.log('activityId', activityId)
      const res = await qsAdminApi.commessa().create(project)
      if (activityId) {
        const getActivity = await qsAdminApi.attivita_comm(+activityId).query()
        const activity: attivita_comm = getActivity.data
        activity.commessa = res.data
        const resActivity = await qsAdminApi.attivita_comm(+activityId).patch(activity)
      }
      return redirect(`../${res.data.id}`)
    }
    case 'PUT':
    case 'PATCH': {
      return await qsAdminApi.commessa(project.id).update(project)
    }
    default: {
      throw new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

const ProjectEditorRoute = {
  path: ':projectId',
  element: <ProjectEditor creating={false} />,
  loader: projectEditorRouteLoader,
  action: projectEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default ProjectEditorRoute
