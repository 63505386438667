import { TicketEditorCompleteFormProps } from '@/routes/tickets/TicketEditor.types'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { useRef } from 'react'
import { ClickEvent } from 'devextreme/ui/button'
import { toolbarHtmlEditor } from '@/routes/utils'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { useScreenSize } from '@/themes/media-query'
const TicketEditorCompleteForm = (props: TicketEditorCompleteFormProps) => {
  const { complete, action, onSaveClick } = props
  const ref = useRef<FormRef>(null)
  const currentScreenSize = useScreenSize()
  return (
    <>
      <DXForm
        formData={complete}
        id={`ticket_action_form_${action}`}
        labelLocation={'top'}
        showValidationSummary={true}
        validationGroup={`ticketActionPopupValidation_${action}`}
        ref={ref}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            ref.current?.instance().option().isDirty !== null &&
            ref.current?.instance().option().isDirty !== undefined
          ) {
            ref.current.instance().getButton('btn-save')?.option('disabled', !ref.current?.instance().option().isDirty)
          }
        }}
      >
        <GroupItem colCount={1}></GroupItem>
        <SimpleItem
          dataField={'solution'}
          editorType={'dxHtmlEditor'}
          label={{ visible: true, text: 'Soluzione' }}
          editorOptions={{
            stylingMode: 'filled',
            valueType: 'html',
            toolbar: toolbarHtmlEditor(currentScreenSize),
            mediaResizing: {
              enabled: true,
            },
            imageUpload: {
              tabs: ['file', 'url'],
              fileUploadMode: 'base64',
            },
            height: '400',
          }}
        >
          <RequiredRule message="Soluzione obbligatoria"></RequiredRule>
        </SimpleItem>
        <GroupItem colCount={1}>
          <SimpleItem
            dataField={'closure'}
            editorType={'dxHtmlEditor'}
            label={{ visible: true, text: 'Note Chiusura' }}
            editorOptions={{
              stylingMode: 'filled',
              valueType: 'html',
              toolbar: toolbarHtmlEditor(currentScreenSize),
              mediaResizing: {
                enabled: true,
              },
              imageUpload: {
                tabs: ['file', 'url'],
                fileUploadMode: 'base64',
              },
              height: '400',
            }}
          >
            <RequiredRule message="Note Chiusura obbligatorie"></RequiredRule>
          </SimpleItem>
        </GroupItem>
        <GroupItem cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={1}>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!ref.current?.instance().option().isDirty}
                icon="check"
                text="Completa"
                width="150px"
                onClick={(e: ClickEvent) => {
                  const validationResult = ref.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  onSaveClick()
                }}
              />
            </ButtonItem>
          </GroupItem>
        </GroupItem>
      </DXForm>
    </>
  )
}

export default TicketEditorCompleteForm
