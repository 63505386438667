import { LoadPanel } from 'devextreme-react'
import { PositionConfig } from 'devextreme/animation/position'
import { PositionAlignment } from 'devextreme/common'

const LoadingPanel = (props: {
  visible?: boolean
  position?: PositionAlignment | PositionConfig | undefined
  shading?: boolean
}) => {
  return (
    <>
      <>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          visible={props.visible ?? true}
          showIndicator={true}
          shading={props.shading ?? true}
          showPane={true}
          hideOnOutsideClick={false}
          hideOnParentScroll={false}
          position={props.position}
        />
      </>
    </>
  )
}

export default LoadingPanel
