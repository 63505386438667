import { ArraySourceComposer } from '@/auth/api/connector'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ad_product_catalog, ad_promotion, ad_subscription } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ILineItemRef, LineItemProps } from '@/routes/quotes/line-items/LineItem.types'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import DataSource from 'devextreme/data/data_source'
import {
  CustomItemCreatingEvent,
  InitializedEvent,
  ValueChangedEvent as ValueChangedEventSelectBox,
} from 'devextreme/ui/select_box'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { NumberBoxTypes } from 'devextreme-react/cjs/number-box'
import './LineItem.scss'
import PricingGroupItem from '@/routes/quotes/line-items/PricingGroupItem'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import Popover, { PopoverRef } from 'devextreme-react/cjs/popover'
import notify from 'devextreme/ui/notify'
import { customButton } from '@/routes/utils'
import { ClickEvent } from 'devextreme/ui/button'

// eslint-disable-next-line react/display-name
const LineItemSwitchTerm = forwardRef<ILineItemRef, LineItemProps>(
  (props: LineItemProps, ref: React.ForwardedRef<ILineItemRef>) => {
    const {
      item,
      products,
      csn,
      creating,
      terms,
      readOnly,
      promotions,
      removeSelf,
      refreshProductsCatalog,
      refreshPromotions,
    } = props

    const title = item.quoteLineNumber ? `${item.action} Term ${item.quoteLineNumber}` : `${item.action} Term`

    const { service } = useQsAdminApiManager()

    const formRef = useRef<FormRef>(null)

    const [loadIndicatorVisible, setLoadIndicatorVisible] = useState(false)
    const [currentSubs, setCurrentSubs] = useState<string | ad_subscription | null | undefined>(item.subscription)
    const subsPopoverRef = useRef<PopoverRef>(null)
    const [style, setStyle] = useState<string>('line-item')
    const [quantitySubscription, setQuantitySubscription] = useState<number>(0)
    const [productSubscription, setProductSubscription] = useState<ad_product_catalog | null>(null)
    const [subscriptionsDataSource, setSubscriptionsDataSource] = useState<ad_subscription[]>([])
    const [termDataSource, setTermDataSource] = useState<DataSource>(ArraySourceComposer('termCode', terms))
    const [promotionDataSource, setPromotionDataSource] = useState<DataSource>(ArraySourceComposer('code', promotions))
    const [productsDataSource, setProductsDataSource] = useState<DataSource>(
      ArraySourceComposer('offeringId', products),
    )

    const getTerms = async (productCode: string) => {
      const termsData = await service.ad_product_catalog().query((builder, product) => {
        builder.filter(product.offeringCode.eq(productCode))
        builder.select('termCode', 'termDescription')
        builder.orderBy(product.termCode.asc())
      })
      return termsData.data.value
    }

    const getPromotions = async (
      action: string | undefined,
      productCode: string | null | undefined,
      iuCode: string | undefined,
      termCode: string | undefined | null,
      amCode: string | undefined | null,
      quantity: number | undefined,
    ) => {
      const promotionsData = await service.ad_promotion().query((builder, promotion) => {
        builder.filter(promotion.offeringCode.eq(productCode ?? null))
        builder.filter(promotion.orderAction.eq(action ?? null))
        builder.filter(promotion.startDate.lowerEquals(new Date().toISOString()))
        builder.filter(promotion.endDate.greaterEquals(new Date().toISOString()).or(promotion.endDate.eq(null)))
        builder.filter(promotion.intendedUsageCode.eq(iuCode ?? null).or(promotion.intendedUsageCode.eq(null)))
        builder.filter(promotion.termCode.eq(termCode ?? null).or(promotion.termCode.eq(null)))
        builder.filter(promotion.accessModelCode.eq(amCode ?? null).or(promotion.accessModelCode.eq(null)))
        builder.filter(promotion.quantityNeeded.lowerEquals(quantity ?? 0))
        builder.orderBy(promotion.code.asc())
      })
      return promotionsData.data.value
    }

    const getProductCatalog = async (productCode: string, termCode: string) => {
      const productData = await service.ad_product_catalog().query((builder, product) => {
        builder.filter(product.offeringCode.eq(productCode))
        builder.filter(product.termCode.eq(termCode))
        builder.select(
          'offeringId',
          'offeringName',
          'offeringCode',
          'accessModelCode',
          'accessModelDescription',
          'servicePlanCode',
          'servicePlanDescription',
        )
      })
      return productData.data.value[0]
    }

    const getSubscriptions = async (csnId: number) => {
      const subscriptionsData = await service.ad_subscription().query((builder, subscription) => {
        builder.filter(subscription.csn.props.id.eq(csnId))
        builder.filter(subscription.status.eq('Active'))
        builder.select(
          'id',
          'subscriptionId',
          'serialNumber',
          'quantity',
          'productCode',
          'productName',
          'term',
          'endDate',
        )
      })
      return subscriptionsData.data.value
    }

    //metodi esposti dal componente
    useImperativeHandle(ref, () => {
      return {
        validate() {
          console.log('index item validate', item.lineNumber)
          return formRef.current?.instance().validate()
        },
      } satisfies ILineItemRef
    }, [item.lineNumber])

    //al primo render del componente carico le subs
    useEffect(() => {
      getSubscriptions(Number(csn?.id)).then((subs: ad_subscription[]) => {
        setSubscriptionsDataSource(subs)
      })
    }, [])

    useEffect(() => {
      setPromotionDataSource(ArraySourceComposer('code', promotions))
    }, [promotions])

    useEffect(() => {
      setProductsDataSource(ArraySourceComposer('offeringId', products))
    }, [products])

    const handleSubscriptionMouseOut = (event: MouseEvent, eventInitialized: InitializedEvent) => {
      if (eventInitialized.component?.option().value) subsPopoverRef.current?.instance().hide()
    }

    const handleSubscriptionMouseOver = async (event: MouseEvent, eventInitialized: InitializedEvent) => {
      if (eventInitialized.component?.option().value) {
        if (eventInitialized.component?.option().value.endDate) {
          subsPopoverRef.current?.instance().option('contentTemplate', function (contentElement: any) {
            const endDate = new Date(eventInitialized.component?.option().value.endDate)
            const product = eventInitialized.component?.option().value.productName
            return `<div><b>Prodotto: ${product}</b></b><br><b>Scadenza: ${endDate.toLocaleDateString()}</b></div>`
          })
        } else {
          const getSub = await service.ad_subscription().query((builder, subsciption) => {
            builder.filter(subsciption.subscriptionId.eq(eventInitialized.component?.option().value))
            builder.select('endDate', 'productName')
          })
          if (getSub.data.value.length > 0) {
            const endDate = new Date(getSub.data.value[0].endDate ?? '')
            const product = getSub.data.value[0].productName
            subsPopoverRef.current
              ?.instance()
              .option(
                'contentTemplate',
                `<div><b>Prodotto: ${product}</b></b><br><b>Scadenza: ${endDate.toLocaleDateString()}</b></div>`,
              )
          } else
            subsPopoverRef.current?.instance().option('contentTemplate', `<div><b>Nessuna data di scadenza.</b></div>`)
        }
        subsPopoverRef.current?.instance().option('target', eventInitialized.element)
        subsPopoverRef.current?.instance().show()
      }
    }

    return (
      <>
        <div id={`item-quote-container-${item.quoteLineNumber}`} className={style}>
          <LoadingPanel
            position={{ of: `#item-quote-container-${item.quoteLineNumber}` }}
            visible={loadIndicatorVisible}
          ></LoadingPanel>
          <DXForm
            id={`item_form_${item.quoteLineNumber}`}
            key={`item_form_${item.quoteLineNumber}`}
            formData={item}
            labelLocation={'top'}
            colCount={1}
            showValidationSummary={true}
            validationGroup={`itemValidation_${item.quoteLineNumber}`}
            ref={formRef}
            readOnly={readOnly}
          >
            <GroupItem colCount={2}>
              <SimpleItem>
                <h3 className="title">{title}</h3>
              </SimpleItem>
              {item.operation === '' || item.operation === 'Insert' ? (
                <ButtonItem>
                  <ButtonOptions
                    disabled={readOnly}
                    text="Elimina"
                    icon={'trash'}
                    stylingMode={'text'}
                    type="danger"
                    onClick={(e) => {
                      removeSelf()
                      setStyle('line-item deleted')
                    }}
                  />
                </ButtonItem>
              ) : (
                <ButtonItem>
                  <ButtonOptions
                    disabled={readOnly}
                    text="Ripristina"
                    icon={'undo'}
                    stylingMode={'text'}
                    type="success"
                    onClick={(e) => {
                      item.operation = ''
                      setStyle('line-item')
                    }}
                  />
                </ButtonItem>
              )}
            </GroupItem>
            <GroupItem colCount={6}>
              <SimpleItem
                colSpan={1}
                dataField="subscription"
                label={{ text: 'Subscription' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    customButton('open', async (e: ClickEvent) => {
                      if (item.subscription) {
                        if (typeof item.subscription === 'object')
                          window.open(`/software/autodesk/${item.subscription.id}`, '_blank')
                        else {
                          const sub = item.subscription
                          const getSub = await service.ad_subscription().query((builder, subsciption) => {
                            builder.filter(subsciption.subscriptionId.eq(sub))
                            builder.select('id')
                          })
                          if (getSub.data.value.length > 0)
                            window.open(`/software/autodesk/${getSub.data.value[0].id}`, '_blank')
                          else notify(`Nessun subcription trovato.`, 'warning', 3000)
                        }
                      } else notify(`Deve essere selezionato un subscription id per poterlo aprire.`, 'warning', 3000)
                    }),
                    { name: 'dropDown', location: 'after' },
                  ],
                  value: currentSubs,
                  dataSource: ArraySourceComposer('id', subscriptionsDataSource),
                  placeholder: 'Selezionare subscription...',
                  displayExpr: 'subscriptionId',
                  searchEnabled: true,
                  acceptCustomValue: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare subscription',
                    hideOnOutsideClick: true,
                  },
                  readOnly: item.quoteLineNumber || readOnly ? true : false,
                  disabled: item.operation === 'Delete',
                  onValueChanged: (e: ValueChangedEventSelectBox) => {
                    if (e.previousValue?.id === e.value?.id) return
                    console.log('SUBSCRIPTION EVENT CHANGED', e.previousValue, e.value)
                    if (e.value && typeof e.value === 'object') {
                      setQuantitySubscription(e.value.quantity)
                      formRef.current?.instance().updateData('quantity', e.value.quantity)
                      getProductCatalog(e.value.productCode, e.value.term).then((res: ad_product_catalog) => {
                        setProductSubscription(res)
                      })
                      setCurrentSubs(e.value)
                    }
                  },
                  onCustomItemCreating: (e: CustomItemCreatingEvent) => {
                    console.log('CustomItemCreatingEvent', e)
                    if (!e.customItem) e.customItem = e.text
                    setCurrentSubs(e.customItem)
                    setQuantitySubscription(0)
                    formRef.current?.instance().updateData('quantity', 0)
                    setProductSubscription(null)
                  },
                  onInitialized: (e: InitializedEvent) => {
                    e.element?.addEventListener('mouseout', (event) => handleSubscriptionMouseOut(event, e))
                    e.element?.addEventListener('mouseover', (event) => handleSubscriptionMouseOver(event, e))
                  },
                }}
              >
                <RequiredRule message="Subscription obbligatorio"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                dataField="offer.term"
                label={{ text: 'Termini' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: termDataSource,
                  placeholder: 'Selezionare il termine...',
                  displayExpr: 'termDescription',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare il termine',
                    hideOnOutsideClick: true,
                  },
                  disabled: item.operation === 'Delete',
                }}
              >
                <RequiredRule message="Termini obbligatorio"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                dataField="quantity"
                editorType="dxNumberBox"
                label={{ text: 'Quantità' }}
                editorOptions={{
                  disabled: item.operation === 'Delete',
                  showSpinButtons: true,
                  min: 0,
                  onValueChanged: (e: NumberBoxTypes.ValueChangedEvent) => {
                    console.log('quantity event changed', e)
                    getPromotions(
                      item.action,
                      'productCatalog' in item ? item.productCatalog?.offeringCode : productSubscription?.offeringCode,
                      item.offer?.intendedUsage?.code,
                      item.offer?.term?.termCode,
                      'productCatalog' in item
                        ? item.productCatalog?.accessModelCode
                        : productSubscription?.accessModelCode,
                      item.quantity,
                    ).then((res: ad_promotion[]) => {
                      setPromotionDataSource(
                        ArraySourceComposer('code', [
                          ...new Map(res.map((promotion: ad_promotion) => [promotion['code'], promotion])).values(),
                        ]),
                      )
                    })
                  },
                }}
              >
                <RequiredRule message="Quantità obbligatoria"></RequiredRule>
                {/* <CustomRule
                  message="Quantità deve essere maggiore di 0 ma non maggiore dell'attuale quantità presente in abbonamento"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    return value > 0 && value <= quantitySubscription
                  }}
                ></CustomRule> */}
              </SimpleItem>
              <SimpleItem
                dataField="promotion"
                label={{ text: 'Promozione' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    {
                      name: 'refresh',
                      location: 'after',
                      options: {
                        disabled: false,
                        visible: true,
                        stylingMode: 'text',
                        icon: 'refresh',
                        hint: 'Aggiorna elenco',
                        onClick: () => {
                          setLoadIndicatorVisible(true)
                          if (refreshPromotions) {
                            refreshPromotions().finally(() => {
                              setLoadIndicatorVisible(false)
                            })
                          }
                        },
                      },
                    },
                    {
                      name: 'clear',
                      location: 'after',
                    },
                    { name: 'dropDown', location: 'after' },
                  ],
                  dataSource: promotionDataSource,
                  placeholder: 'Selezionare la promozione...',
                  displayExpr: (item: ad_promotion) => {
                    return item ? `${item.name} - ${item.code}` : ''
                  },
                  searchEnabled: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare la promozione',
                    hideOnOutsideClick: true,
                    resizeEnabled: true,
                  },
                  clearButtonText: 'Clear',
                  showClearButton: true,
                  disabled: item.operation === 'Delete',
                }}
              ></SimpleItem>
            </GroupItem>
            {!creating && item.operation !== 'Insert' && PricingGroupItem()}
          </DXForm>
          <Popover position="bottom" ref={subsPopoverRef} />
        </div>
      </>
    )
  },
)

export default LineItemSwitchTerm
