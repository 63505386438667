import { Column, DataGrid, Paging, Sorting, StateStoring } from 'devextreme-react/data-grid'
import { TicketEditorEventsProps } from '@/routes/tickets/TicketEditor.types'
import { ArraySourceComposer } from '@/auth/api/connector'
import { evento_ticket } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useScreenSize } from '@/themes/media-query'

const TicketEditorEvents = (props: TicketEditorEventsProps) => {
  const { ticket } = props
  const currentScreenSize = useScreenSize()
  return (
    <>
      <DataGrid
        id={`ticket-events-grid-${ticket && 'id' in ticket ? ticket?.id : null}`}
        dataSource={ArraySourceComposer(
          'id',
          ticket?.eventi?.sort((eventoA: evento_ticket, eventoB: evento_ticket) => {
            const dataA: Date = new Date(eventoA.data ?? '')
            const dataB: Date = new Date(eventoB.data ?? '')
            return dataB.getTime() - dataA.getTime()
          }),
        )}
        noDataText="Nessun evento associato al ticket"
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        allowColumnReordering={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
      >
        <StateStoring enabled={true} type="localStorage" storageKey={'ticket-events-datagrid'} savingTimeout={50} />
        <Paging defaultPageSize={15} />
        <Column
          dataField={'data'}
          caption={'DATA'}
          dataType={'date'}
          format={'dd/MM/yyyy HH:mm:ss'}
          allowSorting={false}
          width={'auto'}
        />
        <Column dataField={'utente.fullname'} caption={'UTENTE'} allowSorting={false} />
        <Column dataField={'tipologia.nome'} caption={'TIPOLOGIA'} allowSorting={false} />
        <Column dataField={'note'} caption={'NOTE'} allowSorting={false} />
      </DataGrid>
    </>
  )
}

export default TicketEditorEvents
