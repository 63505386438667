import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import useTokenRefresh from '@/auth/azure/azureManager'
import DataSource from 'devextreme/data/data_source'
import ODataStore from 'devextreme/data/odata/store'
import {
  Column,
  DataGrid,
  Editing,
  Lookup,
  Paging,
  RequiredRule,
  Button as GridButton,
  StateStoring,
  DataGridRef,
  Toolbar,
  Item,
  DataGridTypes,
} from 'devextreme-react/data-grid'
import type { ColumnButtonClickEvent, InitNewRowEvent, RowDblClickEvent, SavingEvent } from 'devextreme/ui/data_grid'
import { useCallback, useRef } from 'react'
import { OrderEditorTasksGridProps } from '@/routes/orders/OrderEditor.types'
import { TagBox } from 'devextreme-react'
import { TagBoxTypes } from 'devextreme-react/cjs/tag-box'
import { Link, useNavigate } from 'react-router-dom'
import { Form as DxForm, GroupItem } from 'devextreme-react/form'
import DateColumn from '@/components/date-column/DateColumn'
import notify from 'devextreme/ui/notify'
import { useScreenSize } from '@/themes/media-query'
import { ClickEvent } from 'devextreme/ui/button'
import { task } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'

const TecniciTagBoxComponent = (props: any) => {
  const onValueChanged = useCallback(
    (e: TagBoxTypes.ValueChangedEvent) => {
      props.data.setValue(e.value)
    },
    [props],
  )

  const onSelectionChanged = useCallback(() => {
    props.data.component.updateDimensions()
  }, [props])

  return (
    <TagBox
      dataSource={props.data.column.lookup.dataSource}
      defaultValue={props.data.value}
      displayExpr="fullname"
      showSelectionControls={true}
      maxDisplayedTags={3}
      showMultiTagOnly={false}
      applyValueMode="useButtons"
      searchEnabled={true}
      onValueChanged={onValueChanged}
      onSelectionChanged={onSelectionChanged}
    />
  )
}

const OrderEditorTasksGrid = (props: OrderEditorTasksGridProps) => {
  const { order, creating, impiegati, autore, statiTask, readOnly, userInfo } = props
  const currentScreenSize = useScreenSize()
  const navigate = useNavigate()
  const token = useTokenRefresh()
  const gridRef = useRef<DataGridRef>(null)

  const tasksDataSource = new DataSource({
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/task`,
      key: 'id',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: [['ordine.id', order && 'id' in order ? order?.id : null]],
    expand: ['ordine', 'sede($expand=azienda)', 'resp_tecnico', 'proprietario', 'stato', 'tecnici'],
  })

  const cellTemplate = (container: { textContent: any; title: any }, options: any) => {
    const noBreakSpace = '\u00A0'
    console.log(options)
    const tecnici = (options.value || []).map((tecnico: any) => options.column.lookup.calculateCellValue(tecnico.id))
    const text = tecnici.join(', ')

    container.textContent = text || noBreakSpace
    container.title = text
  }

  return (
    <>
      <DxForm id="order-tasks">
        <GroupItem caption="Task">
          <DataGrid
            ref={gridRef}
            id={`order-task-grid-${order && 'id' in order ? order?.id : null}`}
            dataSource={tasksDataSource}
            noDataText="Nessun task associato all'ordine"
            keyExpr={'id'}
            showBorders={true}
            showColumnLines={true}
            focusedRowEnabled={true}
            columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
            allowColumnReordering={true}
            allowColumnResizing={true}
            rowAlternationEnabled={true}
            wordWrapEnabled={false}
            repaintChangesOnly={true}
            width="100%"
            onSaving={(e: SavingEvent) => {
              console.log('SAVING', e)
            }}
            onInitNewRow={(e: InitNewRowEvent) => {
              e.data.ordine = order
              e.data.proprietario = autore
              e.data.stato = statiTask[0]
              e.data.apertura = new Date().toISOString()
              e.data.commessa = order.commessa
              e.data.sede = order.commessa?.sede
              e.data.anno_rif = new Date().getFullYear()
              e.data.ded_Id = 0
            }}
            onRowDblClick={(e: RowDblClickEvent<any, any>) => {
              if (e.rowType === 'data') navigate(`../../tasks/${e.data.id}`)
            }}
          >
            <StateStoring enabled={true} type="localStorage" storageKey={'order-tasks-datagrid'} savingTimeout={50} />
            <Paging defaultPageSize={8} />
            <Editing
              mode="batch"
              allowAdding={!creating && !readOnly}
              allowDeleting={!creating && !readOnly}
              selectTextOnEditStart={true}
              startEditAction={'click'}
            />
            <Column type="buttons" width={'4%'} alignment="left">
              <GridButton
                hint="Details"
                icon="search"
                onClick={(e: ColumnButtonClickEvent) => {
                  navigate(`/tasks/${e.row?.data.id}`)
                }}
              />
            </Column>
            <Column
              dataField={'ded_Dis'}
              caption={'CODICE'}
              cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
                const data: task = cellData.row.data
                return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                  <>
                    <Link to={`/tasks/${data.id}`}>{data.ded_Dis}</Link>
                  </>
                ) : (
                  <>{data.ded_Dis}</>
                )
              }}
              editorOptions={{ readOnly: true }}
              hidingPriority={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
              }
            />
            ,
            <Column dataField={'id'} caption={'ID'} visible={false} />,
            <Column dataField={'ordine.ded_Dis'} caption={'ORDINE'} visible={false} />,
            <Column dataField={'sede.azienda.nome'} caption={'AZIENDA'} visible={false} />
            <Column dataField={'sede.nome'} caption={'SEDE'} visible={false} />
            <Column
              dataField={'proprietario.id'}
              caption={'AUTORE'}
              hidingPriority={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
              }
              editorOptions={{ readOnly: true }}
            >
              <Lookup dataSource={impiegati} displayExpr="fullname" valueExpr="id" allowClearing={true} />
            </Column>
            <Column
              dataField={'resp_tecnico.id'}
              caption={'RESPONSABILE'}
              hidingPriority={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
              }
            >
              <Lookup dataSource={impiegati} displayExpr="fullname" valueExpr="id" allowClearing={true} />
              <RequiredRule message="Il responsabile tecnico è obbligatorio" />
            </Column>
            <Column
              dataField={'tecnici'}
              caption={'TECNICI AUSILIARI'}
              editCellComponent={TecniciTagBoxComponent}
              cellTemplate={cellTemplate}
              hidingPriority={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
              }
            >
              <Lookup dataSource={impiegati} displayExpr="fullname" valueExpr="id" />
            </Column>
            <Column
              dataField={'stato.id'}
              caption={'STATO'}
              editorOptions={{ readOnly: true }}
              hidingPriority={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
              }
            >
              <Lookup dataSource={statiTask} displayExpr="nome" valueExpr="id" allowClearing={true} />
            </Column>
            {DateColumn({
              dataField: 'apertura',
              caption: 'APERTURA',
              format: 'dd/MM/yyyy',
              hidingPriority:
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined,
            })}
            {DateColumn({
              dataField: 'consegna_prevista',
              caption: 'CONSEGNA',
              format: 'dd/MM/yyyy',
              hidingPriority:
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined,
            })}
            <Column dataField={'note'} caption={'NOTE'} visible={false} />
            <Column type="buttons" width={'4%'} alignment="left">
              <GridButton name="delete"></GridButton>
            </Column>
            <Toolbar>
              <Item
                location="before"
                locateInMenu="never"
                showText="inMenu"
                widget="dxButton"
                options={{
                  hint: 'Refresh',
                  text: 'Refresh',
                  icon: 'refresh',
                  onClick: (e: ClickEvent) => {
                    gridRef.current?.instance().refresh()
                  },
                  stylingMode: 'text',
                }}
              ></Item>
              <Item name="addRowButton"></Item>
              <Item name="saveButton"></Item>
              <Item name="revertButton"></Item>
            </Toolbar>
          </DataGrid>
        </GroupItem>
      </DxForm>
    </>
  )
}

export default OrderEditorTasksGrid
