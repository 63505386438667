import { ArraySourceComposer } from '@/auth/api/connector'
import { AzureUserInfo } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import { GridCellColor } from '@/enums'
import { task } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { isUserRoleAllowed } from '@/routes/utils'
import { useScreenSize } from '@/themes/media-query'
import { DataGrid } from 'devextreme-react'
import { Column, Paging, Button as GridButton, StateStoring, DataGridTypes } from 'devextreme-react/cjs/data-grid'
import { CellPreparedEvent, ColumnButtonClickEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import { Link } from 'react-router-dom'

const ProjectEditorFormTasksGrid = (props: { tasks?: task[]; userInfo: AzureUserInfo | undefined }) => {
  const { tasks, userInfo } = props
  const currentScreenSize = useScreenSize()
  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.nome' && e.value) {
      switch (e.value) {
        case 'APERTO': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`

          break
        }
        case 'COMPLETATO': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.EMERALD}`

          break
        }
      }
    }
  }

  return (
    <>
      <DataGrid
        id={`project-orders-grid`}
        dataSource={ArraySourceComposer(
          'id',
          tasks
            ? tasks.sort((taskA: task, taskB: task) => {
                const dataA: Date = new Date(taskA.apertura ?? '')
                const dataB: Date = new Date(taskB.apertura ?? '')
                return dataB.getTime() - dataA.getTime()
              })
            : [],
        )}
        noDataText="Nessun task associato alla commessa"
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onCellPrepared={onCellPrepared}
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') window.open(`/tasks/${e.data.id}`, '_blank')
        }}
      >
        <StateStoring enabled={true} type="localStorage" storageKey={'project-tasks-datagrid'} savingTimeout={50} />
        <Paging defaultPageSize={8} />
        <Column
          type="buttons"
          width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
          alignment="left"
        >
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              window.open(`/tasks/${e.row?.data.id}`, '_blank')
            }}
          />
        </Column>
        <Column
          dataField={'id'}
          caption={'ID'}
          visible={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
          }
        />
        <Column
          dataField={'ded_Dis'}
          caption={'CODICE'}
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: task = cellData.row.data
            return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
              <>
                <Link to={`/tasks/${data.id}`}>{data.ded_Dis}</Link>
              </>
            ) : (
              <>{data.ded_Dis}</>
            )
          }}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
          }
        />
        <Column
          dataField={'proprietario.fullname'}
          caption={'AUTORE'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined
          }
        />
        <Column
          dataField={'resp_tecnico.fullname'}
          caption={'RESPONSABILE'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
          }
        />
        <Column
          dataField={'apertura'}
          dataType="date"
          caption={'APERTURA'}
          format={'dd/MM/yyyy'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
          }
        />
        <Column
          dataField={'consegna_prevista'}
          dataType="date"
          caption={'CONSEGNA'}
          format={'dd/MM/yyyy'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
          }
        />
        <Column
          dataField={'chiusura'}
          dataType="date"
          caption={'CHIUSURA'}
          format={'dd/MM/yyyy'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
          }
        />
        <Column
          dataField={'stato.nome'}
          caption={'STATO'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
          }
        />
      </DataGrid>
    </>
  )
}

export default ProjectEditorFormTasksGrid
