import { SingleCard } from '../../layouts'
import { LoginForm } from '../../components'
import type { RouteObject } from 'react-router-dom'
import { AuthRedirect } from '@/components/login-form/LoginForm'

const LoginRoute: RouteObject = {
  path: '/login',
  element: (
    <AuthRedirect>
      <SingleCard title={`Login`} description={'Accesso utenti Qs Web Admin'} logo={'https://tinyurl.com/25sgqoyx'}>
        <LoginForm />
      </SingleCard>
    </AuthRedirect>
  ),
}

export default LoginRoute
