export enum GridCellColor {
  EMERALD = '#58dd7f',
  SALMON = '#fa8072',
  AQUA = '#00ffff',
  YELLOW = '#ecec43',
  ORANGE = '#ff7f50',
  LILAC = '#9fa2de',
  GREY = '#808080',
  VIOLET = '#7F00FF',
  RED = '#a8260f',
  LIGHTGRREN = '#90ee90',
  BLACK = '#000000',
  BROWN = '#a52a2a',
  CORNFLOWERBLUE = '#6495ed',
  SKYBLUE = '#87ceeb',
  DEEPSKYBLUE = '#00bfff',
}

export enum FormMode {
  Create = 'create',
  View = 'view',
  Edit = 'edit',
}

export enum DocumentPermissions {
  Deny = 'deny',
  Read = 'read',
  Write = 'write',
}
