export enum StatoOfferta {
  'APERTA',
  'PERSA',
  'COMPLETATA',
  'ORDINATA',
  'SUPERATA',
}

export enum TemplateOfferta {
  'QS' = 1,
  'QSINFOR',
}
