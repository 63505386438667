import { ButtonItem, Form, GroupItem, RequiredRule, SimpleItem } from 'devextreme-react/form'
import { useState } from 'react'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'

type OutlookAppointmentsComputeProps = {
  data:
    | {
        startDate: Date
        endDate: Date
      }
    | undefined
  onSubmitEditor:
    | ((
        e: any,
        params: {
          startDate: Date | undefined
          endDate: Date | undefined
        },
      ) => Promise<void>)
    | undefined
}

export const OutlookAppointmentsCompute = (props: OutlookAppointmentsComputeProps) => {
  const { data, onSubmitEditor } = props
  const [loadIndicatorVisible, setLoadIndicatorVisible] = useState<boolean>(false)
  const [endDate, setEndDate] = useState<Date | undefined>(data?.endDate)

  return (
    <>
      <div id="outlook-appointments-compute-form-container" className={'dx-card responsive-paddings'}>
        <LoadingPanel
          position={{ of: '#outlook-appointments-compute-form-container' }}
          visible={loadIndicatorVisible}
        ></LoadingPanel>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            setLoadIndicatorVisible(true)
            const params = {
              startDate: data?.startDate,
              endDate: data?.endDate,
            }
            if (onSubmitEditor) {
              onSubmitEditor(e, params).finally(() => {
                setLoadIndicatorVisible(false)
              })
            }
          }}
        >
          <Form
            id={`outlook-appointments-compute-form}`}
            key={`outlook-appointments-compute-form}`}
            formData={data}
            validationGroup="outlookAppointmentsComputeValidation"
          >
            <GroupItem colCount={1}>
              <SimpleItem
                dataField="startDate"
                editorOptions={{
                  displayFormat: 'dd/MM/yyyy',
                  type: 'date',
                  pickerType: 'calendar',
                  onValueChanged: (e: any) => {
                    console.log('startDate', e.value)
                    const newEndDate = new Date(e.value)
                    newEndDate.setDate(newEndDate.getDate() + 5)
                    console.log('endDate', newEndDate)
                    setEndDate(newEndDate)
                  },
                }}
                editorType="dxDateBox"
                label={{ text: 'Data Inizio' }}
              >
                <RequiredRule message="Data inizio obbligatoria" />
              </SimpleItem>
              <SimpleItem
                dataField="endDate"
                editorOptions={{
                  displayFormat: 'dd/MM/yyyy',
                  type: 'date',
                  pickerType: 'calendar',
                  value: endDate,
                }}
                editorType="dxDateBox"
                label={{ text: 'Data Fine' }}
              >
                <RequiredRule message="Data fine obbligatoria" />
              </SimpleItem>
            </GroupItem>
            <GroupItem cssClass="last-group">
              <GroupItem cssClass="buttons-group" colCount={1}>
                <ButtonItem
                  name="btn-compute"
                  buttonOptions={{
                    icon: 'event',
                    text: 'Crea',
                    disabled: false,
                    useSubmitBehavior: true,
                    width: '150px',
                  }}
                ></ButtonItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </>
  )
}

export default OutlookAppointmentsCompute
