import { LoaderFunction } from 'react-router-dom'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import PackagesUsageGrid from '@/routes/packages/usage/PackagesUsageGrid'
import { PackageUsageEditorRoute } from '@/routes/packages/usage/PackageUsageEditor.route'
import { PackageUsageCreatorRoute } from '@/routes/packages/usage/PackageUsageCreator.route'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Roles } from '@/auth/azure/Roles'
import { getAzureUserInformation } from '@/auth/azure/azureManager'

export const packagesUsageGridRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })
  return {
    aziende: await getAziende,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor],
  }
}) satisfies LoaderFunction

export const PackagesUsageGridRoute = {
  path: 'usage',
  children: [
    {
      index: true,
      loader: packagesUsageGridRouteLoader,
      element: <PackagesUsageGrid />,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    PackageUsageCreatorRoute,
    PackageUsageEditorRoute,
  ],

  icon: 'box',
  text: 'Pacchetti Utilizzati',
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
