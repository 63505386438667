import Button from 'devextreme-react/button'
import { useTheme } from '@/providers/theme'
import { applyTheme } from '@microsoft/mgt-components'
import './theme-selector.scss'

export const ThemeLightSwitcher = () => {
  const { getTheme, setTheme } = useTheme()

  const onButtonClick = () => {
    const currentTheme = getTheme()

    console.log('CT', currentTheme)

    if (currentTheme.includes('dark')) {
      setTheme(currentTheme.replace('dark', 'light'))
      //mgt theme components
      applyTheme('light')
    } else {
      setTheme(currentTheme.replace('light', 'dark'))
      //mgt theme components
      applyTheme('dark')
    }
  }

  return (
    <div>
      <Button
        className="theme-button"
        stylingMode="text"
        icon={`${getTheme()?.includes('dark') ? 'sun' : 'moon'}`}
        onClick={onButtonClick}
      />
    </div>
  )
}

export const ThemeSizeSwitcher = () => {
  const { getTheme, setTheme } = useTheme()

  const onButtonClick = () => {
    const currentTheme = getTheme()

    console.log('CT', currentTheme)

    if (currentTheme.includes('compact')) {
      setTheme(currentTheme.replace('.compact', ''))
    } else {
      setTheme(`${currentTheme}.compact`)
    }
  }

  return (
    <div>
      <Button
        className="theme-button"
        stylingMode="text"
        icon={`${getTheme()?.includes('compact') ? 'expand' : 'collapse'}`}
        onClick={onButtonClick}
      />
    </div>
  )
}
