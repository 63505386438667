import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import InterventionCreatorRoute from '@/routes/interventions/InterventionCreator.route'
import InterventionEditorRoute from '@/routes/interventions/InterventionEditor.route'
import InterventionsPage from '@/routes/interventions/InterventionsPage'
import { RoleRoute, RoleRouteObject, RouteFunctionParams } from '@/types'
import { LoaderFunction, defer } from 'react-router-typesafe'

export const interventionsPageRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  const getImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  return defer({
    aziende: await getAziende,
    impiegati: await getImpiegati,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor, Roles.TechDeveloper],
  })
}) satisfies LoaderFunction

export const InterventionsPageRoute: RoleRoute = {
  path: 'interventions',
  children: [
    {
      index: true,
      element: <InterventionsPage />,
      loader: interventionsPageRouteLoader,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    InterventionEditorRoute,
    InterventionCreatorRoute,
  ],
  errorElement: <RouterErrorHandler />,
  text: 'Interventi',
  icon: 'car',
  mainMenuRoute: true,
  allowedRoles: [Roles.Guest],
}
