import { RouteObject } from 'react-router-dom'
import { taskEditorRouteAction, taskEditorRouteLoader } from '@/routes/tasks/TaskEditor.route'
import TaskEditor from '@/routes/tasks/TaskEditor'
import { RoleRouteObject } from '@/types'
import { Roles } from '@/auth/azure/Roles'

export const TaskCreatorRoute: RoleRouteObject = {
  path: 'new',
  element: <TaskEditor creating={true} />,
  loader: taskEditorRouteLoader,
  action: taskEditorRouteAction,
  allowedRoles: [Roles.Guest],
}
