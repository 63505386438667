export type RoleType = {
  DisplayName: string
  Id: string
  Description: string
  Name: string
}

export class RolesDescriptor {
  private readonly roles: Role[] = []

  constructor(roleNames?: string[]) {
    if (roleNames) {
      this.roles = roleNames.map((roleName) => {
        return Roles.map.get(roleName) as Role
      })
    }
  }

  hasRole(role: Role) {
    return this.roles.includes(role)
  }
  static isEqual(a?: RolesDescriptor, b?: RolesDescriptor): boolean {
    if (!(a && b)) return false
    if (!(a.roles && b.roles)) return false
    return a.roles.every((role) => b?.hasRole?.(role))
  }
  getRoles() {
    return this.roles
  }
}

export class Role {
  // private to disallow creating other instances of this type
  public constructor(
    public readonly key: string,
    public readonly value: RoleType,
  ) {}

  toString() {
    return this.key
  }
}

export class Roles {
  static readonly Administrator = new Role('Administrator', {
    DisplayName: 'Administrator',
    Id: '708db137-bcdb-4719-ba56-23525bff5884',
    Description: 'Grants full administrative privileges to all endpoints.',
    Name: 'Administrator',
  })
  static readonly GlobalAdministrator = new Role('GlobalAdministrator', {
    DisplayName: 'GlobalAdministrator',
    Id: '0c5a4037-d253-4cd3-b90a-391411699312',
    Description: 'Grants full administrative privileges for application developers.',
    Name: 'GlobalAdministrator',
  })
  static readonly Guest = new Role('Guest', {
    DisplayName: 'Guest',
    Id: 'f962f054-7661-45ac-af0e-c68d41db1a5f',
    Description: 'Guest user with implicit deny on all resources.',
    Name: 'Guest',
  })
  static readonly Marketing = new Role('Marketing', {
    DisplayName: 'Marketing',
    Id: '9cfd42ef-8efa-4afa-b67e-8f109a10c7ee',
    Description: 'Grants specific privileges for the marketing department. Implicit allow all.',
    Name: 'Marketing',
  })
  static readonly Sales = new Role('Sales', {
    DisplayName: 'Sales',
    Id: '764beb92-695a-44bc-94c2-994daf260a92',
    Description: 'Grants specific permissions for the sales (commercial) department. Implicit Allow All.',
    Name: 'Sales',
  })
  static readonly TechDeveloper = new Role('TechDeveloper', {
    DisplayName: 'TechDeveloper',
    Id: '8f4096a4-2d15-4635-8c21-ebbc862a58cc',
    Description:
      'Default role assigned to all developers and techs. Grants access to the required resources with limitations on specific sensitive areas.',
    Name: 'TechDeveloper',
  })
  static readonly Supervisor = new Role('Supervisor', {
    DisplayName: 'Supervisor',
    Id: '3fdef64-3fa1-43b3-b973-2441b91f6d4',
    Description: 'Grants access to all resources without administrative privileges.',
    Name: 'Supervisor',
  })
  static readonly DepartmentReferent = new Role('DepartmentReferent', {
    DisplayName: 'DepartmentReferent',
    Id: '9f50059a-bf7d-4fc2-8517-7f1b06f0bef1',
    Description: 'User gets notified by department warning.',
    Name: 'DepartmentReferent',
  })
  static readonly ExternalSales = new Role('ExternalSales', {
    DisplayName: 'ExternalSales',
    Id: '9c341d06-30a2-4742-99c1-652d5f0c8d0c',
    Description:
      'Grants specific permissions to external collaborators who are not directly employed by Qs Informatica and are working in sales departments.',
    Name: 'ExternalSales',
  })
  static readonly map = new Map<string, Role>(Object.entries(Roles))
}
