import { Roles } from '@/auth/azure/Roles'
import OrderEditor from '@/routes/orders/OrderEditor'
import { orderEditorRouteAction, orderEditorRouteLoader } from '@/routes/orders/OrderEditor.route'
import { RoleRouteObject } from '@/types'
import { RouteObject } from 'react-router-dom'

const OrderCreatorRoute = {
  path: 'new',
  element: <OrderEditor creating={true} isReseller={false} />,
  loader: orderEditorRouteLoader,
  action: orderEditorRouteAction,
  allowedRoles: [Roles.Sales, Roles.Administrator, Roles.GlobalAdministrator, Roles.Supervisor, Roles.ExternalSales],
} as RoleRouteObject

export default OrderCreatorRoute
