import { RouteObject } from 'react-router-dom'
import PackageSaleEditor from '@/routes/packages/sale/PackageSaleEditor'
import {
  packageSaleEditorRouteAction,
  packageSaleEditorRouteLoader,
} from '@/routes/packages/sale/PackageSaleEditor.route'
import { RoleRouteObject } from '@/types'
import { Roles } from '@/auth/azure/Roles'

export const PackageSaleCreatorRoute: RouteObject = {
  path: 'new',
  element: <PackageSaleEditor creating={true} />,
  loader: packageSaleEditorRouteLoader,
  action: packageSaleEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
