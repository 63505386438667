import type { DataGridTypes } from 'devextreme-react/data-grid'
import { Workbook } from 'exceljs'
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver'
import { email_aziendale as IClientContactEmail } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { EdmLiteral } from 'devextreme/data/odata/utils'

export const onExporting = (e: DataGridTypes.ExportingEvent) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet('Entities')

  exportDataGridToXLSX({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Entities.xlsx')
    })
  })
  e.cancel = true
}

// Function to check if the email address is in the array
export const emailExistsInCollection = (emails: IClientContactEmail[] | undefined, emailToCheck: string): boolean => {
  if (!emails) return false
  return emails.some((emailObj) => emailObj.email === emailToCheck)
}

export const calculateEmailsFilterExpression = (
  filterValue: any,
  selectedFilterOperation: string | null,
  target: string,
) => {
  console.log('FLT', filterValue, selectedFilterOperation, target)

  if (target === 'filterBuilder' || target === 'filterRow') {
    if (selectedFilterOperation === '=' || selectedFilterOperation === '<>') {
      return [[new EdmLiteral(`emails/any(a: a/email eq '${filterValue}')`), '=', selectedFilterOperation === '=']]
    } else if (
      selectedFilterOperation === 'contains' ||
      selectedFilterOperation === 'startswith' ||
      selectedFilterOperation === 'endswith'
    ) {
      return [[new EdmLiteral(`emails/any(a: ${selectedFilterOperation}(a/email, '${filterValue}'))`), '=', true]]
    } else {
      // caso notcontains
      return [[new EdmLiteral(`emails/any(a: contains(a/email, '${filterValue}'))`), '=', false]]
    }
  } else if (target === 'search' || target === 'headerFilter') {
    return [[new EdmLiteral(`emails/any(a: contains(a/email, '${filterValue}'))`), '=', true]]
  }

  return filterValue
}
