import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { analisi, contatto_aziendale, entita_aziendale } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { redirect } from 'react-router-dom'
import { LoaderFunction, ActionFunction, defer } from 'react-router-typesafe'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import AnalysisEditor from '@/routes/analysis/AnalysisEditor'
import { StatoAnalisi } from '@/routes/analysis/AnalysisEditor.enums'
import { ODataCollectionResponseV4 } from '@odata2ts/odata-core'
import { ODataResponse } from '@odata2ts/http-client-api'
import { Roles } from '@/auth/azure/Roles'
import { Qanalisi } from '@/model/qsadminapi/QQsAdminApiModule'

export const analysisEditorRouteLoader = (async ({
  request,
  params,
}: RouteFunctionParams<'analysisId' | 'clientId'>) => {
  const analysisId = params.analysisId
  const clientId = params.clientId

  const qsAdminApi = useQsAdminApiManager.getState().service

  let getPersone: ODataResponse<ODataCollectionResponseV4<contatto_aziendale>> | undefined
  let getEntita: ODataResponse<ODataCollectionResponseV4<entita_aziendale>> | undefined

  let analysis: analisi

  if (analysisId) {
    //editor
    const getAnalysis = await qsAdminApi.analisi(Number(analysisId)).query((builder, analysis) => {
      builder.expanding('sede', (sedeBuilder, sede) => {
        sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
          aziendaBuilder.expanding('sedi', (sediBuilder, sedeAzienda) => {
            sediBuilder.select('id', 'nome', 'note')
            sediBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaBuilder.select('id', 'nome', 'sedi')
        })
        sedeBuilder.select('id', 'nome', 'azienda', 'note')
      })
      builder.expanding('riferimenti', (riferimentiBuilder, contatto) => {
        riferimentiBuilder.select('id', 'fullname')
        riferimentiBuilder.orderBy(contatto.fullname.asc())
      })
      builder.expanding('riferimenti_entita', (riferimentiEntitaBuilder, entita) => {
        riferimentiEntitaBuilder.select('id', 'nome')
        riferimentiEntitaBuilder.orderBy(entita.nome.asc())
      })
      builder.expanding('stato', (statoBuilder, stato) => {
        statoBuilder.select('id', 'nome')
      })
      builder.expanding('creatore', (creatoreBuilder, impiegato) => {
        creatoreBuilder.select('id', 'fullname')
      })
      builder.expanding('offerte', (offerteBuilder, offerta) => {
        offerteBuilder.select('id', 'ded_Dis')
      })
    })
    analysis = getAnalysis.data satisfies analisi

    if (clientId && analysis?.sede?.azienda && analysis?.sede?.azienda.id !== Number(clientId)) {
      throw new Error(
        `L'analisi ${analysis.ded_Dis} appartiene al cliente ${analysis?.sede?.azienda.nome} e non al cliente corrente`,
      )
    }

    getPersone = qsAdminApi.contatto_aziendale().query((builder, persona) => {
      builder.filter(persona.sede.props.id.eq(Number(analysis.sede?.id)))
      builder.filter(
        persona.attivo
          .eq(true)
          .or(
            analysis.riferimenti
              ? persona.analisi_assegnate.any((analisi_assegnata: Qanalisi) => analisi_assegnata.id.eq(analysis.id))
              : null,
          ),
      )
      builder.select('id', 'fullname')
      builder.orderBy(persona.fullname.asc())
    })
    getEntita = qsAdminApi.entita_aziendale().query((builder, entita_aziendale) => {
      builder.filter(entita_aziendale.sede.props.id.eq(Number(analysis.sede?.id)))
      builder.select('id', 'nome')
      builder.orderBy(entita_aziendale.nome.asc())
    })
  } else {
    //creator
    const userInfo = await getAzureUserInformation()
    const getAutore = await qsAdminApi.user().query((builder, impiegato) => {
      builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
      builder.select('id', 'fullname')
    })
    const getStatoAperta = await qsAdminApi.stato_analisi(StatoAnalisi.APERTA).query((builder, stato) => {
      builder.select('id', 'nome')
    })
    analysis = {
      id: 0,
      ded_Dis: '',
      ded_RootFam: '',
      ded_SubFam: '',
      ded_Rev: '',
      ded_Id: 0,
      revisione: 0,
      oggetto: null,
      creazione: new Date().toISOString(),
      note: null,
      anno_rif: new Date().getFullYear(),
      creatore: getAutore.data.value[0],
      attachmentsFolderUrl: '',
      stato: getStatoAperta.data,
      mainDocumentUrl: '',
      sede: null,
    }
  }

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.filter(azienda.sedi.any())
    builder.filter(clientId ? azienda.id.eq(Number(clientId)) : null)
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
  })

  const getStati = qsAdminApi.stato_analisi().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  return defer({
    analysis,
    getAziende,
    getStati,
    getPersone,
    getEntita,
    getUserInfo: getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor, Roles.TechDeveloper],
  })
}) satisfies LoaderFunction

export const analysisEditorRouteAction = (async ({ request, params }: RouteFunctionParams) => {
  const analysis = (await request.json()) as analisi
  console.log('analisi', analysis)
  const qsAdminApi = useQsAdminApiManager.getState().service

  switch (request.method) {
    case 'POST': {
      const res = await qsAdminApi.analisi().create(analysis)
      console.log(res)
      return redirect(`../${res.data.id}`)
    }
    case 'PUT':
    case 'PATCH': {
      return await qsAdminApi.analisi(analysis.id).update(analysis)
    }
    default: {
      throw new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

const AnalysisEditorRoute = {
  path: ':analysisId',
  element: <AnalysisEditor creating={false} />,
  loader: analysisEditorRouteLoader,
  action: analysisEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default AnalysisEditorRoute
