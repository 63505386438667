import DashboardPageRoute from '@/routes/home/DashboardPage.route'
import { ClientsPageRoute } from '@/routes/clients/ClientsPage.route'
import ContactsPageRoute from '@/routes/contacts/ContactsPage.route'
import { SoftwarePageRoute } from '@/routes/software/SoftwarePage.route'
import { SupportPageRoute } from '@/routes/support/SupportPage.route'
import { SupportQuotePageRoute } from '@/routes/support/SupportQuotePage.route'
import { MeetingsPageRoute } from '@/routes/meetings/MeetingsPage.route'
import { RouteObject } from 'react-router-dom'
import { TestRoute } from '@/components/TestComponent.route'
import { ActivitiesPageRoute } from '@/routes/activities/ActivitiesPage.route'
import { AnalysisPageRoute } from '@/routes/analysis/AnalysisPage.route'
import { OffersPageRoute } from '@/routes/offers/OffersPage.route'
import { ProjectsPageRoute } from '@/routes/projects/ProjectsPage.route'
import { OrdersPageRoute } from '@/routes/orders/OrdersPage.route'
import { QuotesPageRoute } from '@/routes/quotes/QuotesPage.route'
import { InterventionsPageRoute } from '@/routes/interventions/InterventionsPage.route'
import { PackagesPageRoute } from '@/routes/packages/PackagesPage.route'
import { TasksPageRoute } from '@/routes/tasks/TasksPage.route'
import { CostCentersPageRoute } from '@/routes/costcenters/CostCentersPage.route'
import { HoursPageRoute } from '@/routes/hours/HoursPage.route'
import { TicketsPageRoute } from '@/routes/tickets/TicketsPage.route'
import { RoleRoute } from '@/types'
import ProfileRoute from '@/routes/profile/Profile.route'
import { AuthGuard } from '@/routes/authGuard'
import { AuthenticatedLayout } from '@/routes/authenticated/AuthenticatedLayout'

export const allAuthenticatedChildrenRoutes = [
  DashboardPageRoute,
  ClientsPageRoute,
  ContactsPageRoute,
  SoftwarePageRoute,
  SupportPageRoute,
  SupportQuotePageRoute,
  ProjectsPageRoute,
  ActivitiesPageRoute,
  AnalysisPageRoute,
  OffersPageRoute,
  QuotesPageRoute,
  OrdersPageRoute,
  TasksPageRoute,
  InterventionsPageRoute,
  PackagesPageRoute,
  MeetingsPageRoute,
  TicketsPageRoute,
  HoursPageRoute,
  CostCentersPageRoute,
  TestRoute,
  ProfileRoute,
] satisfies RoleRoute[]

export const AuthenticatedLayoutRoute = {
  id: 'authenticated',
  element: (
    <AuthGuard>
      <AuthenticatedLayout />
    </AuthGuard>
  ),
  children: allAuthenticatedChildrenRoutes, // Only use the routes that the user is allowed to access
} satisfies RouteObject
