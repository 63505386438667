import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Roles } from '@/auth/azure/Roles'
import ClientSiteSupportHandler from '@/routes/clients/client/sites/ClientSiteSupportHandler'
import { RouteFunctionParams, RoleRoute } from '@/types'
import { LoaderFunction, defer } from 'react-router-typesafe'

export const clientSiteSupportHandlerRouteLoader = (async ({ params }: RouteFunctionParams<'clientId'>) => {
  const clientId = Number(params.clientId)
  const qsAdminApi = useQsAdminApiManager.getState().service
  const getAzienda = await qsAdminApi.azienda(clientId).query((builder) => {
    builder.expanding('sede_principale', (sedePrincipaleBuilder) => {
      sedePrincipaleBuilder.expand('assistenze')
    })
  })
  console.log(getAzienda.data)
  return defer({ getAzienda })
}) satisfies LoaderFunction

export const ClientSiteSupportHandlerRoute = {
  path: 'support',
  element: <ClientSiteSupportHandler />,
  loader: clientSiteSupportHandlerRouteLoader,
  visible: false,
  allowedRoles: [Roles.Guest],
} satisfies RoleRoute
