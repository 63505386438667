/* eslint-disable unicorn/no-negated-condition */
import React, { useEffect, useRef, useState } from 'react'
import DataGrid, { Column, DataGridRef, Export, Item, StateStoring, Toolbar } from 'devextreme-react/data-grid'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ordine, task } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useScreenSize } from '@/themes/media-query'
import { ClickEvent } from 'devextreme/ui/button'
import CustomStore from 'devextreme/data/custom_store'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import saveAs from 'file-saver'
import dxDataGrid, { ColumnButtonClickEvent, ExportingEvent } from 'devextreme/ui/data_grid'
import { Button as GridButton } from 'devextreme-react/cjs/data-grid'

interface TaskAppointment {
  id: string
  task: string
  order: string
  user: string
  Location: string
  Subject: string
  StartDate: string
  EndDate: string
  Duration: number
  ShowAs: number
}

export interface TaskPlannerProps {
  task?: task
  order?: ordine
  readOnly?: boolean
}

enum eventState {
  Sconosciuto = 0,
  Disponibile = 1,
  Provvisorio = 2,
  Occupato = 3,
  Oof = 4,
  Altro = 5,
}

export const TaskPlanner = (props: TaskPlannerProps) => {
  const currentScreenSize = useScreenSize()
  const gridRef = useRef<DataGridRef>(null)
  const client = useQsAdminApiManager.getState().client

  const store = new CustomStore({
    key: 'id',
    async load(loadOptions) {
      try {
        let planningUrl: string
        if (props.task !== undefined) {
          planningUrl = `/api/task/planning?taskId=${props.task.id}`
        } else if (props.order !== undefined) {
          planningUrl = `/api/task/planning?orderId=${props.order.id}`
        } else {
          throw new Error(`Dati necessari del task o dell'ordine mancanti`)
        }

        const response = await client.get(planningUrl, {
          headers: {
            'Content-Type': 'application/json',
          },
        })

        const result: TaskAppointment[] = response.data

        return {
          data: result,
          totalCount: result.length,
        }
      } catch (error) {
        throw new Error(`Data Loading Error : ${error}`)
      }
    },
  })

  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }

  const ExportDataGridToExcel = (component: dxDataGrid) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('ClientsGrid')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Task-${props.task ? props.task.ded_Dis : props.order?.ded_Dis}-Planning.xlsx`,
        )
      })
    })
  }

  return (
    <div>
      <DataGrid
        dataSource={store}
        ref={gridRef}
        keyExpr="id"
        showBorders={true}
        showColumnLines={true}
        columnAutoWidth={true}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        onExporting={onExporting}
        noDataText="Nessun evento associato al task"
      >
        <StateStoring enabled={true} type="localStorage" storageKey={'task-planner-datagrid'} savingTimeout={50} />
        <Export enabled={!props.readOnly} />
        {!props.readOnly && (
          <Toolbar>
            {!currentScreenSize.isXSmall && <Item name="groupPanel" />}
            <Item
              location="before"
              locateInMenu="never"
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Refresh',
                text: 'Refresh',
                icon: 'refresh',
                onClick: (e: ClickEvent) => {
                  gridRef.current?.instance().refresh()
                },
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              name="columnChooserButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Scelta colonne', text: 'Scelta colonne' }}
            />
            <Item
              name="exportButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Esporta dati in excel', text: 'Esporta dati in excel' }}
            />
            <Item name="searchPanel" />
          </Toolbar>
        )}
        <Column
          type="buttons"
          width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
          alignment="left"
        >
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              window.open(e.row?.data.WebLink, '_blank')
            }}
          />
        </Column>
        <Column
          dataField="task"
          caption="TASK"
          visible={props.order !== null}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 9 : undefined
          }
        />
        <Column
          dataField="order"
          caption="ORDINE"
          visible={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
          }
        />
        <Column
          dataField="User.fullName"
          caption="UTENTE"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
          }
        />
        <Column
          dataField="Subject"
          caption="OGGETTO"
          visible={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined
          }
        />
        <Column
          dataField="Location"
          caption="LUOGO"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
          }
        />
        <Column
          dataField="StartDate"
          caption="INIZIO"
          dataType="date"
          format="dd-MM-yyyy HH:mm"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
          }
        />
        <Column
          dataField="EndDate"
          caption="FINE"
          dataType="date"
          format="dd-MM-yyyy HH:mm"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
          }
        />
        <Column
          dataField="Duration"
          caption="DURATA"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
          }
        />
        <Column
          dataField="ShowAs"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
          }
          caption="STATO"
          cellRender={({ data }) => {
            const value: number = data.ShowAs ?? 0
            return eventState[value]
          }}
        />
      </DataGrid>
    </div>
  )
}
