import { ClientEditorFormProps, ValidationCallbackProps } from '@/routes/clients/client/ClientEditor.types'
import { useRef, useState, useEffect } from 'react'
import 'devextreme-react/lookup'
import 'devextreme-react/text-area'
import {
  AsyncRule,
  ButtonItem,
  ButtonOptions,
  EmailRule,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
  Tab,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form'
import {
  Animation,
  ArgumentAxis,
  Chart,
  ChartRef,
  CommonSeriesSettings,
  Legend,
  SeriesTemplate,
  Tick,
  Title,
} from 'devextreme-react/chart'
import type { FieldDataChangedEvent } from 'devextreme/ui/form'
import { useRevalidator } from 'react-router-dom'
import { ArraySourceComposer } from '@/auth/api/connector'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { azienda, Editableazienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import AutodeskCsnGrid from '@/routes/clients/client/ClientEditor.csns'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { toolbarHtmlEditor } from '@/routes/utils'
import { useScreenSize } from '@/themes/media-query'

const ClientEditorForm = (props: ClientEditorFormProps) => {
  const {
    creating,
    agentHistoryGraph,
    gruppiAziendali,
    rivenditori,
    tipiRapporto,
    agenti,
    tipiPagamento,
    client,
    clientId,
    contattiAziendali,
    isRoleAllowed,
    fetcher,
  } = props

  const currentScreenSize = useScreenSize()
  const [saveButtonText] = useState<string>('Salva')
  const [saveButtonIcon] = useState<string>('save')
  const [formData, setFormData] = useState<ODataModelResponseV4<azienda> | Editableazienda>(client)
  const revalidator = useRevalidator()

  const chartHaRef = useRef<ChartRef>(null)
  const formRef = useRef<FormRef>(null)
  const colGroupCountByScreen = {
    xs: 1,
    sm: 2,
    md: 2,
    lg: 2,
  }

  useEffect(() => {
    const data = client
    if (data) {
      setFormData(data)
    }
  }, [client])

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle')} />
      <DXForm
        id={'client_form'}
        formData={formData}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        validationGroup="clientDataValidation"
        ref={formRef}
        readOnly={!isRoleAllowed}
      >
        <GroupItem colCountByScreen={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
          <GroupItem caption="Anagrafica" colCount={1}>
            <SimpleItem dataField="nome">
              <RequiredRule message="Il nome del cliente è obbligatorio" />
            </SimpleItem>
            <GroupItem colCountByScreen={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
              <SimpleItem
                dataField="gruppo_aziendale"
                label={{ text: 'Gruppo Aziendale' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', gruppiAziendali),
                  placeholder: 'Seleziona il gruppo aziendale...',
                  displayExpr: 'nome',
                  searchEnabled: true,
                  dropDownCentered: true,
                  showClearButton: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare il gruppo aziendale',
                    hideOnOutsideClick: true,
                  },
                }}
              />
              <SimpleItem
                dataField="rapp_inizio"
                editorOptions={{
                  displayFormat: 'dd/MM/yyyy',
                  pickerType: 'rollers',
                }}
                editorType="dxDateBox"
                label={{ text: 'Data Acquisizione' }}
              >
                <RequiredRule message="La data di acquisizione del cliente è obbligatoria" />
              </SimpleItem>
              <SimpleItem
                dataField="agente"
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', agenti),
                  placeholder: "Seleziona l'agente...",
                  displayExpr: 'commerciale_qs.fullname',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: "Selezionare l'agente",
                    hideOnOutsideClick: true,
                  },
                }}
              >
                <RequiredRule message="La definizione dell'agente è obbligatoria" />
              </SimpleItem>
              <SimpleItem
                dataField="rivenditore"
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', rivenditori),
                  placeholder: 'Seleziona il rivenditore...',
                  displayExpr: 'nome',
                  searchEnabled: true,
                  dropDownCentered: true,
                  showClearButton: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare il rivenditore',
                    hideOnOutsideClick: true,
                  },
                }}
              />
              <SimpleItem dataField="p_iva" label={{ text: 'Partita IVA' }}>
                <AsyncRule
                  reevaluate={true}
                  ignoreEmptyValue={true}
                  validationCallback={async (options: ValidationCallbackProps): Promise<boolean> => {
                    const clients = await useQsAdminApiManager
                      .getState()
                      .service.azienda()
                      .query((builder, client) => {
                        builder
                          .select('id', 'p_iva')
                          .filter(client.p_iva.eq(options.value as string))
                          .filter(clientId ? client.id.ne(clientId) : null)
                        builder.count()
                      })
                    return options.value === undefined || options.value === '' || clients.data['@odata.count'] === 0
                  }}
                  message="Partita IVA già presente"
                />
              </SimpleItem>
              <SimpleItem
                dataField="tipologia_rapporto"
                label={{ text: 'Tipologia' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', tipiRapporto),
                  displayExpr: 'nome',
                  placeholder: 'Seleziona il rapporto...',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare il tipo di rapporto',
                    hideOnOutsideClick: true,
                  },
                }}
              >
                <RequiredRule message="La tipologia di azienda è obbligatoria" />
              </SimpleItem>
              <SimpleItem dataField="leg_origine_rapp" label={{ text: 'Segnalato Da' }} />
              <SimpleItem
                dataField="tipologia_pagamento"
                label={{ text: 'Tipologia Pagamento' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', tipiPagamento),
                  placeholder: 'Seleziona il tipo di pagamento...',
                  displayExpr: 'nome',
                  searchEnabled: true,
                  dropDownCentered: true,
                  showClearButton: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare il tipo di rapporto',
                    hideOnOutsideClick: true,
                  },
                }}
              />
              <SimpleItem dataField="email" label={{ text: 'Email' }}>
                <EmailRule message="L'indirizzo email non è valido" ignoreEmptyValue={true} />
              </SimpleItem>
              <SimpleItem dataField="email_pec" label={{ text: 'PEC' }}>
                <EmailRule message="L'indirizzo email non è valido" ignoreEmptyValue={true} />
              </SimpleItem>
            </GroupItem>
          </GroupItem>
          <GroupItem caption="Informazioni" colCount={1}>
            <TabbedItem>
              <TabPanelOptions
                animationEnabled={true}
                swipeEnabled={true}
                deferRendering={false}
                scrollingEnabled={true}
              />
              <Tab title="Ammnistrative">
                <SimpleItem
                  dataField={'note'}
                  editorType={'dxHtmlEditor'}
                  label={{ visible: false }}
                  editorOptions={{
                    stylingMode: 'filled',
                    valueType: 'html',
                    toolbar: toolbarHtmlEditor(currentScreenSize),
                    mediaResizing: {
                      enabled: true,
                    },
                    imageUpload: {
                      tabs: ['file', 'url'],
                      fileUploadMode: 'base64',
                    },
                    height: '370',
                  }}
                ></SimpleItem>
              </Tab>
              <Tab title="Fatturazione">
                <SimpleItem
                  dataField={'note_fatturazione'}
                  editorType={'dxHtmlEditor'}
                  label={{ visible: false }}
                  editorOptions={{
                    stylingMode: 'filled',
                    valueType: 'html',
                    toolbar: toolbarHtmlEditor(currentScreenSize),
                    mediaResizing: {
                      enabled: true,
                    },
                    imageUpload: {
                      tabs: ['file', 'url'],
                      fileUploadMode: 'base64',
                    },
                    height: '370',
                  }}
                ></SimpleItem>
              </Tab>
              {!creating && (
                <Tab title="Storico Agenti">
                  <Chart
                    id="chart-agenti"
                    dataSource={agentHistoryGraph}
                    barGroupPadding={0.2}
                    rotated={true}
                    ref={chartHaRef}
                  >
                    <ArgumentAxis>
                      <Tick visible={false} />
                    </ArgumentAxis>
                    <Title text="Storico Agenti" subtitle="Evoluzione temporale del commerciale" />
                    <CommonSeriesSettings
                      type="rangebar"
                      argumentField="nome_agente"
                      rangeValue1Field="start"
                      rangeValue2Field="end"
                      barOverlapGroup="nome_agente"
                      minBarSize={4}
                    ></CommonSeriesSettings>
                    <Legend verticalAlignment="bottom" horizontalAlignment="center">
                      <Title text="Agenti" />
                    </Legend>
                    <SeriesTemplate nameField="nome_agente" />
                    <Animation enabled={false} />
                  </Chart>
                </Tab>
              )}
              {!creating && (
                <Tab title="Autodesk">
                  <GroupItem colCount={1}>
                    <AutodeskCsnGrid
                      azienda={client}
                      readOnly={creating || !isRoleAllowed}
                      contatti={contattiAziendali}
                    />
                  </GroupItem>
                </Tab>
              )}
              <Tab title="Impostazioni">
                <GroupItem colCount={2}>
                  <SimpleItem dataField="is_rivenditore" label={{ text: 'Rivenditore' }} editorType="dxSwitch" />
                  <SimpleItem dataField="blacklist" label={{ text: 'Black List' }} editorType="dxSwitch" />
                  <SimpleItem dataField="contattabile" label={{ text: 'Contattabile' }} editorType="dxSwitch" />
                </GroupItem>
              </Tab>
            </TabbedItem>
          </GroupItem>
        </GroupItem>
        <GroupItem visible={isRoleAllowed} cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCountByScreen={colGroupCountByScreen}>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!formRef.current?.instance().option().isDirty}
                width="150px"
                text={saveButtonText}
                icon={saveButtonIcon}
                useSubmitBehavior={false}
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  if (formData) {
                    fetcher?.submit(JSON.stringify(formData), {
                      method: creating ? 'POST' : 'PATCH',
                      encType: 'application/json',
                    })
                  } else {
                    console.log('no data to post')
                    console.log(formData)
                  }
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  disabled={!formRef.current?.instance().option().isDirty}
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
    </>
  )
}

export default ClientEditorForm
