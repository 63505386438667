import { Form, SimpleItem } from 'devextreme-react/form'
import { TabPanel, Item } from 'devextreme-react/tab-panel'
import type { azienda as IClient, sede as ISede } from '@/model/qsadminapi/QsAdminApiModuleModel'

export function ClientBriefDetail({ data }: { data: any }) {
  console.log('rtecord!', data)

  return (
    <div className={'dx-card responsive-paddings'}>
      <TabPanel
        noDataText="Non sono ancora state create sedi aziendali"
        deferRendering={true}
        swipeEnabled={true}
        animationEnabled={true}
      >
        {data?.data.sedi.map((sede: ISede, index: number) => (
          <Item key={index} title={sede.nome ?? ''}>
            <Form
              id={'azienda_detail_form'}
              formData={sede}
              labelLocation={'top'}
              colCountByScreen={{ xs: 2, sm: 2, md: 4, lg: 4 }}
            >
              <SimpleItem dataField="telefono" editorOptions={{ readOnly: true }} />
              <SimpleItem dataField="fax" editorOptions={{ readOnly: true }} />
              <SimpleItem dataField="email" editorOptions={{ readOnly: true }} />
              <SimpleItem dataField="email_pec" editorOptions={{ readOnly: true }} label={{ text: 'PEC' }} />
            </Form>
          </Item>
        ))}
      </TabPanel>
    </div>
  )
}
