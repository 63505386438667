import { Suspense } from 'react'
import SoftwareQsEditorForm from './SoftwareQsEditor.form'
import { FormMode } from '@/enums'
import { Await, useLoaderData } from 'react-router-typesafe'
import { softwareQsRouteLoader } from '@/routes/software/qs/SoftwareQsEditor.route'
import { useFetcher, useSubmit } from 'react-router-dom'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { SoftwareQsEditorProps } from '@/routes/software/qs/SoftwareQsEditor.types'
import { isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'

export function SoftwareQsEditor(props: SoftwareQsEditorProps) {
  const {
    getSwLicense,
    getAziende,
    getStatiLicenza,
    getVersioni,
    getTipiLicenza,
    getApplicazioni,
    getUserInfo,
    defaultCRUDAllowedRoles,
  } = useLoaderData<typeof softwareQsRouteLoader>()
  const fetcher = useFetcher()
  return (
    <>
      <Suspense fallback={<LoadingPanel />}>
        <Await
          resolve={Promise.all([
            getSwLicense,
            getAziende,
            getStatiLicenza,
            getVersioni,
            getTipiLicenza,
            getApplicazioni,
            getUserInfo,
          ])}
        >
          {([license, aziende, stati, versioni, tipi, applicazioni, userInfo]) => (
            <>
              <h2>{`${props.mode === FormMode.Create ? 'Nuova Licenza' : 'data' in license && license.data.chiave ? license.data.chiave : 'chiave' in license ? license.chiave : 'Chiave non definita'}`}</h2>
              <div id="edit-qs-license-container" className={`dx-card responsive-paddings`}>
                <fetcher.Form>
                  <SoftwareQsEditorForm
                    mode={props.mode}
                    license={'data' in license ? license.data : license}
                    aziendaList={aziende.data.value}
                    qsStatoLicenzaList={stati.data.value}
                    qsVersioneList={versioni.data.value}
                    qsLicenseTypes={tipi.data.value}
                    qsApplicazioni={applicazioni.data.value}
                    userInfo={userInfo}
                    isRoleAllowed={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                    fetcher={fetcher}
                  />
                </fetcher.Form>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}
