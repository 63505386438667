import { RoleRouteObject, RouteFunctionParams, RoleRoute } from '@/types'
import { createRoutesFromElements, LoaderFunction, Navigate, Route, RouteObject, Routes, useRoutes } from 'react-router'
import ContactsPageRoute from '@/routes/contacts/ContactsPage.route'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import ClientMasterView from '@/routes/clients/client/ClientMasterView'
import { ClientEditorRoute } from '@/routes/clients/client/ClientEditor.route'
import { ClientSitesEditorRoute } from '@/routes/clients/client/sites/ClientSitesEditor.route'
import { ClientSiteSupportHandlerRoute } from '@/routes/clients/client/sites/ClientSiteSupportHandler.route'
import { SoftwarePageRoute } from '@/routes/software/SoftwarePage.route'
import { SupportEditorRoute } from '@/routes/support/SupportEditor.route'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ActivitiesPageRoute } from '@/routes/activities/ActivitiesPage.route'
import { AnalysisPageRoute } from '@/routes/analysis/AnalysisPage.route'
import { OffersPageRoute } from '@/routes/offers/OffersPage.route'
import { ProjectsPageRoute } from '@/routes/projects/ProjectsPage.route'
import { OrdersPageRoute } from '@/routes/orders/OrdersPage.route'
import { QuotesPageRoute } from '@/routes/quotes/QuotesPage.route'
import { InterventionsPageRoute } from '@/routes/interventions/InterventionsPage.route'
import { PackagesPageRoute } from '@/routes/packages/PackagesPage.route'
import { TasksPageRoute } from '@/routes/tasks/TasksPage.route'
import { TicketsPageRoute } from '@/routes/tickets/TicketsPage.route'
import { Roles } from '@/auth/azure/Roles'
import { redirect } from 'react-router-dom'

export const clientTabs: RoleRoute[] = [
  {
    index: true,
    loader: () => redirect('details'),
    visible: false,
    allowedRoles: [Roles.Guest],
  } satisfies RoleRoute,
  ClientEditorRoute,
  ClientSitesEditorRoute,
  ClientSiteSupportHandlerRoute,
  ContactsPageRoute,
  SoftwarePageRoute,
  SupportEditorRoute,
  ProjectsPageRoute,
  ActivitiesPageRoute,
  AnalysisPageRoute,
  OffersPageRoute,
  QuotesPageRoute,
  OrdersPageRoute,
  InterventionsPageRoute,
  TasksPageRoute,
  PackagesPageRoute,
  TicketsPageRoute,
]

export const clientMasterViewRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const currentClientId = +(params.clientId as string)
  const clientData = await useQsAdminApiManager
    .getState()
    .service.azienda(currentClientId)
    .query((builder, client) => {
      builder.select('nome')
    })
  return clientData.data
}) satisfies LoaderFunction

export const ClientMasterViewRoute = {
  path: ':clientId',
  id: 'clientid',
  errorElement: <RouterErrorHandler />,
  element: <ClientMasterView />,
  loader: clientMasterViewRouteLoader,
  children: clientTabs,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
