import { useLocation } from 'react-router'

const AccessDeniedHandler = () => {
  const location = useLocation()
  return (
    <>
      <p>
        <h1
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          Accesso Negato :(
        </h1>
        <h2
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          Consultare un amministratore per ricevere informazioni
        </h2>
        {location.key !== 'default' && (
          <h3
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <span>
              Clicca <a href={'/'}>qui</a> per tornare a dashboard
            </span>
          </h3>
        )}
      </p>
    </>
  )
}

export default AccessDeniedHandler
