import { RouteObject, redirect } from 'react-router-dom'
import { defer, LoaderFunction, ActionFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { entita_aziendale } from '@/model/qsadminapi/QsAdminApiModuleModel'
import EntityCreator from '@/routes/contacts/entity/EntityCreator'
import { Roles } from '@/auth/azure/Roles'
import { getAzureUserInformation } from '@/auth/azure/azureManager'

export const entityCreatorRouteLoader = (async ({ params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  const clientId = params.clientId
  const contact: entita_aziendale = {
    id: 0,
    note: null,
    nome: null,
    telefono: null,
    emails: [
      {
        id: 0,
        email: null,
        note: null,
        rif_ad: false,
      },
    ],
    ad_recipient: false,
  }
  const getAziende = qsAdminApi.azienda().query((builder, qazienda) => {
    builder.orderBy(qazienda.nome.asc())
    builder.select('id', 'nome')
    builder.filter(qazienda.sedi.any())
    builder.filter(clientId ? qazienda.id.eq(Number(clientId)) : null)
  })

  return defer({
    contact,
    getAziende,
    getUserInfo: getAzureUserInformation(),
  })
}) satisfies LoaderFunction

const entityCreatorRouteAction = (async ({ request }: RouteFunctionParams) => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  const contact = (await request.json()) as entita_aziendale
  const res = await qsAdminApi.entita_aziendale().create(contact)
  return redirect(`..`)
}) satisfies ActionFunction

const EntityCreatorRoute = {
  path: 'new',
  element: <EntityCreator />,
  loader: entityCreatorRouteLoader,
  action: entityCreatorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default EntityCreatorRoute
