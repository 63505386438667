import { Roles } from '@/auth/azure/Roles'
import ProjectEditor from '@/routes/projects/ProjectEditor'
import { projectEditorRouteAction, projectEditorRouteLoader } from '@/routes/projects/ProjectEditor.route'
import { RoleRouteObject } from '@/types'
import { RouteObject } from 'react-router-dom'

const ProjectCreatorRoute = {
  path: 'new',
  element: <ProjectEditor creating={true} />,
  loader: projectEditorRouteLoader,
  action: projectEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default ProjectCreatorRoute
