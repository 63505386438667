import {
  argomento_problema,
  azienda,
  nota_ticket,
  piattaforma_ticket,
  qs_supporto_applicazione,
  stato_ticket,
  ticket,
  tipologia_problema,
  tipologia_ticket,
  user,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import { FormProps } from '@/types'
import { ClickEvent } from 'devextreme/ui/button'

export type TicketEditorProps = {
  creating: boolean
}

export interface TicketEditorFormProps extends FormProps {
  states: stato_ticket[]
  clients: azienda[]
  applications: qs_supporto_applicazione[]
  categories: tipologia_ticket[]
  platforms: piattaforma_ticket[]
  problemCategories: tipologia_problema[]
  employees: user[]
  problemArguments: argomento_problema[] | undefined
  ticket: ticket | undefined | null
}

export type TicketEditorEventsProps = {
  ticket: ticket | undefined | null
}

export type TicketEditorCompleteFormProps = {
  complete:
    | {
        ticket: ticket | null | undefined
        closure: string
        solution: string
      }
    | undefined
  action: TicketActions
  onSaveClick: () => void
}

export enum TicketActions {
  None = 0,
  Complete = 1,
  SendTicketNote = 2,
  CreateTicketNote = 3,
  ShowTicketNote = 4,
  EditTicketNote = 5,
}

export const TicketActionDescriptions: { [key in TicketActions]: string } = {
  [TicketActions.None]: 'Nessuna',
  [TicketActions.Complete]: 'Completa',
  [TicketActions.SendTicketNote]: 'Invia nota Ticket',
  [TicketActions.CreateTicketNote]: 'Nota interna Ticket',
  [TicketActions.ShowTicketNote]: 'Visualizza nota Ticket',
  [TicketActions.EditTicketNote]: 'Edita nota Ticket',
}

export type TicketEditorNoteFormProps = {
  ticket: ticket | undefined | null
  note: nota_ticket | undefined | null
  action: TicketActions
  readOnly: boolean
  onSaveClick: () => void
}

export type ScrollViewNoteProps = {
  note: nota_ticket
  readOnly: boolean
  visibleEditClick?: boolean
  onEditClick?: (e: ClickEvent) => void
}
