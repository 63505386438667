import {
  Column,
  ColumnChooser,
  ColumnChooserSearch,
  DataGrid,
  Editing,
  Export,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Item,
  MasterDetail,
  Scrolling,
  SearchPanel,
  Sorting,
  StateStoring,
  Toolbar,
  DataGridTypes,
  DataGridRef,
  Search,
  Pager,
  Button as GridButton,
} from 'devextreme-react/data-grid'
import {
  CellHoverChangedEvent,
  CellPreparedEvent,
  ColumnButtonClickEvent,
  dxDataGridColumn,
  ExportingEvent,
  RowDblClickEvent,
} from 'devextreme/ui/data_grid'
import ODataStore from 'devextreme/data/odata/store'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type {
  qs_licenza,
  qs_licenza_applicazione,
  tipologia_qs_licenza,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useScreenSize } from '@/themes/media-query'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { SoftwareQsEditorForm } from './SoftwareQsEditor.form'
import { EdmLiteral } from 'devextreme/data/odata/utils'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { Popup } from 'devextreme-react/popup'
import { SoftwareQsCodeComputer } from './SoftwareQsCodeComputer'
import { confirm } from 'devextreme/ui/dialog'
import { useLoaderData } from 'react-router-typesafe'
import { FormMode, GridCellColor } from '@/enums'
import { softwareQsLoader } from '@/routes/software/qs/SoftwareQsGrid.route'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { ClickEvent } from 'devextreme/ui/button'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import { FormFilterItemDescriptorType } from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory, isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import { gridTotalItemsLabel } from '@/constants'
import Popover, { PopoverRef } from 'devextreme-react/cjs/popover'

const computeApplicationsValue = (rowData: qs_licenza) => {
  let apps = ''
  rowData.info_applicazioni?.map(
    (app: qs_licenza_applicazione) => (apps = `${apps + app.applicazione?.nome?.toUpperCase()} `),
  )
  return apps.trim()
}

const calculateAppsFilterExpression = (
  filterValue: any,
  selectedFilterOperation: string | null,
  target: string,
): string | (() => any) | Array<any> => {
  console.log('FLT', filterValue, selectedFilterOperation, target)

  if (target === 'filterBuilder' || target === 'filterRow') {
    if (selectedFilterOperation === '=' || selectedFilterOperation === '<>') {
      return [
        [
          new EdmLiteral(`info_applicazioni/any(a: a/applicazione/nome eq '${filterValue}')`),
          '=',
          selectedFilterOperation === '=',
        ],
      ]
    } else if (
      selectedFilterOperation === 'contains' ||
      selectedFilterOperation === 'startswith' ||
      selectedFilterOperation === 'endswith'
    ) {
      return [
        [
          new EdmLiteral(`info_applicazioni/any(a: ${selectedFilterOperation}(a/applicazione/nome, '${filterValue}'))`),
          '=',
          true,
        ],
      ]
    } else {
      // caso notcontains
      return [[new EdmLiteral(`info_applicazioni/any(a: contains(a/applicazione/nome, '${filterValue}'))`), '=', false]]
    }
  } else if (target === 'search' || target === 'headerFilter') {
    return [[new EdmLiteral(`info_applicazioni/any(a: contains(a/applicazione/nome, '${filterValue}'))`), '=', true]]
  }

  return filterValue
}

const cellPrepared = (e: CellPreparedEvent) => {
  if (e.rowType === 'data' && e.value) {
    switch (e.column.dataField) {
      case 'stato_licenza.nome': {
        if (e.value === 'ATTIVA') {
          e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.EMERALD}`
        } else if (e.value === 'SCADUTA') {
          e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.SALMON}`
        } else {
          e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.ORANGE}`
        }
        break
      }
      case 'aggiornamenti': {
        console.log('aggiornamneti', e.value)
        if (e.value === 'COMPLETO') {
          e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.EMERALD}`
        } else if (e.value === 'PARZIALE') {
          e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.YELLOW}`
        } else {
          e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.SALMON}`
        }

        break
      }
      default: {
        break
      }
    }
  }
}

const ExportDataGridToExcel = (component: any) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet('ClientsGrid')
  exportDataGrid({ component, worksheet }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'QsLicenses.xlsx')
    })
  })
}

const onExporting = (e: ExportingEvent) => {
  ExportDataGridToExcel(e.component)
}

const SoftwareQsGrid = () => {
  const currentScreenSize = useScreenSize()

  const { clientId } = useParams()
  const token = useTokenRefresh()
  const {
    qsVersioneList,
    aziendaList,
    qsStatoLicenzaList,
    qsTipiLicenzaList,
    qsApplicazioniList,
    userInfo,
    defaultCRUDAllowedRoles,
  } = useLoaderData<typeof softwareQsLoader>()

  const navigate = useNavigate()
  const swQsGridRef = useRef<DataGridRef>(null)
  const gridPopoverRef = useRef<PopoverRef>(null)

  const [popupVisible, setPopupVisible] = useState(false)
  const [codeComputingQsLicenseData, setCodeComputingQsLicenseData] = useState<any>()
  const { service } = useQsAdminApiManager()

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziendaList.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['sede/azienda/id', this.currentValue]]
        },
      },
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziendaList])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['sede/azienda/id', Number(clientId)]] : [])

  const swQsDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/qs_licenza`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.log(e.errorDetails)
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: [
      'sede($expand=azienda($expand=sedi,agente($expand=commerciale_qs),rivenditore))',
      'info_applicazioni($expand=applicazione,tipologia_licenza,ultima_versione)',
      'versione_uso',
      'stato_licenza',
      'storico($expand=versione,sede($expand=azienda))',
    ],
    filter: currentFilter.length > 0 ? currentFilter : null,
  }

  const getGridHeight = useCallback(() => {
    let h = '72vh'
    if (currentScreenSize.isMedium) {
      h = '68vh'
    } else if (currentScreenSize.isSmall) {
      h = '120vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    return h
  }, [currentScreenSize])

  function statusCellRender(data: any) {
    if (data.value === 'COMPLETO') {
      return <div className="icon dx-icon-check" />
    } else if (data.value === 'PARZIALE') {
      return <div className="icon dx-icon-warning" />
    }
    return <div className="icon dx-icon-remove" />
  }

  const hideImagePopup = useCallback(() => {
    setPopupVisible(false)
  }, [setPopupVisible])

  const deleteLicenza = async (licenseId: number) => {
    return await service.qs_licenza(licenseId).delete()
  }

  const getColumnTooltip = (col: dxDataGridColumn): string => {
    switch (col.dataField) {
      case 'rete': {
        return 'LICENZA DI RETE'
      }
      case 'versione_uso.major': {
        return 'VERSIONE IN USO'
      }
      case 'attenzione_speciale': {
        return 'WARNING KEY'
      }
      case 'aggiornamenti': {
        return 'UPDATE STATUS'
      }
      // No default
    }
    return col.caption ?? ''
  }
  const onCellHoverChanged = (e: CellHoverChangedEvent) => {
    if (e.rowType === 'header' && e.eventType === 'mouseover') {
      gridPopoverRef.current?.instance().option('contentTemplate', function (contentElement: any) {
        const a: dxDataGridColumn = e.column
        return `<div><b>${getColumnTooltip(e.column)}</b></div>`
      })
      gridPopoverRef.current?.instance().option('target', e.cellElement)
      gridPopoverRef.current?.instance().show()
    }
    if (e.eventType === 'mouseout') {
      gridPopoverRef.current?.instance().hide()
    }
  }

  useEffect(() => {
    getCurrentFilter()
  }, [])

  return (
    <>
      {!clientId && (
        <div className="accordion-generic-filter-form">
          <Accordion
            className={filtersApplied ? 'with-filter' : ''}
            collapsible={true}
            onContentReady={(e: ContentReadyEvent<any, any>) => {
              e.component?.collapseItem(0)
            }}
          >
            <AccordionItem icon="filter" title={'Filtri'}>
              <GenericFilterForm
                FormItemDescriptors={filterConfiguration}
                resetFilterClicked={function (e: ClickEvent): void {
                  setFilterConfiguration(getFiltersConfiguration())
                }}
                filterValueChanged={function (): void {
                  const currentFlts = getCurrentFilter()
                  console.log('GENFLT - VALUE CHNG', currentFlts)
                  if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                    console.log('GENFLT - SETTING FILT', currentFlts)
                    setCurrentFilter(currentFlts)
                  }
                }}
              />
            </AccordionItem>
          </Accordion>
        </div>
      )}
      <DataGrid
        id={clientId ? 'client-sw-qs-grid' : 'sw-qs-grid'}
        height={getGridHeight}
        noDataText="Nessuna licenza trovata"
        className={'grid dx-card wide-card'}
        dataSource={swQsDataSource}
        showBorders={false}
        showColumnLines={true}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        allowColumnResizing={currentScreenSize.isLarge || currentScreenSize.isMedium}
        allowColumnReordering={currentScreenSize.isLarge || currentScreenSize.isMedium}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        width="100%"
        onExporting={onExporting}
        onCellHoverChanged={onCellHoverChanged}
        ref={swQsGridRef}
        remoteOperations={{
          filtering: true,
          grouping: false,
          groupPaging: false,
          paging: true,
          sorting: true,
          summary: true,
        }}
        onCellPrepared={cellPrepared}
        onEditorPreparing={(e: any) => {
          if (e.parentType === 'filterRow') {
            if (e.dataField === 'aggiornamenti') {
              e.editorName = 'dxSelectBox'
              e.editorOptions.showClearButton = true
              e.editorOptions.dataSource = ['COMPLETO', 'PARZIALE', 'NESSUNO']
              e.editorOptions.itemTemplate = (data: any) => {
                if (data === 'COMPLETO') {
                  return '<div class="icon dx-icon-check" />'
                } else if (data === 'PARZIALE') {
                  return '<div class="icon dx-icon-warning" />'
                }
                return '<div class="icon dx-icon-remove" />'
              }
            } else if (e.dataField === 'tipologia') {
              e.editorName = 'dxSelectBox'
              e.editorOptions.showClearButton = true
              const typeLicenseValues = []
              qsTipiLicenzaList.data.value.forEach((tpLic: tipologia_qs_licenza) => {
                typeLicenseValues.push(tpLic.nome ?? '')
              })
              typeLicenseValues.push('MISTO')
              e.editorOptions.dataSource = typeLicenseValues
            }
          }
        }}
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') navigate(`${e.data.id}`)
        }}
        groupPanel={{
          visible: currentScreenSize.isLarge || currentScreenSize.isMedium,
          emptyPanelText: 'Trascina qui una colonna per raggruppare',
        }}
      >
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey={clientId ? 'client-sw-qs-grid' : 'sw-qs-grid'}
          savingTimeout={50}
        />
        <Pager
          visible={true}
          allowedPageSizes={'auto'}
          displayMode={'compact'}
          showPageSizeSelector={false}
          showInfo={true}
          showNavigationButtons={false}
          infoText={gridTotalItemsLabel}
        />
        <Export enabled={true} />
        <HeaderFilter visible={true} />
        <GroupPanel visible={true} emptyPanelText="Trascina qui una colonna per raggruppare" />
        <Grouping autoExpandAll={true} />
        <FilterRow visible={true} />
        <FilterPanel visible={true} />
        <SearchPanel visible={true} width={currentScreenSize.isXSmall ? 200 : 250} />
        <Scrolling mode="virtual" />
        <Sorting mode="multiple" />
        <ColumnChooser enabled={true} title={'Scelta Colonne'}>
          <ColumnChooserSearch enabled={true} />
        </ColumnChooser>
        <Editing allowAdding={true} />
        <MasterDetail
          enabled={true}
          render={(props: DataGridTypes.MasterDetailTemplateData) => (
            <SoftwareQsEditorForm
              mode={FormMode.View}
              license={props.data}
              qsStatoLicenzaList={qsStatoLicenzaList.data.value}
              qsVersioneList={qsVersioneList.data.value}
              aziendaList={aziendaList.data.value}
              qsApplicazioni={qsApplicazioniList.data.value}
              qsLicenseTypes={qsTipiLicenzaList.data.value}
            />
          )}
        />
        <Column
          visible={currentScreenSize.isXSmall}
          type="buttons"
          width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
          alignment="left"
          showInColumnChooser={false}
        >
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              navigate(`${e.row?.data.id}`)
            }}
          ></GridButton>
        </Column>
        <Column
          dataField={'id'}
          caption="ID"
          visible={false}
          dataType="number"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
          }
        >
          <HeaderFilter dataSource={columnSourceFactory(token, 'qs_licenza', 'id', true)}></HeaderFilter>
        </Column>
        <Column
          dataField={'sede.azienda.nome'}
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: qs_licenza = cellData.row.data
            return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
              <>
                <Link to={`/clients/${data.sede?.azienda?.id}`}>{data.sede?.azienda?.nome}</Link>
              </>
            ) : (
              <>{data.sede?.azienda?.nome}</>
            )
          }}
          caption={'AZIENDA'}
          visible={clientId === undefined}
          defaultSortOrder="asc"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 18 : undefined
          }
        >
          <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome')}>
            <Search enabled={true} searchExpr={'nome'} />
          </HeaderFilter>
        </Column>
        <Column
          dataField={'sede.nome'}
          caption={'SEDE'}
          allowHeaderFiltering={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 15 : undefined
          }
        />
        <Column
          dataField={'sede.azienda.agente.commerciale_qs.fullname'}
          caption={'AGENTE'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 14 : undefined
          }
        >
          <HeaderFilter dataSource={columnSourceFactory(token, 'agente', 'commerciale_qs.fullname')}>
            <Search enabled={true} searchExpr={'commerciale_qs.fullname'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'sede.azienda.rivenditore.nome'} caption={'RIVENDITORE'} visible={false}>
          <HeaderFilter dataSource={columnSourceFactory(token, 'Azienda', 'rivenditore.nome')}>
            <Search enabled={true} searchExpr={'rivenditore.nome'} />
          </HeaderFilter>
        </Column>
        <Column
          dataField={'tipologia'}
          caption={'TIPO'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 13 : undefined
          }
        >
          <HeaderFilter dataSource={columnSourceFactory(token, 'qs_licenza', 'tipologia')}>
            <Search enabled={true} searchExpr={'tipologia'} />
          </HeaderFilter>
        </Column>
        <Column
          dataField={'stato_licenza.nome'}
          caption={'STATO CHIAVE'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 12 : undefined
          }
        >
          <HeaderFilter dataSource={columnSourceFactory(token, 'qs_licenza', 'stato_licenza.nome')}>
            <Search enabled={true} searchExpr={'stato_licenza.nome'} />
          </HeaderFilter>
        </Column>
        {DateColumn({
          dataField: 'scadenza',
          caption: 'SCADENZA',
          format: 'dd/MM/yyyy',

          hidingPriority:
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 11 : undefined,
        })}
        <Column
          dataField={'chiave'}
          caption={'CHIAVE'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 17 : undefined
          }
        >
          <HeaderFilter dataSource={columnSourceFactory(token, 'qs_licenza', 'chiave')}>
            <Search enabled={true} searchExpr={'chiave'} />
          </HeaderFilter>
        </Column>
        <Column
          dataField={'id_chiave'}
          caption={'ID'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 16 : undefined
          }
        >
          <HeaderFilter dataSource={columnSourceFactory(token, 'qs_licenza', 'id_chiave')}>
            <Search enabled={true} searchExpr={'id_chiave'} />
          </HeaderFilter>
        </Column>
        <Column
          name={'info_applicazioni'}
          caption={'APPLICAZIONI'}
          calculateCellValue={computeApplicationsValue}
          calculateFilterExpression={calculateAppsFilterExpression}
          allowFiltering={true}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
          }
        >
          <HeaderFilter dataSource={columnSourceFactory(token, 'qs_applicazione', 'nome')}>
            <Search enabled={true} searchExpr={'nome'} />
          </HeaderFilter>
        </Column>
        <Column
          dataField={'versione_uso.major'}
          caption={'VU'}
          width={'3%'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
          }
        >
          <HeaderFilter dataSource={columnSourceFactory(token, 'qs_licenza', 'versione_uso.major')}></HeaderFilter>
        </Column>
        <Column
          dataField={'rete'}
          caption={'NET'}
          dataType={'boolean'}
          width={'3%'}
          allowHeaderFiltering={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
          }
        />
        <Column
          dataField={'attenzione_speciale'}
          caption={'WK'}
          dataType={'boolean'}
          width={'3%'}
          allowHeaderFiltering={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
          }
        />
        <Column
          filterOperations={['=']}
          dataField={'aggiornamenti'}
          caption={'US'}
          cellRender={statusCellRender}
          width={'3%'}
          allowHeaderFiltering={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
          }
        />
        <Column
          dataField={'note'}
          caption={'NOTE'}
          visible={false}
          allowHeaderFiltering={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
          }
        />
        <Column
          dataField={'leg_note_sw_aggiunto'}
          caption={'NOTE AGGIUNTIVE'}
          visible={false}
          allowHeaderFiltering={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined
          }
        />
        <Toolbar>
          <Item
            location="before"
            locateInMenu="never"
            showText="inMenu"
            widget="dxButton"
            options={{
              hint: 'Refresh',
              text: 'Refresh',
              icon: 'refresh',
              onClick: (e: ClickEvent) => {
                swQsGridRef.current?.instance().refresh()
              },
              stylingMode: 'text',
            }}
          ></Item>
          <Item name="groupPanel" />
          <Item
            visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
            locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
            showText="inMenu"
            name="addRowButton"
            options={{
              onClick: () => {
                navigate(`new`)
              },
              hint: 'Nuova licenza',
              text: 'Nuova licenza',
            }}
          />

          <Item
            visible={isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.Administrator])}
            locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
            showText="inMenu"
            widget="dxButton"
            options={{
              hint: 'Calcola codici',
              text: 'Calcola codici',
              icon: 'variable',
              onClick: () => {
                const rowkey = swQsGridRef.current?.instance().option('focusedRowKey')
                swQsGridRef.current
                  ?.instance()
                  .byKey(rowkey)
                  .then((rowData: qs_licenza) => {
                    setCodeComputingQsLicenseData({
                      data: {
                        sede: rowData.sede,
                        versione: rowData.versione_uso,
                      },
                      onSubmitEditor: async (e: any, codeComputingCriteria: any) => {
                        e.preventDefault()
                        const response = await useQsAdminApiManager
                          .getState()
                          .client.get(
                            `/api/authcodes/generateinicodes?siteId=${codeComputingCriteria.sede.id}&ver=${codeComputingCriteria.versione.major}`,
                            {
                              headers: {
                                'Content-Disposition': 'attachment;filename=qslicman.ini',
                                'Content-Type': 'application/octet-stream',
                              },
                              responseType: 'arraybuffer',
                            },
                          )
                        saveAs(new Blob([response.data], { type: 'application/octet-stream' }), 'qslicman.ini')
                        setPopupVisible(false)
                      },
                      versioni: qsVersioneList.data.value,
                      sedi: rowData.sede?.azienda?.sedi,
                    })
                  })

                setPopupVisible(true)
              },
              stylingMode: 'text',
            }}
          ></Item>
          <Item
            visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
            locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
            showText="inMenu"
            widget="dxButton"
            options={{
              hint: 'Elimina licenza',
              text: 'Elimina licenza',
              icon: 'trash',
              onClick: () => {
                const rowkey = swQsGridRef.current?.instance().option('focusedRowKey')
                swQsGridRef.current
                  ?.instance()
                  .byKey(rowkey)
                  .then((rowdata) => {
                    console.log(
                      `eliminazione sw qs ${rowdata['id']} -> ${rowdata['sede']?.azienda?.nome ?? 'Non definito'}`,
                    )
                    const result = confirm(
                      `<i>Vuoi davvero eliminare la licenza qs del cliente <b>${
                        rowdata['sede']?.azienda?.nome ?? 'Non definito'
                      }</b>?</i>`,
                      `Eliminazione licenza Qs Informatica`,
                    )
                    result.then((dialogResult) => {
                      if (!dialogResult) return

                      deleteLicenza(rowdata.id)
                        .then(() => {
                          // Aggiornamento griglia
                          swQsGridRef.current?.instance().refresh()

                          notify(
                            {
                              message: `Licenza '${rowdata['id']}' del cliente ${
                                rowdata['sede']?.azienda?.nome ?? 'Non definito'
                              } eliminata con successo`,
                              type: 'success',
                              displayTime: 5000,
                            },
                            {
                              position: 'bottom center',
                              direction: 'up-push',
                            },
                          )
                        })
                        .catch((error: unknown) => {
                          console.log('erro', error)
                          notify(
                            {
                              message: `Errore eliminazione licenza: ${error}`,
                              type: 'error',
                              displayTime: 5000,
                            },
                            {
                              position: 'bottom center',
                              direction: 'up-push',
                            },
                          )
                        })
                    })
                  })
              },
              stylingMode: 'text',
            }}
          ></Item>
          <Item
            name="columnChooserButton"
            locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
            showText="inMenu"
            options={{ hint: 'Scelta colonne', text: 'Scelta colonne' }}
          />
          <Item
            name="exportButton"
            locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
            showText="inMenu"
            options={{ hint: 'Esporta dati in excel', text: 'Esporta dati in excel' }}
          />
          <Item name="searchPanel" />
        </Toolbar>
      </DataGrid>
      <Popup
        width={currentScreenSize.isLarge || currentScreenSize.isMedium ? 400 : currentScreenSize.isXSmall ? 350 : 700}
        height={currentScreenSize.isSmall ? 300 : 480}
        hideOnOutsideClick={true}
        showCloseButton={true}
        deferRendering={true}
        title={'Calcolo codici'}
        visible={popupVisible}
        onHiding={hideImagePopup}
        className="computecode-popup-content"
      >
        <SoftwareQsCodeComputer {...codeComputingQsLicenseData} />
      </Popup>
      <Popover position="bottom" ref={gridPopoverRef} />
    </>
  )
}

export default SoftwareQsGrid
