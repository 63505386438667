import { ArraySourceComposer } from '@/auth/api/connector'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { ad_quote, azienda, offerta, sede } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { OrderEditorFormProps } from '@/routes/orders/OrderEditor.types'
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
  Tab,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useRevalidator } from 'react-router-dom'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { ValidationCallbackData } from 'devextreme/common'
import { StatoOrdine } from '@/routes/orders/OrderEditor.enums'
import { FileUploader } from 'devextreme-react'
import { FileUploaderRef } from 'devextreme-react/file-uploader'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import DataSource from 'devextreme/data/data_source'
import ProjectSimpleItem from '@/components/project-simple-item/ProjectSimpleItem'
import SiteSimpleItem from '@/components/site-simple-item/SiteSimpleItem'
import notify from 'devextreme/ui/notify'
import { PopupRef } from 'devextreme-react/cjs/popup'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { TaskPlanner } from '@/routes/tasks/TaskEditor.planner'
import { Roles } from '@/auth/azure/Roles'
import { customButton, isUserRoleAllowed } from '@/routes/utils'
import { ClickEvent } from 'devextreme/ui/button'

const OrderEditorForm = (props: OrderEditorFormProps) => {
  const { creating, order, stati, isReseller, aziende, isRoleAllowed, userInfo, fetcher } = props

  const { clientId } = useParams()
  const { service } = useQsAdminApiManager()

  const revalidator = useRevalidator()
  const formRef = useRef<FormRef>(null)
  const fileUploaderRef = useRef<FileUploaderRef>(null)
  const popupNoteClienteRef = useRef<PopupRef>(null)

  const [currentOfferta] = useState<offerta | null | undefined>(
    order && 'offerta' in order && order.offerta ? order.offerta : undefined,
  )
  const [currentQuota] = useState<ad_quote | null | undefined>(
    order && 'quota' in order && order.quota ? order.quota : undefined,
  )

  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId && creating ? true : false)
  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId
      ? aziende
        ? aziende[0]
        : undefined
      : order && 'commessa' in order && order.commessa
        ? order.commessa.sede?.azienda
        : undefined,
  )
  const [currentSite, setCurrentSite] = useState<sede | null | undefined>(
    order && 'commessa' in order && order.commessa ? order.commessa.sede : undefined,
  )
  const [sediDataSource, setSediDataSource] = useState<DataSource>(
    ArraySourceComposer('id', order && 'commessa' in order && order.commessa ? [order.commessa.sede] : []),
  )

  const getCompanySites = useCallback(
    async (companyId: number) => {
      const sitesData = await service.sede().query((builder, sede) => {
        builder.filter(sede.azienda.props.id.eq(companyId))
        builder.orderBy(sede.principale.desc())
        builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
            sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi')
        })
        builder.select('id', 'nome', 'azienda', 'note')
      })
      return sitesData.data.value
    },
    [service],
  )

  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', currentCompany)
    if (currentCompany) {
      getCompanySites(currentCompany?.id).then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        formRef.current?.instance().updateData('sede', sites[0])
        setCurrentSite(sites[0])
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, getCompanySites, currentCompany])

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle')} />
      <DXForm
        id={'order_form'}
        formData={order}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="orderValidation"
        ref={formRef}
        readOnly={
          !isRoleAllowed ||
          (userInfo?.roles?.hasRole(Roles.ExternalSales) && order.stato?.id !== StatoOrdine.PROVVISORIO)
        }
      >
        <GroupItem colCount={5}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              label={{ text: 'Azienda' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton('open', (e: ClickEvent) => {
                    if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                    else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                  }),
                  { name: 'dropDown', location: 'after' },
                ],
                value: currentCompany,
                dataSource: ArraySourceComposer(
                  'id',
                  isReseller
                    ? aziende
                    : order && 'commessa' in order && order.commessa
                      ? [order.commessa.sede?.azienda]
                      : [],
                ),
                placeholder: "Seleziona l'azienda...",
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'azienda",
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  console.log(e)
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                  setCurrentCompany(e.value)
                  setCurrentCompanyChanged(true)
                },
                readOnly: !isReseller || (clientId !== null && clientId !== undefined) ? true : false,
              }}
            >
              <RequiredRule message="Azienda obbligatoria"></RequiredRule>
            </SimpleItem>
            {SiteSimpleItem({
              name: 'sede',
              value: currentSite,
              dataSource: sediDataSource,
              rules: [
                {
                  type: 'required',
                  message: `Sede obbligatoria`,
                },
              ],
              readOnly: !isReseller || (clientId !== null && clientId !== undefined) ? true : false,
              onValueChanged: (e: ValueChangedEventSelectBox) => {
                if (e.previousValue?.id === e.value?.id) return
                console.log('SITE EVENT CHANGED', e.previousValue, e.value)
                setCurrentSite(e.value)
                formRef.current?.instance().updateData('sede', e.value)
              },
              onInfoClick: () => {
                if (currentSite) {
                  popupNoteClienteRef.current?.instance().show()
                } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
              },
            })}
            <SimpleItem
              dataField="creazione"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                readOnly: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Creazione' }}
            >
              <RequiredRule message="Data creazione obbligatoria" />
            </SimpleItem>
            <SimpleItem
              dataField="evasione"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                readOnly: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Evasione' }}
            ></SimpleItem>
            <SimpleItem
              dataField="stato"
              label={{ text: 'Stato' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', stati),
                placeholder: 'Seleziona lo stato...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare lo stato',
                  hideOnOutsideClick: true,
                },
                readOnly:
                  creating ||
                  order.stato?.id === StatoOrdine.ANNULLATO ||
                  order.stato?.id === StatoOrdine.EVASO ||
                  userInfo?.roles?.hasRole(Roles.ExternalSales),
                onValueChanged: (e: ValueChangedEvent) => {
                  console.log(e)
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('STATO EVENT CHANGED', e.previousValue, e.value)
                  if (e.value.id === StatoOrdine.EVASO)
                    formRef.current?.instance().updateData('evasione', new Date().toISOString())
                  else formRef.current?.instance().updateData('evasione', null)
                },
              }}
            >
              <RequiredRule message="Stato obbligatorio"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="importo"
              label={{ text: 'Importo' }}
              editorType="dxNumberBox"
              editorOptions={{
                readOnly:
                  order.stato?.id === StatoOrdine.ANNULLATO ||
                  order.stato?.id === StatoOrdine.EVASO ||
                  (userInfo?.roles?.hasRole(Roles.ExternalSales) && order.stato?.id !== StatoOrdine.PROVVISORIO),
              }}
            >
              <RequiredRule message="Importo obbligatorio"></RequiredRule>
              <CustomRule
                message="Importo deve essere maggiore di 0"
                validationCallback={({ value }: ValidationCallbackData) => {
                  return value > 0
                }}
              ></CustomRule>
            </SimpleItem>
            {!isReseller && (
              <SimpleItem
                dataField="offerta"
                label={{ text: 'Offerta' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    customButton(
                      'open',
                      (e: ClickEvent) => {
                        window.open(`/offers/${order?.offerta?.id}`, '_blank')
                      },
                      !isRoleAllowed || (order.offerta ? false : true),
                    ),
                    { name: 'dropDown', location: 'after' },
                  ],
                  dataSource: ArraySourceComposer(
                    'id',
                    order && 'offerta' in order && order.offerta ? [order.offerta] : [],
                  ),
                  placeholder: 'Seleziona offerta...',
                  displayExpr: 'ded_Dis',
                  searchEnabled: true,
                  dropDownCentered: true,
                  showClearButton: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare offerta',
                    hideOnOutsideClick: true,
                  },
                  readOnly: true,
                }}
              >
                <CustomRule
                  message="Obbligatoria solo l'offerta o la quota"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    return currentOfferta && currentQuota ? false : true
                  }}
                ></CustomRule>
              </SimpleItem>
            )}
            {!isReseller && (
              <SimpleItem
                dataField="quota"
                label={{ text: 'Quota' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    customButton(
                      'open',
                      (e: ClickEvent) => {
                        window.open(`/quotes/${order?.quota?.id}`, '_blank')
                      },
                      order.quota ? false : true,
                    ),
                  ],
                  dataSource: ArraySourceComposer('id', order && 'quota' in order && order.quota ? [order.quota] : []),
                  placeholder: 'Seleziona quota...',
                  displayExpr: 'number',
                  searchEnabled: true,
                  dropDownCentered: true,
                  showClearButton: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare quota',
                    hideOnOutsideClick: true,
                  },
                  readOnly: true,
                }}
              >
                <CustomRule
                  message="Obbligatoria solo l'offerta o la quota"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    return currentOfferta && currentQuota ? false : true
                  }}
                ></CustomRule>
              </SimpleItem>
            )}
            {!isReseller &&
              ProjectSimpleItem({
                onFolderClick: () => {
                  window.open(`/projects/${order?.commessa?.id}`, '_blank')
                },
                dataSource: ArraySourceComposer(
                  'id',
                  order && 'commessa' in order && order.commessa ? [order.commessa] : [],
                ),
                readOnly: true,
                required: true,
              })}
            <SimpleItem
              dataField="attivitaTecnicaRichiesta"
              label={{ text: 'Attività Tecnica Richiesta' }}
              editorType="dxSelectBox"
              editorOptions={{
                dataSource: [true, false],
                displayExpr: (item: any) => {
                  console.log('displayExpr', item)
                  if (item === true) return 'SI'
                  else if (item === false) return 'NO'
                  else item === null || item === undefined
                  return null
                },
                placeholder: 'Seleziona flag...',
                searchEnabled: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare falg',
                  hideOnOutsideClick: true,
                },
                readOnly: !isReseller,
              }}
            >
              <CustomRule
                type="custom"
                message="Attivita tecnica richiesta obbligatoria"
                validationCallback={(options: {
                  column: Record<string, any>
                  data: Record<string, any>
                  formItem: Record<string, any>
                  rule: Record<string, any>
                  validator: Record<string, any>
                  value: string | number
                }) => {
                  const value = options.value
                  try {
                    return value.toString() === 'true' || value.toString() === 'false' ? true : false
                  } catch {
                    //se entro nel catch è perche non si riesce ad applicare il toString() su un valore null
                    //quindi il campo non è sicuramente valorizzato e ritorno false sulla validazione
                    return false
                  }
                }}
              ></CustomRule>
            </SimpleItem>
          </GroupItem>
          <GroupItem colSpan={3}>
            <TabbedItem>
              <TabPanelOptions animationEnabled={true} swipeEnabled={true} deferRendering={false} />
              <Tab title="note">
                <SimpleItem
                  dataField={'note'}
                  editorType={'dxTextArea'}
                  label={{ visible: false }}
                  editorOptions={{
                    height: '300',
                  }}
                >
                  <RequiredRule message="Note obbligatorie"></RequiredRule>
                </SimpleItem>
              </Tab>
              {((!creating && !userInfo?.roles?.hasRole(Roles.ExternalSales)) ||
                (!creating &&
                  userInfo?.roles?.hasRole(Roles.ExternalSales) &&
                  order.stato?.id === StatoOrdine.EVASO)) && (
                <Tab title="note evasione">
                  <SimpleItem
                    dataField={'note_evasione'}
                    editorType={'dxTextArea'}
                    label={{ visible: false }}
                    editorOptions={{
                      height: '300',
                    }}
                  ></SimpleItem>
                </Tab>
              )}
              {((!creating && !userInfo?.roles?.hasRole(Roles.ExternalSales)) ||
                (!creating &&
                  userInfo?.roles?.hasRole(Roles.ExternalSales) &&
                  order.stato?.id === StatoOrdine.EVASO)) && (
                <Tab title="Pianificazione">
                  <SimpleItem>
                    <TaskPlanner order={order} />
                  </SimpleItem>
                </Tab>
              )}
            </TabbedItem>
          </GroupItem>
        </GroupItem>
        <GroupItem>
          {creating && (
            <SimpleItem>
              <FileUploader
                name={'orderFileUploader'}
                ref={fileUploaderRef}
                selectButtonText={'Seleziona un pdf'}
                labelText={'o trascina un file qui'}
                multiple={false}
                accept={'application/pdf'}
                uploadMode={'useForm'}
                allowedFileExtensions={['.pdf']}
              />
            </SimpleItem>
          )}
        </GroupItem>
        <GroupItem
          visible={
            isUserRoleAllowed(userInfo?.roles, [
              Roles.GlobalAdministrator,
              Roles.Administrator,
              Roles.Supervisor,
              Roles.Sales,
            ]) ||
            (userInfo?.roles?.hasRole(Roles.ExternalSales) && order.stato?.id === StatoOrdine.PROVVISORIO)
          }
          cssClass="last-group"
        >
          <GroupItem cssClass="buttons-group" colCount={2}>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!formRef.current?.instance().option().isDirty}
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  console.log('order', order)
                  if (order) {
                    console.log('order', order)
                    if (creating) {
                      const formData = new FormData()
                      const jsonBlob = new Blob([JSON.stringify(order)], { type: 'application/json' })
                      formData.append('order', JSON.stringify(order))
                      const files = fileUploaderRef.current?.instance().option().value
                      if (files && files.length > 0) {
                        formData.append('file', files[0])
                      }
                      formData.append('isReseller', String(isReseller))
                      fetcher?.submit(formData, {
                        method: creating ? 'POST' : 'PUT',
                        encType: 'multipart/form-data',
                      })
                    } else {
                      fetcher?.submit(JSON.stringify(order), {
                        method: 'PUT',
                        encType: 'application/json',
                      })
                    }
                  } else console.error('no data tu submit')
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  disabled={!formRef.current?.instance().option().isDirty}
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <FormPopupClientNote
        data={currentSite}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites(currentCompany?.id).then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              setCurrentSite(
                sites.find((site: sede) => {
                  return site.id === currentSite?.id
                }),
              )
            })
          }
        }}
        isRoleAllowed={isUserRoleAllowed(userInfo?.roles, [
          Roles.TechDeveloper,
          Roles.Sales,
          Roles.GlobalAdministrator,
          Roles.ExternalSales,
          Roles.Administrator,
        ])}
      ></FormPopupClientNote>
    </>
  )
}

export default OrderEditorForm
