import { ButtonItem, Form, GroupItem, RequiredRule, SimpleItem } from 'devextreme-react/form'
import { useState } from 'react'
import '../SoftwarePage.scss'
import { ArraySourceComposer } from '@/auth/api/connector'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'

export const SoftwareQsCodeComputer = (props: any) => {
  const [allSitesEnabled] = useState<boolean>(false)
  const [loadIndicatorVisible, setLoadIndicatorVisible] = useState(false)

  return (
    <>
      <div id="edit-qs-license-form-container" className={'dx-card responsive-paddings'}>
        <LoadingPanel
          position={{ of: '#edit-qs-license-form-container' }}
          visible={loadIndicatorVisible}
        ></LoadingPanel>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            setLoadIndicatorVisible(true)
            props.onSubmitEditor(e, props.data).finally(() => {
              setLoadIndicatorVisible(false)
            })
          }}
        >
          <Form
            id={`swqs_codecmp_form}`}
            key={`swqs_codecmp_form}`}
            formData={props.data}
            validationGroup="qsLicenseDataValidation"
          >
            <GroupItem colCount={1}>
              <SimpleItem
                dataField="sede.azienda.nome"
                label={{ text: 'Azienda' }}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField="sede"
                label={{ text: 'Sede' }}
                editorType="dxLookup"
                editorOptions={{
                  readOnly: allSitesEnabled,
                  dataSource: ArraySourceComposer('id', props.sedi),
                  placeholder: '*',
                  displayExpr: 'nome',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare la sede',
                    hideOnOutsideClick: true,
                  },
                }}
              ></SimpleItem>
              <SimpleItem
                dataField="versione"
                label={{ text: 'Versione' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', props.versioni),
                  placeholder: 'Seleziona la versione...',
                  displayExpr: 'major',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare la versione',
                    hideOnOutsideClick: true,
                  },
                }}
              >
                <RequiredRule message="La definizione della versione è obbligatoria" />
              </SimpleItem>
            </GroupItem>
            <GroupItem cssClass="last-group">
              <GroupItem cssClass="buttons-group" colCountByScreen={colGroupCountByScreen}>
                <ButtonItem
                  name="btn-compute"
                  buttonOptions={{
                    icon: 'variable',
                    text: 'Calcola',
                    disabled: false,
                    useSubmitBehavior: true,
                    width: '150px',
                  }}
                ></ButtonItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </>
  )
}

const colGroupCountByScreen = {
  xs: 1,
  sm: 2,
  md: 2,
  lg: 2,
}

export default SoftwareQsCodeComputer
