import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import type { contatto_aziendale, entita_aziendale } from '@/model/qsadminapi/QsAdminApiModuleModel'

export function isIPerson(obj: contatto_aziendale | entita_aziendale): obj is contatto_aziendale {
  return (obj as contatto_aziendale).cognome !== undefined
}

export const onEmailsCreate = async (
  newdata: contatto_aziendale | entita_aziendale,
): Promise<contatto_aziendale | entita_aziendale> => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  if (newdata.emails) {
    for (const new_email of newdata.emails) {
      const newStoredEmailObj = await qsAdminApi.email_aziendale().create(new_email)
      console.log('newStoredEmailObj', newStoredEmailObj.data)
      new_email.id = newStoredEmailObj.data.id
    }
  }

  return newdata
}
