import { memo, useRef, useState } from 'react'
import ODataStore from 'devextreme/data/odata/store'
import DataSource from 'devextreme/data/data_source'
import type { qs_licenza as ISwQs } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { Column, DataGrid, DataGridRef, Editing, Item, StateStoring, Toolbar } from 'devextreme-react/data-grid'
import { FormMode } from '@/enums'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import useTokenRefresh from '@/auth/azure/azureManager'
import notify from 'devextreme/ui/notify'
import { useScreenSize } from '@/themes/media-query'
import { ClickEvent } from 'devextreme/ui/button'

type SoftwareQsEditorHistoryProps = {
  license: ISwQs
  mode: FormMode
  readOnly?: boolean
}

export const SoftwareQsEditorHistory = (props: SoftwareQsEditorHistoryProps) => {
  const [currentQsLicenseData] = useState<ISwQs>(props.license)
  const token = useTokenRefresh()
  const currentScreenSize = useScreenSize()
  const qsLicenseHistoryDataSource = new DataSource({
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/qs_storico_licenza`,
      key: 'id',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: [['licenza.id', currentQsLicenseData?.id]],
    expand: ['sede($expand=azienda)', 'versione', 'licenza'],
  })
  const gridRef = useRef<DataGridRef>(null)

  return (
    <DataGrid
      ref={gridRef}
      id={`sw-qshst-grid-${currentQsLicenseData?.id}`}
      dataSource={qsLicenseHistoryDataSource}
      keyExpr={'id'}
      showBorders={true}
      showColumnLines={true}
      focusedRowEnabled={true}
      columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
      allowColumnReordering={true}
      allowColumnResizing={true}
      rowAlternationEnabled={true}
      wordWrapEnabled={false}
      width="100%"
    >
      <StateStoring enabled={true} type="localStorage" storageKey={'swqs-history-datagrid'} savingTimeout={50} />
      <Editing
        mode="batch"
        allowUpdating={false}
        allowAdding={false}
        allowDeleting={props.mode !== FormMode.View && !props.readOnly}
      />
      <Column dataField={'id'} visible={false} />,
      <Column dataField={'data'} caption={'DATA'} dataType={'date'} format={'dd/MM/yyyy'} />
      <Column dataField={'versione.major'} caption={'VERSIONE'} />
      <Column dataField={'sede.azienda.nome'} caption={'AZIENDA'} />
      <Column dataField={'sede.nome'} caption={'SEDE'} />
      <Toolbar>
        <Item
          location="before"
          locateInMenu="never"
          showText="inMenu"
          widget="dxButton"
          options={{
            hint: 'Refresh',
            text: 'Refresh',
            icon: 'refresh',
            onClick: (e: ClickEvent) => {
              gridRef.current?.instance().refresh()
            },
            stylingMode: 'text',
          }}
        ></Item>
        <Item name="addRowButton"></Item>
        <Item name="saveButton"></Item>
        <Item name="revertButton"></Item>
      </Toolbar>
    </DataGrid>
  )
}

const SoftwareQsEditorHistoryMemoed = memo(SoftwareQsEditorHistory, (oldProps, newProps) => {
  return oldProps.license.id === newProps.license.id
})

export default SoftwareQsEditorHistoryMemoed
