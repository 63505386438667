import { Roles } from '@/auth/azure/Roles'
import AnalysisEditor from '@/routes/analysis/AnalysisEditor'
import { analysisEditorRouteAction, analysisEditorRouteLoader } from '@/routes/analysis/AnalysisEditor.route'
import { RoleRouteObject } from '@/types'
import { RouteObject } from 'react-router-dom'

const AnalysisCreatorRoute = {
  path: 'new',
  element: <AnalysisEditor creating={true} />,
  loader: analysisEditorRouteLoader,
  action: analysisEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default AnalysisCreatorRoute
