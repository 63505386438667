import { Roles } from '@/auth/azure/Roles'
import InterventionEditor from '@/routes/interventions/InterventionEditor'
import {
  interventionEditorRouteAction,
  interventionEditorRouteLoader,
} from '@/routes/interventions/InterventionEditor.route'
import { RoleRouteObject } from '@/types'

const InterventionCreatorRoute = {
  path: 'new',
  element: <InterventionEditor creating={true} />,
  loader: interventionEditorRouteLoader,
  action: interventionEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default InterventionCreatorRoute
