import { create } from 'zustand'
import { QsAdminApiModuleService } from '@/model/qsadminapi/QsAdminApiModuleService'
import { AuthenticatedAxiosClient } from '@/auth/AxiosClient'
import axios, { AxiosInstance } from 'axios'
import { useAzureManager } from '@/auth/azure/azureManager'

interface QsAdminApiManagerProps {
  service: QsAdminApiModuleService<AuthenticatedAxiosClient>
  client: AxiosInstance
}
interface QsAdminApiManagerState extends QsAdminApiManagerProps {}

const createQsAdminApiManagerStore = (initProps: Partial<QsAdminApiManagerProps>) => {
  const newClient = axios.create({
    baseURL: `${import.meta.env.VITE_QSADMINAPI_HOST}`,
  })
  newClient.interceptors.request.use(async (config) => {
    config.headers.Authorization = `Bearer ${await useAzureManager.getState().getToken()}`
    config.headers.Accept = 'application/json;odata.metadata=none'
    console.log(`${config.method?.toUpperCase()} ${config.url}`)
    return config
  })
  const currentHost = import.meta.env.VITE_QSADMINAPI_HOST
  console.log(`I am using the host: ${currentHost}`)
  const DEFAULT_PROPS: QsAdminApiManagerProps = {
    service: new QsAdminApiModuleService(new AuthenticatedAxiosClient(), currentHost),
    client: newClient,
  }
  // console.log('initialized pca')
  return create<QsAdminApiManagerState>()((set, get) => ({
    ...DEFAULT_PROPS,
    ...initProps,
  }))
}

type QsAdminApiManagerStore = ReturnType<typeof createQsAdminApiManagerStore>
export const useQsAdminApiManager = createQsAdminApiManagerStore({})
