import { LoaderFunction } from 'react-router-dom'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import PackagesSaleGrid from '@/routes/packages/sale/PackagesSaleGrid'
import { PackageSaleCreatorRoute } from '@/routes/packages/sale/PackageSaleCreator.route'
import { PackageSaleEditorRoute } from '@/routes/packages/sale/PackageSaleEditor.route'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Roles } from '@/auth/azure/Roles'

export const packagesSaleGridRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })
  return {
    aziende: await getAziende,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor],
  }
}) satisfies LoaderFunction

export const PackagesSaleGridRoute = {
  path: 'sale',
  children: [
    {
      index: true,
      loader: packagesSaleGridRouteLoader,
      element: <PackagesSaleGrid />,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    PackageSaleCreatorRoute,
    PackageSaleEditorRoute,
  ],

  icon: 'box',
  text: 'Pacchetti Venduti',
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
