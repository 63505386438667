import TestComponent from '@/components/TestComponent'
import React from 'react'
import { LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { satisfies } from 'semver'
import { RoleRouteObject } from '@/types'
import { Roles } from '@/auth/azure/Roles'

export const TestComponentLoader = (async () => {}) satisfies LoaderFunction

export const TestRoute = {
  path: 'test',
  // loader: TestComponentLoader,
  element: <TestComponent />,
  allowedRoles: [Roles.Guest],
} satisfies RoleRouteObject
