import LoginRoute from './_unauthenticated/login.route'
import CreateRoute from './_unauthenticated/cognito/create.route'
import ResetRoute from './_unauthenticated/cognito/reset.route'
import ResetCodeRoute from './_unauthenticated/cognito/resetCode.route'
import { redirect, RouteObject } from 'react-router-dom'
import { typesafeBrowserRouter } from 'react-router-typesafe'
import App from '@/App'
import { IPublicClientApplication } from '@azure/msal-browser'
import {
  allAuthenticatedChildrenRoutes,
  AuthenticatedLayoutRoute,
} from '@/routes/authenticated/AuthenticatedLayout.route'
import { QueryClient } from '@tanstack/react-query'
import TicketRatingRoute from '@/routes/_unauthenticated/ticketrating.route'
import { AzureUserInfo, getAzureUserInformation, useAzureManager } from '@/auth/azure/azureManager'
import { RoleRoute, RoleRouteObject } from '@/types'
import { Role } from '@/auth/azure/Roles'
import { AuthenticatedLayout } from '@/routes/authenticated/AuthenticatedLayout'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import { AuthGuard } from '@/routes/authGuard'
import AccessDeniedRoute from '@/routes/_unauthenticated/AccessDenied.route'

export const getRouter = async (msalInstance: IPublicClientApplication, queryClient: QueryClient) => {
  const baseRoutes = [
    {
      element: <App pca={msalInstance} queryClient={queryClient} />,
      errorElement: <RouterErrorHandler />,
      path: '/',
      children: [
        {
          index: true,
          loader: async () => {
            return useAzureManager.getState().userInfo?.name ? redirect('/dashboard') : redirect('/login')
          },
          // element: <h1>ACTUAL HOMEPAGE TO BE IMPLEMENTED!</h1>,
        },
        LoginRoute,
        // CreateRoute,
        // ResetRoute,
        // ResetCodeRoute,
        TicketRatingRoute,
        AuthenticatedLayoutRoute,
        AccessDeniedRoute,
      ],
    },
  ] satisfies RouteObject[]

  const { router, href } = typesafeBrowserRouter(baseRoutes)
  return router
}
