import AnalysisEditorForm from '@/routes/analysis/AnalysisEditor.form'
import { analysisEditorRouteLoader } from '@/routes/analysis/AnalysisEditor.route'
import { AnalysisEditorProps } from '@/routes/analysis/AnalysisEditor.types'
import { Suspense, useRef } from 'react'
import { Await, useLoaderData } from 'react-router-typesafe'
import { Form as DXForm, Tab, TabbedItem } from 'devextreme-react/form'
import AttachmentsManager from '@/components/file-manager/AttachmentsManager'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import DocumentCreator from '@/components/document/DocumentCreator'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useFetcher, useParams } from 'react-router-dom'
import { FileManagerRef } from 'devextreme-react/file-manager'
import { isUserRoleAllowed } from '@/routes/utils'
import { Role, Roles } from '@/auth/azure/Roles'
import { DocumentPermissions } from '@/enums'
import { AxiosError } from 'axios'

const AnalysisEditor = (props: AnalysisEditorProps) => {
  const { creating } = props
  const { clientId } = useParams()

  const { service } = useQsAdminApiManager()

  const { analysis, getAziende, getStati, getPersone, getEntita, getUserInfo, defaultCRUDAllowedRoles } =
    useLoaderData<typeof analysisEditorRouteLoader>()
  const title: string = creating ? `Nuova analisi` : `Analisi ${analysis?.ded_Dis}`

  const documentoGetRef = useRef<any>(null)
  const fetcher = useFetcher()
  const fileManagerRef = useRef<FileManagerRef>(null)
  const { client } = useQsAdminApiManager()

  return (
    <>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getStati, getAziende, getEntita, getPersone, getUserInfo])}>
          {([stati, aziende, entita, persone, userInfo]) => (
            <>
              <h2 className={clientId ? '' : 'content-block'}>{title}</h2>
              <div
                id="editor-analysis-container"
                className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
              >
                <fetcher.Form>
                  <AnalysisEditorForm
                    creating={creating}
                    analysis={analysis}
                    stati={stati.data.value}
                    aziende={aziende.data.value}
                    entita={entita?.data.value}
                    persone={persone?.data.value}
                    documentoGetRef={documentoGetRef}
                    isRoleAllowed={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                    userInfo={userInfo}
                    fetcher={fetcher}
                  ></AnalysisEditorForm>
                </fetcher.Form>
              </div>
              {!creating && (
                <div
                  id="allegati-activity-container"
                  className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
                >
                  <DXForm id="form-allegati">
                    <TabbedItem>
                      <Tab title="Documento">
                        <DocumentCreator
                          title="Documento"
                          documentUrl={analysis.mainDocumentUrl}
                          documentGetRef={documentoGetRef}
                          action={async () => {
                            await service.analisi(analysis.id).CreateDocument()
                          }}
                          preliminarPermissionsAction={async () => {
                            try {
                              const response = await client.post(
                                '/api/analisi/drivepermissions',
                                {
                                  objectId: analysis.id,
                                  path: analysis.mainDocumentUrl,
                                },
                                {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                },
                              )
                              return response.status === 200
                                ? (response.data as DocumentPermissions)
                                : DocumentPermissions.Deny
                            } catch (error) {
                              console.log('Errore in settaggio permessi sul documento analisi', error)
                              return DocumentPermissions.Deny
                            }
                          }}
                        />
                      </Tab>
                      <Tab title="Allegati">
                        <AttachmentsManager
                          fileManagerRef={fileManagerRef}
                          folderUrl={analysis?.attachmentsFolderUrl}
                          preliminarPermissionsAction={async () => {
                            try {
                              const response = await client.post(
                                '/api/analisi/drivepermissions',
                                {
                                  objectId: analysis.id,
                                  path: analysis.attachmentsFolderUrl,
                                },
                                {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                },
                              )
                              return response.status === 200
                                ? (response.data as DocumentPermissions)
                                : DocumentPermissions.Deny
                            } catch (error) {
                              console.log('Errore in settaggio permessi sulla cartella allegati analisi', error)
                              return DocumentPermissions.Deny
                            }
                          }}
                          createDirectoryAction={async (
                            rootFolderPath: string,
                            name: string,
                            conflictBehavior: string,
                          ) => {
                            return await client.post(
                              '/api/analisi/createfolder',
                              {
                                rootFolder: rootFolderPath,
                                newFolderName: name,
                                conflictBehavior,
                                objectId: analysis.id,
                              },
                              {
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                              },
                            )
                          }}
                        />
                      </Tab>
                    </TabbedItem>
                  </DXForm>
                </div>
              )}
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default AnalysisEditor
