import { useLoaderData } from 'react-router-typesafe'
import { ButtonItem, ButtonOptions, Form as DXForm, GroupItem, SimpleItem } from 'devextreme-react/form'
import { useRef, useState } from 'react'
import { GoogleGenerativeAI } from '@google/generative-ai'
import { Column, DataGrid, DataGridRef, type DataGridTypes, Selection } from 'devextreme-react/data-grid'
import { Lookup } from 'devextreme-react'
import { LookupRef } from 'devextreme-react/cjs/lookup'
import { ArraySourceComposer } from '@/auth/api/connector'
import { ContactsUnifierLoader } from '@/routes/contacts/ContactsUnifier.route'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import notify from 'devextreme/ui/notify'
import { contatto_aziendale } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ClickEvent } from 'devextreme/ui/button'
import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'

interface GeminiContact {
  id: number
  fullname: string
  ad_recipient: boolean
  nome: string
  cognome: string
  ruolo: string
  note: string
  titolo: string
  telefono: string
  attivo: boolean
  emails: [{ id: number; email: string }]
}
interface GeminiResponse {
  sedi: [
    {
      id: number
      nome: string
      duplicated_contacts: [
        {
          similarity_group: GeminiContact[]
        },
      ]
    },
  ]
}

interface UnifiesRequest {
  sites: {
    siteId: number
    duplicatedContacts: {
      chosenContact: GeminiContact | undefined
      otherContacts: GeminiContact[] | undefined
    }[]
  }[]
}

const ContactsUnifier = () => {
  const { clients } = useLoaderData<typeof ContactsUnifierLoader>()
  const { clientId } = useParams()

  // eslint-disable-next-line no-secrets/no-secrets
  const googleApiKey: string = `AIzaSyCRtCcl2LnRxTW1SMaoReLZE7_VefE-9GM`
  const genAI = new GoogleGenerativeAI(googleApiKey)
  const model = genAI.getGenerativeModel({
    model: 'gemini-1.5-flash',
    systemInstruction: `You are a similarity matching engine.
Ensure your answers are complete, unless the user requests a more concise approach.
Do not accept any json that is not formatted as such:
{
  "id": int,
  "nome": string
  "sedi": [
  "id": int,
  "nome": string
  "contatti_aziendali": [
    {
      "id": int,
      "fullname": string,
      "ad_recipient": bool,
      "nome": string,
      "cognome": string,
      "ruolo": string,
      "note": string,
      "titolo": string,
      "telefono": string,
      "attivo": bool
      "emails": [{ "id": int, "email": string}]
    },
    ]
  ]
}
Respond with a json object that contains a new field in any 'sedi' list object called "duplicated_contacts" which is a list of "similarity_group" objects which contain a list of object of all "contatti_aziendali" which are similar to each other either by fullname or email in emails list.
Remove the contatti_aziendali list in any 'sedi' list object from the return object
Do not under any circumstance truncate data
Do not under any circumstance deviate from the provided structure`,
  })

  const dataGridsRef = useRef<{
    [key: number]: DataGridRef<GeminiContact, any> | null
  }>({})
  const [geminiResponse, setGeminiResponse] = useState<GeminiResponse | null>(null)
  const refAziendaLookup = useRef<LookupRef>(null)
  const [loadPanelVisible, setLoadPanleVisible] = useState<boolean>(false)
  const { service, client } = useQsAdminApiManager()
  const [buttonComputeEnabled, setButtonComputeEnabled] = useState<boolean>(true)
  const [buttonUnifiesEnabled, setButtonUnifiesEnabled] = useState<boolean>(false)

  const request: UnifiesRequest = { sites: [] }

  return (
    <>
      <h2>Unifica contatti</h2>
      <div id="contacts-unifier-container" className={'dx-card responsive-paddings'}>
        <LoadingPanel visible={loadPanelVisible}></LoadingPanel>
        <DXForm id={'azienda_form'}>
          <GroupItem colCount={1} colSpan={1}>
            <SimpleItem>
              <Lookup
                ref={refAziendaLookup}
                defaultValue={clientId ? clients.data.value[0] : undefined}
                dataSource={ArraySourceComposer('id', clients.data.value)}
                placeholder="Azienda"
                displayExpr="nome"
                searchEnabled={true}
                dropDownCentered={true}
                dropDownOptions={{ showTitle: true, title: 'Azienda', hideOnOutsideClick: true }}
                readOnly={!buttonComputeEnabled || clientId !== undefined}
              />
            </SimpleItem>
          </GroupItem>
          <GroupItem cssClass="last-group">
            <GroupItem cssClass="buttons-group" colCount={2}>
              <ButtonItem name="btn-compute">
                <ButtonOptions
                  disabled={!buttonComputeEnabled}
                  text="calcola"
                  icon="pulldown"
                  useSubmitBehavior={false}
                  width={'150px'}
                  onClick={async (e: ClickEvent) => {
                    if (refAziendaLookup.current?.instance().option('value')) {
                      setLoadPanleVisible(true)
                      try {
                        const response = await service.sede().query((sedeBuilder, sede) => {
                          sedeBuilder.filter(
                            sede.azienda.props.id.eq(refAziendaLookup.current?.instance().option('value').id),
                          )
                          sedeBuilder.expanding('contatti_aziendali', (contattiBuilder, contatto) => {
                            contattiBuilder.expanding('emails', (emailsBuilder, email) => {
                              emailsBuilder.select('id', 'email')
                            })
                          })
                          sedeBuilder.select('id', 'nome', 'contatti_aziendali')
                        })
                        const requestGemini = JSON.stringify({
                          id: refAziendaLookup.current?.instance().option('value').id,
                          nome: refAziendaLookup.current?.instance().option('value').nome,
                          sedi: response.data.value,
                        })
                        const resultGemini = await model.generateContent(requestGemini)
                        const jsonResult: GeminiResponse = JSON.parse(
                          resultGemini.response
                            .text()
                            .slice(0, Math.max(0, resultGemini.response.text().length - 4))
                            .replace('```json', ''),
                        )
                        console.log('jsonResult', jsonResult)
                        setGeminiResponse(jsonResult)
                        setButtonUnifiesEnabled(true)
                        setButtonComputeEnabled(false)
                      } catch (error) {
                        notify(
                          {
                            message: `Errore recupero contatti: ${error}`,
                            type: 'error',
                            displayTime: 2000,
                          },
                          {
                            position: 'bottom center',
                            direction: 'up-push',
                          },
                        )
                      } finally {
                        setLoadPanleVisible(false)
                      }
                    } else
                      notify(
                        {
                          message: `Selezionare prima un' azienda.`,
                          type: 'warning',
                          displayTime: 2000,
                        },
                        {
                          position: 'bottom center',
                          direction: 'up-push',
                        },
                      )
                  }}
                />
              </ButtonItem>
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  disabled={!buttonUnifiesEnabled}
                  text="reset"
                  icon="revert"
                  useSubmitBehavior={false}
                  width={'150px'}
                  onClick={(e: ClickEvent) => {
                    setGeminiResponse(null)
                    setButtonUnifiesEnabled(false)
                    setButtonComputeEnabled(true)
                  }}
                />
              </ButtonItem>
            </GroupItem>
          </GroupItem>
        </DXForm>
        {geminiResponse === null ? (
          <></>
        ) : (
          <>
            {geminiResponse.sedi.map(
              (
                sede: {
                  id: number
                  nome: string
                  duplicated_contacts: [
                    {
                      similarity_group: GeminiContact[]
                    },
                  ]
                },
                indexSede: number,
              ) => {
                return (
                  <>
                    <h3>Sede: {sede.nome}</h3>
                    {sede.duplicated_contacts.length > 0 ? (
                      <>
                        {sede.duplicated_contacts.map(
                          (
                            duplicated_contact: {
                              similarity_group: GeminiContact[]
                            },
                            indexDuplicated_contact: number,
                          ) => {
                            return (
                              <>
                                <h4>{duplicated_contact.similarity_group[0].fullname}</h4>
                                <DataGrid
                                  id={`sede_${indexSede}_duplicated_contacts_${indexDuplicated_contact}`}
                                  key={`sede_${indexSede}_duplicated_contacts_${indexDuplicated_contact}`}
                                  ref={(ref) => {
                                    dataGridsRef.current[
                                      Number(indexSede.toString() + indexDuplicated_contact.toString())
                                    ] = ref
                                  }}
                                  dataSource={duplicated_contact.similarity_group}
                                  noDataText="Nessuna contatto"
                                  className={'dx-card wide-card'}
                                  showBorders={false}
                                  showColumnLines={true}
                                  wordWrapEnabled={false}
                                  showRowLines={true}
                                  rowAlternationEnabled={true}
                                  allowColumnResizing={true}
                                  allowColumnReordering={true}
                                  width="100%"
                                  onContextMenuPreparing={(e: DataGridTypes.ContextMenuPreparingEvent<any, any>) => {
                                    if (e.row?.rowType === 'data' && e.component.getDataSource().items().length > 1) {
                                      const row: GeminiContact = e.row.data
                                      if (!e.items) e.items = []
                                      e.items.push({
                                        text: 'Scorpora',
                                        icon: 'group',
                                        visible: true,
                                        onItemClick: () => {
                                          const newDuplicatedContactStarterGroup = {
                                            similarity_group: geminiResponse.sedi[indexSede].duplicated_contacts[
                                              indexDuplicated_contact
                                            ].similarity_group.filter((dup) => dup.id !== row.id),
                                          }
                                          geminiResponse.sedi[indexSede].duplicated_contacts[indexDuplicated_contact] =
                                            newDuplicatedContactStarterGroup
                                          const newDuplicatedContacts =
                                            geminiResponse.sedi[indexSede].duplicated_contacts
                                          newDuplicatedContacts.push({ similarity_group: [row] })
                                          geminiResponse.sedi[indexSede].duplicated_contacts = newDuplicatedContacts
                                          setGeminiResponse({
                                            sedi: geminiResponse.sedi,
                                          })
                                        },
                                      })
                                    }
                                  }}
                                >
                                  <Selection allowSelectAll={false} mode="multiple" />
                                  <Column dataField="id" caption="ID" />
                                  <Column
                                    dataField="fullname"
                                    caption="NOME"
                                    minWidth={100}
                                    cellRender={(cell: DataGridTypes.ColumnCellTemplateData) => (
                                      <div className="name-template">
                                        <div>{cell.data.fullname}</div>
                                        <div className="dx-field-item-label-text position">{cell.data.titolo}</div>
                                      </div>
                                    )}
                                  />
                                  <Column dataField="telefono" caption="TELEFONO" />
                                  <Column dataField="ad_recipient" dataType="boolean" caption="RIF ADSK" />
                                  <Column
                                    name={'emails'}
                                    caption={'EMAIL'}
                                    calculateCellValue={(rowData: contatto_aziendale) =>
                                      rowData.emails?.[0]?.email?.trim()
                                    }
                                  />
                                </DataGrid>
                              </>
                            )
                          },
                        )}
                      </>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          width: '100%',
                        }}
                      >
                        Nessun duplicato trovato
                      </div>
                    )}
                  </>
                )
              },
            )}
            <DXForm id={'unifies_form'}>
              <GroupItem cssClass="last-group">
                <GroupItem cssClass="buttons-group" colCount={1}>
                  <ButtonItem name="btn-unifies">
                    <ButtonOptions
                      disabled={!buttonUnifiesEnabled}
                      text="unifica"
                      icon="user"
                      useSubmitBehavior={false}
                      width={'150px'}
                      onClick={async (e: ClickEvent) => {
                        if (geminiResponse) {
                          for (let indexSede = 0; indexSede < geminiResponse.sedi.length; indexSede++) {
                            const site: {
                              siteId: number
                              duplicatedContacts: {
                                chosenContact: GeminiContact | undefined
                                otherContacts: GeminiContact[] | undefined
                              }[]
                            } = { siteId: geminiResponse.sedi[indexSede].id, duplicatedContacts: [] }
                            for (
                              let indexDuplicatedContact = 0;
                              indexDuplicatedContact < geminiResponse.sedi[indexSede].duplicated_contacts.length;
                              indexDuplicatedContact++
                            ) {
                              const group = dataGridsRef.current[
                                Number(indexSede.toString() + indexDuplicatedContact.toString())
                              ]
                                ?.instance()
                                .getSelectedRowsData()
                              if (group?.length && group.length > 1) {
                                notify(
                                  `Selezionare un contatto per gruppo di contatti. Verifica ${geminiResponse.sedi[indexSede].duplicated_contacts[indexDuplicatedContact].similarity_group[0].fullname}`,
                                  'warning',
                                  2000,
                                )
                                return
                              }
                              if (group?.length === 1) {
                                site.duplicatedContacts?.push({
                                  chosenContact: dataGridsRef.current[
                                    Number(indexSede.toString() + indexDuplicatedContact.toString())
                                  ]
                                    ?.instance()
                                    .getSelectedRowsData()[0],
                                  otherContacts: dataGridsRef.current[
                                    Number(indexSede.toString() + indexDuplicatedContact.toString())
                                  ]
                                    ?.instance()
                                    .getDataSource()
                                    .items()
                                    .filter(
                                      (contact) =>
                                        contact.id !==
                                        dataGridsRef.current[
                                          Number(indexSede.toString() + indexDuplicatedContact.toString())
                                        ]
                                          ?.instance()
                                          .getSelectedRowsData()[0].id,
                                    ),
                                })
                              }
                            }
                            request.sites.push(site)
                          }
                        }
                        console.log('request', request)

                        setLoadPanleVisible(true)

                        try {
                          const response: AxiosResponse<any, any> = await client.put(
                            '/api/contattoaziendale/unifies',
                            {
                              request,
                            },
                            {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            },
                          )
                          console.log('response', response)
                          notify(`Unificazione contatti avvenuta con successo`, 'success', 2000)
                        } catch (error) {
                          notify(`Errore unificazione contatti. Errore:  ${error}`, 'error', 2000)
                        } finally {
                          setLoadPanleVisible(false)
                          setGeminiResponse(null)
                          setButtonUnifiesEnabled(false)
                          setButtonComputeEnabled(true)
                        }
                      }}
                    />
                  </ButtonItem>
                </GroupItem>
              </GroupItem>
            </DXForm>
          </>
        )}
      </div>
    </>
  )
}

export default ContactsUnifier
