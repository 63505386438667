import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { TicketRatingErrorHandler, TicketRatingWrapper } from '@/components/ticketrating-form/TicketRatingPage'
import { RouteFunctionParams } from '@/types'
import { AxiosResponse } from 'axios'
import type { RouteObject } from 'react-router-dom'
import { defer, ActionFunction, LoaderFunction } from 'react-router-typesafe'

const base64UrlToBase64 = (base64Url: string) => {
  return base64Url.replaceAll('-', '+').replaceAll('_', '/')
}

const decodeBase64 = (base64: string) => {
  return decodeURIComponent(
    atob(base64)
      // eslint-disable-next-line unicorn/prefer-spread
      .split('')
      // eslint-disable-next-line prefer-template, unicorn/prefer-code-point
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  )
}

const parseHmacEncodedJson = async (encodedJson: string) => {
  try {
    const base64 = base64UrlToBase64(encodedJson)
    console.log('BASE64', base64)
    const decodedString = decodeBase64(base64)
    console.log('BASE64DDD', decodedString)
    return JSON.parse(decodedString)
  } catch (error) {
    console.error('Error decoding and parsing JSON:', error)
    return null
  }
}

export const ticketRatingWrapperRouteLoader = (async ({ request, params }: RouteFunctionParams<'data'>) => {
  const dataEncoded = params.data
  const client = useQsAdminApiManager.getState().client

  const getRating = client.get(`/api/ticket/rating?encodedData=${dataEncoded}`)
  const getHmacJson = parseHmacEncodedJson(dataEncoded || '')
  return defer({
    getRating,
    getHmacJson,
  })
}) satisfies LoaderFunction

export const ticketRatingWrapperRouteAction = (async ({ request }: RouteFunctionParams) => {
  const ticketRatingData = await request.json()
  console.log('ticket data', ticketRatingData)
  const client = useQsAdminApiManager.getState().client

  const httpPostConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const res = await client.post('/api/ticket/rating', ticketRatingData, httpPostConfig)
  console.log(res)
  return res
}) satisfies ActionFunction

const TicketRatingRoute: RouteObject = {
  path: '/ticketrating/:data',
  loader: ticketRatingWrapperRouteLoader,
  action: ticketRatingWrapperRouteAction,
  element: <TicketRatingWrapper />,
  errorElement: <TicketRatingErrorHandler />,
}

export default TicketRatingRoute
