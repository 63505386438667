import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import OfferCreatorRoute from '@/routes/offers/OfferCreator.route'
import OfferEditorRoute from '@/routes/offers/OfferEditor.route'
import OffersPage from '@/routes/offers/OffersPage'
import { RoleRoute, RoleRouteObject, RouteFunctionParams } from '@/types'
import { defer, LoaderFunction } from 'react-router-typesafe'

export const offersPageRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  return defer({
    aziende: await getAziende,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Supervisor,
      Roles.Sales,
      Roles.ExternalSales,
    ],
  })
}) satisfies LoaderFunction

export const OffersPageRoute: RoleRoute = {
  path: 'offers',
  children: [
    {
      index: true,
      element: <OffersPage />,
      loader: offersPageRouteLoader,
      allowedRoles: [
        Roles.Sales,
        Roles.ExternalSales,
        Roles.Administrator,
        Roles.GlobalAdministrator,
        Roles.Marketing,
        Roles.Supervisor,
      ],
    } as RoleRouteObject,
    OfferCreatorRoute,
    OfferEditorRoute,
  ],
  errorElement: <RouterErrorHandler />,
  text: 'Offerte',
  icon: 'money',
  mainMenuRoute: true,
  allowedRoles: [
    Roles.Sales,
    Roles.ExternalSales,
    Roles.Administrator,
    Roles.GlobalAdministrator,
    Roles.Marketing,
    Roles.Supervisor,
  ],
}
