import { ArraySourceComposer } from '@/auth/api/connector'
import { AzureUserInfo } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import DateColumn from '@/components/date-column/DateColumn'
import { GridCellColor } from '@/enums'
import { contatto_aziendale, entita_aziendale, offerta } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { isUserRoleAllowed } from '@/routes/utils'
import { useScreenSize } from '@/themes/media-query'
import { DataGrid } from 'devextreme-react'
import { Column, Paging, Button as GridButton, StateStoring, DataGridTypes } from 'devextreme-react/cjs/data-grid'
import { CellPreparedEvent, ColumnButtonClickEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import { Link, useNavigate } from 'react-router-dom'

const ProjectEditorFormOffersGrid = (props: { offers?: offerta[]; userInfo: AzureUserInfo | undefined }) => {
  const { offers, userInfo } = props
  const currentScreenSize = useScreenSize()
  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.nome' && e.value) {
      switch (e.value) {
        case 'PERSA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.SALMON}`

          break
        }
        case 'APERTA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`

          break
        }
        case 'ORDINATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.LILAC}`

          break
        }
        case 'SUPERATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.ORANGE}`

          break
        }
      }
    }
  }

  const calculateRiferimentiValue = (rowData: offerta) => {
    let riferimenti: string = ''
    rowData.riferimenti?.map(
      (contatto: contatto_aziendale) => (riferimenti = `${riferimenti + contatto.fullname?.toUpperCase()} `),
    )
    rowData.riferimenti_entita?.map(
      (contatto: entita_aziendale) => (riferimenti = `${riferimenti + contatto.nome?.toUpperCase()} `),
    )
    return riferimenti.trim()
  }

  return (
    <>
      <DataGrid
        id={`project-offers-grid`}
        dataSource={ArraySourceComposer(
          'id',
          offers
            ? offers.sort((offerA: offerta, offerB: offerta) => {
                const dataA: Date = new Date(offerA.data_creazione ?? '')
                const dataB: Date = new Date(offerB.data_creazione ?? '')
                return dataB.getTime() - dataA.getTime()
              })
            : [],
        )}
        noDataText="Nessun' offerta associata alla commessa"
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onCellPrepared={onCellPrepared}
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') window.open(`/offers/${e.data.id}`, '_blank')
        }}
      >
        <StateStoring enabled={true} type="localStorage" storageKey={'project-offers-datagrid'} savingTimeout={50} />
        <Paging defaultPageSize={8} />
        <Column
          type="buttons"
          width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
          alignment="left"
        >
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              window.open(`/offers/${e.row?.data.id}`, '_blank')
            }}
          />
        </Column>
        <Column
          dataField={'id'}
          caption={'ID'}
          visible={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
          }
        />
        ,
        <Column
          dataField={'ded_Dis'}
          caption={'CODICE'}
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: offerta = cellData.row.data
            return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
              <>
                <Link to={`/offers/${data.id}`}>{data.ded_Dis}</Link>
              </>
            ) : (
              <>{data.ded_Dis}</>
            )
          }}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
          }
        />
        ,
        <Column
          dataField={'revisione'}
          caption={'REV'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined
          }
        />
        ,
        <Column
          dataField={'autore.fullname'}
          caption={'AUTORE'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
          }
        />
        <Column
          name={'info_riferimenti'}
          caption={'RIFERIMENTI'}
          calculateCellValue={calculateRiferimentiValue}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
          }
        />
        {DateColumn({
          dataField: 'data_creazione',
          caption: 'DATA',
          format: 'dd/MM/yyyy',
          hidingPriority:
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined,
        })}
        <Column
          dataField={'note'}
          caption={'NOTE'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
          }
        />
        <Column
          dataField={'stato.nome'}
          caption={'STATO'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
          }
        />
      </DataGrid>
    </>
  )
}

export default ProjectEditorFormOffersGrid
