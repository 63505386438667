import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { ArraySourceComposer } from '@/auth/api/connector'
import useTokenRefresh, { useAzureManager } from '@/auth/azure/azureManager'
import { Lookup } from 'devextreme-react'
import PieChart, {
  Series,
  Label,
  Connector,
  Export as PieChartExport,
  Font,
  Legend,
  PieChartTypes,
} from 'devextreme-react/pie-chart'
import DataSource from 'devextreme/data/data_source'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { useEffect, useState } from 'react'
import { Duration } from 'luxon'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { CostCentersPieProps } from '@/routes/costcenters/CostCentersPie.types'

export const CostCentersPie = (props: CostCentersPieProps) => {
  const token = useTokenRefresh()
  const criteri = [
    { id: 'centro_costo.nome', nome: 'CENTRO DI COSTO' },
    { id: 'piattaforma.nome', nome: 'PIATTAFORMA' },
    { id: 'task.ded_Dis', nome: 'TASK' },
    { id: 'sede.azienda.nome', nome: 'AZIENDA' },
    { id: 'impiegato.fullname', nome: 'IMPIEGATO' },
  ]

  const [currentCriteria, setCurrentCriteria] = useState<{ id: string; nome: string }>(criteri[0])

  //array finale di oggetti passato al grafico
  const [graphItems, setGraphItems] = useState<{ key: string; ore: number }[]>([])

  useEffect(() => {
    if (!token) return

    const newDataSource = new DataSource({
      store: new ODataStore({
        url: `${import.meta.env.VITE_QSADMINAPI_HOST}/attivita_lavorativa`,
        key: 'id',
        keyType: 'Int32',
        version: 4,
        errorHandler: (e) => {
          console.error(e.errorDetails)
          if (!e.errorDetails) return
          notify(
            {
              message: `Errore : ${e.errorDetails?.message}`,
              type: 'error',
              displayTime: 5000,
            },
            {
              position: 'bottom center',
              direction: 'up-push',
            },
          )
        },
        beforeSend: ODataStoreRequestConfiguration(token),
      }),
      filter: props.filter?.length > 0 ? props.filter : null,
      select: [`${currentCriteria.id}`, 'tempo_cliente', 'tempo_trasferta', 'tempo_ufficio'],
      group: { selector: currentCriteria.id, desc: true },
      paginate: false,
    })

    newDataSource
      .load()
      .then((data) => {
        // Transform data into the format required for graphItems
        const transformedData = data.map((item: { key: any; items: any[] }) => ({
          key: item.key,
          ore: item.items.reduce(
            (acc: number, subItem: { tempo_cliente: string; tempo_trasferta: string; tempo_ufficio: string }) => {
              return (
                acc +
                Duration.fromISO(subItem.tempo_cliente).hours +
                Duration.fromISO(subItem.tempo_cliente).minutes / 60 +
                Duration.fromISO(subItem.tempo_trasferta).hours +
                Duration.fromISO(subItem.tempo_trasferta).minutes / 60 +
                Duration.fromISO(subItem.tempo_ufficio).hours +
                Duration.fromISO(subItem.tempo_ufficio).minutes / 60
              )
            },
            0,
          ),
        }))
        // Update the state with the new graph items
        setGraphItems(transformedData)
      })
      .catch((error) => {
        console.error('Data source loading error:', error)
      })
  }, [token, currentCriteria, props.filter]) // Consolidated useEffect to handle both DataSource creation and loading

  const pointClickHandler = (e: PieChartTypes.PointClickEvent) => {
    toggleVisibility(e.target)
  }

  const legendClickHandler = (e: PieChartTypes.LegendClickEvent) => {
    const arg = e.target
    const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0]
    toggleVisibility(item)
  }

  const toggleVisibility = (item: any) => {
    item.isVisible() ? item.hide() : item.show()
  }
  return (
    <>
      <Lookup
        dataSource={ArraySourceComposer('id', criteri)}
        placeholder="Criterio"
        displayExpr="nome"
        value={currentCriteria}
        dropDownCentered={true}
        dropDownOptions={{ showTitle: true, title: 'Criterio', hideOnOutsideClick: true }}
        onValueChanged={(e: ValueChangedEvent) => {
          console.log('VALUE CHANGED', e.value)
          setCurrentCriteria(e.value)
        }}
      />
      <PieChart
        id="centerCostPie"
        dataSource={graphItems}
        palette="Bright"
        resolveLabelOverlapping="shift"
        onPointClick={pointClickHandler}
        onLegendClick={legendClickHandler}
      >
        <Series argumentField="key" valueField="ore">
          <Label
            visible={true}
            customizeText={(arg: { argumentText: string; valueText: string; percentText: string }) => {
              return `${arg.argumentText} ${arg.valueText} ore (${arg.percentText})`
            }}
          >
            <Font size={12} />
            <Connector visible={true} width={1} />
          </Label>
        </Series>
        <Legend itemTextPosition="right" />
        <PieChartExport enabled={true} />
      </PieChart>
    </>
  )
}
