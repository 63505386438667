import ClientSitesEditor from '@/routes/clients/client/sites/ClientSitesEditor'
import { RouteFunctionParams, RoleRoute, RoleRouteObject } from '@/types'
import { ActionFunction, LoaderFunction, defer } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Navigate } from 'react-router'
import { Roles } from '@/auth/azure/Roles'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { redirect } from 'react-router-dom'

const clientSiteEditorRouteAction = (async ({ request, params }: RouteFunctionParams<'siteId' | 'clientId'>) => {
  console.log('Called clientSiteEditorRouteAction')
  if (params.siteId) {
    const siteId = +params.siteId
    console.log('siteId', siteId)
    console.log('request.method', request.method)
    switch (request.method) {
      case 'DELETE': {
        return useQsAdminApiManager.getState().service.sede(siteId).patch({ archived: true })
      }
      case 'PATCH':
      case 'PUT':
      case 'POST': {
        return useQsAdminApiManager
          .getState()
          .service.sede(siteId)
          .patch(await request.json())
      }
      default: {
        throw new Response('', { status: 405 })
      }
    }
  } else {
    console.log('Wrong parameter value.\nExpected a number, got', params.siteId)
    throw new Response(`Wrong parameter value.\nExpected a number, got ${params.siteId}`, { status: 400 })
  }
}) satisfies ActionFunction

const clientSiteCreatorRouteAction = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  return await useQsAdminApiManager
    .getState()
    .service.sede()
    .create(await request.json())
}) satisfies ActionFunction

export const clientSiteEditorRouteLoader = (async ({ params }: RouteFunctionParams<'clientId'>) => {
  const clientId = Number(params.clientId)
  const qsAdminApi = useQsAdminApiManager.getState().service
  const getAzienda = qsAdminApi.azienda(clientId).query()
  const getSedi = await qsAdminApi.sede().query((builder, qsede) => {
    builder.filter(qsede.azienda.props.id.eq(clientId)).orderBy(qsede.azienda.props.id.asc())
  })
  return defer({
    getAzienda,
    getSedi,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Supervisor,
      Roles.Sales,
      Roles.ExternalSales,
    ],
  })
}) satisfies LoaderFunction

export const ClientSitesEditorRoute = {
  path: 'sites',
  element: <ClientSitesEditor />,
  loader: clientSiteEditorRouteLoader,
  children: [
    {
      path: 'new',
      action: clientSiteCreatorRouteAction,
      loader: () => redirect('./../'),
      allowedRoles: [
        Roles.GlobalAdministrator,
        Roles.Administrator,
        Roles.Supervisor,
        Roles.Sales,
        Roles.ExternalSales,
      ],
    } as RoleRouteObject,
    {
      path: ':siteId',
      action: clientSiteEditorRouteAction,
      loader: () => redirect('./../'),
      allowedRoles: [
        Roles.GlobalAdministrator,
        Roles.Administrator,
        Roles.Supervisor,
        Roles.Sales,
        Roles.ExternalSales,
      ],
    } as RoleRouteObject,
  ],
  text: 'Sedi',
  icon: 'globe',
  allowedRoles: [Roles.Guest],
} satisfies RoleRoute
