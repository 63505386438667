import { getAzureUserInformation } from '@/auth/azure/azureManager'
import DashboardPage from './DashboardPage'
import { RoleRoute, RouteFunctionParams } from '@/types'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'

export const dashboardPageRouterLoader = (async ({ request, params }: RouteFunctionParams) => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  try {
    const userInfo = await getAzureUserInformation()
    const getCurrentEmployee = qsAdminApi.user().query((builder, impiegato) => {
      builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
      builder.select('id', 'fullname', 'email')
    })
    return defer({
      getCurrentEmployee,
    })
  } catch (error) {
    console.log(`Auth Error: ${error}`)
    return {
      getCurrentEmployee: undefined,
    }
  }
}) satisfies LoaderFunction

const DashboardPageRoute: RoleRoute = {
  path: 'dashboard',
  loader: dashboardPageRouterLoader,
  element: <DashboardPage />,
  errorElement: <RouterErrorHandler />,
  text: 'Dashboard',
  icon: 'datapie',
  mainMenuRoute: true,
  allowedRoles: [Roles.Guest],
}

export default DashboardPageRoute
