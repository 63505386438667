import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { SoftwareQsCreatorRoute, SoftwareQsEditorRoute } from '@/routes/software/qs/SoftwareQsEditor.route'
import SoftwareQsGrid from '@/routes/software/qs/SoftwareQsGrid'
import { LoaderFunction } from 'react-router-dom'
import { defer } from 'react-router-typesafe'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { Roles } from '@/auth/azure/Roles'

export const softwareQsLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getAziende = qsAdminApi.azienda().query((builder, qazienda) => {
    builder.orderBy(qazienda.nome.asc())
    builder.select('nome', 'id')
  })

  const getStatiLicenza = qsAdminApi.stato_qs_licenza().query((builder, qstato_qs_licenza) => {
    builder.select('id', 'nome')
    builder.orderBy(qstato_qs_licenza.nome.asc())
  })

  const getVersioni = qsAdminApi.qs_versione().query((builder, qqs_versione) => {
    builder.filter(qqs_versione.minor.eq(null))
    builder.filter(qqs_versione.sub.eq(null))
    builder.orderBy(qqs_versione.major.desc())
  })

  const getTipiLicenza = qsAdminApi.tipologia_qs_licenza().query((builder, tipologia_qs_licenza) => {
    builder.select('id', 'nome')
    builder.orderBy(tipologia_qs_licenza.nome.asc())
  })

  const getApplicazioni = qsAdminApi.qs_applicazione().query((builder, applicazione) => {
    builder.select('id', 'nome')
    builder.orderBy(applicazione.nome.asc())
  })

  console.log('swqs loader fired')
  return defer({
    aziendaList: await getAziende,
    qsStatoLicenzaList: await getStatiLicenza,
    qsVersioneList: await getVersioni,
    qsTipiLicenzaList: await getTipiLicenza,
    qsApplicazioniList: await getApplicazioni,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor],
  })
}) satisfies LoaderFunction

export const SoftwareQsRoute = {
  path: 'qs',
  icon: 'codeblock',
  children: [
    {
      index: true,
      element: <SoftwareQsGrid />,
      loader: softwareQsLoader,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    SoftwareQsCreatorRoute,
    SoftwareQsEditorRoute,
  ],
  text: 'Software Qs',
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
