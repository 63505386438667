import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { ordine, task } from '@/model/qsadminapi/QsAdminApiModuleModel'
import TaskEditor from '@/routes/tasks/TaskEditor'
import { StatoTask } from '@/routes/tasks/TaskEditor.enums'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { redirect } from 'react-router-dom'
import { defer, LoaderFunction, ActionFunction } from 'react-router-typesafe'
import { ODataCollectionResponseV4 } from '@odata2ts/odata-core'
import { ODataResponse } from '@odata2ts/http-client-api'
import { Roles } from '@/auth/azure/Roles'

export const taskEditorRouteLoader = (async ({ request, params }: RouteFunctionParams<'taskId' | 'clientId'>) => {
  const taskId = params.taskId
  const clientId = params.clientId

  const qsAdminApi = useQsAdminApiManager.getState().service
  const userInfo = await getAzureUserInformation()

  let getOrdini: ODataResponse<ODataCollectionResponseV4<ordine>> | undefined

  let task: task

  if (taskId) {
    //editor
    const getTask = await qsAdminApi.task(Number(taskId)).query((builder, task) => {
      builder.expanding('sede', (sedeBuilder, sede) => {
        sedeBuilder.expanding('azienda', (aziendaSedeBuilder, aziendaSede) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
            sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi')
        })
        sedeBuilder.select('id', 'nome', 'azienda', 'note')
      })
      builder.expanding('resp_tecnico', (respBuilder, resp) => {
        respBuilder.select('id', 'fullname', 'email')
      })
      builder.expanding('stato', (statoBuilder, stato) => {
        statoBuilder.select('id', 'nome')
      })
      builder.expanding('proprietario', (proprietarioBuilder, proprietario) => {})
      builder.expanding('ordine', (ordineBuilder, ordine) => {
        ordineBuilder.expanding('offerta', (offertaOrdineBuilder, offertaOrdine) => {
          offertaOrdineBuilder.expand('analisi')
        })
        ordineBuilder.select('id', 'ded_Dis', 'offerta', 'note')
      })
      builder.expanding('commessa', (commessaBuilder, commessa) => {
        commessaBuilder.select('id', 'ded_Dis', 'titolo')
      })
      builder.expanding('tecnici', (tecniciBuilder, tecnico) => {})
    })
    task = getTask.data satisfies task
    if (clientId && task?.sede?.azienda && task?.sede?.azienda.id !== Number(clientId)) {
      throw new Error(
        `Il task ${task.ded_Dis} appartiene al cliente ${task?.sede?.azienda.nome} e non al cliente corrente`,
      )
    }
  } else {
    //creator
    const getAutore = await qsAdminApi.user().query((builder, impiegato) => {
      builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
      builder.select('id', 'fullname')
    })

    const getStatoAperta = await qsAdminApi.stato_task(StatoTask.APERTO).query((builder, stato) => {
      builder.select('id', 'nome')
    })

    task = {
      id: 0,
      ded_Dis: '',
      attachmentsFolderUrl: '',
      ded_RootFam: '',
      ded_SubFam: '',
      ded_Id: 0,
      consegna_prevista: null,
      apertura: new Date().toISOString(),
      concluso: false,
      chiusura: null,
      note: null,
      note_completamento: null,
      ultima_modifica: null,
      avviso_completamento: false,
      appuntamento_outlook: null,
      proprietario: getAutore.data.value[0],
      stato: getStatoAperta.data,
      sede: null,
      anno_rif: new Date().getFullYear(),
    }
  }

  //se il task non ha il proprietario (caso raro che succede per i pochi task creati appena prima dell'import) lo imposto di default
  if (!task.proprietario) {
    const getAutore = await qsAdminApi.user().query((builder, impiegato) => {
      builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
      builder.select('id', 'fullname')
    })
    task.proprietario = getAutore.data.value[0]
  }

  //query di popolazione lookup
  if (task.sede) {
    getOrdini = qsAdminApi.ordine().query((builder, ordine) => {
      builder.filter(ordine.commessa.props.sede.props.id.eq(Number(task.sede?.id)))
      builder.expanding('commessa', (commessaBuilder, commessa) => {
        commessaBuilder.select('id', 'ded_Dis', 'titolo')
      })
      builder.select('id', 'ded_Dis', 'note', 'commessa')
      builder.orderBy(ordine.ded_Dis.desc())
    })
  }

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.filter(azienda.sedi.any())
    builder.filter(clientId ? azienda.id.eq(Number(clientId)) : null)
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
  })

  const getStati = qsAdminApi.stato_task().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const getQsImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  return defer({
    task,
    getAziende,
    getStati,
    getQsImpiegati,
    getOrdini,
    userInfo,
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor, Roles.TechDeveloper],
  })
}) satisfies LoaderFunction

export const taskEditorRouteAction = (async ({ request, params }: RouteFunctionParams) => {
  const task = (await request.json()) as task
  console.log('task', task)
  const qsAdminApi = useQsAdminApiManager.getState().service

  switch (request.method) {
    case 'POST': {
      task.commessa = task.ordine?.commessa
      const res = await qsAdminApi.task().create(task)
      console.log(res)
      return redirect(`../${res.data.id}`)
    }
    case 'PUT':
    case 'PATCH': {
      return await qsAdminApi.task(task.id).update(task)
    }
    default: {
      throw new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

const TaskEditorRoute = {
  path: ':taskId',
  element: <TaskEditor creating={false} />,
  loader: taskEditorRouteLoader,
  action: taskEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default TaskEditorRoute
