import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import {
  analisi,
  commessa,
  contatto_aziendale,
  entita_aziendale,
  offerta,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import OfferEditor from '@/routes/offers/OfferEditor'
import { StatoOfferta, TemplateOfferta } from '@/routes/offers/OfferEditor.enums'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { redirect } from 'react-router-dom'
import { LoaderFunction, ActionFunction, defer } from 'react-router-typesafe'
import { ODataCollectionResponseV4, ODataModelResponseV4 } from '@odata2ts/odata-core'
import { ODataResponse, HttpResponseModel } from '@odata2ts/http-client-api'
import { Roles } from '@/auth/azure/Roles'
import { useAccount } from '@azure/msal-react'
import { Qofferta } from '@/model/qsadminapi/QQsAdminApiModule'

export const offerEditorRouteLoader = (async ({ request, params }: RouteFunctionParams<'offerId' | 'clientId'>) => {
  const offerId = params.offerId
  const clientId = params.clientId
  //parametro analisi id presente quando si crea offerta da analisi
  const analysisId = new URL(request.url).searchParams.get('analysisId')
  console.log('analisi id', analysisId)

  const qsAdminApi = useQsAdminApiManager.getState().service

  let getEntita: ODataResponse<ODataCollectionResponseV4<entita_aziendale>> | undefined
  let getPersone: ODataResponse<ODataCollectionResponseV4<contatto_aziendale>> | undefined
  let getAnalisi: ODataResponse<ODataCollectionResponseV4<analisi>> | undefined
  let getCommesse: ODataResponse<ODataCollectionResponseV4<commessa>> | undefined

  let offer: offerta

  if (offerId) {
    //editor
    const getOffer = await qsAdminApi.offerta(Number(offerId)).query((builder, offerta) => {
      builder.expanding('sede', (sedeBuilder, sede) => {
        sedeBuilder.expanding('azienda', (aziendaSedeBuilder, aziendaSede) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
            sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaSedeBuilder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
            rivenditoreBuilder.select('id', 'nome')
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi', 'rivenditore')
        })
        sedeBuilder.select('id', 'nome', 'azienda', 'note')
      })
      builder.expanding('riferimenti', (riferimentiBuilder, contatto) => {
        riferimentiBuilder.select('id', 'fullname')
        riferimentiBuilder.orderBy(contatto.fullname.asc())
      })
      builder.expanding('riferimenti_entita', (riferimentiEntitaBuilder, contatto) => {
        riferimentiEntitaBuilder.select('id', 'nome')
        riferimentiEntitaBuilder.orderBy(contatto.nome.asc())
      })
      builder.expanding('stato', (statoBuilder, stato) => {
        statoBuilder.select('id', 'nome')
      })
      builder.expanding('autore', (autoreBuilder, impiegato) => {
        autoreBuilder.select('id', 'fullname')
      })
      builder.expanding('tipologia_pagamento', (tipologiaPagamentoBuilder, tipologia) => {
        tipologiaPagamentoBuilder.select('id', 'nome')
      })
      builder.expanding('analisi', (analisiBuilder, analisi) => {
        analisiBuilder.select('id', 'ded_Dis')
      })
      builder.expanding('commessa', (commessaBuilder, commessa) => {
        commessaBuilder.select('id', 'titolo', 'ded_Dis')
      })
      builder.expanding('ordini', (ordiniBuilder, ordine) => {
        ordiniBuilder.select('id', 'ded_Dis')
      })
      builder.expanding('template', (templateBuilder, template) => {
        templateBuilder.select('id', 'nome')
      })
    })
    offer = getOffer.data satisfies offerta
    if (clientId && offer?.sede?.azienda && offer?.sede?.azienda.id !== Number(clientId)) {
      throw new Error(
        `L'offerta ${offer.ded_Dis} appartiene al cliente ${offer?.sede?.azienda.nome} e non al cliente corrente`,
      )
    }
  } else {
    //creator
    let getAnalysis: HttpResponseModel<ODataModelResponseV4<analisi>> | undefined
    if (analysisId) {
      //creator a partire da analisi quidi recupero analisi
      getAnalysis = await qsAdminApi.analisi(Number(analysisId)).query((builder, analysis) => {
        builder.expanding('sede', (sedeBuilder, sede) => {
          sedeBuilder.expanding('azienda', (aziendaSedeBuilder, aziendaSede) => {
            aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
              sediAziendaSedeBuilder.select('id', 'nome', 'note')
              sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
            })
            aziendaSedeBuilder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
              rivenditoreBuilder.select('id', 'nome')
            })
            aziendaSedeBuilder.select('id', 'nome', 'sedi', 'rivenditore')
          })
          sedeBuilder.select('id', 'nome', 'azienda', 'note')
        })
        builder.expanding('riferimenti', (riferimentiBuilder, contatto) => {
          riferimentiBuilder.select('id', 'fullname')
        })
        builder.expanding('riferimenti_entita', (riferimentiEntitaBuilder, contatto) => {
          riferimentiEntitaBuilder.select('id', 'nome')
        })
        builder.select('id', 'sede', 'riferimenti', 'riferimenti_entita', 'ded_Dis')
      })
    }

    const userInfo = await getAzureUserInformation()
    const getAutore = await qsAdminApi.user().query((builder, impiegato) => {
      builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
      builder.select('id', 'fullname')
    })

    const getStatoDefault = await qsAdminApi.stato_offerta(StatoOfferta.APERTA).query((builder, stato) => {
      builder.select('id', 'nome')
    })

    const getTemplateDefault = await qsAdminApi.template_offerta(TemplateOfferta.QS).query((builder, template) => {
      builder.select('id', 'nome')
    })

    offer = {
      id: 0,
      ded_Dis: '',
      ded_RootFam: '',
      ded_SubFam: '',
      ded_Rev: '',
      ded_Id: 0,
      revisione: 0,
      data_creazione: new Date().toISOString(),
      note: null,
      anno_rif: new Date().getFullYear(),
      data_invio: null,
      creazione_automatica: null,
      stato: getStatoDefault.data,
      autore: getAutore.data.value[0],
      sede: getAnalysis ? getAnalysis.data.sede : null,
      riferimenti: getAnalysis ? getAnalysis.data.riferimenti : [],
      riferimenti_entita: getAnalysis ? getAnalysis.data.riferimenti_entita : [],
      analisi: getAnalysis ? getAnalysis.data : null,
      commessa: null,
      attachmentsFolderUrl: '',
      mainDocumentUrl: '',
      template: getTemplateDefault.data,
      attivitaTecnicaRichiesta: false,
    }
  }

  //query di popolazione lookup
  if (offer.sede) {
    getPersone = qsAdminApi.contatto_aziendale().query((builder, persona) => {
      builder.filter(persona.sede.props.id.eq(Number(offer.sede?.id)))
      builder.filter(
        persona.attivo
          .eq(true)
          .or(offer.riferimenti ? persona.offerte.any((offerta: Qofferta) => offerta.id.eq(offer.id)) : null),
      )
      builder.select('id', 'fullname')
      builder.orderBy(persona.fullname.asc())
    })
    getEntita = qsAdminApi.entita_aziendale().query((builder, entita) => {
      builder.filter(entita.sede.props.id.eq(Number(offer.sede?.id)))
      builder.select('id', 'nome')
      builder.orderBy(entita.nome.asc())
    })
    getAnalisi = qsAdminApi.analisi().query((builder, analisi) => {
      builder.filter(analisi.sede.props.id.eq(Number(offer.sede?.id)))
      // builder.select('id', 'ded_Dis')
      // builder.orderBy(analisi.ded_Dis.desc())
    })
    getCommesse = qsAdminApi.commessa().query((builder, commessa) => {
      builder.filter(commessa.sede.props.id.eq(Number(offer.sede?.id)))
      builder.select('id', 'ded_Dis', 'titolo')
      builder.orderBy(commessa.id.desc())
    })
  }

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.filter(azienda.sedi.any())
    builder.filter(clientId ? azienda.id.eq(Number(clientId)) : null)
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
  })

  const getStati = qsAdminApi.stato_offerta().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const getQsImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  const getTemplates = qsAdminApi.template_offerta().query((builder, template) => {
    builder.select('id', 'nome')
    builder.orderBy(template.nome.asc())
  })

  const userInfo = await getAzureUserInformation()

  const getCurrentUser = qsAdminApi.user().query((builder, user) => {
    builder.select('id', 'nome', 'email', 'azienda')
    builder.expanding('azienda', (riferimentiBuilder, azienda) => {
      riferimentiBuilder.select('id', 'nome')
    })
    builder.filter(user.email.eq(userInfo?.email ?? ''))
  })

  return defer({
    offer,
    getAziende,
    getStati,
    getQsImpiegati,
    getPersone,
    getEntita,
    getAnalisi,
    getCommesse,
    getTemplates,
    getUserInfo: userInfo,
    getCurrentUser,
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Supervisor,
      Roles.Sales,
      Roles.ExternalSales,
    ],
  })
}) satisfies LoaderFunction

export const offerEditorRouteAction = (async ({ request, params }: RouteFunctionParams) => {
  const offer = (await request.json()) as offerta
  console.log('offerta', offer)
  const qsAdminApi = useQsAdminApiManager.getState().service

  switch (request.method) {
    case 'POST': {
      const res = await qsAdminApi.offerta().create(offer)
      console.log(res)
      return redirect(`../${res.data.id}`)
    }
    case 'PUT':
    case 'PATCH': {
      return await qsAdminApi.offerta(offer.id).update(offer)
    }
    default: {
      throw new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

const OfferEditorRoute = {
  path: ':offerId',
  element: <OfferEditor creating={false} />,
  loader: offerEditorRouteLoader,
  action: offerEditorRouteAction,
  allowedRoles: [
    Roles.Sales,
    Roles.ExternalSales,
    Roles.Administrator,
    Roles.GlobalAdministrator,
    Roles.Marketing,
    Roles.Supervisor,
  ],
} as RoleRouteObject

export default OfferEditorRoute
