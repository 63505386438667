import '@/themes/generated/variables.css'
import { Outlet } from 'react-router-dom'
import { useScreenSizeClass } from '@/themes/media-query'
import React, { StrictMode, useEffect } from 'react'
import { InteractionType, IPublicClientApplication } from '@azure/msal-browser'
import { HashLoader } from 'react-spinners'
import '@/routes/authenticated/AuthenticatedLayout.scss'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { SideNavOuterToolbar } from '@/layouts'
import { useNavigate } from 'react-router'

const MsalErrorComponent = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/login')
  }, [navigate])
  return (
    <>
      <p>MsalErrorComponent rendering...</p>
    </>
  )
}

export const MsalLoadingComponent = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      {/*make the color relative to the scss*/}
      <HashLoader size={'200px'} loading={true} className={'spinner'} color={'#ff5722'} />
    </div>
  )
}

export const AuthenticatedLayout = () => {
  const [title, setTitle] = React.useState('Qs Web Admin')
  return (
    <div className={`app ${useScreenSizeClass}`}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={MsalErrorComponent}
        loadingComponent={MsalLoadingComponent}
        authenticationRequest={{
          scopes: ['openid', 'profile'],
        }}
      >
        <SideNavOuterToolbar title={title}>
          <StrictMode>
            <Outlet />
          </StrictMode>
        </SideNavOuterToolbar>
      </MsalAuthenticationTemplate>
    </div>
  )
}
