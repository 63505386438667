import { InterventionEditorFormProps } from '@/routes/interventions/InterventionEditor.types'
import { ArraySourceComposer } from '@/auth/api/connector'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import {
  azienda,
  contatto_aziendale,
  entita_aziendale,
  rapporto,
  sede,
  task,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useRevalidator, useSearchParams } from 'react-router-dom'
import DataSource from 'devextreme/data/data_source'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ValidationCallbackData } from 'devextreme/common'
import { AxiosError, AxiosResponse } from 'axios'
import saveAs from 'file-saver'
import notify from 'devextreme/ui/notify'
import { ClickEvent } from 'devextreme/ui/button'
import { customButton, isUserRoleAllowed, toolbarHtmlEditor } from '@/routes/utils'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { ValueChangedEvent as ValueChangedEventSwitch } from 'devextreme/ui/switch'
import SiteSimpleItem from '@/components/site-simple-item/SiteSimpleItem'
import Popup, { PopupRef } from 'devextreme-react/cjs/popup'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import ContactEditorForm from '@/routes/contacts/ContactEditor.form'
import { isIPerson, onEmailsCreate } from '@/routes/contacts/utils'
import { StatoTask } from '@/routes/tasks/TaskEditor.enums'
import { useScreenSize } from '@/themes/media-query'
import { Roles } from '@/auth/azure/Roles'

const InterventionEditorForm = (props: InterventionEditorFormProps) => {
  const {
    creating,
    intervention,
    clients,
    billingStates,
    technicians,
    references,
    tasks,
    isRoleAllowed,
    userInfo,
    fetcher,
  } = props
  const now = new Date()
  const [searchParams] = useSearchParams()
  const taskId = searchParams.get('taskId')
  const { clientId, packageUsageId } = useParams()
  const { service, client } = useQsAdminApiManager()
  const currentScreenSize = useScreenSize()
  const revalidator = useRevalidator()
  const formRef = useRef<FormRef>(null)
  const popupNoteClienteRef = useRef<PopupRef>(null)
  const [remote, setRemote] = useState<boolean | undefined>(intervention?.remote)
  const [currentContact, setCurrentContact] = useState<contatto_aziendale>()
  const [currentRapporti, setCurrentRapporti] = useState<rapporto[]>()
  const refNuovoContattoPopup = useRef<PopupRef>(null)
  const getRapporti = async () => {
    const rapportiData = await service.rapporto().query((builder, rapporto) => {
      builder.select('id', 'nome')
      builder.orderBy(rapporto.nome.asc())
    })
    return rapportiData.data.value
  }

  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId
      ? clients
        ? clients[0]
        : undefined
      : intervention && 'sede' in intervention
        ? intervention?.sede?.azienda
        : undefined,
  )
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId && creating ? true : false)
  const [currentSite, setCurrentSite] = useState<sede | null | undefined>()
  const [currentSiteChanged, setCurrentSiteChanged] = useState<boolean>(false)
  const [sediDataSource, setSediDataSource] = useState<DataSource>(
    ArraySourceComposer('id', intervention && 'sede' in intervention ? intervention?.sede?.azienda?.sedi : []),
  )
  const [tasksDataSource, setTasksDataSource] = useState<DataSource>(ArraySourceComposer('id', tasks))
  const [referencesDataSource, setReferencesDataSource] = useState<DataSource>(ArraySourceComposer('id', references))

  const [loadPanelVisible, setLoadPanelVisible] = useState(false)

  const localeItems = [
    { id: 'it', value: 'Italiano' },
    { id: 'en', value: 'Inglese' },
  ]

  const getCompanySites = useCallback(
    async (companyId: number) => {
      const sitesData = await service.sede().query((builder, sede) => {
        builder.filter(sede.azienda.props.id.eq(companyId))
        builder.orderBy(sede.principale.desc())
        builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
            sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi')
        })
        builder.select('id', 'nome', 'azienda', 'note')
      })
      return sitesData.data.value
    },
    [service],
  )

  const getSiteTasks = useCallback(
    async (siteId: number | undefined) => {
      const tasksData = await service.task().query((builder, task) => {
        builder.filter(task.sede.props.id.eq(Number(siteId)))
        builder.filter(task.stato.props.id.eq(StatoTask.APERTO))
        builder.select('id', 'ded_Dis')
        builder.orderBy(task.id.desc())
      })
      return tasksData.data.value
    },
    [service],
  )

  const getSiteReferences = useCallback(
    async (siteId: number | undefined) => {
      const referencesData = await service.contatto_aziendale().query((builder, contatto) => {
        builder.filter(contatto.sede.props.id.eq(Number(siteId)))
        builder.filter(contatto.attivo.eq(true))
        builder.expanding('emails', (emailsBuilder, email) => {
          emailsBuilder.select('id', 'email')
        })
        builder.select('id', 'fullname', 'emails')
        builder.orderBy(contatto.fullname.asc())
      })
      return referencesData.data.value
    },
    [service],
  )

  useEffect(() => {
    if (!currentSiteChanged) return
    console.log('site effect')
    if (intervention?.sede) {
      getSiteTasks(intervention.sede.id).then((tasks: task[]) => {
        console.log('tasks', tasks)
        setTasksDataSource(ArraySourceComposer('id', tasks))
        formRef.current?.instance().updateData('task', null)
      })
      getSiteReferences(intervention.sede.id).then((ref: contatto_aziendale[]) => {
        console.log('ref', ref)
        setReferencesDataSource(ArraySourceComposer('id', ref))
        formRef.current?.instance().updateData('riferimenti_cliente', [])
      })
    }
    setCurrentSiteChanged(false)
  }, [currentSiteChanged, intervention?.sede, getSiteTasks, getSiteReferences])

  const handleOnClickExportPDF = async (e: ClickEvent) => {
    setLoadPanelVisible(true)
    client
      .get('/api/intervento/exportpdf', {
        params: { interventoId: Number(intervention?.id) },
        headers: {
          'Content-Disposition': `attachment;filename=${intervention?.ded_Dis}.pdf`,
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse) => {
        console.log('response', response.data)
        saveAs(new Blob([response.data], { type: 'application/octet-stream' }), `${intervention?.ded_Dis}.pdf`)
      })
      .catch((error: AxiosError) => {
        notify(`Errore esportazione documento. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        setLoadPanelVisible(false)
      })
  }

  const handleOnClickSendEmail = async (e: ClickEvent) => {
    setLoadPanelVisible(true)
    client
      .get('/api/intervento/sendemail', {
        params: { interventoId: Number(intervention?.id) },
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response: AxiosResponse) => {
        notify('Invio email effettuato con successo', 'success', 2000)
      })
      .catch((error: AxiosError) => {
        notify(`Errore invio email. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        setLoadPanelVisible(false)
      })
  }

  const handleOnClickFatturazione = async () => {
    if (intervention)
      service
        .intervento(intervention.id)
        .patch(intervention)
        .then(() => {
          notify('Aggiornamento intervento avvenuto con successo', 'success', 2000)
        })
        .catch((error) => {
          notify(`Errore aggiornamento intervento. Dettagli : ${error}`, 'error', 2000)
        })
  }

  const onContactCreate = async (newdata: contatto_aziendale | entita_aziendale): Promise<boolean> => {
    console.log('SAVING NEWDATA', newdata)
    if (isIPerson(newdata)) {
      await onEmailsCreate(newdata)
      console.log('CONTACT UPDATE BEFORE', newdata)

      await service
        .contatto_aziendale()
        .create(newdata)
        .then(async (result) => {
          notify(`Contatto creato con successo`, 'success', 2000)
          refNuovoContattoPopup.current?.instance().hide()
        })
        .catch((error) => {
          notify(error, 'error', 2000)
        })

      getSiteReferences(Number(intervention?.sede?.id)).then((contacts: contatto_aziendale[]) => {
        console.log('contacts', contacts)
        setReferencesDataSource(ArraySourceComposer('id', contacts))
      })
    }
    return true
  }
  const onContactReset = async (contactdata: contatto_aziendale | entita_aziendale): Promise<boolean> => {
    return true
  }

  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', currentCompany)
    if (currentCompany) {
      getCompanySites(currentCompany.id).then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        formRef.current?.instance().updateData('sede', sites[0])
        setCurrentSiteChanged(true)
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, currentCompany, getCompanySites])

  //trigger iniziale
  useEffect(() => {
    setCurrentCompany(
      clientId
        ? clients
          ? clients[0]
          : undefined
        : intervention && 'sede' in intervention
          ? intervention?.sede?.azienda
          : undefined,
    )
  }, [])

  useEffect(() => {
    if (remote !== undefined && remote !== null) formRef.current?.instance().validate()
  }, [remote])

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle' && !loadPanelVisible)} />
      <DXForm
        id={'intervention_form'}
        formData={intervention}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="interventionValidation"
        ref={formRef}
        disabled={intervention ? false : true}
        readOnly={packageUsageId !== undefined || !isRoleAllowed}
      >
        <GroupItem colCount={packageUsageId === undefined ? 5 : 2}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              label={{ text: 'Azienda' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton('open', (e: ClickEvent) => {
                    if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                    else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                  }),
                  { name: 'dropDown', location: 'after' },
                ],
                value: currentCompany,
                dataSource: ArraySourceComposer('id', clients),
                placeholder: "Seleziona l'azienda...",
                displayExpr: 'nome',
                searchEnabled: true,
                readOnly: !isRoleAllowed || (clientId ? true : packageUsageId !== undefined) || (creating && taskId),
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'azienda",
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                  if (e.value) {
                    setCurrentCompany(e.value)
                    setCurrentCompanyChanged(true)
                  }
                },
              }}
            >
              <RequiredRule message="Azienda obbligatoria"></RequiredRule>
            </SimpleItem>
            {SiteSimpleItem({
              dataSource: sediDataSource,
              onValueChanged: (e: ValueChangedEventSelectBox) => {
                if (e.previousValue?.id === e.value?.id) return
                console.log('SITE EVENT CHANGED', e.previousValue, e.value)
                if (e.value) setCurrentSiteChanged(true)
              },
              rules: [
                {
                  type: 'required',
                  message: `Sede obbligatoria`,
                },
              ],
              readOnly:
                !isRoleAllowed || packageUsageId !== undefined || (creating && taskId !== null && taskId !== undefined),
              onInfoClick: () => {
                if (intervention?.sede) {
                  setCurrentSite(intervention.sede)
                  popupNoteClienteRef.current?.instance().show()
                } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
              },
            })}
            <SimpleItem
              dataField="riferimenti_cliente"
              editorType="dxTagBox"
              label={{ text: 'Riferimenti' }}
              editorOptions={{
                buttons: [
                  customButton(
                    'add',
                    (e: ClickEvent) => {
                      if (intervention?.sede) {
                        const contact: contatto_aziendale = {
                          id: 0,
                          fullname: null,
                          ad_recipient: false,
                          nome: null,
                          cognome: null,
                          ruolo: null,
                          note: null,
                          titolo: null,
                          telefono: null,
                          attivo: true,
                          sede: intervention.sede,
                          emails: [
                            {
                              id: 0,
                              email: null,
                              note: null,
                              rif_ad: false,
                            },
                          ],
                        }
                        getRapporti().then((result: rapporto[]) => {
                          setCurrentRapporti(result)
                        })
                        setCurrentContact(contact)
                        refNuovoContattoPopup.current?.instance().show()
                      } else notify(`Deve essere selezionata una sede per poter inserire un contatto.`, 'warning', 3000)
                    },
                    !isRoleAllowed || packageUsageId !== undefined,
                  ),
                ],
                dataSource: referencesDataSource,
                // displayExpr: 'fullname',
                displayExpr: (item: contatto_aziendale) => {
                  let displayExpreValue: string = ''
                  if (item) {
                    displayExpreValue += item.fullname
                    if (item.emails && item.emails.length > 0) displayExpreValue += ` - ${item.emails[0].email}`
                  }
                  return displayExpreValue
                },
                placeholder: 'Seleziona riferimenti...',
                applyValueMode: 'instantly',
                showSelectionControls: true,
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="data"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
              }}
              editorType="dxDateBox"
              label={{ text: 'Data' }}
            >
              <RequiredRule message="Data obbligatoria" />
            </SimpleItem>
            <SimpleItem
              dataField="impiegati"
              editorType="dxTagBox"
              label={{ text: 'Tecnici' }}
              editorOptions={{
                dataSource: ArraySourceComposer('id', technicians),
                displayExpr: 'fullname',
                placeholder: 'Seleziona tecnici...',
                applyValueMode: 'instantly',
                showSelectionControls: true,
              }}
            >
              <RequiredRule message="Necessario specificare almeno un tecnico" />
            </SimpleItem>
            <SimpleItem
              dataField="durata_viaggio"
              editorType="dxNumberBox"
              label={{ text: 'Ore Viaggio' }}
              editorOptions={{
                showSpinButtons: true,
                min: 0,
                step: 0.5,
              }}
            >
              <RequiredRule message="Ore Viaggio obbligatorio" />
              <CustomRule
                message={"Le ore di viaggio devono essere una valore maggiore di 0 se l'intervento non è remoto"}
                validationCallback={({ value }: ValidationCallbackData) => {
                  if (remote) return true
                  if (value) {
                    return value > 0
                  }
                  return false
                }}
              />
            </SimpleItem>
            <GroupItem colCount={4} colSpan={2}>
              <SimpleItem
                dataField="mattina_inizio"
                editorOptions={{
                  displayFormat: 'HH:mm',
                  type: 'time',
                  pickerType: 'list',
                  interval: 15,
                  min: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 5, 0),
                  max: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 14, 0),
                  showClearButton: true,
                }}
                editorType="dxDateBox"
                label={{ text: 'Mattino dalle' }}
              >
                <CustomRule
                  message="L'orario di inizio deve essere impostato e deve essere minore dell'orario di fine"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    if (intervention?.mattina_fine === undefined || intervention?.mattina_fine === null) return true
                    if (
                      intervention.mattina_fine !== undefined &&
                      intervention.mattina_fine !== null &&
                      (value === undefined || value === null)
                    )
                      return false
                    const startDate = new Date(value)
                    const endDate = new Date(intervention.mattina_fine)
                    console.log('MORNING VALIDATION', endDate, startDate, value)
                    return endDate > startDate
                  }}
                />
              </SimpleItem>
              <SimpleItem
                dataField="mattina_fine"
                editorOptions={{
                  displayFormat: 'HH:mm',
                  type: 'time',
                  pickerType: 'list',
                  interval: 15,
                  min: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 5, 0),
                  max: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 14, 0),
                  showClearButton: true,
                }}
                editorType="dxDateBox"
                label={{ text: 'Mattino alle' }}
              >
                <CustomRule
                  message="L'orario di fine deve essere impostato e deve essere maggiore dell'orario di inizio"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    if (intervention?.mattina_inizio === undefined || intervention?.mattina_inizio === null) return true
                    if (
                      intervention.mattina_inizio !== undefined &&
                      intervention.mattina_inizio !== null &&
                      (value === undefined || value === null)
                    )
                      return false
                    const startDate = new Date(intervention.mattina_inizio)
                    const endDate = new Date(value)
                    return startDate < endDate
                  }}
                />
              </SimpleItem>
              <SimpleItem
                dataField="pomeriggio_inizio"
                editorOptions={{
                  displayFormat: 'HH:mm',
                  type: 'time',
                  pickerType: 'list',
                  interval: 15,
                  min: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 11, 30),
                  max: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 45),
                  showClearButton: true,
                }}
                editorType="dxDateBox"
                label={{ text: 'Pomeriggio dalle' }}
              >
                <CustomRule
                  message="L'orario di inizio deve essere impostato e deve essere minore dell'orario di fine"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    if (intervention?.pomeriggio_fine === undefined || intervention?.pomeriggio_fine === null)
                      return true
                    if (
                      intervention.pomeriggio_fine !== undefined &&
                      intervention.pomeriggio_fine !== null &&
                      (value === undefined || value === null)
                    )
                      return false
                    const startDate = new Date(value)
                    const endDate = new Date(intervention.pomeriggio_fine)
                    return endDate > startDate
                  }}
                />
              </SimpleItem>
              <SimpleItem
                dataField="pomeriggio_fine"
                editorOptions={{
                  displayFormat: 'HH:mm',
                  type: 'time',
                  pickerType: 'list',
                  interval: 15,
                  min: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 11, 30),
                  max: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 45),
                  showClearButton: true,
                }}
                editorType="dxDateBox"
                label={{ text: 'Pomeriggio alle' }}
              >
                <CustomRule
                  message="L'orario di fine deve essere impostato e deve essere maggiore dell'orario di inizio"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    if (intervention?.pomeriggio_inizio === undefined || intervention?.pomeriggio_inizio === null)
                      return true
                    if (
                      intervention.pomeriggio_inizio !== undefined &&
                      intervention.pomeriggio_inizio !== null &&
                      (value === undefined || value === null)
                    )
                      return false
                    const startDate = new Date(intervention.pomeriggio_inizio)
                    const endDate = new Date(value)
                    return startDate < endDate
                  }}
                />
              </SimpleItem>
            </GroupItem>
            <SimpleItem
              dataField="task"
              label={{ text: 'Task' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: tasksDataSource,
                placeholder: 'Seleziona il task...',
                displayExpr: 'ded_Dis',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare il task',
                  hideOnOutsideClick: true,
                },
                readOnly: creating && taskId,
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="stato_fatturazione"
              label={{ text: 'Fatturazione' }}
              editorType="dxSelectBox"
              editorOptions={{
                dataSource: ArraySourceComposer('id', billingStates),
                placeholder: 'Seleziona lo stato...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare lo stato',
                  hideOnOutsideClick: true,
                },
                readOnly: !isRoleAllowed,
                buttons:
                  packageUsageId === undefined
                    ? [{ name: 'dropDown', location: 'after' }]
                    : [
                        {
                          name: 'check',
                          location: 'after',
                          options: {
                            disabled: false,
                            visible: true,
                            stylingMode: 'text',
                            icon: 'check',
                            onClick: handleOnClickFatturazione,
                          },
                        },
                        { name: 'dropDown', location: 'after' },
                      ],
              }}
            >
              <RequiredRule message="Fatturazione obbligatorio"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="remote"
              label={{ text: 'Remoto' }}
              editorType="dxSwitch"
              editorOptions={{
                onValueChanged: (e: ValueChangedEventSwitch) => {
                  setRemote(e.value)
                },
              }}
            />
            <SimpleItem
              dataField="locale"
              label={{ text: 'Locale' }}
              editorType="dxRadioGroup"
              editorOptions={{ items: localeItems, valueExpr: 'id', displayExpr: 'value', layout: 'horizontal' }}
            />
            {packageUsageId !== undefined && (
              <SimpleItem
                colSpan={2}
                dataField={'note'}
                editorType={'dxHtmlEditor'}
                label={{ visible: true, text: 'Note' }}
                editorOptions={{
                  stylingMode: 'filled',
                  valueType: 'html',
                  toolbar: toolbarHtmlEditor(currentScreenSize),
                  mediaResizing: {
                    enabled: true,
                  },
                  imageUpload: {
                    tabs: ['file', 'url'],
                    fileUploadMode: 'base64',
                  },
                  height: '330',
                }}
              >
                <RequiredRule message="Note obbligatorie"></RequiredRule>
              </SimpleItem>
            )}
          </GroupItem>
          {packageUsageId === undefined && (
            <GroupItem colSpan={3}>
              <SimpleItem
                dataField={'note'}
                editorType={'dxHtmlEditor'}
                label={{ visible: true, text: 'Note' }}
                editorOptions={{
                  stylingMode: 'filled',
                  valueType: 'html',
                  toolbar: toolbarHtmlEditor(currentScreenSize),
                  mediaResizing: {
                    enabled: true,
                  },
                  imageUpload: {
                    tabs: ['file', 'url'],
                    fileUploadMode: 'base64',
                  },
                  height: '330',
                }}
              >
                <RequiredRule message="Note obbligatorie"></RequiredRule>
              </SimpleItem>
            </GroupItem>
          )}
        </GroupItem>
        {packageUsageId === undefined && (
          <GroupItem visible={isRoleAllowed} cssClass="last-group">
            <GroupItem cssClass="buttons-group" colCount={creating ? 3 : 4}>
              <ButtonItem name="btn-sendEmail">
                <ButtonOptions
                  text="Invia Rapporto"
                  icon="email"
                  useSubmitBehavior={false}
                  width={'160px'}
                  onClick={handleOnClickSendEmail}
                  disabled={creating}
                />
              </ButtonItem>
              <ButtonItem name="btn-exportPDF">
                <ButtonOptions
                  text="esporta pdf"
                  icon="pdffile"
                  useSubmitBehavior={false}
                  width={'160px'}
                  onClick={handleOnClickExportPDF}
                  disabled={creating}
                />
              </ButtonItem>
              <ButtonItem name="btn-save">
                <ButtonOptions
                  icon="save"
                  disabled={!formRef.current?.instance().option().isDirty}
                  text="Salva"
                  width="160px"
                  onClick={async () => {
                    const validationResult = formRef.current?.instance().validate()
                    if (!validationResult?.isValid) return
                    console.log('validation ok')
                    if (intervention) {
                      console.log('intervention', intervention)
                      fetcher?.submit(JSON.stringify(intervention), {
                        method: creating ? 'POST' : 'PUT',
                        encType: 'application/json',
                      })
                    } else console.error('no data tu submit')
                  }}
                />
              </ButtonItem>
              {!creating && (
                <ButtonItem name="btn-cancel">
                  <ButtonOptions
                    disabled={!formRef.current?.instance().option().isDirty}
                    width="160px"
                    text="Annulla"
                    icon="undo"
                    onClick={() => {
                      revalidator.revalidate()
                    }}
                  />
                </ButtonItem>
              )}
            </GroupItem>
          </GroupItem>
        )}
      </DXForm>
      <FormPopupClientNote
        data={currentSite}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites(currentCompany?.id).then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              formRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === currentSite?.id
                }),
              )
            })
          }
        }}
        isRoleAllowed={isUserRoleAllowed(userInfo?.roles, [
          Roles.TechDeveloper,
          Roles.Sales,
          Roles.GlobalAdministrator,
          Roles.ExternalSales,
          Roles.Administrator,
        ])}
      ></FormPopupClientNote>
      {packageUsageId === undefined && (
        <Popup
          ref={refNuovoContattoPopup}
          id="nuovo_contatto_popup"
          dragEnabled={false}
          hideOnOutsideClick={false}
          showCloseButton={true}
          showTitle={true}
          title={`Nuovo contatto`}
          container=".dx-viewport"
          resizeEnabled={true}
          width={currentScreenSize.isLarge || currentScreenSize.isMedium ? 900 : currentScreenSize.isXSmall ? 350 : 700}
          height={currentScreenSize.isSmall ? 300 : 700}
        >
          <ContactEditorForm
            contact={currentContact}
            rapporti={currentRapporti}
            aziende={currentCompany ? [currentCompany] : []}
            sedi={currentContact && currentContact.sede ? [currentContact.sede] : []}
            creating={true}
            onDataReset={onContactReset}
            onDataSave={onContactCreate}
          ></ContactEditorForm>
        </Popup>
      )}
    </>
  )
}

export default InterventionEditorForm
