import { useAzureManager } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import PlatformsEditor from '@/routes/costcenters/platforms/PlatformsEditor'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { RouteObject } from 'react-router-dom'
import { LoaderFunction, defer } from 'react-router-typesafe'

const PlatformsEditorRoute = {
  path: 'platforms',
  element: <PlatformsEditor />,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default PlatformsEditorRoute
