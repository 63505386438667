import { isAxiosError } from 'axios'
import { isRouteErrorResponse, useLocation, useRouteError } from 'react-router'

const RouterErrorHandler = () => {
  const routeError = useRouteError()
  const location = useLocation()
  console.error('routeError', routeError)
  // Function to extract error message
  const getErrorMessage = (error: unknown) => {
    // axios error
    if (isAxiosError(error)) {
      if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
        // access to parts of the error, like response
        console.log('Error axios response status:', error.response.status)
        console.log('Error axios response data:', error.response.data)
        return `${error.response.status}: ${error.response.data.error.message}`
      } else {
        // Something happened in setting up the request
        console.log('Error axios status', error.status)
        console.log('Error axios message', error.message)
        return `${error.status}: ${error.message}`
      }
    } else if (isRouteErrorResponse(error)) {
      // route error
      console.log('route error response status', error.status)
      console.log('route error response statusText', error.statusText)
      return `${error.status} ${error.statusText} ${error.data}`
    }
    console.log('unknown error')
    return `${routeError}`
  }

  return (
    <>
      <p>
        <h1
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          Si sono verificati degli errori :(
        </h1>
        <h2
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          {getErrorMessage(routeError)}
        </h2>
        {location.key !== 'default' && (
          <h3
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <span>
              Clicca <a href={location.pathname}>qui</a> per ricaricare la pagina
            </span>
          </h3>
        )}
      </p>
    </>
  )
}

export default RouterErrorHandler
