import type { ICookieStorageData } from 'amazon-cognito-identity-js'
import { CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js'

export const authEndpoint = 'qsadminauth.qsinformatica.it'
// export const authEndpoint = 'd3vaq423rt28u8.cloudfront.net'
// eslint-disable-next-line no-secrets/no-secrets
export const clientId = '20j2cqbhulrppckpouf1t4rps4'
export const userPoolRegion = 'eu-south-1'
export const userPoolId = `${userPoolRegion}_WwNcgO8yW`
export const identityPoolId = `${userPoolRegion}:e80fc746-f53c-48d6-8804-1e1519a6d499`
export const activeHostname = import.meta.env.PROD ? 'qswebadmin.qsinformatica.it' : 'localhost'
export const activeDomain = import.meta.env.PROD ? `https://${activeHostname}` : `http://${activeHostname}:3000`
export const redirectUri = `${activeDomain}/authenticated`
export const idp = `QsInformaticaAzure`
export const cognitoCookieStorageConfiguration: ICookieStorageData = {
  domain: activeHostname,
  secure: import.meta.env.PROD,
  path: '/',
  expires: 30,
  sameSite: 'strict',
}

export const userPool = new CognitoUserPool({
  UserPoolId: userPoolId,
  ClientId: clientId,
  Storage: new CookieStorage(cognitoCookieStorageConfiguration),
})
export const loginData = {
  clientId,
  responseType: 'code', // or 'token
  redirectUri,
  idp,
}
