import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { SoftwareAdskEditor } from '@/routes/software/adsk/SoftwareAdskEditor'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { FormMode } from '@/enums'
import { LoaderFunction, Params, RouteObject, redirect } from 'react-router-dom'
import { defer, makeAction } from 'react-router-typesafe'
import { HttpResponseModel } from '@odata2ts/http-client-api'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { Editablead_subscription, ad_subscription } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { Role, Roles } from '@/auth/azure/Roles'
import { getAzureUserInformation } from '@/auth/azure/azureManager'

export const softwareAdskRouteLoader = (async ({ request, params }: { request: Request; params: Params }) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  let getNewSwAdsk:
    | Editablead_subscription
    | Promise<HttpResponseModel<ODataModelResponseV4<ad_subscription>>>
    | undefined

  if (params.swAdskId) {
    const currentSwId = params.swAdskId
    if (Number.isNaN(currentSwId)) throw new Error('Invalid swAdskId')
    getNewSwAdsk = qsAdminApi.ad_subscription(Number(currentSwId)).query((builder, qqs_licenza) => {
      builder.expanding('csn', (csnBuilder, csn) => {
        csnBuilder.expand('azienda')
      })
    })

    const clientId = params.clientId
    if (clientId) {
      const swAdskResp = await getNewSwAdsk
      const swAdsk = swAdskResp.data satisfies ad_subscription
      if (swAdsk?.csn?.azienda && swAdsk?.csn?.azienda.id !== Number(clientId)) {
        throw new Error(
          `Il software ${swAdsk.serialNumber} appartiene al cliente ${swAdsk?.csn?.azienda.nome} e non al cliente corrente`,
        )
      }
    }
  }

  const getClientList = qsAdminApi.azienda().query((builder, azienda) => {
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
  })

  return defer({
    getNewSwAdsk,
    getClientList,
    getUserInfo: getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor],
  })
}) satisfies LoaderFunction

export const SoftwareAdskEditorRoute = {
  path: ':swAdskId',
  element: <SoftwareAdskEditor mode={FormMode.Edit} />,
  loader: softwareAdskRouteLoader,
  action: makeAction(async ({ request, params }) => {
    const swAdskData = (await request.json()) as ad_subscription
    const res = await useQsAdminApiManager.getState().service.ad_subscription(swAdskData.id).patch(swAdskData)
    return null
  }),
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export const SoftwareAdskCreatorRoute = {
  path: 'new',
  element: <SoftwareAdskEditor mode={FormMode.Create} />,
  loader: softwareAdskRouteLoader,
  index: true,
  action: makeAction(async ({ request, params }: RouteFunctionParams) => {
    const swAdskData = (await request.json()) as ad_subscription
    const res = await useQsAdminApiManager.getState().service.ad_subscription().create(swAdskData)
    return redirect(`../${res.data.id}`)
  }),
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
