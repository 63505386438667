import { RouteObject } from 'react-router-dom'
import { meetingEditorRouteAction, meetingEditorRouteLoader } from '@/routes/meetings/MeetingEditor.route'
import MeetingEditor from '@/routes/meetings/MeetingEditor'
import { RoleRouteObject } from '@/types'
import { Roles } from '@/auth/azure/Roles'

export const MeetingCreatorRoute: RouteObject = {
  path: 'new',
  element: <MeetingEditor creating={true} />,
  loader: meetingEditorRouteLoader,
  action: meetingEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
