import { DataGridTypes } from 'devextreme-react/cjs/data-grid'
import { Form as DXForm, GroupItem, SimpleItem, Tab, TabbedItem } from 'devextreme-react/form'
import type { riunione } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { FileList, MgtTemplateProps } from '@microsoft/mgt-react'
import { LoadIndicator } from 'devextreme-react'
import DocumentCreator from '@/components/document/DocumentCreator'
import { useRef } from 'react'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { DocumentPermissions } from '@/enums'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'

export function MeetingBriefDetail(props: DataGridTypes.MasterDetailTemplateData) {
  const qsAdminApi = useQsAdminApiManager.getState().service
  const { client } = useQsAdminApiManager()
  const meeting: riunione = props.data.data
  let path
  if (meeting.attachmentsFolderUrl) {
    path = meeting.attachmentsFolderUrl
  }
  const verbaleGetRef = useRef<any>(null)
  const MyEvent = (props: MgtTemplateProps) => {
    const { template } = props
    switch (template) {
      case 'no-data': {
        return <div>Nessun allegato caricato</div>
      }
      case 'loading': {
        return <LoadIndicator id="small-indicator" height={20} width={20} />
      }
      default: {
        break
      }
    }
  }
  return (
    <div className={'dx-card responsive-paddings'}>
      <DXForm id={'riunione_brief_detail_form'} formData={meeting} labelLocation={'top'} readOnly={true}>
        <GroupItem colCount={5} colSpan={5}>
          <GroupItem colCount={1} colSpan={5}>
            <TabbedItem>
              <Tab title="Verbale">
                <DocumentCreator
                  title="Verbale"
                  documentUrl={meeting.mainDocumentUrl}
                  documentGetRef={verbaleGetRef}
                  action={async () => {
                    await qsAdminApi.riunione(meeting.id).CreateDocument()
                  }}
                  preliminarPermissionsAction={async () => {
                    try {
                      const response = await client.post(
                        '/api/riunione/drivepermissions',
                        {
                          objectId: meeting.id,
                          path: meeting.mainDocumentUrl,
                        },
                        {
                          headers: {
                            'Content-Type': 'application/json',
                          },
                        },
                      )
                      return response.status === 200 ? (response.data as DocumentPermissions) : DocumentPermissions.Deny
                    } catch (error) {
                      console.log('Errore in settaggio permessi sul documento riunione', error)
                      return DocumentPermissions.Deny
                    }
                  }}
                />
              </Tab>
              <Tab title="Argomento">
                <SimpleItem dataField={'argomento'} editorType={'dxTextArea'} label={{ visible: false }} />
              </Tab>
              <Tab title="Allegati">
                <FileList
                  driveId={import.meta.env.VITE_QSADMINSITE_DRIVE_ID}
                  itemPath={path}
                  itemView="twolines"
                  pageSize={3}
                  draggable={true}
                >
                  <MyEvent template="no-data" />
                  <MyEvent template="loading" />
                </FileList>
              </Tab>
            </TabbedItem>
          </GroupItem>
        </GroupItem>
      </DXForm>
    </div>
  )
}
