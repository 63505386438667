import { Roles } from '@/auth/azure/Roles'
import TicketEditor from '@/routes/tickets/TicketEditor'
import { ticketEditorRouteAction, ticketEditorRouteLoader } from '@/routes/tickets/TicketEditor.route'
import { RoleRouteObject } from '@/types'
import { RouteObject } from 'react-router-dom'

const TicketCreatorRoute = {
  path: 'new',
  element: <TicketEditor creating={true} />,
  loader: ticketEditorRouteLoader,
  action: ticketEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default TicketCreatorRoute
