import { TicketEditorProps } from '@/routes/tickets/TicketEditor.types'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { Suspense } from 'react'
import { useFetcher, useParams } from 'react-router-dom'
import { Await, useLoaderData } from 'react-router-typesafe'
import { ticketEditorRouteLoader } from '@/routes/tickets/TicketEditor.route'
import TicketEditorForm from '@/routes/tickets/TicketEditor.form'
import { Rating, ThinRoundedStar } from '@smastrom/react-rating'
import { StatoTicket } from '@/routes/tickets/TicketEditor.enums'
import { isUserRoleAllowed } from '@/routes/utils'

export const TicketEditor = (props: TicketEditorProps) => {
  const { creating } = props

  const { clientId } = useParams()

  const {
    ticket,
    getClients,
    getStates,
    getSupportApplications,
    getCategories,
    getPlatforms,
    getProblemCategories,
    getEmployees,
    getProblemArguments,
    getUserInfo,
    defaultCRUDAllowedRoles,
  } = useLoaderData<typeof ticketEditorRouteLoader>()
  const title: string = creating ? `Nuovo ticket` : `Ticket ${ticket?.ded_Dis}`

  const fetcher = useFetcher()
  return (
    <>
      <Suspense fallback={<LoadingPanel />}>
        <Await
          resolve={Promise.all([
            getClients,
            getStates,
            getSupportApplications,
            getCategories,
            getPlatforms,
            getProblemCategories,
            getEmployees,
            getProblemArguments,
            getUserInfo,
          ])}
        >
          {([
            clients,
            states,
            supportApplications,
            categories,
            platforms,
            problemCategories,
            employees,
            problemArguments,
            userInfo,
          ]) => (
            <>
              <h2 className={clientId ? '' : 'content-block'}>
                {title}
                {ticket.stato?.id === StatoTicket.COMPLETATO && ticket.rating && (
                  <Rating
                    style={{ width: '5%' }}
                    value={ticket.rating}
                    readOnly={true}
                    itemStyles={{
                      itemShapes: ThinRoundedStar,
                      activeFillColor: '#ffb700',
                      inactiveFillColor: '#fbf1a9',
                    }}
                  />
                )}
              </h2>

              <div
                id="editor-ticket-container"
                className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
              >
                <fetcher.Form>
                  <TicketEditorForm
                    creating={creating}
                    ticket={ticket}
                    clients={clients.data.value}
                    states={states.data.value}
                    applications={supportApplications.data.value}
                    categories={categories.data.value}
                    platforms={platforms.data.value}
                    problemCategories={problemCategories.data.value}
                    employees={employees.data.value}
                    problemArguments={problemArguments?.data.value}
                    isRoleAllowed={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                    userInfo={userInfo}
                    fetcher={fetcher}
                  />
                </fetcher.Form>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default TicketEditor
