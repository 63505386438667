import PackageUsageEditor from '@/routes/packages/usage/PackageUsageEditor'
import {
  packageUsageEditorRouteAction,
  packageUsageEditorRouteLoader,
} from '@/routes/packages/usage/PackageUsageEditor.route'
import { RoleRouteObject } from '@/types'
import { Roles } from '@/auth/azure/Roles'

export const PackageUsageCreatorRoute = {
  path: 'new',
  element: <PackageUsageEditor creating={true} />,
  loader: packageUsageEditorRouteLoader,
  action: packageUsageEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
