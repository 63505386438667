import { RoleRoute, RoleRouteObject } from '@/types'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import CostCentersPage from '@/routes/costcenters/CostCentersPage'
import CostCentersEditorRoute from '@/routes/costcenters/CostCentersEditor.route'
import PlatformsEditorRoute from '@/routes/costcenters/platforms/PlatformsEditor.route'
import CausalsEditorRoute from '@/routes/costcenters/causals/CausalsEditor.route'
import { Roles } from '@/auth/azure/Roles'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import { isUserRoleAllowed } from '@/routes/utils'

export const costCentersPageRouteLoader = (async () => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  const userInfo = await getAzureUserInformation()

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  const getCentriCosto = qsAdminApi.centro_costo().query((builder, centro_costo) => {
    builder.select('id', 'nome')
    builder.orderBy(centro_costo.nome.asc())
  })

  const getPiattaforme = qsAdminApi.piattaforma_attivita_lavorativa().query((builder, piattaforma) => {
    builder.select('id', 'nome')
    builder.orderBy(piattaforma.nome.asc())
  })

  const getTasks = qsAdminApi.task().query((builder, task) => {
    builder.select('id', 'ded_Dis')
    builder.orderBy(task.ded_Dis.desc())
  })

  const getImpiegati = await qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname', 'reparto')
    builder.expanding('reparto', (repartoBuilder, reparto) => {
      repartoBuilder.select('id', 'nome')
    })
    builder.filter(
      isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor])
        ? null
        : impiegato.email.eq(userInfo?.email ?? ''),
    )
    builder.orderBy(impiegato.fullname.asc())
  })
  const getReparti = qsAdminApi.reparto().query((builder, reparto) => {
    builder.select('id', 'nome')
    builder.filter(
      isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor])
        ? null
        : reparto.id.eq(Number(getImpiegati.data.value[0].reparto?.id)),
    )
    builder.orderBy(reparto.nome.asc())
  })

  return defer({
    centriCosto: await getCentriCosto,
    aziende: await getAziende,
    piattaforme: await getPiattaforme,
    tasks: await getTasks,
    reparti: await getReparti,
    userInfo: await getAzureUserInformation(),
    impiegati: getImpiegati,
  })
}) satisfies LoaderFunction

export const CostCentersPageRoute: RoleRoute = {
  path: 'costcenters',
  children: [
    {
      index: true,
      element: <CostCentersPage />,
      loader: costCentersPageRouteLoader,
      allowedRoles: [
        Roles.GlobalAdministrator,
        Roles.Administrator,
        Roles.Marketing,
        Roles.TechDeveloper,
        Roles.Sales,
        Roles.Supervisor,
      ],
    } as RoleRouteObject,
    CostCentersEditorRoute,
    PlatformsEditorRoute,
    CausalsEditorRoute,
  ],
  errorElement: <RouterErrorHandler />,
  text: 'Centri di costo',
  icon: 'gift',
  mainMenuRoute: true,
  allowedRoles: [
    Roles.GlobalAdministrator,
    Roles.Administrator,
    Roles.Marketing,
    Roles.TechDeveloper,
    Roles.Sales,
    Roles.Supervisor,
  ],
}
