import { useCallback, useEffect, useState } from 'react'
import { useScreenSize } from '@/themes/media-query'
import { isIPerson } from './utils'
import { Button } from 'devextreme-react/button'
import type {
  contatto_aziendale,
  entita_aziendale,
  sede,
  azienda,
  rapporto,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import classNames from 'classnames'
import { Item as ToolbarItem, Toolbar } from 'devextreme-react/toolbar'
import ContactEditorForm from '@/routes/contacts/ContactEditor.form'

type ContactEditorPanelFormProps = {
  contact: contatto_aziendale | entita_aziendale
  isOpened: boolean
  changePanelOpened: (value: boolean) => void
  changePanelPinned: (value: boolean) => void
  onDataReset: (data: contatto_aziendale | entita_aziendale) => Promise<boolean>
  onDataSave: (data: contatto_aziendale | entita_aziendale) => Promise<boolean>
  rapporti: rapporto[]
  aziende: azienda[]
  sedi: sede[]
  creating?: boolean
  readOnly?: boolean
}

export const ContactEditorPanelForm = (props: ContactEditorPanelFormProps) => {
  const {
    contact,
    isOpened,
    changePanelOpened,
    changePanelPinned,
    onDataReset,
    onDataSave,
    rapporti,
    aziende,
    sedi,
    creating,
    readOnly,
  } = props
  const [isPinned, setIsPinned] = useState(false)
  const { isLarge, isMedium } = useScreenSize()

  useEffect(() => {
    changePanelPinned(isPinned)
  }, [isPinned, changePanelPinned])

  const onPinClick = useCallback(() => {
    setIsPinned(!isPinned)
  }, [isPinned])

  const onClosePanelClick = () => {
    setIsPinned(false)
    changePanelOpened(false)
  }

  const RenderTitle = () => {
    if (isIPerson(contact)) {
      let title = ''
      if (contact.nome) {
        title = contact.nome
      }
      title = `${title} ${contact.cognome ?? ''}`
      return title.trim()
    } else {
      return contact.nome ?? ''
    }
  }

  return (
    <>
      <div
        id="contact-panel"
        className={classNames({
          panel: true,
          open: isOpened,
          pin: isPinned && (isLarge || isMedium),
        })}
      >
        <div className="data-wrapper">
          <Toolbar className="panel-toolbar">
            <ToolbarItem location="before">
              <span className="contact-name value">{RenderTitle()}</span>
            </ToolbarItem>
            <ToolbarItem location="before">{/* <ContactStatus text={contact.status} /> */}</ToolbarItem>
            <ToolbarItem location="after" visible={isLarge || isMedium}>
              <Button icon={isPinned ? 'pin' : 'unpin'} stylingMode="text" onClick={onPinClick} />
            </ToolbarItem>
            <ToolbarItem location="after">
              <Button icon="close" stylingMode="text" onClick={onClosePanelClick} />
            </ToolbarItem>
          </Toolbar>
          <ContactEditorForm
            contact={contact}
            rapporti={rapporti}
            aziende={aziende}
            sedi={sedi}
            creating={creating}
            onDataReset={onDataReset}
            onDataSave={onDataSave}
            readOnly={readOnly}
          ></ContactEditorForm>
        </div>
      </div>
    </>
  )
}
