import { Roles } from '@/auth/azure/Roles'
import OfferEditor from '@/routes/offers/OfferEditor'
import { offerEditorRouteAction, offerEditorRouteLoader } from '@/routes/offers/OfferEditor.route'
import { RoleRouteObject } from '@/types'
import { RouteObject } from 'react-router-dom'

const OfferCreatorRoute = {
  path: 'new',
  element: <OfferEditor creating={true} />,
  loader: offerEditorRouteLoader,
  action: offerEditorRouteAction,
  allowedRoles: [Roles.Sales, Roles.Administrator, Roles.GlobalAdministrator, Roles.Supervisor],
} as RoleRouteObject

export default OfferCreatorRoute
