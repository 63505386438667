import { ArraySourceComposer } from '@/auth/api/connector'
import { AzureUserInfo } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import { GridCellColor } from '@/enums'
import { ad_quote, ordine } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { isUserRoleAllowed } from '@/routes/utils'
import { useScreenSize } from '@/themes/media-query'
import { DataGrid } from 'devextreme-react'
import { Column, Paging, Button as GridButton, StateStoring, DataGridTypes } from 'devextreme-react/cjs/data-grid'
import { CellPreparedEvent, ColumnButtonClickEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import { Link } from 'react-router-dom'

const ProjectEditorFormQuotesGrid = (props: { quotes?: ad_quote[]; userInfo: AzureUserInfo | undefined }) => {
  const { quotes, userInfo } = props
  const currentScreenSize = useScreenSize()
  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'status' && e.value) {
      switch (e.value) {
        case 'ORDERED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.EMERALD}`
          break
        }
        case 'UNDER REVIEW': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`
          break
        }
        case 'QUOTED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.CORNFLOWERBLUE}`
          break
        }

        case 'DRAFT': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.GREY}`
          break
        }
        case 'FINALIZING': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.VIOLET}`
          break
        }
        case 'EXPIRED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.BROWN}`
          break
        }
        case 'CANCELLED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.SALMON}`
          break
        }
        case 'CANCELLING': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.ORANGE}`
          break
        }
        case 'FAILED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.RED}`
          break
        }
        case 'ORDER SUBMITTED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.LILAC}`
          break
        }
      }
    }
  }

  return (
    <>
      <DataGrid
        id={`project-quotes-grid`}
        dataSource={ArraySourceComposer('id', quotes ?? [])}
        noDataText="Nessuna quota associata alla commessa"
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onCellPrepared={onCellPrepared}
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') window.open(`/quotes/${e.data.id}`, '_blank')
        }}
      >
        <StateStoring enabled={true} type="localStorage" storageKey={'project-quotes-datagrid'} savingTimeout={50} />
        <Paging defaultPageSize={8} />
        <Column
          type="buttons"
          width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
          alignment="left"
        >
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              window.open(`/quotes/${e.row?.data.id}`, '_blank')
            }}
          />
        </Column>
        <Column
          dataField={'id'}
          caption={'ID'}
          visible={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
          }
        />
        ,
        <Column
          dataField="transactionId"
          caption="ID TRANSACTION"
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: ad_quote = cellData.row.data
            return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
              data.number && data.number !== 'NA' ? (
                <>{data.transactionId?.toString()}</>
              ) : (
                <>
                  <Link to={`/quotes/${data.id}`}>{data.transactionId?.toString()}</Link>
                </>
              )
            ) : (
              <>{data.transactionId?.toString()}</>
            )
          }}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
          }
        />
        <Column
          dataField="number"
          caption="NUMBER"
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: ad_quote = cellData.row.data
            return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
              data.number === 'NA' ? (
                <>{data.number}</>
              ) : (
                <>
                  <Link to={`/quotes/${data.id}`}>{data.number}</Link>
                </>
              )
            ) : (
              <>{data.number}</>
            )
          }}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
          }
        />
        <Column
          dataField="csn.csn"
          caption="CSN"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
          }
        />
        <Column
          dataField="status"
          caption="STATO"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
          }
        />
        <Column
          dataField="contact.fullname"
          caption="CONTATTO"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
          }
        />
        <Column
          dataField="admin.fullname"
          caption="AMMINISTRATORE"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
          }
        />
        <Column
          dataField="auto"
          caption="AUTOMATICA"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined
          }
        />
      </DataGrid>
    </>
  )
}

export default ProjectEditorFormQuotesGrid
